import { Alert } from "@mui/material";
import InfoIcon from '@mui/icons-material/Info';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import { useNavigate } from "react-router-dom";
import { useMemo } from "react";

export default function SystemAnalyzeResult() {

    const { t } = useTranslation()
    const navigate = useNavigate()
    const { list: serversAdvices, loading } = useSelector(store => store?.serverAnalyze)

    const routes = ["transcoder", "streamer", "archiver", "VOD"]

    const haveAdvices = useMemo(() => { return serversAdvices.some(item => item.some(item => item.advices?.length > 0)) }, [serversAdvices])

    if (!haveAdvices) {
        return null
    }

    return <MDBox mb={5}>
        {serversAdvices?.map((servers, i) => {
            return servers?.map((advice) => {
                if (advice?.advices?.length > 0) {
                    return <MDBox key={advice._id} borderRadius="10px" style={{ border: "2px solid #ed4337" }} variant="contained" bgColor="white" py={2} px={2}  >
                        <MDBox mb={2} style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                            <MDBox style={{ display: "flex", alignItems: "center", justifyContent: "space-between", gap: "10px" }}>
                                <ErrorOutlineIcon fontSize="large" color={"error"} />
                                <strong>{advice.name}</strong>
                            </MDBox>
                            <MDButton onClick={() => { navigate(`/${routes[i]}`) }}>{t("learnMore")}</MDButton>
                        </MDBox>
                        {advice?.advices?.map(advice => (
                            <Alert key={advice.adviceKey} icon={advice.severity === "error" ? <ReportProblemIcon fontSize="inherit" /> : <InfoIcon fontSize="inherit" />} severity={advice.severity}>
                                {t(`advices.${advice.adviceKey}`, { name: advice.name, ...(advice.vars ?? {}) })}
                            </Alert>
                        ))}
                    </MDBox>
                } else{
                    return null
                }
            })
        })}
    </MDBox>
}