import React, { useMemo, useState } from 'react';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import MDButton from 'components/MDButton';
import MDTypography from 'components/MDTypography';
import { useTranslation } from 'react-i18next';
import CreateTranscode from '../../pages/transcoders/transcodes/CreateTranscode';
import CreateStream from '../../pages/streamers/streams/CreateStream';
import CreateArchive from '../../pages/archivers/archives/CreateArchive';
import CreateChannel from '../../pages/channels/CreateChannel';
import store from 'redux/store';
import { defaultResolution } from 'utils/constants';
import { useDispatch } from 'react-redux';
import { createTranscode } from 'redux/actions/transcoder';
import { createStream } from 'redux/actions/streams';
import { createArchive } from 'redux/actions/archiver';
import { createChannel } from 'redux/actions/channels';
import MDBox from 'components/MDBox';
import { ClipLoader } from 'react-spinners';

const getStreamDataFromTranscode = (transcode) => {
    return {
        name: transcode.name,
        inputs: transcode.resolution.map(it => {
            const url = `${transcode.transcoderInfo.host}/ramdisk/${transcode.name}/${it.resolution.replaceAll(':', 'x')}/index.m3u8`
            return {
                input: url,
                resolution: it,
            }
        })
    }
}

const getArchiverDataFromStream = (stream) => {
    const hierarchy = ["FullHD", "HD", "SD"]
    let foundInput
    for (let i = 0; i < hierarchy.length; i++) {
        foundInput = stream.inputs.find(it => it.resolution.name === hierarchy[i])
        if (foundInput) {
            break;
        }
    }
    if (!foundInput) {
        foundInput = stream.inputs[0]
    }
    return {
        name: stream.name,
        input: foundInput.input
    }
}

const getChannelDataFromStream = (stream) => {
    const resolutions = stream.inputs.map(item => {
        return {
            input: `${stream.streamerInfo.host}/streams/${stream.name}/${item.resolution.name}/index.m3u8`,
            resolution: item.resolution
        }
    })
    resolutions.push({
        input: `${stream.streamerInfo.host}/streams/${stream.name}/master.m3u8`,
        resolution: defaultResolution
    })

    return {
        name: stream.name,
        resolutions: resolutions
    }
}


export default function CreateAllFlow() {
    const dispatch = useDispatch()
    const { t } = useTranslation()
    const [activeStep, setActiveStep] = React.useState(0);
    const steps = [t("createTranscode"), t("createStream"), t("createArchive"), t("createChannel")];
    const [stepData, setStepData] = useState([])
    const [loading, setLoading] = useState(null)

    const handleNext = async (values) => {
        setStepData(prev => {
            prev[activeStep] = values
            return prev
        })

        switch (activeStep) {
            case 0:
                setStepData(prev => {
                    prev[1] = getStreamDataFromTranscode(values)
                    return prev
                })
                break;
            case 1:
                setStepData(prev => {
                    prev[2] = getArchiverDataFromStream(values)
                    return prev
                })
                break;
            case 2:
                setStepData(prev => {
                    prev[3] = getChannelDataFromStream(stepData[1])
                    return prev
                })
                break;
            default:
                break;
        }
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        if (activeStep + 1 === steps.length) {
            setLoading(t("creatingTranscode"))
            await dispatch(createTranscode({...stepData[0], transcoderId: stepData[0].transcoderInfo._id}))
            setLoading(t("creatingStream"))
            await dispatch(createStream({...stepData[1], streamerId: stepData[1].streamerInfo._id}))
            setLoading(t("creatingArchive"))
            await dispatch(createArchive({...stepData[2], archiverId: stepData[2].archiverInfo._id}))
            setLoading(t("creatingChannel"))
            await dispatch(createChannel({...stepData[3]}))
            setLoading(null)
        }
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleReset = () => {
        setActiveStep(0);
    };

    const stepContent = useMemo(() => {
        switch (activeStep) {
            case 0:
                return <CreateTranscode openModal={{ modalValues: stepData[0] }} handleConfirm={handleNext} />;
            case 1:
                return <CreateStream openModal={{ modalValues: stepData[1] }} handleConfirm={handleNext} />;
            case 2:
                return <CreateArchive openModal={{ modalValues: stepData[2] }} handleConfirm={handleNext} />;
            case 3:
                return <CreateChannel openModal={{ modalValues: stepData[3] }} handleConfirm={handleNext} />;
            default:
                return 'Unknown stepIndex';
        }
    }, [activeStep])

    return (
        <div>
            <Stepper activeStep={activeStep} alternativeLabel>
                {steps.map((label) => (
                    <Step key={label}>
                        <StepLabel>{label}</StepLabel>
                    </Step>
                ))}
            </Stepper>
            <div>
                {activeStep === steps.length ? (
                    <MDBox sx={{ padding: "40px", display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", gap: "10px" }}>
                        {loading ? <>
                            <MDTypography>{loading}</MDTypography>
                            <ClipLoader />
                        </> :
                        <MDTypography variant="h5"  >{t("AllStepscompleted")}</MDTypography>
                        }
                    </MDBox>
                ) : (
                    <div>
                        {stepContent}
                        <div>
                            <MDButton
                                disabled={activeStep === 0}
                                onClick={handleBack}

                            >
                                Back
                            </MDButton>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}
