import Copy from "components/Copy";

const { Tooltip } = require("@mui/material");

const TruncateString = ({ text, copy, maxLength = 50, truncatePosition = "end" }) => {
    let truncatedText = text;

    if (text.length > maxLength) {
        const startLength = Math.ceil((maxLength - 3) / 2) - 12;
        const endLength = Math.floor((maxLength - 3) / 2) + 12;
        switch (truncatePosition) {
            case 'start':
                truncatedText = '...' + text.slice(text.length - maxLength + 3);
                break;
            case 'middle':

                truncatedText = text.slice(0, startLength) + '...' + text.slice(-endLength);
                break;
            case 'end':
            default:
                truncatedText = text.slice(0, maxLength - 3) + '...';
                break;
        }
    }

    if (text.length > maxLength) {
        return <Tooltip title={text}>
            <span>
                {truncatedText}
                {copy && <Copy str={text} />}
            </span>
        </Tooltip>
    }
    return <span>{truncatedText}</span>
};

export default TruncateString