export default {
  translations: {
    appTitle: "RippleTV",
    noDataText: "No rows to show",
    updateNow: "click to update now",
    loading: "Loading",
    noNotifications: "No notifications",
    noPermission: "You don't have corresponding permission",
    active: 'Active',
    inactive: 'Inactive',
    create: 'Create',
    forbidDelete: 'The host have saved processes delete them first if you want to delete the host.',
    EmailisnotValidated: 'Email is not validated',
    EmailisValidated: 'Email is validated',
    askDelete: 'Are you sure to delete',
    dblClick: 'Double click to delete',
    en: 'English',
    ru: 'Russian',
    am: 'Armenian',
    Search: 'Search',
    addedBy: 'Added By',
    Type: 'Payment Type',
    Source: 'Source',
    Interval: 'Interval',
    hours: 'hours',
    minutes: 'minutes',
    seconds: 'seconds',
    activeCron: 'Cron job is active',
    inactiveCron: 'Cron job is inactive',
    getSoftwareId: 'Get Software ID',
    toGetSoftwareId: 'Fill URL of server and click to Get Software ID',
    CPU_Temp: 'CPU Temp',
    CPU_average_load: 'CPU average load',
    platform: 'Platform',
    distro: 'Distro',
    release: 'Release',
    kernel: 'Kernel',
    manufacturer: 'Manufacturer',
    brand: 'Brand',
    vendor: 'Vendor',
    cores: 'Cores',
    physicalCores: 'Physical Cores',
    RAM: 'RAM',
    Used: 'Used',
    Free: 'Free',
    Advanced_mode: 'Advanced mode',
    FFmpeg: 'Command',
    Streams: 'Streams',
    Archives: 'Archives',
    Transcodes: 'Transcodes',
    View: 'View',
    learnMore: "Learn More",




    "Title": "Title",
    "message": "Message",
    "confirmText": "Confirm",
    "cancelText": "Cancel",
    "notifyAllCustomers": "Notify All Customers",
    "addNotifications": "Add Notifications",
    "notifications": "Notifications",
    versions: "Versions",
    version_error: "Invalid version format. Please enter a version in the format X.Y.Z (e.g., 1.0.5)",
    Default_devices: "Default Devices",
    createStream: "Create Stream",
    createChannel: "Create Channel",
    createArchive: "Create Archive",
    createTranscode: "Create Transcode",

    creatingTranscode: "Creating Transcode",
    creatingStream: "Creating Stream",
    creatingArchive: "Creating Archive",
    creatingChannel: "Creating Channel",
    AllStepscompleted: "All Steps Completed",
    FullFlow: "Full Flow",

    epgHintHour: 'use a number to set hour(s) for cron job',
    epgHintCron: 'use cron syntax to set interval e.g. 0 */6 * * * * (every 6 hours)',

    ShowingOfEntries: 'Showing {{entriesStart}} to {{entriesEnd}} of {{length}} entries',
    advanced_settings: 'Advanced settings',

    VOD_Categories: 'VOD Categories',
    Channels_Categories: 'Channels Categories',

    Full_Name: 'Full Name',
    Email: 'Username',
    Created_By: 'Created By',
    Role: 'Role',

    Admin_Info: "Admin Info",
    Permissions: "Permissions",
    VOD_: "VOD",
    First_Name: "First Name",
    Last_Name: "Last Name",

    ChangePassword: "Change Password",


    "Create_": "Create",
    "View_": "View",
    "Update_": "Update",
    "Delete_": "Delete",
    "Advanced Mode_": "Advanced Mode",
    "Billing_": "Billing",
    "Add Subuser_": "Add Subuser",
    "Update Subuser_": "Update Subuser",
    "Delete Subuser_": "Delete Subuser",
    "Refresh_": "Refresh",
    "justRefresh_": "Refresh",
    "Play_": "Play",
    "Update Source_": "Update Source",
    "Update Server Settings_": "Update Server Settings",
    "Change Active Status_": "Change Active Status",

    "Global Admin_": "Global Admin",
    "Moderator_": "Moderator",
    "Analyst_": "Analyst",
    "Cashier_": "Cashier",
    "Total entries": 'Total entries',
    "Show detailed info": 'Show detailed info',


    Created: 'Created',
    Updated: 'Updated',
    Deleted: 'Deleted',
    Viewed: 'Viewed',
    channel: 'channel',
    tariff: 'tariff',
    customer: 'customer',
    stream: 'stream',
    archive: 'archive',
    transcode: 'transcode',
    Configurator: 'Configurator',
    settings: 'settings',
    "Sidenav Colors": 'Sidenav Colors',
    "Sidenav Type": 'Sidenav Type',
    errorOccuired: "Error from the server",
    "Transparent": 'Transparent',
    "White": 'White',
    "Navbar Fixed": 'Navbar Fixed',
    "Light": 'Light',
    "Dark": 'Dark',

    Space: 'Memory',
    Brand: 'Brand',
    "Fan speed": 'Fan speed',
    "Power/limit": 'Power/limit',
    'GPU Temp': 'GPU Temp',
    "Software ID": 'Software ID',
    "attached Gpus": "Attached GPUs",
    "cuda Version": "Cuda Version",
    "driver Version": "Driver Version",

    "Show inline": 'Show inline',
    Old: 'Old',
    New: 'New',

    'channel(s)': "channel(s)",
    Close: 'Close',
    UserName: "Username",
    Fullname: "Full Name",
    type: 'Type',
    Autofill: 'Autofill',
    Upload: 'Upload',
    Year: 'Year',
    Delete: 'Delete',
    Saved: 'Saved',
    success_saved: 'Changes successfully saved',
    Total_watched_channels: 'Most viewed channels',
    views: 'Views',
    view_all: 'View all',
    noViews: 'No views for the specified period',
    General: 'General',
    Categories: 'Categories',
    Admins: 'Admins',
    Add_new: 'Add new',
    Defaults: 'Defaults',
    Clipboard: 'Clipboard',
    Copied: 'Copied',

    Price: 'Price',
    Raiting: 'Raiting',
    'Length_(min)': 'Length (min)',
    Genre: 'Genre',
    updated: 'Updated',
    Name: 'Name',
    archiver_URL: 'archiver URL',
    archive_duration_hours: 'archive duration (hours)',
    HLS_Time_seconds: 'HLS Time seconds',
    FFmpeg: 'FFmpeg',
    Amount: 'Amount',
    Upload_image: 'Upload image',
    Transcoder: 'Transcoder',
    Archiver: 'Archiver',
    Streamer: 'Streamer',
    Customers: 'Customers',
    Channels: 'Channels',
    Tariff: 'Tariff',
    EPG: 'EPG',
    Settings: 'Settings',
    License: 'License',
    Item_Four: 'Item Four',
    Item_Five: 'Item Five',
    Item_Six: 'Item Six',
    Item_Seven: 'Item Seven',
    Save: 'Save',
    streamer_URL: 'streamer URL',
    transcoder_URL: 'transcoder URL',
    VOD_Server_URL: 'VOD Server URL',
    Create_Account: 'Create Account',
    Account_created_successfully: 'Account created successfully',
    Go_to_login: 'Go to login',
    Create: 'Create',
    watch_history: 'Watch history',
    reorder_channels: 'Reorder channels',
    firstName: "Firstname",
    lastName: "Lastname",
    password: "Password",
    passwordConfirm: "Confirm password",
    Subusers_count: "Subusers count",
    tariff: "Tariff",
    Avatar: "Avatar",
    Upload_image: "Upload image",

    Stream_URL: 'Stream URL',
    Category: 'Category',
    Archive: 'Archive',

    Total_channels: 'Total channels',
    Total_subuser_accounts: 'Total subuser accounts',
    Total_active_VODs: 'Total active VODs',
    Revenue_this_month: 'Revenue this month',
    Total_payments_count: 'Total payments count',
    monthly_active_users: 'monthly active users',
    monthly_active_devices: 'Monthly Active Devices',
    active_devices: 'Active Devices',
    daily_active_devices: 'Daily Active Devices',
    Total_users_usage: "Top Users by Usage",
    Monthly_payments: 'Monthly payments',
    Total_User_Accounts: 'Total User Accounts',
    Monthly_User_Accounts: 'Monthly User Accounts',
    Dashboard: "Dashboard",
    Tariffs: "Tariffs",
    Channels: "Channels",
    Customers: "Customers",
    Epg: "Epg",
    Streamers: "Streamers",
    Transcoders: "Transcoders",
    Archivers: "Archivers",
    VODRoute: "VOD",
    Settings: "Settings",

    Firstname: 'Firstname',
    Lastname: 'Lastname',
    Tariff: 'Tariff',
    Max_Subusers: 'Max Subusers',
    Balance: 'Balance',
    Last_Payment_Date: 'Last Payment Date',
    Created_At: 'Created At',

    Amount: 'Amount',
    Balance_Before: 'Balance Before',
    Balance_After: 'Balance After',
    Made_By: 'Made By',
    Date: 'Date',

    Name: "Name",
    Favorite_channels: "Favorite channels",
    Language: "Language",
    Last_online_date: "Last online date",
    action: "Action",

    IP: "IP",
    Device_Brand: "Device Brand",
    Device_Model: "Device Model",
    OS: "OS",
    Date: "Date",

    Subusers: "Subusers",
    Tariff: "Tariff",
    Payments: "Payments",
    ChannelsWatched: "Channels Watched",
    usageHistory: "Usage History",
    Notes: "Notes",
    Tariff_Type: "Tariff type",
    Price: 'Price',
    Resolutions: 'Resolutions',
    Output: 'Output URL',
    server_URL: 'Server URL',
    Important: 'Important',
    own_risk_TEXT: "Use this by your own risk. this may crash the server",
    own_risk_suggest: "fill all inputs above before changing the command directly",
    sendResetEmail: "Send Reset Password Link",
    Reset: "Reset",
    passwordChanged: "Password Changed",
    'archive_duration_(hours)': 'Duration (hours)',
    'HLS_Time_(seconds)': 'Segment Size (seconds)',
    tariffs: {
      header: "Tariff plans",
      addNew: "Create new Tariff",
      freeBillTypeNoPrice: "Payment type set to free this won't take effect."
    },
    channels: {
      header: "Channels",
      addNew: "Create new channel",
      emptyChannels: "No channels to show",
    },
    customers: {
      header: "Customers",
      addNew: "Add new customer",
      empty: "No customers to show",
    },
    epg: {
      header: "EPG (Electronic program guide)",
      addNew: "Add new source or file",
      empty: "No info to show",
      updateFullList: "Update full list",
      inactiveCron: "Cron is inactive",
      activeCron: "Cron is Active",
    },
    streamer: {
      header: "Streamers",
      addNew: "Add new stream",
      addNewStreamer: "Add new streamer",
      empty: "No info to show",
      updateFullList: "Update full list",
      create: 'Create streamer',
      update: 'Update streamer',
    },
    streams: {
      header: "Streams of {{streamer}}",
      addNew: "Add new stream",
      empty: "No info to show",
      updateFullList: "Update full list",
      create: 'Create stream',
      update: 'Update stream',

    },
    VOD: {
      header: "VOD Servers",
      addNew: "Add new VOD Server",
      addNewVOD: "Add new VOD Server",
      empty: "No info to show",
      updateFullList: "Update full list",
      create: 'Create VOD',
      update: 'Update VOD',
      processNupdate: 'Process and update VOD',
      processNcreate: 'Process and create VOD',
    },
    VODServer: {
      header: "VODs of {{VOD}}",
      addNew: "Add new VOD",
      empty: "No info to show",
      updateFullList: "Update full list",
      create: 'Create VOD',
      update: 'Update VOD',
    },
    archiver: {
      header: "Archiver",
      addNew: "Add new archive",
      empty: "No info to show",
      updateFullList: "Update full list",
      addNewArchiver: "Add new archiver",
      update: 'Update archiver',
    },


    archives: {
      header: "Archives of {{archiver}}",
      addNew: "Add new archive",
      empty: "No info to show",
      updateFullList: "Update full list",
      addNewArchiver: "Add new archive",
      create: 'Create archive',
      update: 'Update archive',

    },

    channel: {
      addNew: "Add new channel",
      empty: "No info to show",
      updateFullList: "Update full list",
      addNewArchiver: "Add new channel",
      create: 'Create channel',
      update: 'Update channel',

    },

    transcoder: {
      header: "Transcoder",
      addNew: "Add new transcoder",
      empty: "No info to show",
      updateFullList: "Update full list",
      addNewTranscoder: "Add new transcoder",
      update: 'Update transcoder',
    },
    transcodes: {
      header: "Transcodes of {{transcoder}}",
      addNew: "Add new transcode",
      empty: "No info to show",
      updateFullList: "Update full list",
      addNewTranscoder: "Add new transcode",
      update: 'Update transcode',
      create: 'Create transcode',
    },
    advices: {
      cpuHighTempErr: 'Consider improving cooling solutions for the CPU.',
      cpuHighTempWarn: 'Monitor CPU temperatures, potentially high.',
      heatsink: 'Check for uneven thermal paste application or dust accumulation in the heatsink.',
      lowRam: 'Consider adding more RAM to your system.',
      Optimize: 'Optimize running applications to free up memory.',
      highNetwork: 'High network activity detected. Verify no unexpected processes are using the network.',
      GPUTempErr: `GPU ({{gpuName}}) temperature is high, consider improving cooling.`,
      GPUTempWarn: `GPU ({{gpuName}}) memory usage is high. Consider optimizing GPU workload.`,
      unreachable: 'Server is unreachable',
      unreachableLong: 'The server is unreachable. Below is the information from when the server was last alive.',
      cpuHighUsageErr: 'CPU usage is critically high at an average of {{avgLoad}}%. Immediate action required.',
      cpuHighUsageWarn: 'CPU usage is high at an average of {{avgLoad}}%. Monitor and consider optimizing processes.'
    },
    "Email is required": "Email is required",
    "provided email is incorrect": "Provided correct Email",
    "firstname is required": "firstname is required",
    "lastName is required": "Lastname is required",
    "plan is required": "Tariff is required",
    "Subusers count is required": "Subusers count is required",
    "password is required": "Password is required",
    "password confirmation is required": "Password confirmation is required",
    "Passwords do not match": "Passwords do not match",
    accessibleDevices: "Access",
    devicesCount: "Max Devices Count",
    Device: "Device",
    DeviceType: "Device Types",
    "codec_name": "Codec Name",
    "codec_long_name": "Codec Long Name",
    "display_aspect_ratio": "Display Aspect Ratio",
    "avg_frame_rate": "Average Frame Rate",
    "coded_width": "Width",
    "coded_height": "Height",
    "language": "Language",
    "title": "Title",
    "channel_layout": "Channel Layout",
    contentofVod: "Content of VOD {{name}}",
    Content: "Content",
    selectvideoaudio: "Select at least one Audio and Video",
    content_of_vod: "Content of VOD {{name}}",
    Uploaded_successfully: "Uploaded successfully!",
    Uploading: "Uploading",
    Transcoded_successfully: "Transcoded successfully!",
    Transcoding: "Transcoding",
    Upload_subtitle: "Upload Subtitles",
    Subtitle: "Subtitle",
    phone: "Phone",
    rowsPerPage: "Rows Per Page",
    customersOnline: "{{count}} Customers Online",
    refresh: "Refresh",
    AllWidgetsAdded: "No Widgets Found",
    SaveWidgets: "Save Widgets",
    EditWidgets: "Edit Widgets"
  },
};
