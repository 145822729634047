import { api } from 'api/config';
import MDBox from 'components/MDBox'
import Access from 'pages/customers/profileModal/Access';
import React, { useState } from 'react'
import { useDispatch } from 'react-redux';
import { ClipLoader } from 'react-spinners';
import { getSettings } from 'redux/actions/settings';

export default function UsersSettings({ settings, loading }) {
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(false)
  const handleSaveAccess = async (data) => {
    try {
      setIsLoading(true)
      await api.put("/settings/customerSettings", data)
      await dispatch(getSettings())
      setIsLoading(false)
    } catch (error) {
      console.log(error)
      setIsLoading(false)
    }
  }
  return (
    <MDBox>
      {loading || isLoading && <div className='loader' ><ClipLoader /></div>}
      <Access onSave={handleSaveAccess} user={{ devices: { accessibleDevices: settings.customerSettings.accessibleDevices, maxDeviceCount: settings.customerSettings.maxDeviceCount } }} />
    </MDBox>
  )
}
