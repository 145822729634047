import { Cancel, Check, DeleteForever, Edit, ExpandLess, ExpandMore } from '@mui/icons-material'
import { Collapse, IconButton, List, ListItemButton, ListItemText, TextField, Tooltip } from '@mui/material'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import MDBox from 'components/MDBox';
import MDButton from 'components/MDButton';
import { v4 } from 'uuid';
import { useDispatch } from 'react-redux';
import { ClipLoader } from 'react-spinners';
import { updateCategories } from 'redux/actions/settings';
import { updateResolutions } from 'redux/actions/settings';
import MDInput from 'components/MDInput';
import styles from './styles.module.css'
import { useTranslation } from 'react-i18next';

function createData(
    name,
    resolution,
    bitrate,
    maxRate,
    minRate,
    bufSize,
) {
    return {
        name,
        resolution,
        bitrate,
        maxRate,
        minRate,
        bufSize,
    };
}

// {resolution: '720:576', bitrate: '2.5M', maxRate: '3M', minRate: '2M', bufSize: '2.5M', id: 0, name: '720p'},
// {resolution: '1920:1080', bitrate: '5M', maxRate: '5.5M', minRate: '4M', bufSize: '5M', id: 1, name: '1080p'}, 

function Resolutions({ settings, loading }) {
    const dispatch = useDispatch()
    const {t} = useTranslation()
    const [open, setOpen] = useState(null)
    const [inEdit, setInEdit] = useState(null)
    const [categoriesChannels, setCategoriesChannels] = useState(settings?.resolutions ?? [])
    const resolutionRef = useRef(null)
    const bitrateRef = useRef(null)
    const maxRateRef = useRef(null)
    const minRateRef = useRef(null)
    const bufSizeRef = useRef(null)
    const nameRef = useRef(null)
    const [errors, setErrors] = useState({})

    useEffect(() => {
        setCategoriesChannels(settings?.resolutions ?? [])
    }, [settings?.resolutions])


    const handleClick = (val) => {
        if (open === val) {
            setOpen(null)
        } else {
            setOpen(val)
        }
    }

    const cahnnelsRows = useMemo(() => categoriesChannels.map(item => createData(
        item.name,
        item.resolution,
        item.bitrate,
        item.maxRate,
        item.minRate,
        item.bufSize,
        )), [categoriesChannels])

    const handleEdit = (item, i, data) => {
        setErrors({})
        setInEdit(`${data}_${i}`)
    }

    const handleSave = async (item, i, type) => {
        let haveError = false
        setErrors({})
        let errors = {}
        const splitted = resolutionRef.current.value?.split(':')
        if(!nameRef.current.value){
            errors = { ...errors, 'name': 'Name is required' }
            haveError = true
        }
        if(nameRef.current.value.includes(" ") || nameRef.current.value.includes("/") || nameRef.current.value.includes("\\")){
            errors = { ...errors, 'name': 'Name Can not include "/", "\\", "Space". only letters and numbers.' }
            haveError = true
        }
        if (splitted?.length !== 2 || Number.isNaN(+splitted[0]) || Number.isNaN(+splitted[1])) {
            errors = { ...errors, 'resolution': 'Resolution (e.g. 1920:1080), seperator as ":" is required' }
            haveError = true
        }
        if ((!bitrateRef.current.value.endsWith('M') && !bitrateRef.current.value.endsWith('K') && !bitrateRef.current.value.endsWith('G')) || Number.isNaN(+bitrateRef.current.value.substring(0, bitrateRef.current.value.length - 1))) {
            errors = { ...errors, 'bitrate': 'Bitrate (e.g. 5M), should end with "K", "M" or "G"' }
            haveError = true
        }
        if ((!maxRateRef.current.value.endsWith('M') && !maxRateRef.current.value.endsWith('K') && !maxRateRef.current.value.endsWith('G')) || parseFloat(maxRateRef.current.value) < parseFloat(bitrateRef.current.value) || Number.isNaN(+maxRateRef.current.value.substring(0, maxRateRef.current.value.length - 1))) {
            errors = { ...errors, 'maxRate': 'Max Rate (e.g. 5.5M), should end with "K", "M" or "G", can not be less than bitrate' }
            haveError = true
        }
        if ((!minRateRef.current.value.endsWith('M') && !minRateRef.current.value.endsWith('K') && !minRateRef.current.value.endsWith('G')) || parseFloat(minRateRef.current.value) > parseFloat(bitrateRef.current.value) || Number.isNaN(+minRateRef.current.value.substring(0, minRateRef.current.value.length - 1))) {
            errors = { ...errors, 'minRate': 'Min Rate (e.g. 5M), should end with "K", "M" or "G", can not be greater than bitrate' }
            haveError = true
        }
        if ((!bufSizeRef.current.value.endsWith('M') && !bufSizeRef.current.value.endsWith('K') && !bufSizeRef.current.value.endsWith('G')) || parseFloat(bufSizeRef.current.value) <= parseFloat(bitrateRef.current.value) || Number.isNaN(+bufSizeRef.current.value.substring(0, bufSizeRef.current.value.length - 1))) {
            errors = { ...errors, 'bufSize': 'Buffer Size (e.g. 6M), should end with "K", "M" or "G", can not be less than or equal to bitrate' }
            haveError = true
        }
        if (haveError) {
            setErrors(errors)
            return
        }
        const row = { 
            name: nameRef.current.value,
            resolution: resolutionRef.current.value,
            bitrate: bitrateRef.current.value,
            maxRate: maxRateRef.current.value,
            minRate: minRateRef.current.value,
            bufSize: bufSizeRef.current.value,
         }
        const data = type === 'c' ? [...categoriesChannels] : [...categoriesVOD]
        data.splice(i, 1, { ...data[i], ...row })
        const body = {
            endpoint: type === 'c' ? 'channels' : 'vod',
            key: type === 'c' ? 'categoriesChannels' : 'categoriesVOD',
            data: data
        }
        const onSuccess = () => { setInEdit(null) }
        await dispatch(updateResolutions(body, onSuccess))

    }

    const handleCancel = (type) => {
        setErrors({})
        const data = type === 'c' ? categoriesChannels : categoriesVOD
        const setter = type === 'c' ? setCategoriesChannels : setCategoriesVOD
        if (
            data.length &&
            !data?.[0]?.name &&
            !data?.[0]?.resolution &&
            !data?.[0]?.bitrate &&
            !data?.[0]?.maxRate &&
            !data?.[0]?.minRate &&
            !data?.[0]?.bufSize
        ) {
            setter(data.slice(1))
        }
        setInEdit(null)
    }

    const handleDelete = async (item, i, type) => {
        setErrors({})
        const data = type === 'c' ? [...categoriesChannels] : [...categoriesVOD]
        data.splice(i, 1)
        const body = {
            endpoint: type === 'c' ? 'channels' : 'vod',
            key: type === 'c' ? 'categoriesChannels' : 'categoriesVOD',
            data: data
        }
        const onSuccess = () => { setInEdit(null) }
        await dispatch(updateCategories(body, onSuccess))
    }

    const handleAddRow = (type) => {
        setErrors({})
        const data = categoriesChannels
        if (
            data.length &&
            !data?.[0]?.name &&
            !data?.[0]?.resolution &&
            !data?.[0]?.bitrate &&
            !data?.[0]?.maxRate &&
            !data?.[0]?.minRate &&
            !data?.[0]?.bufSize
        ) {
            return
        }
        const setter = setCategoriesChannels
        setter([{ 
            id: v4(), 
            name: '',
            resolution: '',
            bitrate: '',
            maxRate: '',
            minRate: '',
            bufSize: '',
         }, ...data,])
        setInEdit(`${type}_0`)

    }

    return (
        <List sx={{ position: 'relative', overflow: "auto" }} component={Paper}>
            {loading && <div className='loader' ><ClipLoader /></div>}
            <Table padding='none' size="medium" sx={{ minWidth: 650 }} aria-label="a dense table">
                <TableRow>
                    <TableCell align="left"><strong>Name</strong></TableCell>
                    <TableCell align="left"><strong>Resolution</strong></TableCell>
                    <TableCell align="left"><strong>Bitrate</strong></TableCell>
                    <TableCell align="left"><strong>MaxRate</strong></TableCell>
                    <TableCell align="left"><strong>Min Rate</strong></TableCell>
                    <TableCell align="left"><strong>Buffer Size</strong></TableCell>

                    <TableCell align="left"><MDButton onClick={() => handleAddRow('c')} variant='outlined' color='info'>Add new</MDButton></TableCell>
                </TableRow>
                <TableBody>
                    {cahnnelsRows.map((row, i) => (
                        <TableRow key={row.en}  >
                            
                            <TableCell width={'15%'} align="left">{inEdit === `c_${i}` ? <div className={styles.inpCont} ><MDInput margin='none' inputRef={nameRef} fullWidth  defaultValue={row.name} /> {errors.name && <div className={styles.error}>{errors.name}</div>}</div> : row.name}</TableCell>
                            <TableCell width={'15%'} align="left">{inEdit === `c_${i}` ? <div className={styles.inpCont} ><MDInput margin='none' inputRef={resolutionRef} fullWidth  defaultValue={row.resolution} /> {errors.resolution && <div className={styles.error}>{errors.resolution}</div>}</div> : row.resolution}</TableCell>
                            <TableCell width={'15%'} align="left">{inEdit === `c_${i}` ? <div className={styles.inpCont} ><MDInput margin='none' inputRef={bitrateRef} fullWidth  defaultValue={row.bitrate} /> {errors.bitrate && <div className={styles.error}>{errors.bitrate}</div>}</div> : row.bitrate}</TableCell>
                            <TableCell width={'15%'} align="left">{inEdit === `c_${i}` ? <div className={styles.inpCont} ><MDInput margin='none' inputRef={maxRateRef} fullWidth  defaultValue={row.maxRate} /> {errors.maxRate && <div className={styles.error}>{errors.maxRate}</div>}</div> : row.maxRate}</TableCell>
                            <TableCell width={'15%'} align="left">{inEdit === `c_${i}` ? <div className={styles.inpCont} ><MDInput margin='none' inputRef={minRateRef} fullWidth  defaultValue={row.minRate} /> {errors.minRate && <div className={styles.error}>{errors.minRate}</div>}</div> : row.minRate}</TableCell>
                            <TableCell width={'15%'} align="left">{inEdit === `c_${i}` ? <div className={styles.inpCont} ><MDInput margin='none' inputRef={bufSizeRef} fullWidth  defaultValue={row.bufSize} /> {errors.bufSize && <div className={styles.error}>{errors.bufSize}</div>}</div> : row.bufSize}</TableCell>
                            <TableCell align="right">
                                <MDBox>
                                    {inEdit === `c_${i}` ?
                                        <>
                                            <IconButton
                                                color="success"
                                                onClick={() => {
                                                    handleSave(row, i, 'c');
                                                }}
                                            >
                                                <Check />
                                            </IconButton>
                                            <IconButton
                                                onClick={() => {
                                                    handleCancel('c');
                                                }}
                                            >
                                                <Cancel />
                                            </IconButton>
                                        </>
                                        :
                                        <>
                                            <IconButton
                                                onClick={() => {
                                                    handleEdit(row, i, 'c');
                                                }}
                                            >
                                                <Edit />
                                            </IconButton>
                                            <Tooltip title={t('dblClick')} >
                                                <IconButton
                                                    onDoubleClick={() => {
                                                        handleDelete(row, i, 'c');
                                                    }}
                                                    color="error"
                                                >
                                                    <DeleteForever />
                                                </IconButton>
                                            </Tooltip>
                                        </>
                                    }

                                </MDBox>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </List>
    )
}

export default Resolutions