import { SET_AUTH } from "redux/types";

const defaults = {
    email: null, 
    firstName: null, 
    lastName: null, 
    avatar: null, 
    id: null,
    loading: false
}

export default (state = defaults, action) => {
    switch (action.type) {
      case SET_AUTH:
        return { ...state, ...action.payload };
  
      default:
        return state;
    }
  };
  