import { api } from "api/config"
import store from "redux/store"
import { UPDATE_SETTINGS_RESOLUTIONS } from "redux/types"
import { UPDATE_SETTINGS_DEFAULT_COMMANDS } from "redux/types"
import { UPDATE_SETTINGS_CATEGORIES } from "redux/types"
import { GET_SETTINGS } from "redux/types"
import { UPDATE_SETTINGS } from "redux/types"
import { catchAsync } from "utils/catchAsync"
import { addNotification } from "./notifications"
import i18n from "i18n"

export const getSettings = () => catchAsync(async (dispatch) => {
    dispatch({
        type: GET_SETTINGS,
        payload: { loading: true, errorMessage: null, status: null }
    })
    const res = await api.get('/settings')
    dispatch({
        type: GET_SETTINGS,
        payload: { settings: res.data?.result, loading: false, errorMessage: null, status: 'success', isFetched: true }
    })
}, (error) => {
    store.dispatch({
        type: UPDATE_SETTINGS,
        payload: { ...error, loading: false, status: 'success', isFetched: true }
    })
}
)

export const updateSettings = body => catchAsync(async (dispatch) => {
    dispatch({
        type: UPDATE_SETTINGS,
        payload: { loading: true, errorMessage: null, status: null }
    })
    await api.patch('/settings', body)

    dispatch({
        type: UPDATE_SETTINGS,
        payload: { settings: { license: body.license }, loading: false, errorMessage: null, status: 'success' }
    })
}, (error) => {
    store.dispatch({
        type: UPDATE_SETTINGS,
        payload: { ...error, loading: false }
    })
}
)

export const updateCategories = (body, onSuccess) => catchAsync(async (dispatch) => {
    dispatch({
        type: UPDATE_SETTINGS_CATEGORIES,
        payload: { state: { loading: true, errorMessage: null, status: null } }
    })
    await api.put(`/settings/categories/${body.endpoint}`, body.data)

    dispatch({
        type: UPDATE_SETTINGS_CATEGORIES,
        payload: { settings: { [body.key]: body.data }, state: { loading: false, errorMessage: null, status: 'success' } }
    })
    onSuccess?.()
}, (error) => {
    store.dispatch({
        type: UPDATE_SETTINGS_CATEGORIES,
        payload: { state: { ...error, loading: false } }
    })
}
)

export const updateDefaultCommands = (body, onSuccess, onError) => catchAsync(async (dispatch) => {
    dispatch({
        type: UPDATE_SETTINGS_DEFAULT_COMMANDS,
        payload: { state: { loading: true, errorMessage: null, status: null } }
    })
    await api.put(`/settings/defaults`, body.data)

    dispatch({
        type: UPDATE_SETTINGS_DEFAULT_COMMANDS,
        payload: { settings: { defaults: body.data }, state: { loading: false, errorMessage: null, status: 'success' } }
    })
    dispatch(addNotification({
        color: 'success',
        icon: 'success',
        title: i18n.t('Saved'),
        content: i18n.t('success_saved'),
        dateTime: Date.now(),
    }))
}, (error) => {
    store.dispatch({
        type: UPDATE_SETTINGS_DEFAULT_COMMANDS,
        payload: { state: { ...error, loading: false } }
    })
}
)


export const updateResolutions = (body, onSuccess) => catchAsync(async (dispatch) => {
    dispatch({
        type: UPDATE_SETTINGS_RESOLUTIONS,
        payload: { state: { loading: true, errorMessage: null, status: null } }
    })
    await api.put(`/settings/resolutions`, body.data)

    dispatch({
        type: UPDATE_SETTINGS_RESOLUTIONS,
        payload: { settings: { resolutions: body.data }, state: { loading: false, errorMessage: null, status: 'success' } }
    })
    onSuccess?.()
}, (error) => {
    store.dispatch({
        type: UPDATE_SETTINGS_RESOLUTIONS,
        payload: { state: { ...error, loading: false } }
    })
}
)