import { CopyAllOutlined } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { addNotification } from 'redux/actions/notifications';
import { copyToClipboard } from 'utils/helpers';

const Copy = ({str}) => {
    const {t} = useTranslation()
    const dispatch = useDispatch()
    if(!window.isSecureContext || !str){
        return null
    }
    return (
        <IconButton onClick={()=>{copyToClipboard(str); dispatch(addNotification({
            dateTime: Date.now(),
            color: 'info',
            title: t('Clipboard'),
            content: t('Copied'),
          }))
        }} > <CopyAllOutlined/> </IconButton>
    );
}

export default Copy;
