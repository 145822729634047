import { GET_TRANSCODERS, UPDATE_TRANSCODE_STATUS } from "redux/types";

const defaults = {
  loading: false,
  list: [],
};

export default (state = defaults, action) => {
  switch (action.type) {
    case GET_TRANSCODERS:
      return { ...state, ...action.payload };
    case UPDATE_TRANSCODE_STATUS: 
      return { ...state, activeStreams: action.payload }
    default:
      return state;
  }
};
