import { baseURLpublic } from 'api/config';
import { io } from 'socket.io-client';
import { getToken } from 'utils';

export const socket = io(baseURLpublic, {
    autoConnect: false,
    extraHeaders:{
        Authorization: `${getToken()}`,
    },
});

