/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect } from "react";

// react-router-dom components
import { useLocation } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React context
import { useMaterialUIController, setLayout } from "context";

import { useDispatch, useSelector } from "react-redux";
import MDSnackbar from "components/MDSnackbar";
import { archiveNotification } from "redux/actions/notifications";
import moment from "moment";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import InfoModal from "components/messageModal/MessageModal";

function DashboardLayout({ children }) {
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav } = controller;
  const { pathname } = useLocation();
  const notifications = useSelector((store) => store?.notifications?.list);
  const dispatchAction = useDispatch();
  const infoModal = useSelector((store) => store?.infoModal);
  useEffect(() => {
    setLayout(dispatch, "dashboard");
  }, [pathname]);

  return (
    <MDBox
      sx={({ breakpoints, transitions, functions: { pxToRem } }) => ({
        p: 3,
        position: "relative",

        [breakpoints.up("xl")]: {
          marginLeft: miniSidenav ? pxToRem(120) : pxToRem(274),
          transition: transitions.create(["margin-left", "margin-right"], {
            easing: transitions.easing.easeInOut,
            duration: transitions.duration.standard,
          }),
        },
      })}
    >
      <DashboardNavbar />
      {notifications.map((item) => (
        <MDSnackbar
          key={item.dateTime}
          color={item.color}
          icon={item.icon}
          title={item.title}
          content={item.content}
          dateTime={item.dateTime}
          open={notifications[notifications.length - 1].dateTime === item.dateTime}
          onClose={() => {
            dispatchAction(archiveNotification(true));
          }}
          close={() => {
            dispatchAction(archiveNotification());
          }}
        />
      ))}
      <InfoModal/>
      {children}
    </MDBox>
  );
}

// Typechecking props for the DashboardLayout
DashboardLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default DashboardLayout;
