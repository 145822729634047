import { Grid, Tooltip } from "@mui/material";
import MDBox from "components/MDBox";
import MDDialog from "components/MDDialog";
import MDInput from "components/MDInput";
import MDSelect from "components/MDSelect";
import MDSelectMui from "components/MDSelectMui";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getChannels } from "redux/actions/channels";

export default function Modal({ openModal, setOpenModal }) {

  const billtypes = [{ label: 'Monthly', value: 'MONTHLY' }, { label: 'Yearly', value: 'YEARLY' }, { label: 'Free', value: 'FREE' },]
  const { t } = useTranslation()
  const dispatch = useDispatch();
  const channels = useSelector((store) => store?.channels?.list);
  const [editName, setEditName] = useState("");
  const [price, setPrice] = useState(2000);
  const [billType, setBillType] = useState(billtypes[0]);
  const [editChannels, setEditChannels] = useState([]);
  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    setEditName(openModal?.modalValues?.name ?? "");
    setEditChannels(channels.filter(item => openModal?.modalValues?.channels?.includes?.(item._id)) ?? []);
    const billType = openModal?.modalValues?.billType ? billtypes.find(item => item.value === openModal?.modalValues?.billType) : undefined
    setBillType(billType)
    setPrice(openModal?.modalValues?.price ?? 0)
    return () => {
      setEditName("")
      setEditChannels([])
      setBillType(billtypes[0])
      setPrice(2000)
    }
  }, [openModal?.bool]);


  const handleSelectChannels = (_, newValue) => {
    setEditChannels(newValue);
  };


  const handleSelectBillType = (e) => {
    setBillType(e);
  }

  return (
    <MDDialog
      maxWidth="sm"
      fullWidth
      dialogHeight="50vh"
      open={openModal?.bool}
      setOpen={setOpenModal}
      onSave={() => {
        openModal?.onSave({ name: editName, channels: editChannels.map(item => item._id), price, billType: billType?.value });
        setOpenModal({ bool: false });
      }}
    >
      <MDBox>
        <Grid container>
          <Grid item xs={4}>
            {t('Name')}:
          </Grid>
          <Grid item xs={8}>
            <MDInput fullWidth value={editName} onChange={(e) => setEditName(e.target.value)} />
          </Grid>

          <Grid item xs={4}>
            {t('Price')}:
          </Grid>
          <Grid item xs={8}>
            <Tooltip title={billType?.value === "FREE" ? t("tariffs.freeBillTypeNoPrice") : ""}>
              <span>
                <MDInput disabled={billType?.value === "FREE"} fullWidth type='number' value={price} onChange={(e) => setPrice(e.target.value)} />
              </span>
            </Tooltip>
          </Grid>
          <Grid item xs={4}>
            {t('Type')}:
          </Grid>
          <Grid item xs={8}>
            <MDSelect
              size='small'
              label=""
              value={billType}
              onChange={handleSelectBillType}
              options={billtypes}
              getOptionLabel={(option) => option.label}
            />
          </Grid>
          <Grid item xs={4}>
            {t('Channels')}:
          </Grid>
          <Grid item xs={8}>
            <MDSelectMui
              withChip
              label="channels"
              multiple
              value={editChannels}
              labelKey="name"
              valueKey="_id"
              onChange={handleSelectChannels}
              options={channels}
              sx={{maxHeight: 100}}
            />
          </Grid>
        </Grid>

      </MDBox>
    </MDDialog >
  );
}
