import { apiAuth } from "api/config";
import { api } from "api/config";
import axios from "axios";

const abortControllers = new Map() 

const checker = async ({name, dispatchCb, cb, pathStreamer, pathapi, host, input}) =>{
  
  const res = await apiAuth.get(`${host}/${pathStreamer}/checkProcess`, {
    signal: abortControllers?.get(name)?.signal,
    params: {
      name, 
      input
    }
  })
    const {
      data: { result },
    } = await api.patch(`/${pathapi}/${name}`, {isActive: res.status === 200})
    dispatchCb(result)
    
}

export const statusChecker = async ({name, dispatchCb, cb, pathStreamer, pathapi, host, input}) => {
    abortControllers.set(name, new AbortController())
    checker({name, dispatchCb, cb, pathStreamer, pathapi, host, input})
    return setInterval(async () => {
        try {
          if(abortControllers.has(name)){
            abortControllers.get(name).abort();
            abortControllers.delete(name);
          }
          abortControllers.set(name, new AbortController())
          await checker({name, dispatchCb, cb, pathStreamer, pathapi, host, input})

        } catch (error) {
          const {
            data: { result },
          } =  await api.patch(`/${pathapi}/${name}`, {isActive: false})  
          if(cb instanceof Function){
            cb()
          }      
          dispatchCb(result)
        } finally {
          if(cb instanceof Function){
            cb()
          }
        }
      }, 10000);
}