import { ARCHIVE_NOTIFICATION } from "redux/types"
import { ADD_NOTIFICATION } from "redux/types"

export const addNotification = body => dispatch => {
    dispatch({
        type: ADD_NOTIFICATION,
        payload: body
    })
}

export const archiveNotification = body => dispatch => {
    dispatch({
        type: ARCHIVE_NOTIFICATION,
        payload: body
    })
}