import { GET_CHANNELS } from "redux/types";

const defaults = {
  loading: false,
  list: [],
};

export default (state = defaults, action) => {
  switch (action.type) {
    case GET_CHANNELS:
      return { ...state, ...action.payload };

    default:
      return state;
  }
};
