import axios from "axios";
import { statusChecker } from "redux/reduxHelpers";
import store from "redux/store";
import { GET_VOD } from "redux/types";
import { UPDATE_STREAM_STATUS } from "redux/types";
import { UPDATE_CRONS } from "redux/types";
import { catchAsync } from "utils/catchAsync";
import { generateFormDataFromObject } from "utils/helpers";
import { api, apiAuth } from "../../api/config";
import { UPDATE_VOD_STATUS } from "redux/types";
import { UPDATE_SINGLE_VOD_STATUS } from "redux/types";
import { FINISH_SINGLE_VOD_STATUS } from "redux/types";


const intervals = {}

export const getVOD = () =>
  catchAsync(async (dispatch) => {
    // dispatch({
    //   type: GET_VOD,
    //   payload: {
    //     loading: true,
    //   },
    // });
    const {
      data: { result }
    } = await api.get("/VOD");

    const {
      data: { result: activeVods }
    } = await api.get("/VOD/activeProcesses");

    dispatch({
      type: GET_VOD,
      payload: {
        loading: false,
        list: result.map(item => ({ ...item, loading: false })),
        activeVods
      },
    });
  });


export const getActiveVODs = () =>
  catchAsync(async (dispatch) => {
    const {
      data: { result: activeVods }
    } = await api.get("/VOD/activeProcesses");


    dispatch({
      type: GET_VOD,
      payload: {
        activeVods
      },
    });
  });


export const createVOD = (body) =>
  catchAsync(async (dispatch) => {
    const formData = generateFormDataFromObject(body)
    const {
      data: { result },
    } = await api.post("/VOD", formData);



    dispatch({
      type: UPDATE_STREAM_STATUS,
      payload: {
        ...body,
        loading: true,
      },
    });
    dispatch({
      type: GET_VOD,
      payload: {
        list: result,

      },
    });
  });

export const updateVOD = (id, body) => async (dispatch) => {
  dispatch({
    type: UPDATE_STREAM_STATUS,
    payload: { ...body, _id: id, loading: true }
  })
  const formData = generateFormDataFromObject(body)
  const {
    data: { result },
  } = await api.put(`/VOD/${id}`, formData);

  dispatch({
    type: GET_VOD,
    payload: {
      loading: false,
      list: result
    },
  });
};

export const updateVODStatus = (body) => async (dispatch) => {
  dispatch({
    type: UPDATE_SINGLE_VOD_STATUS,
    payload: body
  })
};

export const finishVODStatus = (id) => async (dispatch) => {
  dispatch({
    type: FINISH_SINGLE_VOD_STATUS,
    payload: id
  })
};

export const refreshVOD = (values) => async (dispatch) => {
  try {
    dispatch({
      type: UPDATE_STREAM_STATUS,
      payload: { ...values, loading: true }
    })
    await apiAuth.post(`${values?.streamerInfo?.host}/VOD/refresh`, values)
  } catch (error) {
  }
};

export const stopVOD = (values) => async (dispatch) => {
  dispatch({
    type: UPDATE_STREAM_STATUS,
    payload: { ...values, loading: true }
  })
  await apiAuth.post(`${values?.streamerInfo?.host}/VOD/destroy`, values)
}

export const deleteVOD = (values) =>
  catchAsync(async (dispatch) => {
    dispatch({
      type: GET_VOD,
      payload: {
        loading: true,
      },
    });
    // const res = await apiAuth.post(`${values?.streamerInfo?.host}/stream/destroy`, {...values, isDelete: true} )
    //   if(res.status === 200){
    const { data: { result } } = await api.delete(`/VOD/${values._id}`);
    dispatch({
      type: GET_VOD,
      payload: {
        loading: false,
        list: result
      },
    });
    // }
  });
