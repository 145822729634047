import { GET_ACTIIVE_DEVICES } from "redux/types";

const defaults = {
    loading: false,
    list: [],
}

export default (state = defaults, action) => {
    switch (action.type) {
        case GET_ACTIIVE_DEVICES:
            return { ...state, ...action.payload };

        default:
            return state;
    }
};
