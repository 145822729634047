import { GET_ARCHIVERS, UPDATE_ARCHIVE_STATUS } from "redux/types";

const defaults = {
  loading: false,
  list: [],
};

export default (state = defaults, action) => {
  switch (action.type) {
    case GET_ARCHIVERS:
      return { ...state, ...action.payload };
    case UPDATE_ARCHIVE_STATUS: 
      return { ...state, activeStreams: action.payload }
    default:
      return state;
  }
};
