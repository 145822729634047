import { GET_SYSTEM_STATUS } from "redux/types";

const defaults = {
  loading: false,
  status: {
    cpu: [], 
    systemInfo: [], 
    temp: [], 
    memory: [], 
    currentLoad: [],
  }
};

export default (state = defaults, action) => {
  switch (action.type) {
    case GET_SYSTEM_STATUS:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};