import store from "redux/store";
import { UPDATE_CRONS } from "redux/types";
import { GET_EPG } from "redux/types";
import { catchAsync } from "utils/catchAsync";
import { api } from "../../api/config";

export const getEPGs = () =>
  catchAsync(async (dispatch) => {
    dispatch({
      type: GET_EPG,
      payload: {
        loading: true,
      },
    });
    const {
      data: { result, activeCrons },
    } = await api.get("/epg");
    dispatch({
      type: GET_EPG,
      payload: {
        loading: false,
        list: result,
        activeCrons
      },
    });
  });


  export const createEPG = (values) =>
  catchAsync(async (dispatch) => {
    dispatch({
      type: GET_EPG,
      payload: {
        loading: true,
      },
    });
    const {
      data: {result, activeCrons},
    } = await api.post("/epg", values);
    dispatch({
      type: GET_EPG,
      payload: {
        loading: false,
        list: result,
        activeCrons
      },
    });
  });

  export const updateEPG = (id, values) =>
  catchAsync(async (dispatch) => {
    dispatch({
      type: GET_EPG,
      payload: {
        loading: true,
      },
    });
    const {
      data: {result, activeCrons},
    } = await api.patch(`/epg/${id}`, values);
    dispatch({
      type: GET_EPG,
      payload: {
        loading: false,
        list: result,
        activeCrons
      },
    });
  });

  export const deleteEPG = (id) =>
  catchAsync(async (dispatch) => {
    dispatch({
      type: GET_EPG,
      payload: {
        loading: true,
      },
    });
    const {data: { result, activeCrons } } = await api.delete(`/epg/${id}`);
    dispatch({
      type: GET_EPG,
      payload: {
        loading: false,
        list: result,
        activeCrons
      },
    });
  });


  export const updateCrons = (id, cb) => async (dispatch) => {
    try {
      const {data: { activeCrons } } = await api.get(`/epg/fetch/${id}`)
      dispatch({
        type: UPDATE_CRONS,
        payload: activeCrons,
      });
    } catch (error) {
      const { activeCrons } = store.getState()
      dispatch({
        type: UPDATE_CRONS,
        payload: activeCrons.filter(item=>item!==id),
      });
    } finally {
      cb()
    }
  };
