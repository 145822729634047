import React, { useEffect, useState } from "react";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDDialog from "components/MDDialog";
import MDInput from "components/MDInput";
import { useFormik } from "formik";
import * as yup from "yup";
import { DropzoneDialog } from 'material-ui-dropzone';
import MDSelect from "components/MDSelect";
import { Grid } from "@mui/material";
import { resolutionsList as resolutions } from "utils/constants";
import { AddOutlined, CloseRounded, Remove } from "@mui/icons-material";
import MDSelectMui from "components/MDSelectMui";
import { useDispatch, useSelector } from "react-redux";
import { getSettings } from "redux/actions/settings";
import { useTranslation } from "react-i18next";
import DividerMui from "components/dividerMui/dividerMui";
import {
    roles,
    transcoderPermissionsList,
    archiverPermissionsList,
    streamerPermissionsList,
    VODPermissionsList,
    customersPermissionsList,
    EPGPermissionsList,
    channelsPermissionsList,
    tariffsPermissionsList,
    settingsPermissionsList,
} from "./constants";

const schema = yup.object({
    firstName: yup.string("Enter Admin name").required("Firstname is required"),
    lastName: yup.string("Enter Admin name").required("Lastname is required"),
    email: yup.string("Enter Email").required("Email is required"),
});

export default function Modal({ openModal, setOpenModal, data }) {
    const dispatch = useDispatch()
    const { t, i18n } = useTranslation()
    const [openDropzone, setOpenDropZone] = useState(false)
    const [file, setFile] = useState(null)
    const [image, setImage] = useState();

    const lang = i18n.language
    const initValues = {
        firstName: openModal?.modalValues?.firstName ?? "",
        lastName: openModal?.modalValues?.lastName ?? undefined,
        email: openModal?.modalValues?.email ?? "",
        permissions: openModal?.modalValues?.permissions ?? {
            transcoder: [],
            archiver: [],
            streamer: [],
            VOD: [],
            customers: [],
            EPG: [],
            channels: [],
            tariffs: [],
            settings: []
        },
        avatar: openModal?.modalValues?.avatar ?? "",
    }

    const { values, handleChange, touched, errors, handleSubmit, setFieldValue } = useFormik({
        initialValues: initValues,
        validationSchema: schema,
        onSubmit: (values) => {
            openModal?.onSave({ ...values });
            setOpenModal({ bool: false });
        },
    });


    useEffect(() => {
        if (file) {
            const reader = new FileReader();

            reader.addEventListener("load", function () {
                // convert image file to base64 string
                setImage(reader.result);
            }, false);

            reader.readAsDataURL(file);

            setFieldValue('avatar', file)
        }

    }, [file])


    const [selectedRole, setSelectedRole] = useState(null)

    const handlePermissions = (e, key) => {
        setFieldValue('permissions', { ...values.permissions, [key]: e })
    }
    const handleSetRole = (item) => {
        setSelectedRole(item)
        setFieldValue('permissions', item.values)
    }

    useEffect(() => {
        const stringified = JSON.stringify(values.permissions)
        const found = roles.find(item => JSON.stringify(item.values) === stringified)
        if (found) {
            setSelectedRole(found)
        } else {
            setSelectedRole(null)
        }
    }, [values.permissions])

    return (
        <MDDialog
            maxWidth="lg"
            fullWidth
            open={openModal?.bool}
            setOpen={setOpenModal}
            onSave={handleSubmit}
        >
            <MDBox sx={{ height: '70vh' }} my={1}>
                <Grid container spacing={2}>
                    <Grid item xs={4} sx={{ display: 'flex', flexDirection: 'column' }} >
                        <img style={{ width: 'auto', height: 'auto', maxHeight: '150px', margin: '20px auto' }}
                            src={
                                image ? image :
                                    initValues.avatar ?
                                        `${process.env.REACT_APP_BASE_ORIGIN}/img/users/${initValues.avatar}` :
                                        `${process.env.REACT_APP_BASE_ORIGIN}/img/default.png`
                            }
                            alt={'user'}
                        />
                        <MDButton onClick={() => { setOpenDropZone(true) }} mt={2} variant="gradient" color="secondary">
                            {t('Upload_image')}
                        </MDButton>
                    </Grid>
                    <Grid item xs={8} container spacing={2} >
                        <Grid xs={12} >
                            <DividerMui text={t('Admin_Info')} />
                        </Grid>
                        <Grid item xs={4}>
                            <MDInput
                                sx={{ margin: "20px 0px" }}
                                label={t("First_Name")}
                                name="firstName"
                                id="firstName"
                                fullWidth
                                value={values.firstName}
                                error={!!touched.firstName && !!errors.firstName}
                                helperText={touched.firstName && errors.firstName}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <MDInput
                                sx={{ margin: "20px 0px" }}
                                label={t("Last_Name")}
                                name="lastName"
                                id="lastName"
                                fullWidth
                                value={values.lastName}
                                error={!!touched.lastName && !!errors.lastName}
                                helperText={touched.lastName && errors.lastName}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={4} >
                            <MDInput
                                disabled={openModal.isUpdate}
                                sx={{ margin: "20px 0px" }}
                                label={t("Email")}
                                name="email"
                                id="email"
                                fullWidth
                                value={values.email}
                                error={!!touched.email && !!errors.email}
                                helperText={touched.email && errors.email}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid xs={12} item >
                            <DividerMui />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid sx={{ padding: '20px', height: '500px' }} container>
                    <Grid xs={12} item >
                        <DividerMui text={t('Permissions')} />
                        <MDBox sx={{ margin: '20px 0px', width: '100%', display: 'flex', justifyContent: 'space-around', alignItems: 'center' }} >
                            {roles.map(item => (<MDButton variant={selectedRole?.label === item.label ? 'gradient' : 'outlined'} color={selectedRole?.label === item.label ? "info" : "secondary"} key={item.label} onClick={() => handleSetRole(item)}>
                                {t(`${item.label}_`)}
                            </MDButton>))}
                        </MDBox>
                    </Grid>
                   <Grid xs={12} sx={{marginTop: "-15px"}} item >
                            <DividerMui />
                        </Grid>
                    <Grid container alignItems={'center'} justifyContent={'center'} xs={12} item >
                        <Grid item xs={4}>
                            {t('Transcoder')}
                        </Grid>
                        <Grid item xs={8}>
                            <MDSelectMui
                                withChip
                                multiple={true}
                                value={values.permissions.transcoder.map(item => ({ label: item, value: item }))}
                                onChange={(_, e) => handlePermissions(e.map(item=>item.value || item), 'transcoder')}
                                options={transcoderPermissionsList.map(item => ({ label: item, value: item }))}
                            />
                        </Grid>
                    </Grid>
                   <Grid xs={12} sx={{marginTop: "-15px"}} item >
                            <DividerMui />
                        </Grid>
                    <Grid container alignItems={'center'} justifyContent={'center'} xs={12} item >
                        <Grid item xs={4}>
                            {t('Archiver')}
                        </Grid>
                        <Grid item xs={8}>
                            <MDSelectMui
                                withChip
                                multiple={true}
                                value={values.permissions.archiver.map(item => ({ label: item, value: item }))}
                                onChange={(_, e) => handlePermissions(e.map(item=>item.value || item), 'archiver')}
                                options={archiverPermissionsList.map(item => ({ label: t(`${item}`), value: item }))}
                            />
                        </Grid>
                    </Grid>
                   <Grid xs={12} sx={{marginTop: "-15px"}} item >
                            <DividerMui />
                        </Grid>
                    <Grid container alignItems={'center'} justifyContent={'center'} xs={12} item >
                        <Grid item xs={4}>
                            {t('Streamer')}
                        </Grid>
                        <Grid item xs={8}>
                            <MDSelectMui
                                withChip
                                multiple={true}
                                value={values.permissions.streamer.map(item => ({ label: item, value: item }))}
                                onChange={(_, e) => handlePermissions(e.map(item=>item.value || item), 'streamer')}
                                options={streamerPermissionsList.map(item => ({ label: t(`${item}_`), value: item }))}
                            />
                        </Grid>
                    </Grid>
                   <Grid xs={12} sx={{marginTop: "-15px"}} item >
                            <DividerMui />
                        </Grid>
                    <Grid container alignItems={'center'} justifyContent={'center'} xs={12} item >
                        <Grid item xs={4}>
                            {t('VOD_')}
                        </Grid>
                        <Grid item xs={8}>
                            <MDSelectMui
                                withChip
                                multiple={true}
                                value={values.permissions.VOD.map(item => ({ label: item, value: item }))}
                                onChange={(_, e) => handlePermissions(e.map(item=>item.value || item), 'VOD')}
                                options={VODPermissionsList.map(item => ({ label: t(`${item}_`), value: item }))}
                            />
                        </Grid>
                    </Grid>
                   <Grid xs={12} sx={{marginTop: "-15px"}} item >
                            <DividerMui />
                        </Grid>
                    <Grid container alignItems={'center'} justifyContent={'center'} xs={12} item >
                        <Grid item xs={4}>
                            {t('Customers')}
                        </Grid>
                        <Grid item xs={8}>
                            <MDSelectMui
                                withChip
                                multiple={true}
                                value={values.permissions.customers.map(item => ({ label: item, value: item }))}
                                onChange={(_, e) => handlePermissions(e.map(item=>item.value || item), 'customers')}
                                options={customersPermissionsList.map(item => ({ label: t(`${item}_`), value: item }))}
                            />
                        </Grid>
                    </Grid>
                   <Grid xs={12} sx={{marginTop: "-15px"}} item >
                            <DividerMui />
                        </Grid>
                    <Grid container alignItems={'center'} justifyContent={'center'} xs={12} item >
                        <Grid item xs={4}>
                            {t('Channels')}
                        </Grid>
                        <Grid item xs={8}>
                            <MDSelectMui
                                withChip
                                multiple={true}
                                value={values.permissions.channels.map(item => ({ label: item, value: item }))}
                                onChange={(_, e) => handlePermissions(e.map(item=>item.value || item), 'channels')}
                                options={channelsPermissionsList.map(item => ({ label: t(`${item}_`), value: item }))}
                            />
                        </Grid>
                    </Grid>
                   <Grid xs={12} sx={{marginTop: "-15px"}} item >
                            <DividerMui />
                        </Grid>
                    <Grid container alignItems={'center'} justifyContent={'center'} xs={12} item >
                        <Grid item xs={4}>
                            {t('Tariff')}
                        </Grid>
                        <Grid item xs={8}>
                            <MDSelectMui
                                withChip
                                multiple={true}
                                label="Permmissions"
                                name="permissions"
                                id="permissions"
                                value={values.permissions.tariffs.map(item => ({ label: item, value: item }))}
                                onChange={(_, e) => handlePermissions(e.map(item=>item.value || item), 'tariffs')}
                                options={tariffsPermissionsList.map(item => ({ label: t(`${item}_`), value: item }))}
                            />
                        </Grid>
                    </Grid>
                   <Grid xs={12} sx={{marginTop: "-15px"}} item >
                            <DividerMui />
                        </Grid>
                    <Grid container alignItems={'center'} justifyContent={'center'} xs={12} item >
                        <Grid item xs={4}>
                            {t('Epg')}
                        </Grid>
                        <Grid item xs={8}>
                            <MDSelectMui
                                withChip
                                multiple={true}
                                label="Permmissions"
                                name="permissions"
                                id="permissions"
                                value={values.permissions.EPG.map(item => ({ label: item, value: item }))}
                                onChange={(_, e) => handlePermissions(e.map(item=>item.value || item), 'EPG')}
                                options={EPGPermissionsList.map(item => ({ label: t(`${item}_`), value: item }))}
                            />
                        </Grid>
                    </Grid>
                   <Grid xs={12} sx={{marginTop: "-15px"}} item >
                            <DividerMui />
                        </Grid>
                    <Grid container alignItems={'center'} justifyContent={'center'} xs={12} item >
                        <Grid item xs={4}>
                            {t('Settings')}
                        </Grid>
                        <Grid item xs={8}>
                            <MDSelectMui
                                withChip
                                multiple={true}
                                label="Permmissions"
                                name="permissions"
                                id="permissions"
                                value={values.permissions.settings.map(item => ({ label: item, value: item }))}
                                onChange={(_, e) => handlePermissions(e.map(item=>item.value || item), 'settings')}
                                options={settingsPermissionsList.map(item => ({ label: t(`${item}_`), value: item }))}
                            />
                        </Grid>
                    </Grid>
                   <Grid xs={12} sx={{marginTop: "-15px"}} item >
                            <DividerMui />
                        </Grid>
                </Grid>
            </MDBox>
            <DropzoneDialog
                acceptedFiles={['image/*']}
                cancelButtonText={"cancel"}
                submitButtonText={"submit"}
                filesLimit={1}
                maxFileSize={5000000}
                open={openDropzone}
                onClose={() => setOpenDropZone(false)}
                onSave={(files) => {
                    setFile(files[0])
                    setOpenDropZone(false);
                }}
                showPreviews={true}
                showFileNamesInPreview={true}
            />

        </MDDialog>
    );
}
