import React from 'react';
import { Route, Navigate } from 'react-router-dom';
import { isLogged } from 'utils';

const PublicRoute = ({children}) => {
    return (
        isLogged() ? <Navigate to="/dashboard" /> : children
    );
};

export default PublicRoute;