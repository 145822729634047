import { FormControlLabel, Grid, } from "@mui/material";
import MDBox from "components/MDBox";
import MDDialog from "components/MDDialog";
import MDInput from "components/MDInput";
import MDSelect from "components/MDSelect";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import * as yup from "yup";
import { getTariffs } from "redux/actions/tariffs";
import { DropzoneDialog } from "material-ui-dropzone";
import MDButton from "components/MDButton";
import { useTranslation } from "react-i18next";
import Checkbox from '@mui/material/Checkbox';
import ImageWithFallback from "components/ImgWithFallback";
import Access from "./profileModal/Access";


export default function Modal({ openModal, setOpenModal }) {
  const { t } = useTranslation()
  const schema = {
    email: yup.string(t("Enter Customers Username")).required(t("Username is required")),
    firstName: yup.string(t("Enter Customer's firstname")).required(t("firstname is required")),
    lastName: yup.string(t("Enter Customer's lastName")).required(t("lastName is required")),
    tariff: yup.array(t("Select Customer's plan")).of(yup.string()).required(t("plan is required")),
    maxSubusersCount: yup.number(t("Enter Max Subusers count")).required(t("Subusers count is required")),
    phone: yup.string(t("Enter Phone number")).required(t("Phone number is required")),
  };

  const passSchema = {
    password: yup.string(t("Enter Customer's password")).required(t("password is required")),
    passwordConfirm: yup.string(t("Enter Customer's password")).required(t("password confirmation is required")),
  }
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false)
  const tariffs = useSelector((store) => store?.tariffs?.list);
  
  const initValues = {
    email: openModal?.modalValues?.email ?? "",
    firstName: openModal?.modalValues?.firstName ?? "",
    lastName: openModal?.modalValues?.lastName ?? "",
    tariff: openModal?.modalValues?.tariff ?? [],
    image: openModal?.modalValues?.image,
    password: "",
    passwordConfirm: "",
    maxSubusersCount: openModal?.modalValues?.maxSubusersCount ?? 3,
    phone: ""
  };
  const { values, handleChange, touched, errors, handleSubmit, setFieldValue, setErrors } = useFormik({
    initialValues: initValues,
    validationSchema: yup.object({ ...schema, ...(showPassword ? passSchema : {}) }),
    onSubmit: (values) => {
      if (showPassword && (values.passwordConfirm !== values.password)) {
        setErrors({ passwordConfirm: t("Passwords do not match") })
        return
      }
      openModal?.onSave({ ...values }, ()=>setOpenModal({ bool: false }));
      
    },
  });

  const [openDropzone, setOpenDropZone] = useState(false)
  const [file, setFile] = useState()
  const [image, setImage] = useState()

  useEffect(() => {
    if (file) {
      const reader = new FileReader();

      reader.addEventListener("load", function () {
        // convert image file to base64 string
        setImage(reader.result);
      }, false);

      reader.readAsDataURL(file);

      setFieldValue('image', file)
    }

  }, [file])

  useEffect(() => {
    if (!openModal.bool) {
      setFile(null)
      setImage(null)
    }
  }, [openModal.bool])



  const handleSelectTariff = (e) => {
    setFieldValue("tariff", [e._id]);
  };

  useEffect(() => {
    setShowPassword(!openModal?.modalValues?.email)
    if (openModal?.modalValues) {
      Object.keys(initValues).forEach((key) => {
        if (key === "tariff") {
          setFieldValue('tariff', openModal?.modalValues?.tariff ?? ['']);
        }
        setFieldValue(key, openModal?.modalValues?.[key] ?? '');
      });
    }
  }, [openModal?.modalValues]);
  useEffect(() => {
    if (!tariffs.length) {
      dispatch(getTariffs());
    }
  }, []);

  const onAccessValueChange = useCallback((data) => {
    setFieldValue('devices', data)
  }, [setFieldValue])


  return (
    <MDDialog
      maxWidth="md"
      fullWidth
      dialogHeight="fit-content"
      open={openModal?.bool}
      setOpen={setOpenModal}
      onSave={handleSubmit}
    >
      <MDBox
        style={{
          paddingTop: "10px"
        }}
      >
        {openModal?.modalValues?.email && <MDBox
          style={{
            position: "absolute",
            top: 0,
            right: 10,
            display: 'flex',
            zIndex: 9000,
            backgroundColor: '#fff',
            alignItems: "center"
          }}
        >
          <FormControlLabel
            value={showPassword}
            onChange={() => setShowPassword(prev => !prev)}
            control={<Checkbox />}
            label={t("ChangePassword")}
            labelPlacement="end"
          />
        </MDBox>}
        <Grid rowSpacing={2} container>
          <Grid sx={{ display: 'flex', flexDirection: 'column' }} item xs={12} md={4}>
            <ImageWithFallback alt={'channel'} style={{ maxWidth: '180px', height: 'auto', maxHeight: "150px", margin: '20px auto' }} src={image ? image : `${process.env.REACT_APP_BASE_ORIGIN}/img/users/${initValues.image}`} />
            <MDButton onClick={() => { setOpenDropZone(true) }} mt={2} variant="gradient" color="secondary">
              {t("Upload_image")}
            </MDButton>
            <DropzoneDialog
              acceptedFiles={['image/*']}
              cancelButtonText={"cancel"}
              submitButtonText={"submit"}
              filesLimit={1}
              maxFileSize={5000000}
              open={openDropzone}
              onClose={() => setOpenDropZone(false)}
              onSave={(files) => {
                setFile(files[0])
                setOpenDropZone(false);
              }}
              showPreviews={true}
              showFileNamesInPreview={true}
            />
          </Grid>
          <Grid item xs={12} md={8} rowSpacing={2} container>
            <Grid item xs={3} sx={{ textAlign: "center" }}>
              {t('Email')}:
            </Grid>
            <Grid item xs={9}>
              <MDInput
                fullWidth
                value={values.email}
                id="email"
                name="email"
                error={touched.email && errors.email}
                helperText={touched.email && errors.email}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={3} sx={{ textAlign: "center" }}>
              {t('firstName')}:
            </Grid>
            <Grid item xs={9}>
              <MDInput
                fullWidth
                value={values.firstName}
                id="firstName"
                name="firstName"
                error={touched.firstName && errors.firstName}
                helperText={touched.firstName && errors.firstName}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={3} sx={{ textAlign: "center" }}>
              {t('lastName')}:
            </Grid>
            <Grid item xs={9}>
              <MDInput
                fullWidth
                value={values.lastName}
                id="lastName"
                name="lastName"
                error={touched.lastName && errors.lastName}
                helperText={touched.lastName && errors.lastName}
                onChange={handleChange}
              />
            </Grid>
          </Grid>
          {showPassword && <>
            <Grid item xs={3}>
              {t('password')}:
            </Grid>
            <Grid item xs={9}>
              <MDInput
                fullWidth
                value={values.password}
                type="password"
                id="password"
                name="password"
                error={touched.password && errors.password}
                helperText={touched.password && errors.password}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={3}>
              {t('passwordConfirm')}:
            </Grid>
            <Grid item xs={9}>
              <MDInput
                fullWidth
                value={values.passwordConfirm}
                type="password"
                id="passwordConfirm"
                name="passwordConfirm"
                error={touched.passwordConfirm && errors.passwordConfirm}
                helperText={touched.passwordConfirm && errors.passwordConfirm}
                onChange={handleChange}
              />
            </Grid>
          </>
          }
          <Grid item xs={3}>
            {t('phone')}:
          </Grid>
          <Grid item xs={9}>
            <MDInput
              fullWidth
              value={values.phone}
              id="phone"
              name="phone"
              error={touched.phone && errors.phone}
              helperText={touched.phone && errors.phone}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={3}>
            {t('Subusers_count')}:
          </Grid>
          <Grid item xs={9}>
            <MDInput
              fullWidth
              value={values.maxSubusersCount}
              type="number"
              id="maxSubusersCount"
              name="maxSubusersCount"
              error={touched.maxSubusersCount && errors.maxSubusersCount}
              helperText={touched.maxSubusersCount && errors.maxSubusersCount}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={3}>
            {t('tariff')}:
          </Grid>
          <Grid item xs={9}>
            <MDSelect
              value={tariffs.find(item => item._id === values.tariff[0])}
              error={touched.tariff && errors.tariff}
              helperText={touched.tariff && errors.tariff}
              onChange={handleSelectTariff}
              options={tariffs}
              getOptionLabel={(option) => option.name}
              isOptionEqualToValue={(option, value) => { return option._id === value._id }}
            />
          </Grid>
          {/* <Grid item xs={12}>
            <Access user={values} onChange={onAccessValueChange} />
          </Grid> */}

        </Grid>
      </MDBox>
    </MDDialog>
  );
}
