import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import MDBox from 'components/MDBox';
import { useDispatch, useSelector } from 'react-redux';
import { getSettings } from 'redux/actions/settings';
import { Grid } from '@mui/material';
import MDInput from 'components/MDInput';
import MDButton from 'components/MDButton';
import * as yup from "yup";
import { useFormik } from 'formik';
import { useLocation } from 'react-router-dom';
import colors from 'assets/theme/base/colors';
import { updateSettings } from 'redux/actions/settings';
import Categories from './categories/categories';
import Admins from './Admins/Admins';
import Resolutions from './resolutions/resolutions';
import { useTranslation } from 'react-i18next';
import Defaults from './Defaults/Defaults';
import Copy from 'components/Copy';
import MDTypography from 'components/MDTypography';
import { api } from 'api/config';
import UsersSettings from './UsersSettings';
import Versions from './Versions';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

const schema = yup.object({
  license: yup.string().required('field is required'),
});

export default function VerticalTabs() {
  const { t } = useTranslation()
  const [value, setValue] = React.useState(0);
  const [licenseError, setLicenseError] = React.useState(null)
  const { settings, errorMessage, status, loading } = useSelector(store => store.settings)
  const location = useLocation()
  const [isChanged, setIsChanged] = React.useState(false)
  const dispatch = useDispatch()
  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  const initValues = {
    license: settings.license
  };


  const { values, handleChange, errors, handleSubmit, setFieldValue, setFieldError, setFieldTouched } = useFormik({
    initialValues: initValues,
    validationSchema: schema,
    onSubmit: async (values) => {
      setIsChanged(false)
      await dispatch(updateSettings({ license: values.license, server: 'Middleware', instances: 0 }))
      dispatch(getSettings())
    },
  });

  React.useEffect(() => {
    dispatch(getSettings())
  }, [])

  React.useEffect(() => {
    if (settings.license)
      setFieldValue('license', settings.license)
  }, [settings])

  React.useEffect(() => {
    if (location?.state?.licenseError && status !== 'success') {
      setLicenseError(location.state.licenseError)
    } else {
      setLicenseError(null)
    }
  }, [location?.state?.licenseError, status])

  const [softwareId, setSoftwareId] = React.useState('')

  React.useEffect(() => {
    const getSoftwareId = async () => {
      const result = await api.get('/settings/softwareId')
      setSoftwareId(result.data.serverInfo)
    }
    getSoftwareId()
  }, [])

  return (
    <DashboardLayout>
      <MDBox
        sx={{ flexGrow: 1, bgcolor: 'background.paper', }}
      >
        <Tabs
          variant="scrollable"
          scrollButtons="auto"
          value={value}
          onChange={handleTabChange}
          // aria-label="Vertical tabs example"
          sx={{
            backgroundColor: "divider",
            borderRadius: 0,
            borderRight: 1,
            borderColor: 'divider',
            "& .MuiTabs-flexContainer": {
              display: window.innerWidth < 600 ? "-webkit-box" : "flex"
            }
          }}
        >
          <Tab sx={{ px: 2, }} label={t("General")} />
          <Tab sx={{ px: 2, }} label={t("Categories")} />
          <Tab sx={{ px: 2, }} label={t("Admins")} />
          <Tab sx={{ px: 2, }} label={t("Default_devices")} />
          <Tab sx={{ px: 2, }} label={t("Resolutions")} />
          <Tab sx={{ px: 2, }} label={t("advanced_settings")} />
          <Tab sx={{ px: 2, }} label={t("versions")} />
          {/*<Tab label="Account settings" {...a11yProps(2)} /> */}
          {/* <Tab label="Item Four" {...a11yProps(3)} />
        <Tab label="Item Five" {...a11yProps(4)} />
        <Tab label="Item Six" {...a11yProps(5)} />
        <Tab label="Item Seven" {...a11yProps(6)} /> */}
        </Tabs>
        <TabPanel sx={{overflow: "auto"}} value={value} index={0}>
          <Grid container spacing={2}>
            <Grid item xs={3}>
              {t('Software ID')}:
            </Grid>
            <Grid item xs={9}>
              <MDTypography variant='caption' >{settings.middlewareSoftwareId || softwareId}</MDTypography>
              <Copy str={settings.middlewareSoftwareId || softwareId} />
            </Grid>
            <Grid item xs={3}>
              {t('License')}:
            </Grid>
            <Grid item xs={9}>
              <MDInput
                multiline
                fullWidth
                rows={5}
                value={values.license}
                id="license"
                name="license"
                placeholder="M_"
                error={errorMessage || licenseError}
                success={!isChanged && !errorMessage && !licenseError && !loading}
                helperText={loading ? 'Checking license' : (errorMessage || errors.license) || (!isChanged && 'License is valid')}
                onChange={(e) => { !isChanged && setIsChanged(true); handleChange(e) }}
                style={{ borderColor: errorMessage || licenseError ? colors.error.main : 'inherit' }}
              />
            </Grid>
          </Grid>
        </TabPanel>
        <TabPanel sx={{overflow: "auto"}} value={value} index={1}>
          <Categories loading={loading} settings={settings} />
        </TabPanel>
        <TabPanel sx={{overflow: "auto"}} value={value} index={2}>
          <Admins loading={loading} settings={settings} />
        </TabPanel>
        <TabPanel sx={{overflow: "auto"}} value={value} index={3}>
          <UsersSettings loading={loading} settings={settings} />
        </TabPanel>
        <TabPanel sx={{overflow: "auto"}} value={value} index={4}>
          <Resolutions loading={loading} settings={settings} />
        </TabPanel>
        <TabPanel sx={{overflow: "auto"}} value={value} index={5}>
          <Defaults loading={loading} settings={settings} />
        </TabPanel>
        <TabPanel sx={{overflow: "auto"}} value={value} index={6}>
          <Versions loading={loading} settings={settings} />
        </TabPanel>
        <TabPanel sx={{overflow: "auto"}} value={value} index={7}>
          {t('Item_Seven')}
        </TabPanel>
      </MDBox>
      {value === 0 && <MDButton style={{ float: "right" }} disabled={loading} loading={loading} variant="gradient" color="info" onClick={handleSubmit}>
        {t('Save')}
      </MDButton>}
    </DashboardLayout>
  );
}
