import { Card, Grid, IconButton, Popover, Tooltip } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import DataTable from "examples/Tables/DataTable";
import React, { useEffect, useRef, useState } from "react";
import ModalSubUser from '../SubUserModal';
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import moment from "moment";
import { api } from "api/config";
import { DateRangePicker } from "react-date-range";
import { startOfYear } from 'date-fns';
import { ClipLoader } from "react-spinners";
import { getDayStartTimestamp } from "utils/helpers";
import { getDayEndTimestamp } from "utils/helpers";
import { truncateString } from "utils/helpers";

export default function UsageHistory({ data, tariff }) {

    const dispatch = useDispatch()
    const { t } = useTranslation();
    const { list: adminsList } = useSelector(store => store.admins)

    const [openSubuserModal, setOpenSubuserModal] = useState({ bool: false });
    const [userData, setUserData] = useState([])
    const [dates, setDates] = useState([
        {
            startDate: startOfYear(new Date()),
            endDate: new Date(),
            key: 'selection'
        }
    ]);
    const id = useRef(null)
    const [dateText, setDateText] = useState(`${moment(dates[0].startDate).format("MMM DD YYYY")} - ${moment(dates[0].endDate).format("MMM DD YYYY")}`)
    const [anchorEl, setAnchorEl] = React.useState(null);

    const [loading, setLoading] = useState(false)

    useEffect(() => {
        const getData = async () => {
            try {
                setLoading(true)
                const result = await api.get(`/history/${data._id}?start=${(getDayStartTimestamp(dates[0].startDate))}&end=${(getDayEndTimestamp(dates[0].endDate))}`)
                setUserData(result.data.data);
                setLoading(false)
            } catch (error) {
                console.log({ error });
                setLoading(false)
            }
        }
        if (data?._id) {
            getData()
        }
    }, [data, dates])


    const columns = [
        { Header: t("IP"), accessor: "ip", align: "left" },
        { Header: t("Device_Brand"), accessor: "brand", align: "left" },
        { Header: t("Device_Model"), accessor: "model", align: "left" },
        { Header: t("OS"), accessor: "os", align: "left" },
        { Header: t("APP"), accessor: "app", align: "left" },
        { Header: t("Date"), accessor: "Date", align: "left" },
    ];
    const rows = userData?.map((item) => {
        const truncateLength = 15
        const os = `${!item?.clientDevice?.osName || item?.clientDevice?.osName?.toLocaleLowerCase?.() === "unknown" ? "" : item?.clientDevice?.osName} ${!item?.clientDevice?.osVersion || item?.clientDevice?.osVersion?.toLocaleLowerCase?.() === "unknown" ? "" : item?.clientDevice?.osVersion}`
        return {
            ip: <Tooltip title={item?.clientIp?.length > truncateLength ? item?.clientIp : ''}><div>{truncateString(item?.clientIp || '-', truncateLength)}</div></Tooltip>,
            brand: <Tooltip title={item?.clientDevice?.brand?.length > truncateLength ? item?.clientDevice?.brand : ''}><div>{truncateString(item?.clientDevice?.brand || '-', truncateLength)}</div></Tooltip>,
            model: <Tooltip title={item?.clientDevice?.modelName?.length > truncateLength ? item?.clientDevice?.modelName : ''}><div>{truncateString(item?.clientDevice?.modelName || '-', truncateLength)}</div></Tooltip>,
            os: <Tooltip title={os?.length > truncateLength ? os : ''}><div>{truncateString(os || '', truncateLength)}</div></Tooltip>,
            app: item?.clientDevice?.appVersion,
            Date: item.activeDate ? moment(item.activeDate).format('DD/MM/YYYY HH:mm:ss') : '',
        }
    });


    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const idPop = open ? 'simple-popover' : undefined;


    const handleDateChange = (item) => {
        setDates([item.selection])
        setDateText(`${moment(item.selection.startDate).format("MMM DD YYYY")} - ${moment(item.selection.endDate).format("MMM DD YYYY")}`)
    }

    return (
        <MDBox sx={{ position: 'relative' }} >
            {loading && <div className="loader">
                <ClipLoader />
            </div>}
            <Grid container spacing={0}>
                <Grid item xs={12}>
                    <MDBox
                        pl={2}
                        pr={2}
                        sx={{ display: "flex", justifyContent: "space-between", alignItems: 'center' }}
                    >
                        <MDButton aria-describedby={idPop} variant="outlined" fullWidth color="secondary" onClick={handleClick}>
                            {dateText}
                        </MDButton>
                        <Popover
                            id={id}
                            open={open}
                            anchorEl={anchorEl}
                            onClose={handleClose}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'center',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'center',
                            }}
                        >
                            <div
                                style={{ width: '90wv', overflow: 'auto' }}
                            >
                                <DateRangePicker
                                    onChange={handleDateChange}
                                    showSelectionPreview={true}
                                    moveRangeOnFirstSelection={false}
                                    months={2}
                                    ranges={dates}
                                    direction="horizontal"
                                    maxDate={new Date()}
                                    preventSnapRefocus={true}
                                    calendarFocus="backwards"

                                />
                            </div>
                        </Popover>
                    </MDBox>
                    <MDBox sx={{ height: '275px', overflow: 'auto' }}>
                        {rows && (
                            <DataTable
                                table={{ columns, rows }}
                                isSorted={false}
                                showTotalEntries={false}
                                canSearch={false}
                                entriesPerPageBox={false}
                                suppressPagination={true}
                            />
                        )}
                    </MDBox>
                </Grid>
            </Grid>
            <ModalSubUser openModal={openSubuserModal} setOpenModal={setOpenSubuserModal} />
        </MDBox>
    )
}
