import { Box, Card, Checkbox, FormControlLabel, Grid, IconButton, Tooltip, Typography } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DataTable from "examples/Tables/DataTable";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import AddIcon from "@mui/icons-material/Add";
import { CheckBox, Delete, Edit } from "@mui/icons-material";
import PaidIcon from '@mui/icons-material/Paid';
import Modal from "./Modal";
import ModalSubUser from './SubUserModal';
import { getSingleItemByAnOption } from "utils/helpers";
import { getCustomers } from "redux/actions/customers";
import { createCustomer } from "../../redux/actions/customers";
import { deleteCustomer } from "redux/actions/customers";
import { updateCustomer, createSubuser } from "../../redux/actions/customers";
import MDAvatar from "components/MDAvatar";
import TemporaryDrawer from 'components/Drawer'
import MDSelect from "components/MDSelect"
import SubusersList from "./subusersList";
import PaymentModal from './paymentModal'
import WithPermission from "components/withPermission/WithPermission";
import { BillingPermission } from "pages/settings/Admins/constants";
import { EditPermission } from "pages/settings/Admins/constants";
import { DeletePermission } from "pages/settings/Admins/constants";
import { checkPermission } from "utils/checkPermission";
import { AddPermission } from "pages/settings/Admins/constants";
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { ViewPermission } from "pages/settings/Admins/constants";
import ProfileModal from "./profileModal/profileModal";
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import { getChannels } from "redux/actions/channels";
import Notifications from "./Notifications";
import MUITable from "components/MuiTable";

export default function Customers() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const allTarrifs = useSelector((store) => store?.tariffs?.list);
  const loading = useSelector((store) => store?.customer?.loading);
  const customers = useSelector((store) => store?.customer?.list);
  const [openModal, setOpenModal] = useState({ bool: false });
  const [openSubuserModal, setOpenSubuserModal] = useState({ bool: false });
  const [openSubusersList, setOpenSubusersList] = useState({ bool: false })
  const [paymentModal, setPaymentModal] = useState({ bool: false })
  const [profileModal, setProfileModal] = useState({ bool: false })
  const [searchTariff, setSearchTariff] = useState(null)
  const [showOnlineUsers, setShowOnlineUsers] = useState(false)



  useEffect(() => {
    dispatch(getChannels())
  }, []);

  useEffect(() => {
    dispatch(getCustomers());
  }, [paymentModal.bool]);

  function handleCreateCustomer(params, cb) {
    const formData = new FormData();
    for (let key in params) {
      if (params[key] instanceof File) {
        formData.append(key, params[key], params[key].name);
      } else {
        formData.append(key, params[key]);
      }
    }
    dispatch(createCustomer(formData, cb));
  }

  function handleSelectTariff(e) {
    setSearchTariff(e?._id || null);
  }

  function handleDeleteCustomer(item) {
    dispatch(deleteCustomer(item._id));
  }

  async function handleUpdateCustomer(params) {
    const formData = new FormData();
    for (let key in params) {
      if (params[key] instanceof File) {
        formData.append(key, params[key], params[key].name);
      } else {
        params[key] && formData.append(key, params[key]);
      }
    }

    await dispatch(updateCustomer(this._id, formData, () => setOpenModal({ bool: false })));

  }

  function handleOpenCustomerModal(item, isUpdate) {
    setOpenModal({
      bool: true,
      onSave: isUpdate ? handleUpdateCustomer.bind(item) : handleCreateCustomer,
      modalValues: item,
    });
  }

  const handleCreateSubuser = function (params) {
    const formData = new FormData();
    for (let key in params) {
      if (params[key] instanceof File) {
        formData.append(key, params[key], params[key].name);
      } else {
        formData.append(key, params[key]);
      }
    }
    dispatch(createSubuser(formData, this.admin._id));
  }

  function handleSubuserModal(item) {
    setOpenSubuserModal({
      bool: true,
      onSave: handleCreateSubuser.bind({ admin: item }),
      modalValues: item,
    });
  }

  function handlePaymentModal(item) {
    setPaymentModal({
      bool: true,
      user: item
    })
  }

  const handleProfileModal = (item, userTariff) => {
    setProfileModal({
      bool: true,
      user: item,
      userTariff
    })
  }

  const handleSubusersList = (admin) => {
    setOpenSubusersList({ bool: true, list: admin.subusers, admin: admin })
  }

  useEffect(() => {
    if (openSubusersList.bool) {
      const admin = customers.find(item => item._id === openSubusersList.admin._id)
      setOpenSubusersList({ bool: true, list: admin.subusers, admin: admin })
    }
  }, [customers])

  const columns = [
    {
      header: t("UserName"), align: "left", renderer: ({ row }) => {
        return <MDBox sx={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
          <MDAvatar
            src={`${process.env.REACT_APP_BASE_ORIGIN}/img/users/${row?.image}`}
            name={row.email}
            size="sm"
          />{" "}
          &nbsp; {row.email}
        </MDBox>
      }
    },
    { header: t("Fullname"), align: "left", renderer: ({ row }) => `${row?.firstName} ${row?.lastName}` },
    {
      header: t("Tariff"), accessor: "Tariff", align: "center", renderer: ({ row }) => {
        const userTariff = getSingleItemByAnOption(allTarrifs, "_id", row?.tariff?.[0])
        return <>{userTariff?.name}</>
      }
    },
    {
      header: t("action"), accessor: "action", align: "center", renderer: ({ row }) => {
        const userTariff = getSingleItemByAnOption(allTarrifs, "_id", row?.tariff?.[0])
        return <MDBox>
          <WithPermission permissionsList={[BillingPermission]} permissionKey={'customers'} >
            <IconButton
              disabled={!checkPermission([BillingPermission], 'customers')}
              onClick={() => {
                handlePaymentModal(row);
              }}
            >
              <PaidIcon />
            </IconButton>
          </WithPermission>
          <WithPermission permissionsList={[ViewPermission]} permissionKey={'customers'} >
            <IconButton
              disabled={!checkPermission([ViewPermission], 'customers')}
              onClick={() => {
                handleProfileModal(row, userTariff);
              }}
            >
              <AccountBoxIcon />
            </IconButton>
          </WithPermission>
          {/* <WithPermission permissionsList={[UpdateSubuserPermission]} permissionKey={'customers'} >
      <IconButton
      disabled={!checkPermission([UpdateSubuserPermission], 'customers')}
        onClick={() => {
          handleSubusersList(row);
        }}
      >
        <List />
      </IconButton>
    </WithPermission>
    <WithPermission permissionsList={[AddSubuserPermission]} permissionKey={'customers'} >
      <IconButton
      disabled={!checkPermission([AddSubuserPermission], 'customers')}
        onClick={() => {
          handleSubuserModal(row);
        }}
      >
        <Add />
      </IconButton>
    </WithPermission> */}
          <WithPermission permissionsList={[EditPermission]} permissionKey={'customers'} >
            <IconButton
              disabled={!checkPermission([EditPermission], 'customers')}
              onClick={() => {
                handleOpenCustomerModal(row, true);
              }}
            >
              <Edit />
            </IconButton>
          </WithPermission>
          <WithPermission permissionsList={[DeletePermission]} permissionKey={'customers'} >
            <Tooltip title={t('dblClick')} >
              <IconButton
                disabled={!checkPermission([DeletePermission], 'customers')}
                onDoubleClick={() => {
                  handleDeleteCustomer(row);
                }}
              >
                <Delete />
              </IconButton>
            </Tooltip>
          </WithPermission>
          <Tooltip title={row.isOnline ? t('Online') : t('Offline')} >
            <IconButton>
              {row.isOnline ? <FiberManualRecordIcon sx={{ color: 'success.main' }} /> : <FiberManualRecordIcon sx={{ color: 'error.main' }} />}
            </IconButton>
          </Tooltip>
        </MDBox>
      }
    },
  ];

  const customersList = useMemo(() => {
    const list = searchTariff ? customers.filter(item => item.tariff[0] === searchTariff) : customers;
    const customersFiltered = showOnlineUsers ? list.filter(item => item.isOnline) : list
    return customersFiltered
  }, [searchTariff, customers, showOnlineUsers])

  return (
    <DashboardLayout>
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                sx={{ display: "flex", justifyContent: "space-between" }}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  {t("customers.header")}
                </MDTypography>
                <WithPermission disable={true} permissionsList={[AddPermission]} permissionKey={'customers'} >
                  <MDButton disabled={!checkPermission([AddPermission], 'customers')} onClick={handleOpenCustomerModal} withIcon={<AddIcon />} loading={loading}>
                    {t("customers.addNew")}
                  </MDButton>
                </WithPermission>
              </MDBox>
              <MDBox pt={3}>
                {!!customers.length && (
                  <MUITable
                    searchable
                    columns={columns}
                    data={customersList}
                    searchKeys={["email", "firstName", "lastName"]}
                    rowsPerPageOptions={[10, 50, 100]}
                    headerRightComponent={
                      <>
                        <Notifications />
                      </>
                    }
                    headerLeftComponent={
                      <>
                        <MDSelect
                          label={t("tariff")}
                          sx={{ minWidth: 100, maxWidth: 200 }}
                          value={allTarrifs.find(item => item._id === searchTariff)}
                          onChange={handleSelectTariff}
                          options={allTarrifs}
                          getOptionLabel={(option) => option.name}
                          isOptionEqualToValue={(option, value) => { return option._id === value?._id }}
                        />
                        <FormControlLabel
                          value={showOnlineUsers}
                          onChange={() => setShowOnlineUsers(prev => !prev)}
                          control={<Checkbox />}
                          label={t("customersOnline", { count: customers?.filter?.(item => item.isOnline)?.length })}
                          labelPlacement="end"
                        />
                      </>
                    }
                  />
                  // <DataTable
                  //   canSearch={true}
                  //   table={{ columns, rows }}
                  //   isSorted={false}
                  //   noEndBorder
                  //   headerLeftComponent={<Notifications/>}
                  // />
                )}
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      {openModal.bool && <Modal openModal={openModal} setOpenModal={setOpenModal} />}
      <ModalSubUser openModal={openSubuserModal} setOpenModal={setOpenSubuserModal} />
      <PaymentModal openModal={paymentModal} setOpenModal={setPaymentModal} />
      <ProfileModal openModal={profileModal} setOpenModal={setProfileModal} />
      <TemporaryDrawer paperStyles={"drawerInfoPanel"} open={openSubusersList.bool} setOpen={setOpenSubusersList}>
        <SubusersList data={openSubusersList} />
      </TemporaryDrawer>
    </DashboardLayout>
  );
}
