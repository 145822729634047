export const AddPermission = "Create"
export const ViewPermission = "View"
export const EditPermission = "Update"
export const DeletePermission = "Delete"
export const AdvancedModePermission = "Advanced Mode"
export const BillingPermission = "Billing"
export const AddSubuserPermission = "Add Subuser"
export const UpdateSubuserPermission = "Update Subuser"
export const DeleteSubuserPermission = "Delete Subuser"
export const RefreshPermission = "Refresh"
export const PlayPermission = "Play"
export const EditSourcePermission = "Update Source"
export const EditServerSettingsPermission = "Update Server Settings"
export const ActivatePermission = "Change Active Status"


export const transcoderPermissionsList = [ViewPermission, PlayPermission, AddPermission, EditPermission, DeletePermission, AdvancedModePermission, EditServerSettingsPermission, EditSourcePermission, RefreshPermission]
export const archiverPermissionsList = [ViewPermission, AddPermission, EditPermission, DeletePermission, AdvancedModePermission, EditServerSettingsPermission, EditSourcePermission, RefreshPermission]
export const streamerPermissionsList = [ViewPermission, PlayPermission, AddPermission, EditPermission, DeletePermission, AdvancedModePermission, EditServerSettingsPermission, EditSourcePermission, RefreshPermission]
export const VODPermissionsList = [ViewPermission, PlayPermission, AddPermission, EditPermission, DeletePermission, ActivatePermission, EditServerSettingsPermission]
export const customersPermissionsList = [ViewPermission, AddPermission, EditPermission, DeletePermission, BillingPermission, AddSubuserPermission, UpdateSubuserPermission, DeleteSubuserPermission]
export const EPGPermissionsList = [ViewPermission, AddPermission, EditPermission, DeletePermission, RefreshPermission]
export const channelsPermissionsList = [ViewPermission, PlayPermission, AddPermission, EditPermission, DeletePermission, EditSourcePermission]
export const tariffsPermissionsList = [ViewPermission, AddPermission, EditPermission, DeletePermission]
export const settingsPermissionsList = [ViewPermission, AddPermission, EditPermission, DeletePermission]

export const roles = [
    {
        label: 'Global Admin', values: {
            transcoder: transcoderPermissionsList,
            archiver: archiverPermissionsList,
            streamer: streamerPermissionsList,
            VOD: VODPermissionsList,
            customers: customersPermissionsList,
            EPG: EPGPermissionsList,
            channels: channelsPermissionsList,
            tariffs: tariffsPermissionsList,
            settings: settingsPermissionsList
        }
    },
    {
        label: 'Moderator', values: {
            transcoder: [ViewPermission, PlayPermission, AddPermission, EditPermission, AdvancedModePermission, EditServerSettingsPermission, EditSourcePermission, RefreshPermission],
            archiver: [ViewPermission, AddPermission, EditPermission, AdvancedModePermission, EditServerSettingsPermission, EditSourcePermission, RefreshPermission],
            streamer: [ViewPermission, PlayPermission, AddPermission, EditPermission, AdvancedModePermission, EditServerSettingsPermission, EditSourcePermission, RefreshPermission],
            VOD: [ViewPermission, PlayPermission, AddPermission, EditPermission, ActivatePermission, EditServerSettingsPermission],
            customers: [ViewPermission, AddPermission, EditPermission, BillingPermission, AddSubuserPermission, UpdateSubuserPermission, DeleteSubuserPermission],
            EPG: [ViewPermission, AddPermission, EditPermission, RefreshPermission],
            channels: [ViewPermission, PlayPermission, AddPermission, EditPermission, EditSourcePermission],
            tariffs: [ViewPermission, AddPermission, EditPermission],
            settings: [ViewPermission, AddPermission]
        }
    },
    {
        label: 'Analyst', values: {
            transcoder: [ViewPermission, PlayPermission],
            archiver: [ViewPermission],
            streamer: [ViewPermission, PlayPermission],
            VOD: [ViewPermission, PlayPermission],
            customers: [ViewPermission],
            EPG: [ViewPermission],
            channels: [ViewPermission, PlayPermission],
            tariffs: [ViewPermission],
            settings: [ViewPermission]
        }
    },
    {
        label: 'Cashier', values: {
            transcoder: [],
            archiver: [],
            streamer: [],
            VOD: [],
            customers: [ViewPermission, AddPermission, EditPermission, BillingPermission, AddSubuserPermission, UpdateSubuserPermission, DeleteSubuserPermission],
            EPG: [ViewPermission],
            channels: [ViewPermission],
            tariffs: [ViewPermission],
            settings: []
        }
    },
]
