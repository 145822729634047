import React, { useEffect, useRef, useState } from "react";
import MDBox from "components/MDBox";
import MDDialog from "components/MDDialog";
import { objectToString } from "utils/helpers";
import styles from "./styles.module.css"
import { Divider, Grid, Switch } from "@mui/material";
import { highlightChanges } from "utils/helpers";
import { diffChars } from "diff";
import DividerMui from "components/dividerMui/dividerMui";
import { useTranslation } from "react-i18next";
import theme from "assets/theme";
import colors from "assets/theme/base/colors";

export default function FullInfoModal({ openModal, setOpenModal }) {
    const { t } = useTranslation()
    const [marked, setMarked] = useState([])
    const [inline, setInline] = useState(false)

    useEffect(() => {
        if (openModal?.modalValues?.item.oldData && openModal?.modalValues?.item.newData) {
            let text1, text2 = ['']
            if (openModal?.modalValues?.item.oldData) {
                text1 = objectToString(openModal?.modalValues?.item.oldData).split('\n')
            }
            if (openModal?.modalValues?.item.newData) {
                text2 = objectToString(openModal?.modalValues?.item.newData).split('\n')
            }
            const arr = []
            const arrR = []
            const count = Math.max(text1.length, text2.length)
            for (let i = 0; i < count; i++) {
                arr.push(highlightChanges(text1[i] ?? '', text2[i] ?? ''))

            }
            for (let i = 0; i < count; i++) {
                arrR.push(highlightChanges(text2[i] ?? '', text1[i] ?? ''))

            }
            setMarked(arr.join('<br/>'));
        }
    }, [openModal?.modalValues?.item.oldData, openModal?.modalValues?.item.newData, openModal.bool])

    const oldRef = useRef(null)
    const newRef = useRef(null)


    const handleScrollOld = (e) => {
        newRef.current.scrollTo({ top: e.target.scrollTop, left: e.target.scrollLeft })

    }
    const handleScrollNew = (e) => {
        oldRef.current.scrollTo({ top: e.target.scrollTop, left: e.target.scrollLeft })
    }


    return (
        <MDDialog
            maxWidth="md"
            fullWidth
            open={openModal?.bool}
            setOpen={setOpenModal}
            title={<span className={styles.switch}>
                <span className={styles.title}> {openModal.modalValues.actionText} </span>
                <span className={styles.switch}>
                    <span className={styles.label}>{t("Show inline")}</span>
                    <Switch disabled={!openModal?.modalValues?.item.oldData} onChange={() => setInline(prev => !prev)} value={inline} />
                </span>
            </span>}
        >
            <MDBox className={styles.box} my={1}>

                {inline ? <Grid container spacing={2} >
                    <Grid sx={{ overflow: 'auto' }} item xs={12}>
                        <div dangerouslySetInnerHTML={{ __html: marked }} />
                    </Grid>
                </Grid>
                    :
                    <div style={{ display: 'flex', flexWrap: 'nowrap' }}>
                        {openModal?.modalValues?.item.oldData &&
                        <div style={{ width: '48%' }}>
                            <div style={{ backgroundColor: colors.highlight.error }} className={styles.heading}>{t('Old')}</div>
                            <div style={{ overflow: 'auto', height: '30vh', position: 'relative' }} ref={oldRef} onScroll={handleScrollOld}>
                                <pre style={{ margin: '10px' }} >
                                    {objectToString(openModal?.modalValues?.item.oldData)}
                                </pre>
                            </div>
                        </div>}
                        {openModal?.modalValues?.item.oldData && <DividerMui orientation="vertical" />}


                        <div style={{ width: openModal?.modalValues?.item.oldData ? '48%' : '100%' }}>
                            <div style={{ backgroundColor: colors.highlight.success }} className={styles.heading}>{t('New')}</div>
                            <div style={{ overflow: 'auto', height: '30vh', position: 'relative' }} ref={newRef} onScroll={handleScrollNew}>
                                <pre style={{ margin: '10px' }} >
                                    {objectToString(openModal?.modalValues?.item.newData)}
                                </pre>
                            </div>

                        </div>


                    </div>}
            </MDBox>

        </MDDialog>
    );
}
