import { Add } from '@mui/icons-material'
import { Card, Grid } from '@mui/material'
import MDBox from 'components/MDBox'
import MDButton from 'components/MDButton'
import MDTypography from 'components/MDTypography'
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import Modal from './modal'
import HostCard from 'components/HostCard'
import TemporaryDrawer from 'components/Drawer'
import VODServer from './VOD'
import MainLoader from 'components/loader/loader'
import { getVOD } from 'redux/actions/VOD'
import { getVODs } from 'redux/actions/VODs'
import { addVODs } from 'redux/actions/VODs'
import { deleteVODs } from 'redux/actions/VODs'
import { AddPermission } from 'pages/settings/Admins/constants'
import { checkPermission } from 'utils/checkPermission'
import WithPermission from 'components/withPermission/WithPermission'

export default function VODs() {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const allVOD = useSelector(store=>store?.VOD?.list)
    const VODs = useSelector(store=>store?.VODs?.list)
    const loading = useSelector(store=>store?.VODs?.loading)
    const [openModal, setOpenModal] = useState({bool: false})
    const [openVOD, setOpenVOD] = useState(false)

    

    useEffect(() => {
        dispatch(getVOD())
        dispatch(getVODs({initial: true}))
        const id = setInterval(()=>{
            dispatch(getVODs())
        }, 10000)
        return () => {
            clearInterval(id)
        }
    }, [])

    // const handleDelete = (values) => {
    //     dispatch(deleteStreamer(values))
    // }


    const handleCreate =  (values) => {
        dispatch(addVODs(values))
    }

    // const handleUpdate = () => {
        
    // }

    // const handleStopStream = (item) => {
    //     dispatcher({ type: 'add', id: item._id })
    //     dispatch(stopStream(item, () => dispatcher({ type: 'remove', id: item._id })))
    // }

    // const handleStartStream = (item) => {
    //     dispatcher({ type: 'add', id: item._id })
    //     dispatch(refreshStream(item, ()=> dispatcher({ type: 'remove', id: item._id })))
    // }

    // const handleRefreshStream = (item) => {
    //     dispatcher({ type: 'add', id: item._id })
    //     dispatch(refreshStream(item, ()=> dispatcher({ type: 'remove', id: item._id })))
    // }


    const handleOpenModal = (item, isUpdate) => {
        setOpenModal({
            bool: true,
            onSave: isUpdate ? handleUpdate.bind(item) : handleCreate,
            modalValues: item,
            type: "Vod"
        });
    }

    const handleOpenVOD = (VOD) => {
        setOpenVOD(VOD)
    }

    const handleDeleteVodServer = (id) => {
        if(!checkHaveVOD(id)) dispatch(deleteVODs(id))
    }

    const checkHaveVOD = (id) => {
        const streams = allVOD?.filter(item=>item.VODServerId === id)
        return streams.length > 0
    }

    return (
        <DashboardLayout>
            <MDBox pt={6} pb={3}>
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Card>
                            <MDBox
                                mx={2}
                                mt={-3}
                                py={3}
                                px={2}
                                sx={{ display: "flex", justifyContent: "space-between" }}
                                variant="gradient"
                                bgColor="info"
                                borderRadius="lg"
                                coloredShadow="info"
                            >
                                <MDTypography variant="h6" color="white">
                                    {t("VOD.header")}
                                </MDTypography>
                                <WithPermission disable={true} permissionsList={[AddPermission]} permissionKey={'VOD'}>
                                <MDButton disabled={!checkPermission([AddPermission], 'VOD')} onClick={handleOpenModal} withIcon={<Add />} loading={false}>
                                    {t("VOD.addNewVOD")}
                                </MDButton>
                                </WithPermission>
                            </MDBox>
                            <MDBox pt={3}>
                                <Grid container>
                                {loading ? 
                                <Grid item xs={12}>
                                    <MDBox sx={{margin: '30vh auto', width: 'fit-content'}} >
                                        <MainLoader />
                                    </MDBox> 
                                </Grid>
                                :
                                VODs?.map(item=>(
                                    <Grid key={item._id} item xs={12}>
                                        <HostCard permissionKey={'VOD'} type='VODs' deleteCB={!checkHaveVOD(item._id) && handleDeleteVodServer} id={item._id} host={item.url} name={item.name} systemInfo={item} actionText={`${t("View")} VOD`} actionClick={handleOpenVOD} />
                                    </Grid>
                                ))}
                                </Grid>
                            </MDBox>
                        </Card>
                    </Grid>
                </Grid>
                <Modal openModal={openModal} setOpenModal={setOpenModal} />
                <TemporaryDrawer paperStyles={"drawerInfoPanelServers"} open={openVOD} setOpen={setOpenVOD} >
                    <VODServer VODServerInfo={openVOD}  />
                </TemporaryDrawer>
            </MDBox>
        </DashboardLayout>
    )
}
