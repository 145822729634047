import { Grid } from "@mui/material";
import MDBox from "components/MDBox";
import MDDialog from "components/MDDialog";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { infoModalState } from "redux/actions/infoModal";

export default function InfoModal() {

  const dispatch = useDispatch();
  const infoModal = useSelector((store) => store?.infoModal);

  return (
    <MDDialog
      maxWidth="sm"
      fullWidth
      dialogHeight="50vh"
      open={infoModal.open}
      title={infoModal?.header}
      handleClose={()=>dispatch(infoModalState({open: false}))}
    >
      <MDBox style={{height: '100%',}}>
        <Grid style={{height: '100%',}} container>
          <Grid style={{height: '100%',}} item xs={12}>
            <textarea
              style={{height: '100%', width: '100%', padding: '10px'}}
              disabled={true}
              value={infoModal?.info}
            />
          </Grid>
        </Grid>
      </MDBox>
    </MDDialog >
  );
}
