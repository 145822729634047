import { FormControlLabel, Grid, Switch, Tooltip } from "@mui/material";
import MDBox from "components/MDBox";
import MDDialog from "components/MDDialog";
import React, { memo, useEffect, useState } from "react";
import { socket } from "socket/socket";
import { eventTypes } from "socket/eventTypes";
import LineChart from "components/charts/lineChart";
import moment from "moment";
import { bitrateChartOptions, frameRateOptions, segmentDurationOptions, segmentSizeOptions } from "./constants";
import { useTranslation } from "react-i18next";
import Info from "@mui/icons-material/Info";
import { debounce } from "utils/helpers";
import { api } from "api/config";
import { ClipLoader } from "react-spinners";



function AnalyzerModal({ openModal, setOpenModal }) {
    const { t } = useTranslation()
    const [report, setReport] = useState([])
    const [bitrateSeries, setBitrateSeries] = useState([])
    const [frameRateSeries, setFrameRateSeries] = useState([])
    const [segmentDurationSeries, setSegmentDurationSeries] = useState([])
    const [segmentSizeSeries, setSegmentSizeSeries] = useState([])
    const [keepAnalyzing, setKeepAnalyzing] = useState(openModal?.modalValues?.keepAnalyzing?.includes(openModal.modalValues.currentResolution))
    const [loading, setLoading] = useState(true)

    const generateChartData = (data) => {
        const videoBitrates = [];
        const audioBitrates = [];

        data.forEach((item) => {
            const timestamp = moment(item.date).valueOf();

            const videoStream = item.segmentInfo.format;
            const audioStream = item.segmentInfo.streams.find((stream) => stream.codec_type === 'audio');

            if (videoStream && videoStream.bit_rate) {
                videoBitrates.push({x: timestamp, y: videoStream.bit_rate, segmentName: item.segmentName})
            }

            if (audioStream && audioStream.bit_rate) {
                audioBitrates.push({x: timestamp, y: audioStream.bit_rate, segmentName: item.segmentName});
            }
        });

        return [
            {
                name: 'Video Bitrate',
                data: videoBitrates,
            },
            {
                name: 'Audio Bitrate',
                data: audioBitrates,
            },
        ];
    };


    useEffect(() => {
        const getReport = (data) => {
            if (Array.isArray(data)) {
                setLoading(false)
                setReport(data.at(-1))
                const series = generateChartData(data)
                const frameRate = data.map((item) => {
                    const stream = item.segmentInfo.streams.find((s) => s.codec_type === 'video');
                    return {x: moment(item.date).valueOf(), y: stream.avg_frame_rate.split('/')[0], segmentName: item.segmentName};
                })
                const segmentDuration = data.map((item) => ({x: moment(item.date).valueOf(), y: item.segmentInfo.format.duration, segmentName: item.segmentName}))
                const segmentSize = data.map((item) =>({x: moment(item.date).valueOf(), y: item.segmentInfo.format.size, segmentName: item.segmentName}) )
                setBitrateSeries(series)
                setFrameRateSeries([
                    {
                        name: 'Frame Rate',
                        data: frameRate,
                    },
                ])
                setSegmentDurationSeries([
                    {
                        name: 'Segment Duration',
                        data: segmentDuration,
                    },
                ])
                setSegmentSizeSeries([
                    {
                        name: 'Segment Size',
                        data: segmentSize,
                    },
                ])

            } else if (data?.segmentInfo?.streams && data?.segmentInfo?.format) {
                setReport(data)
                const videoFormat = data.segmentInfo.format;
                const videoStream = data.segmentInfo.streams.find((s) => s.codec_type === 'video');
                const audioStream = data.segmentInfo.streams.find((stream) => stream.codec_type === 'audio');
                const timestamp = moment(data.date).valueOf();
                setBitrateSeries(prev => ([
                    {
                        name: 'Video Bitrate',
                        data: [...(prev?.[0]?.data || []), {x: timestamp, y: videoFormat.bit_rate, segmentName: data.segmentName}],
                    },
                    {
                        name: 'Audio Bitrate',
                        data: [...(prev?.[1]?.data || []), {x: timestamp, y: audioStream.bit_rate, segmentName: data.segmentName}],
                    },
                ]))
                setFrameRateSeries(prev => ([
                    {
                        name: 'Frame Rate',
                        data: [...(prev?.[0]?.data || []), {x: timestamp, y: videoStream.avg_frame_rate.split('/')[0], segmentName: data.segmentName}],
                    },
                ]))
                setSegmentDurationSeries(prev => ([
                    {
                        name: 'Segment Duration',
                        data: [...(prev?.[0]?.data || []), {x: timestamp, y: videoFormat.duration, segmentName: data.segmentName}],
                    },
                ]))
                setSegmentSizeSeries(prev => ([
                    {
                        name: 'Segment Size',
                        data: [...(prev?.[0]?.data || []), {x: timestamp, y: videoFormat.size, segmentName: data.segmentName}],
                    },
                ]))
            }
        }
        socket.connect()
        socket.emit(eventTypes.analyzeReport.subscribeToReportData, openModal.modalValues)
        socket.on(eventTypes.analyzeReport.reportData, getReport)
        return () => {
            socket.off(eventTypes.analyzeReport.reportData, getReport)
            socket.disconnect()
        }
    }, []);


    // useEffect(() => {
    //     if (bitrateSeries?.[0]?.data?.length && segmentSizeSeries?.[0]?.data?.length){
    //         setMaxPoints({
    //             bitrateMaxIndex: bitrateSeries?.[0]?.data.findIndex(
    //                 (point) => {
    //                     console.log(point[1], Math.max(...bitrateSeries?.[0]?.data.map((d) => d[1])));
    //                     return +point[1] === Math.max(...bitrateSeries?.[0]?.data.map((d) => d[1]))}
    //             ),
    //             sizeMaxIndex: segmentSizeSeries?.[0]?.data.findIndex(
    //                 (point) => +point[1] === Math.max(...segmentSizeSeries?.[0]?.data.map((d) => d[1]))
    //             ),
    //         })
    //     }
    // }, [bitrateSeries, segmentSizeSeries])


    const debouncedChangeAnalazeStatus = debounce((id, keepAnalyzing, resolution) => api.patch("/transcodes/analyzeStatus", { id, keepAnalyzing, resolution }), 400)

    const handleKeepAnalyzing = (e) => {
        setKeepAnalyzing(e.target.checked)
        debouncedChangeAnalazeStatus(openModal.modalValues._id, e.target.checked, openModal.modalValues.currentResolution)
    }

    const handleClose = () => {
        socket.emit(eventTypes.analyzeReport.subscribeToReportData, { ...(openModal?.modalValues || {}), remove: !keepAnalyzing })
        setOpenModal({ bool: false })
    }


    // const bitrateOptionsWithMax = Object.assign(bitrateChartOptions, maxPoints.bitrateMaxIndex !== null && maxPoints.bitrateMaxIndex > -1 ? createAnnotation(bitrateSeries?.[0]?.data, maxPoints.bitrateMaxIndex, convertBytes(bitrateSeries?.[0]?.data[maxPoints.bitrateMaxIndex][1] / 8)) : {})
    // console.log(maxPoints,);
    // const segmentSizeOptionsWithMax = Object.assign(segmentSizeOptions, maxPoints.sizeMaxIndex !== null && maxPoints.sizeMaxIndex > -1 ? createAnnotation(segmentSizeSeries?.[0]?.data, maxPoints.sizeMaxIndex, convertBytes(segmentSizeSeries?.[0]?.data[maxPoints.sizeMaxIndex][1])) : {})

    return (
        <MDDialog
            maxWidth="md"
            fullWidth
            dialogHeight="fit-content"
            open={openModal?.bool}
            setOpen={setOpenModal}
            handleClose={handleClose}
            title={
                <MDBox sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", gap: "5px" }}>
                    {t("Segment Report")}
                    {" " + openModal.modalValues.name}
                    <FormControlLabel control={<Switch label="info" defaultChecked={keepAnalyzing} onChange={(e) => handleKeepAnalyzing(e)} />} label={t("Keep Analyzer on")} />

                </MDBox>
            }
        >
            {loading && <div className='loader' ><ClipLoader /></div>}
            <MDBox>

                <MDBox sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", gap: "5px", fontWeight: "bold" }}>
                    {t("Segment")}:{" "}
                    {report?.segmentName}
                </MDBox>
                <Grid rowSpacing={0} container>
                    {report?.segmentInfo?.streams?.map((stream, i) => {
                        return <Grid rowSpacing={0} container item xs={6}>
                            <Grid item xs={6}>
                                {t("Stream")} {i + 1}:
                            </Grid>
                            <Grid item xs={6}>
                                {stream?.codec_type}
                            </Grid>
                            <Grid item xs={6}>
                                {t("Codec")}:
                            </Grid>
                            <Grid item xs={6} sx={{ display: "flex", alignItems: "center", gap: "5px" }}>
                                {stream?.codec_name}
                                <Tooltip title={stream?.codec_long_name} >
                                    <Info color="info" />
                                </Tooltip>
                            </Grid>
                            {stream?.codec_type === "video" ? <>
                                <Grid item xs={6}>
                                    {t("Resolution")}:
                                </Grid>
                                <Grid item xs={6}>
                                    {stream?.coded_width}x{stream?.coded_height}
                                </Grid>
                                <Grid item xs={6}>
                                    {t("Aspect Ratio")}:
                                </Grid>
                                <Grid item xs={6}>
                                    {stream?.display_aspect_ratio}
                                </Grid>
                            </> : stream?.codec_type === "audio" ? <>
                                <Grid item xs={6}>
                                    {t("Channel")}:
                                </Grid>
                                <Grid item xs={6}>
                                    {stream?.channel_layout}
                                </Grid>
                                <Grid item xs={6}>
                                    {t("Language")}:
                                </Grid>
                                <Grid item xs={6}>
                                    {stream?.tags?.language}
                                </Grid>
                            </> : <> </>
                            }
                        </Grid>
                    })}
                    <Grid item xs={12}>
                        <LineChart series={bitrateSeries} customOptions={bitrateChartOptions} />
                        <LineChart series={segmentSizeSeries} customOptions={segmentSizeOptions} />
                        <LineChart series={segmentDurationSeries} customOptions={segmentDurationOptions} />
                        <LineChart series={frameRateSeries} customOptions={frameRateOptions} />
                    </Grid>
                </Grid>
            </MDBox>
        </MDDialog>
    );
}

export default memo(AnalyzerModal)
