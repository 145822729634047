import moment from "moment"
import { convertBytes } from "utils/helpers"

export const bitrateChartOptions = {
    chart: {
        stacked: false,
        width: '100%',
        height: 200,
        type: 'line',
        animations: {
            enabled: true,
            easing: 'linear',
            dynamicAnimation: {
                speed: 1000
            }
        },
        toolbar: {
            show: true
        },
        zoom: {
            enabled: true,
        }
    },
    dataLabels: {
        enabled: false
    },
    stroke: {
        curve: 'straight',
        width: 1,
    },
    fill: {
        colors: [({ value, seriesIndex, w }) => {
            if (seriesIndex === 0) {
                return '#7E36AF'
            } else if (seriesIndex === 1) {
                return '#164666'
            } else {
                return '#D9534F'
            }
        }]
    },
    title: {
        text: "Video and Audio Bitrates Over Time",
        align: 'center'
    },
    markers: {
        size: 0
    },
    xaxis: {
        type: 'datetime',
        title: "Timestamp",
        labels: {
            formatter: (value, timestamp) => {
                return moment(timestamp).format('MM/DD HH:mm:ss')
            },
        },
        // range: XAXISRANGE,
    },
    yaxis: {
        labels: {
            formatter: (value) => {
                return convertBytes(value)
            },
        },
        title: "Bitrate (bps)",
    },
    legend: {
        show: true
    },
    tooltip: {
        custom: ({ series, seriesIndex, dataPointIndex, w }) => {
            const data = w.config.series[seriesIndex].data[dataPointIndex];

            // Create the default tooltip content manually
            return `
          <div class="apexcharts-tooltip-title" style="font-family: Helvetica, Arial, sans-serif; font-size: 12px;"><b>${data?.segmentName?.toLocaleUpperCase?.()}</b> - ${moment(data.x).format('MM/DD HH:mm:ss')}</div>
          <div class="apexcharts-tooltip-series-group apexcharts-active" style="order: 1; display: flex;"><span
                  class="apexcharts-tooltip-marker" style="background-color: rgb(0, 143, 251); display: block;"></span>
              <div class="apexcharts-tooltip-text"
                  style="font-family: Helvetica, Arial, sans-serif; font-size: 12px; display: block;">
                  <div class="apexcharts-tooltip-y-group"><span class="apexcharts-tooltip-text-y-label">${w.config.series[0].name}:
                      </span><span class="apexcharts-tooltip-text-y-value">${convertBytes(data.y)}</span></div>
                  <div class="apexcharts-tooltip-goals-group"><span class="apexcharts-tooltip-text-goals-label"></span><span
                          class="apexcharts-tooltip-text-goals-value"></span></div>
                  <div class="apexcharts-tooltip-z-group"><span class="apexcharts-tooltip-text-z-label"></span><span
                          class="apexcharts-tooltip-text-z-value"></span></div>
              </div>
          </div>
          <div class="apexcharts-tooltip-series-group apexcharts-active" style="order: 2; display: flex;"><span
                  class="apexcharts-tooltip-marker" style="background-color: rgb(0, 227, 150); display: block;"></span>
              <div class="apexcharts-tooltip-text"
                  style="font-family: Helvetica, Arial, sans-serif; font-size: 12px; display: block;">
                  <div class="apexcharts-tooltip-y-group"><span class="apexcharts-tooltip-text-y-label">${w.config.series[1].name}:
                      </span><span class="apexcharts-tooltip-text-y-value">${convertBytes(w.config.series[1].data[dataPointIndex].y)}</span></div>
                  <div class="apexcharts-tooltip-goals-group"><span class="apexcharts-tooltip-text-goals-label"></span><span
                          class="apexcharts-tooltip-text-goals-value"></span></div>
                  <div class="apexcharts-tooltip-z-group"><span class="apexcharts-tooltip-text-z-label"></span><span
                          class="apexcharts-tooltip-text-z-value"></span></div>
              </div>
          </div>
          `;
        },
    },


}

export const frameRateOptions = {
    chart: {
        stacked: false,
        width: '100%',
        height: 200,
        type: 'line',
        animations: {
            enabled: true,
            easing: 'linear',
            dynamicAnimation: {
                speed: 1000
            }
        },
        toolbar: {
            show: true
        },
        zoom: {
            enabled: true,
        }
    },
    dataLabels: {
        enabled: false
    },
    stroke: {
        curve: 'straight',
        width: 1,
    },
    fill: {
        colors: [({ value, seriesIndex, w }) => {
            if (seriesIndex === 0) {
                return '#7E36AF'
            } else if (seriesIndex === 1) {
                return '#164666'
            } else {
                return '#D9534F'
            }
        }]
    },
    title: {
        text: "Frame Rates Over Time",
        align: 'center'
    },
    markers: {
        size: 0
    },
    xaxis: {
        type: 'datetime',
        title: "Timestamp",
        labels: {
            formatter: (value, timestamp) => {
                return moment(timestamp).format('MM/DD HH:mm:ss')
            },
        },
    },
    yaxis: {
        labels: {
            formatter: (value, timestamp) => {
                return value + " FPS"
            },
        },
        title: "Frames Per Second",
    },
    legend: {
        show: true
    },
    tooltip: {
        custom: ({ series, seriesIndex, dataPointIndex, w }) => {
            const data = w.config.series[seriesIndex].data[dataPointIndex];

            // Create the default tooltip content manually
            return `
          <div class="apexcharts-tooltip-title" style="font-family: Helvetica, Arial, sans-serif; font-size: 12px;"><b>${data?.segmentName?.toLocaleUpperCase?.()}</b> - ${moment(data.x).format('MM/DD HH:mm:ss')}</div>
          <div class="apexcharts-tooltip-series-group apexcharts-active" style="order: 1; display: flex;"><span
                  class="apexcharts-tooltip-marker" style="background-color: rgb(0, 143, 251); display: block;"></span>
              <div class="apexcharts-tooltip-text"
                  style="font-family: Helvetica, Arial, sans-serif; font-size: 12px; display: block;">
                  <div class="apexcharts-tooltip-y-group"><span class="apexcharts-tooltip-text-y-label">${w.config.series[0].name}:
                      </span><span class="apexcharts-tooltip-text-y-value">${(data.y)}</span></div>
                  <div class="apexcharts-tooltip-goals-group"><span class="apexcharts-tooltip-text-goals-label"></span><span
                          class="apexcharts-tooltip-text-goals-value"></span></div>
                  <div class="apexcharts-tooltip-z-group"><span class="apexcharts-tooltip-text-z-label"></span><span
                          class="apexcharts-tooltip-text-z-value"></span></div>
              </div>
          </div>
          `;
        },
    },
}

export const segmentDurationOptions = {
    chart: {
        stacked: false,
        width: '100%',
        height: 200,
        type: 'line',
        animations: {
            enabled: true,
            easing: 'linear',
            dynamicAnimation: {
                speed: 1000
            }
        },
        toolbar: {
            show: true
        },
        zoom: {
            enabled: true,
        }
    },
    dataLabels: {
        enabled: false
    },
    stroke: {
        curve: 'straight',
        width: 1,
    },
    fill: {
        colors: [({ value, seriesIndex, w }) => {
            if (seriesIndex === 0) {
                return '#7E36AF'
            } else if (seriesIndex === 1) {
                return '#164666'
            } else {
                return '#D9534F'
            }
        }]
    },
    title: {
        text: "Segments Duration Over Time",
        align: 'center'
    },
    markers: {
        size: 0
    },
    xaxis: {
        type: 'datetime',
        title: "Timestamp",
        labels: {
            formatter: (value, timestamp) => {
                return moment(timestamp).format('MM/DD HH:mm:ss')
            },
        },
        // range: XAXISRANGE,
    },
    yaxis: {
        title: "Duration (seconds)",
        labels: {
            formatter: (value, timestamp) => {
                return (value).toFixed(4) + " (s)"
            },
        },
    },
    legend: {
        show: true
    },
    tooltip: {
        custom: ({ series, seriesIndex, dataPointIndex, w }) => {
            const data = w.config.series[seriesIndex].data[dataPointIndex];
            // Create the default tooltip content manually
            return `
          <div class="apexcharts-tooltip-title" style="font-family: Helvetica, Arial, sans-serif; font-size: 12px;"><b>${data?.segmentName?.toLocaleUpperCase?.()}</b> - ${moment(data.x).format('MM/DD HH:mm:ss')}</div>
          <div class="apexcharts-tooltip-series-group apexcharts-active" style="order: 1; display: flex;"><span
                  class="apexcharts-tooltip-marker" style="background-color: rgb(0, 143, 251); display: block;"></span>
              <div class="apexcharts-tooltip-text"
                  style="font-family: Helvetica, Arial, sans-serif; font-size: 12px; display: block;">
                  <div class="apexcharts-tooltip-y-group"><span class="apexcharts-tooltip-text-y-label">${w.config.series[0].name}:
                      </span><span class="apexcharts-tooltip-text-y-value">${(data.y)}</span></div>
                  <div class="apexcharts-tooltip-goals-group"><span class="apexcharts-tooltip-text-goals-label"></span><span
                          class="apexcharts-tooltip-text-goals-value"></span></div>
                  <div class="apexcharts-tooltip-z-group"><span class="apexcharts-tooltip-text-z-label"></span><span
                          class="apexcharts-tooltip-text-z-value"></span></div>
              </div>
          </div>
          `;
        },
    },
}



export const segmentSizeOptions = {
    chart: {
        stacked: false,
        width: '100%',
        height: 200,
        type: 'line',
        animations: {
            enabled: true,
            easing: 'linear',
            dynamicAnimation: {
                speed: 1000
            }
        },
        toolbar: {
            show: true
        },
        zoom: {
            enabled: true,
        }
    },
    dataLabels: {
        enabled: false
    },
    stroke: {
        curve: 'straight',
        width: 1,
    },
    fill: {
        colors: [({ value, seriesIndex, w }) => {
            if (seriesIndex === 0) {
                return '#7E36AF'
            } else if (seriesIndex === 1) {
                return '#164666'
            } else {
                return '#D9534F'
            }
        }]
    },
    title: {
        text: "Chunk Size Over Time",
        align: 'center'
    },
    markers: {
        size: 0
    },
    xaxis: {
        type: 'datetime',
        title: "Timestamp",
        labels: {
            formatter: (value, timestamp) => {
                return moment(timestamp).format('MM/DD HH:mm:ss')
            },
        },
        // range: XAXISRANGE,
    },
    yaxis: {
        labels: {
            formatter: (value) => {
                return convertBytes(value)
            },
        },
        title: "Chunk Size",
    },
    legend: {
        show: true
    },
    tooltip: {
        custom: ({ series, seriesIndex, dataPointIndex, w }) => {
            const data = w.config.series[seriesIndex].data[dataPointIndex];

            // Create the default tooltip content manually
            return `
          <div class="apexcharts-tooltip-title" style="font-family: Helvetica, Arial, sans-serif; font-size: 12px;"><b>${data?.segmentName?.toLocaleUpperCase?.()}</b> - ${moment(data.x).format('MM/DD HH:mm:ss')}</div>
          <div class="apexcharts-tooltip-series-group apexcharts-active" style="order: 1; display: flex;"><span
                  class="apexcharts-tooltip-marker" style="background-color: rgb(0, 143, 251); display: block;"></span>
              <div class="apexcharts-tooltip-text"
                  style="font-family: Helvetica, Arial, sans-serif; font-size: 12px; display: block;">
                  <div class="apexcharts-tooltip-y-group"><span class="apexcharts-tooltip-text-y-label">${w.config.series[0].name}:
                      </span><span class="apexcharts-tooltip-text-y-value">${convertBytes(data.y)}</span></div>
                  <div class="apexcharts-tooltip-goals-group"><span class="apexcharts-tooltip-text-goals-label"></span><span
                          class="apexcharts-tooltip-text-goals-value"></span></div>
                  <div class="apexcharts-tooltip-z-group"><span class="apexcharts-tooltip-text-z-label"></span><span
                          class="apexcharts-tooltip-text-z-value"></span></div>
              </div>
          </div>
          `;
        },
    },
}


export const createAnnotation = (data, maxIndex, convertedValue) => ({
    annotations: {
        points: [
            {
                x: data[maxIndex][0],
                y: data[maxIndex][1],
                marker: {
                    size: 6,
                    fillColor: '#FF4560',
                    strokeColor: '#000000',
                    radius: 2,
                },
                label: {
                    text: `Max: ${convertedValue}`,
                    offsetY: -10,
                    style: {
                        color: '#fff',
                        background: '#FF4560',
                    },
                },
            },
        ],
    }
})
