import React, { useEffect, useState } from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TablePagination,
    Paper,
    Checkbox,
    Box,
    TextField,
} from '@mui/material';
import MDBox from 'components/MDBox';
import { useTranslation } from 'react-i18next';
import { usePrevious } from 'utils/customHooks';

const MUITable = ({
    columns,
    data,
    onChange,
    setSelecteds,
    selecteds,
    withCheckbox,
    headerColumnCheckbox,
    suppressPagination,
    headerLeftComponent,
    headerCenterComponent,
    searchKeys,
    searchable,
    headerRightComponent,
    rowsPerPageOptions = [5, 10, 15],
    checkboxCompareKey = "id"
}) => {
    const { t } = useTranslation()
    const [page, setPage] = useState(0);
    const defRowsPage = +localStorage.getItem("defaultRowsPerPage") && rowsPerPageOptions.includes(+localStorage.getItem("defaultRowsPerPage")) ? +localStorage.getItem("defaultRowsPerPage") : rowsPerPageOptions[0]
    const [rowsPerPage, setRowsPerPage] = useState(defRowsPage);
    const [search, setSearch] = useState("")
    const [localData, setLocalData] = useState(data)
    const prevData = usePrevious(data)

    useEffect(() => {
        if (search) {
            const filtered = data.filter(item => searchKeys.some(searchKey => item[searchKey]?.toLocaleLowerCase().includes(search)))
            setLocalData(filtered)
        } else {
            setLocalData(data)
        }
        if(data?.length !== prevData?.length){
            setPage(0)
        }
    }, [data])

    const handleSearch = (e) => {
        setSearch(e.target.value)
        if (searchKeys.length) {
            const filtered = data.filter(item => searchKeys.some(searchKey => item[searchKey]?.toLocaleLowerCase().includes(e.target.value)))
            setLocalData(filtered)
            page !== 0 && setPage(0)
        }
    }

    const handleCheckbox = (cell) => {
        const index = selecteds.findIndex(item => item[checkboxCompareKey] === cell[checkboxCompareKey])
        if (index === -1) {
            setSelecteds(prev => { onChange([...prev, cell]); return [...prev, cell] })
        } else {
            setSelecteds(prev => {
                onChange([
                    ...prev.slice(0, index),
                    ...prev.slice(index + 1),
                ]);
                return [
                    ...prev.slice(0, index),
                    ...prev.slice(index + 1),
                ]
            })
        }
    }

    const handleSelectAll = () => {
        if (selecteds.length === localData.length) {
            setSelecteds([])
            onChange([])
        } else {
            setSelecteds(localData)
            onChange(localData)
        }
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        localStorage.setItem("defaultRowsPerPage", parseInt(event.target.value, 10))
        setPage(0);
    };

    const visibleData = localData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

    return (
        <Paper>
            <Box sx={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center", px: 2, flexWrap: "wrap" }}>
                <Box sx={{ flexWrap: "wrap", display: "flex", justifyContent: "flex-start", alignItems: "center", gap: 1 }}>
                    {searchable && <TextField
                        sx={{ minWidth: 200 }}
                        size='small'
                        label={t("Search")}
                        value={search}
                        onChange={handleSearch}
                    />}
                    {headerLeftComponent}
                </Box>
                <Box sx={{ flexWrap: "wrap", display: "flex", justifyContent: "flex-start", alignItems: "center", gap: 1 }}>
                    {headerCenterComponent}
                </Box>
                <Box sx={{flexWrap: "wrap", display: "flex", justifyContent: "flex-start", alignItems: "center", gap: 1 }}>
                    {headerRightComponent}
                </Box>
            </Box>
            <TableContainer>
                <Table size="small" >
                    <TableHead sx={{ display: "table-header-group !important" }}>
                        <TableRow>
                            {columns.map((column, index) => (
                                <TableCell align={column.align || "left"} key={index}>
                                    <MDBox sx={{ display: "flex", alignItems: "center", justifyContent: column.align === "left" ? "flex-start" : column.align === "right" ? "flex-end" : "center" }} >
                                        <div>{headerColumnCheckbox && index === 0 && <Checkbox checked={data.every(item => selecteds.some(sel => sel[checkboxCompareKey] === item[checkboxCompareKey]))} onChange={handleSelectAll} />}</div>
                                        <div>{column.header}</div>
                                    </MDBox>
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {(suppressPagination ? data : visibleData).map((row, rowIndex) => (
                            <TableRow isSelected={true} key={rowIndex} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}                            >
                                {columns.map((item, cellIndex) => {
                                    const value = row[item.accessor]
                                    return <TableCell align={item.align || "left"} key={cellIndex}>
                                        {withCheckbox && cellIndex === 0 && <Checkbox checked={selecteds.some(item => item[checkboxCompareKey] === row[checkboxCompareKey])} onChange={() => handleCheckbox(row)} />}
                                        {!(withCheckbox && cellIndex === 0) && item?.renderer ? item?.renderer({ value, row }) : value}
                                    </TableCell>
                                })}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            {!suppressPagination && <TablePagination
                rowsPerPageOptions={rowsPerPageOptions}
                component="div"
                count={localData.length}
                rowsPerPage={rowsPerPage}
                page={page}
                labelRowsPerPage={t("rowsPerPage")}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />}
        </Paper>
    );
};

export default MUITable;
