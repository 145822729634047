import { Card, Grid, IconButton } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import DataTable from "examples/Tables/DataTable";
import React, { useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import { Delete, Edit } from "@mui/icons-material";
import ModalSubUser from './SubUserModal';
import { updateSubuser, createSubuser } from "redux/actions/customers";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { deleteSubuser } from "redux/actions/customers";
import WithPermission from "components/withPermission/WithPermission";
import { AddSubuserPermission } from "pages/settings/Admins/constants";
import { checkPermission } from "utils/checkPermission";
import { DeleteSubuserPermission } from "pages/settings/Admins/constants";

const SubusersList = ({ data }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation();
  const [openSubuserModal, setOpenSubuserModal] = useState({ bool: false });

  const loading = useSelector((store) => store?.customer?.loading);

  const handleCreateSubuser = function (params) {
    const formData = new FormData();
    for (let key in params) {
      if (params[key] instanceof File) {
        formData.append(key, params[key], params[key].name);
      } else {
        formData.append(key, params[key]);
      }
    }
    dispatch(createSubuser(formData, data.admin._id));
  }

  const handleUpdateSubuser = function (params) {
    dispatch(updateSubuser({ ...this, ...params }, data.admin._id));
  }


  function handleSubuserModal(item, isUpdate) {
    setOpenSubuserModal({
      bool: true,
      onSave: isUpdate ? handleUpdateSubuser.bind(item) : handleCreateSubuser,
      modalValues: item,
    });
  }

  const handleDeleteSubuser = (item) => {
    dispatch(deleteSubuser(item, data.admin._id))
  }


  const columns = [
    { Header: "Name", accessor: "Fullname", align: "left" },
    { Header: "action", accessor: "action", align: "center" },
  ];

  const rows = data?.list?.map((item) => ({

    Fullname: `${item?.name}`,
    action: (
      <MDBox>
        <WithPermission disable={true} permissionsList={[AddSubuserPermission]} permissionKey={'customers'} >
          <IconButton
            disabled={!checkPermission([AddSubuserPermission], 'customers')}
            onClick={() => {
              handleSubuserModal(item, true);
            }}
          >
            <Edit />
          </IconButton>
        </WithPermission>
        <WithPermission disable={true} permissionsList={[DeleteSubuserPermission]} permissionKey={'customers'} >
          <IconButton
            disabled={!checkPermission([DeleteSubuserPermission], 'customers')}
            onClick={() => {
              handleDeleteSubuser(item);
            }}
          >
            <Delete />
          </IconButton>
        </WithPermission>
      </MDBox>
    ),
  }));




  return (
    <MDBox pt={6} pb={3}>
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Card>
            <MDBox
              mx={2}
              mt={-3}
              py={3}
              px={2}
              sx={{ display: "flex", justifyContent: "space-between" }}
              variant="gradient"
              bgColor="info"
              borderRadius="lg"
              coloredShadow="info"
            >
              <MDTypography variant="h6" color="white">
                {t("customers.header")}
              </MDTypography>
              <MDButton onClick={handleSubuserModal} withIcon={<AddIcon />} loading={loading}>
                {t("customers.addNew")}
              </MDButton>
            </MDBox>
            <MDBox pt={3}>
              {rows && (
                <DataTable
                  table={{ columns, rows }}
                  isSorted={false}
                  noEndBorder
                />
              )}
            </MDBox>
          </Card>
        </Grid>
      </Grid>
      <ModalSubUser openModal={openSubuserModal} setOpenModal={setOpenSubuserModal} />
    </MDBox>
  );
}

export default SubusersList;
