import { api } from "api/config";
import { GET_ACTIIVE_DEVICES } from "redux/types";
import { catchAsync } from "utils/catchAsync";


export const getActiveDevices = () =>
  catchAsync(async (dispatch) => {
    dispatch({
      type: GET_ACTIIVE_DEVICES,
      payload: {
        loading: true,
      },
    });
    const {
      data: { data: list },
    } = await api.get(`/history/devices/days`);

    dispatch({
      type: GET_ACTIIVE_DEVICES,
      payload: {
        loading: false,
        list: list,
      },
    });
  });

  export const getActiveDevicesMonths = () =>
    catchAsync(async (dispatch) => {
      dispatch({
        type: GET_ACTIIVE_DEVICES,
        payload: {
          loading: true,
        },
      });
      const {
        data: { data: list },
      } = await api.get(`/history/devices/months`);
  
      dispatch({
        type: GET_ACTIIVE_DEVICES,
        payload: {
          loading: false,
          listMonths: list,
        },
      });
    });
