import axios from "axios";
import { statusChecker } from "redux/reduxHelpers";
import store from "redux/store";
import { UPDATE_ARCHIVE_STATUS } from "redux/types";
import { UPDATE_CRONS } from "redux/types";
import { GET_ARCHIVER } from "redux/types";
import { catchAsync } from "utils/catchAsync";
import { api, apiAuth } from "../../api/config";

const intervals = {}

export const getArchives = () =>
  catchAsync(async (dispatch) => {
    // dispatch({
    //   type: GET_ARCHIVER,
    //   payload: {
    //     loading: true,
    //   },
    // });
    const {
      data: { result }
    } = await api.get("/archives");

    dispatch({
      type: GET_ARCHIVER,
      payload: {
        loading: false,
        list: result.map(item => ({ ...item, loading: false }))
      },
    });
  }, (error) => {
    store.dispatch({
      type: GET_ARCHIVER,
      payload: { ...error, loading: false }
    })
  });


export const createArchive = (values) =>
  catchAsync(async (dispatch) => {
    const {
      data: { result },
    } = await api.post("/archives", values);
    dispatch({
      type: UPDATE_ARCHIVE_STATUS,
      payload: { ...values, loading: true },
    });

    dispatch({
      type: GET_ARCHIVER,
      payload: {
        loading: false,
        list: result,
      },
    });
  });

export const updateArchive = (id, values) =>
  async (dispatch) => {
    dispatch({
      type: UPDATE_ARCHIVE_STATUS,
      payload: { ...values, _id: id, loading: true }
    })

    const {
      data: { result },
    } = await api.put(`/archives/${id}`, values);

    dispatch({
      type: GET_ARCHIVER,
      payload: {
        loading: false,
        list: result
      },
    });

  }


export const refreshArchive = (values, cb) =>
  catchAsync(async (dispatch) => {
    dispatch({
      type: UPDATE_ARCHIVE_STATUS,
      payload: { ...values, loading: true }
    })
    await apiAuth.post(`${values?.archiverInfo?.host}/archive/refresh`, values)

  });



export const stopArchive = (values, cb) =>
  catchAsync(async (dispatch) => {
    dispatch({
      type: UPDATE_ARCHIVE_STATUS,
      payload: { ...values, loading: true }
    })
    await apiAuth.post(`${values?.archiverInfo?.host}/archive/destroy`, values)
  })

export const deleteArchive = (values) =>
  catchAsync(async (dispatch) => {
    dispatch({
      type: GET_ARCHIVER,
      payload: {
        loading: true,
      },
    });
    const { data: { result } } = await api.delete(`/archives/${values._id}`);
    dispatch({
      type: GET_ARCHIVER,
      payload: {
        loading: false,
        list: result
      },
    });
  });
