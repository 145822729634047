import store from "redux/store";
import { addNotification } from "redux/actions/notifications";

export const catchAsync = (callback, onError) => (req) => {
    return Promise.resolve(callback(req)).catch((error)=>{
      console.log({error});
      store.dispatch(addNotification({
        dateTime: Date.now(),
        color: 'error',
        title: 'Error',
        content: error?.response?.data?.errorMsg || error?.response?.data?.message,
      }))
      onError?.({
        errorMessage: error?.response?.data?.errorMsg || error?.response?.data?.message, 
        status: 'fail',
        store
      })
    });
  };
  