import { api } from "api/config";
import jwtDecode from "jwt-decode";
import { SET_AUTH } from "redux/types";
import { setToken, removeToken } from "utils";

export const setAuth = body => (async dispatch => {
    dispatch({
      type: SET_AUTH,
      payload: { loading: true },
    });
    const { email, password } = body;
    try {
      const { data: res } = await api.post('/users/loginadmin', {email, password});
      if (res) {
        setToken(res.token)
        const {email, firstName, lastName, avatar, id, permissions} = jwtDecode(res.token)
        dispatch({
          type: SET_AUTH,
          payload: { email, firstName, lastName, avatar, id, permissions, loading: false },
        });
      }
      return res
    }
    catch (error) {
      return error
    }
  }
  );

  export const setUser = token => (async dispatch => {
    dispatch({
      type: SET_AUTH,
      payload: { loading: true },
    });
    try {
      setToken(token)
      const {email, firstName, lastName, avatar, permissions, id} = jwtDecode(token)
      dispatch({
        type: SET_AUTH,
        payload: { email, firstName, lastName, avatar, permissions, id, loading: false },
      });
    }
    catch (error) {
      removeToken();
    }
  }
  );
  
  