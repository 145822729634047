import { GET_TRANSCODER, UPDATE_TRANSCODE_STATUS } from "redux/types";

const defaults = {
  loading: false,
  list: [],
  activeTranscodes: []
};

export default (state = defaults, action) => {
  switch (action.type) {
    case GET_TRANSCODER:
      return { ...state, ...action.payload };
    case UPDATE_TRANSCODE_STATUS: 
    const index = state.list.findIndex(item=>item._id === action.payload._id)
    let newList = state.list
    if(index !== -1){
      newList = [
        ...state.list.slice(0, index),
        action.payload,
        ...state.list.slice(index+1)
      ];
    }else if(state.list.length){
      newList = [
        ...state.list,
        action.payload,
      ];
    }else{
      newList = [action.payload]
    }

      return { ...state, list: newList }
    default:
      return state;
  }
};
