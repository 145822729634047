import MDBox from "components/MDBox";
import MDDialog from "components/MDDialog";
import VideoJS from "./Player/VideoJS";
import React, { useCallback, useEffect, useState } from "react";
import { getToken } from "utils";
import MDButton from "./MDButton";

export default function ModalPlayer({ primaryUrl, openModal, setOpenModal, headerComponent, leftComponent, rightComponent, downComponent }) {
  const [url, setUrl] = useState("");

  useEffect(() => {
    setUrl(openModal?.modalValues?.url ?? "");
  }, [openModal?.bool]);

  const playerRef = React.useRef(null);
  const intervalIdRef = React.useRef(null);
  // eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjY1N2NiYmFkN2ZjODU2NjE3Njk5MjYzMSIsInBlcm1pc3Npb25zIjp7InRyYW5zY29kZXIiOlsiVmlldyIsIlBsYXkiLCJDcmVhdGUiLCJVcGRhdGUiLCJEZWxldGUiLCJBZHZhbmNlZCBNb2RlIiwiVXBkYXRlIFNlcnZlciBTZXR0aW5ncyIsIlVwZGF0ZSBTb3VyY2UiLCJSZWZyZXNoIl0sImFyY2hpdmVyIjpbIlZpZXciLCJDcmVhdGUiLCJVcGRhdGUiLCJEZWxldGUiLCJBZHZhbmNlZCBNb2RlIiwiVXBkYXRlIFNlcnZlciBTZXR0aW5ncyIsIlVwZGF0ZSBTb3VyY2UiLCJSZWZyZXNoIl0sInN0cmVhbWVyIjpbIlZpZXciLCJQbGF5IiwiQ3JlYXRlIiwiVXBkYXRlIiwiRGVsZXRlIiwiQWR2YW5jZWQgTW9kZSIsIlVwZGF0ZSBTZXJ2ZXIgU2V0dGluZ3MiLCJVcGRhdGUgU291cmNlIiwiUmVmcmVzaCJdLCJWT0QiOlsiVmlldyIsIlBsYXkiLCJDcmVhdGUiLCJVcGRhdGUiLCJEZWxldGUiLCJDaGFuZ2UgQWN0aXZlIFN0YXR1cyIsIlVwZGF0ZSBTZXJ2ZXIgU2V0dGluZ3MiXSwiY3VzdG9tZXJzIjpbIlZpZXciLCJDcmVhdGUiLCJVcGRhdGUiLCJEZWxldGUiLCJCaWxsaW5nIiwiQWRkIFN1YnVzZXIiLCJVcGRhdGUgU3VidXNlciIsIkRlbGV0ZSBTdWJ1c2VyIl0sIkVQRyI6WyJWaWV3IiwiQ3JlYXRlIiwiVXBkYXRlIiwiRGVsZXRlIiwiUmVmcmVzaCJdLCJjaGFubmVscyI6WyJWaWV3IiwiUGxheSIsIkNyZWF0ZSIsIlVwZGF0ZSIsIkRlbGV0ZSIsIlVwZGF0ZSBTb3VyY2UiXSwidGFyaWZmcyI6WyJWaWV3IiwiQ3JlYXRlIiwiVXBkYXRlIiwiRGVsZXRlIl0sInNldHRpbmdzIjpbIlZpZXciLCJDcmVhdGUiLCJVcGRhdGUiLCJEZWxldGUiXX0sImVtYWlsIjoiZGVtb0BtYWlsLm90dCIsImZpcnN0TmFtZSI6IkRlbW8iLCJsYXN0TmFtZSI6IkFjY291bnQiLCJhdmF0YXIiOiJhZG1pbi0xNzAyNzQwNTA2Mzk2LnBuZyIsImlhdCI6MTcwNjIzMDAwMSwiZXhwIjoxNzA2NDg5MjAxfQ.-jLNbZ4AImwsKuN_QMphkVeiBM0doozPo9V7xPKMCpE

  useEffect(() => {
    playerRef?.current?.handleSrc_(
      [
        {
          src: primaryUrl,
          type: 'application/x-mpegURL',
        },
      ],
      true
    );
  }, [primaryUrl])

  const videoJsOptions = {
    autoplay: true,
    preload: 'auto',
    controls: false,
    responsive: false,
    controls: true,
    fill: true,
    liveui: false,
    // controlBar: false,
    // loadingSpinner: true,
    bigPlayButton: false,
    fluid: false,
    aspectRatio: '16:9',
    sources: [{
      src: url,
      type: 'application/x-mpegURL',
    }],
    tracks: openModal?.modalValues?.item?.subtitles?.map?.((item, index) => ({ ...item, label: item.title || `Track ${index}`, srclang: item.language || `Caption ${index + 1}`, src: `${openModal?.modalValues?.item.VODServerInfo.host}${item.uri}` })) || [],
    html5: {
      hls: {
        overrideNative: true,
        beforeRequest: (x) => {
          console.log(x);
        },
        xhrSetup: xhr => {
          xhr.setRequestHeader('Authorization', getToken())
        }
      },
      vhs: {
        overrideNative: true,
        xhrSetup: xhr => {
          xhr.setRequestHeader('Authorization', getToken())
        }
      },
      hlsjsConfig: {
        // debug: true,
        xhrSetup: xhr => {
          xhr.setRequestHeader('Authorization', getToken())
        }

      }
    }
  };

  const selectSubs = (item) => {
    const tracks = playerRef?.current?.textTracks();
    Array.from(tracks).forEach(sub => sub.mode = sub?.src?.includes?.(item.uri) ? "showing" : "disabled")
  }

  // const selectAudioss = (item) => {
  //   const tracks = playerRef?.current?.audioTracks();
  //   Array.from(tracks).forEach(sub => sub.enabled = sub?.id?.includes?.(item.id))
  //   console.log(Array.from(tracks).find(sub => sub?.id?.includes?.(item.id)))
  // }



  const handlePlayerReady = useCallback((player) => {
    playerRef.current = player;
    player.vhs.xhr.beforeRequest = function (options) {
      options.headers = { ...options.headers, Authorization: getToken() }
      return options;
    };
    // var track = new videojs.AudioTrack({
    //   id: 'my-spanish-audio-track',
    //   kind: 'translation',
    //   label: 'Spanish',
    //   language: 'es'
    // });
    // playerRef.current.handleSrc_(
    //   [
    //     {
    //       src: url,
    //       type: 'application/x-mpegURL',
    //     },
    //   ],
    //   true
    // );
    // important for start from current live position
    // playerRef.current.currentTime(0)


    // player events
    // player.tech_.on(Hls.Events.ERROR, function (e) {
    //   console.log('HLS ERROR log', e);
    //   if(intervalIdRef.current){
    //     clearTimeout(intervalIdRef.current)
    //   }
    //     intervalIdRef.current = setTimeout(()=>{
    //       player.handleSrc_(
    //         [
    //           {
    //             src: url,
    //             type: 'application/x-mpegURL',
    //           },
    //         ],
    //         true
    //       );
    //     }, 5000)

    // })

    // player.on("loadeddata", () => {
    //   const audioTracks = player.audioTracks()
    //   const textTracks = player.textTracks()
    //   console.log(audioTracks.tracks_, textTracks.tracks_);
    // });
  }, []);
  // const audioTrackList = Array.from(playerRef.current.audioTracks());

  return (
    <MDDialog
      dialogHeight="fit-content"
      maxWidth="xl"
      fullWidth
      open={openModal?.bool}
      setOpen={setOpenModal}

    >
      <MDBox>
        <MDBox sx={{ display: 'flex', justifyContent: 'flex-end', maxWidth: '100%' }}>
          {/* <input value = {seekVal} onChange={(e)=>setSeekVal(e.target.value)} />
        <button onClick={()=>seek(seekVal)} > Seek</button> */}
          {headerComponent}
        </MDBox>
        {/* {url} */}
        <MDBox sx={{ display: 'flex' }} >
          {leftComponent}
          <VideoJS options={videoJsOptions} onReady={handlePlayerReady} />
          {rightComponent}
        </MDBox>
        <MDBox>
          {downComponent}
        </MDBox>
        <MDBox>
          {openModal?.modalValues?.item?.subtitles?.map?.(item => {
            return <MDButton variant="outlined" color="info" onClick={() => selectSubs(item)}>
              {item.title}
            </MDButton>
          })}
        </MDBox>

        {/* {audioTrackList?.map?.(item => {
          return <button onClick={() => selectAudioss(item)}>
            {item.label}
          </button>
        }
        )} */}

      </MDBox>
      <MDBox>
        {/* <video width="320" height="240" controls>
          <source src={url} type={'video/mp2t'} />
        </video> */}
      </MDBox>
    </MDDialog>
  );
}
