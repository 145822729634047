import { Add } from '@mui/icons-material'
import { Card, Grid } from '@mui/material'

import MDBox from 'components/MDBox'
import MDButton from 'components/MDButton'
import MDTypography from 'components/MDTypography'
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import Modal from './modal'
import HostCard from 'components/HostCard'
import TemporaryDrawer from 'components/Drawer'
import Transcodes from './transcodes'
import { addTranscoder } from 'redux/actions/transcoders'
import { getTranscoders } from 'redux/actions/transcoders'
import { deleteTranscoder } from 'redux/actions/transcoders'
import { getTranscodes } from 'redux/actions/transcoder'
import MainLoader from 'components/loader/loader'
import WithPermission from 'components/withPermission/WithPermission'
import { AddPermission } from 'pages/settings/Admins/constants'
import { checkPermission } from 'utils/checkPermission'

export default function Transcoders() {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const transcoders = useSelector(store => store?.transcoders?.list)
    const loading = useSelector(store => store?.transcoders?.loading)
    // const transcoder = useSelector(store=>store?.transcoder)
    // const transcodes = transcoder?.list?.filter(item=>item.transcoderId === transcoderInfo.id)
    const allTranscodes = useSelector(store => store?.transcoder?.list)
    const [openModal, setOpenModal] = useState({ bool: false })
    const [openTranscodes, setOpenTranscodes] = useState(false)



    useEffect(() => {
        dispatch(getTranscoders({ initial: true }))
        dispatch(getTranscodes())
        const id = setInterval(() => {
            dispatch(getTranscoders())
        }, 15000)
        return () => {
            clearInterval(id)
        }
    }, [])

    // const handleDelete = (values) => {
    //     dispatch(deleteStreamer(values))
    // }


    const handleCreate = (values) => {
        dispatch(addTranscoder(values))
    }

    // const handleUpdate = () => {

    // }

    // const handleStopStream = (item) => {
    //     dispatcher({ type: 'add', id: item._id })
    //     dispatch(stopStream(item, () => dispatcher({ type: 'remove', id: item._id })))
    // }

    // const handleStartStream = (item) => {
    //     dispatcher({ type: 'add', id: item._id })
    //     dispatch(refreshStream(item, ()=> dispatcher({ type: 'remove', id: item._id })))
    // }

    // const handleRefreshStream = (item) => {
    //     dispatcher({ type: 'add', id: item._id })
    //     dispatch(refreshStream(item, ()=> dispatcher({ type: 'remove', id: item._id })))
    // }


    const handleOpenModal = (item, isUpdate) => {
        setOpenModal({
            bool: true,
            onSave: isUpdate ? handleUpdate.bind(item) : handleCreate,
            modalValues: item,
            type: "Transcoder"
        });
    }

    const handleOpenTranscodes = (transcodes) => {
        setOpenTranscodes(transcodes)
    }

    const handleDeleteTranscoder = (id) => {
        if (!checkHaveTranscodes(id)) dispatch(deleteTranscoder(id))
    }

    const checkHaveTranscodes = (id) => {
        const transcodes = allTranscodes?.filter(item => item.transcoderId === id)
        return transcodes.length > 0
    }

    return (
        <DashboardLayout>
            <MDBox pt={6} pb={3}>
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Card>
                            <MDBox
                                mx={2}
                                mt={-3}
                                py={3}
                                px={2}
                                sx={{ display: "flex", justifyContent: "space-between" }}
                                variant="gradient"
                                bgColor="info"
                                borderRadius="lg"
                                coloredShadow="info"
                            >
                                <MDTypography variant="h6" color="white">
                                    {t("transcoder.header")}
                                </MDTypography>
                                <WithPermission disable={true} permissionsList={[AddPermission]} permissionKey={'transcoder'}>
                                    <MDButton disabled={!checkPermission([AddPermission], 'transcoder')} onClick={handleOpenModal} withIcon={<Add />} loading={false}>
                                        {t("transcoder.addNewTranscoder")}
                                    </MDButton>
                                </WithPermission>
                            </MDBox>
                            <MDBox pt={3}>
                                <Grid container>
                                    {loading && !transcoders.length ?
                                        <Grid item xs={12}>
                                            <MDBox sx={{ margin: '30vh auto', width: 'fit-content' }} >
                                                <MainLoader />
                                            </MDBox>
                                        </Grid>
                                        :
                                        transcoders?.map(item => (
                                            <Grid key={item._id} item xs={12}>
                                                <HostCard
                                                    permissionKey='transcoder'
                                                    type='Transcoder'
                                                    deleteCB={!checkHaveTranscodes(item._id) && handleDeleteTranscoder}
                                                    id={item._id}
                                                    host={item.url}
                                                    name={item.name}
                                                    systemInfo={item}
                                                    actionText={`${t("View")} ${t("Transcodes")}`}
                                                    actionClick={handleOpenTranscodes}
                                                />
                                            </Grid>
                                        ))}
                                </Grid>
                            </MDBox>
                        </Card>
                    </Grid>
                </Grid>
                <Modal openModal={openModal} setOpenModal={setOpenModal} />
                <TemporaryDrawer paperStyles={"drawerInfoPanelServers"} open={openTranscodes} setOpen={setOpenTranscodes} >
                    <Transcodes transcoderInfo={openTranscodes} />
                </TemporaryDrawer>
            </MDBox>
        </DashboardLayout>
    )
}
