import { GET_CUSTOMERS } from "redux/types";
import { catchAsync } from "utils/catchAsync";
import { api } from "../../api/config";
import store from "redux/store";
import { addNotification } from "./notifications";
import i18n from "i18n";

export const getCustomers = () =>
  catchAsync(async (dispatch) => {
    dispatch({
      type: GET_CUSTOMERS,
      payload: {
        loading: true,
      },
    });
    const {
      data: { data: allCustomers },
    } = await api.get("/users");
    dispatch({
      type: GET_CUSTOMERS,
      payload: {
        loading: false,
        list: allCustomers,
      },
    });
  }, (error) => {
    store.dispatch({
      type: GET_CUSTOMERS,
      payload: { ...error, loading: false }
    })
  });

export const deleteCustomer = (id) =>
  catchAsync(async (dispatch) => {
    dispatch({
      type: GET_CUSTOMERS,
      payload: {
        loading: true,
      },
    });
    const {
      data: { data: allCustomers },
    } = await api.delete(`/users/${id}`);

    dispatch({
      type: GET_CUSTOMERS,
      payload: {
        loading: false,
        list: allCustomers,
      },
    });
  });

export const updateCustomer = (id, body, cb, suppressSuccessNotification) =>
  catchAsync(async (dispatch) => {
    dispatch({
      type: GET_CUSTOMERS,
      payload: {
        loading: true,
      },
    });
    const result = await api.patch(`/users/${id}`, body);
    const allCustomers = result?.data?.customers
    cb?.()
    if(!suppressSuccessNotification){
      dispatch(addNotification({
        dateTime: Date.now(),
        color: 'success',
        title: i18n.t('Saved'),
        content: i18n.t('success_saved'),
      }))
    }
    dispatch({
      type: GET_CUSTOMERS,
      payload: {
        loading: false,
        list: allCustomers,
      },
    });
  }, (error) => {
    store.dispatch({
      type: GET_CUSTOMERS,
      payload: { loading: false }
    })
  });

export const createCustomer = (body, cb) =>
  catchAsync(async (dispatch) => {
    dispatch({
      type: GET_CUSTOMERS,
      payload: {
        loading: true,
      },
    });
    const {
      data: { data: allCustomers },
    } = await api.post(`/users`, body);
    cb?.()
    dispatch(addNotification({
      dateTime: Date.now(),
      color: 'success',
      title: i18n.t('Saved'),
      content: i18n.t('success_saved'),
    }))

    dispatch({
      type: GET_CUSTOMERS,
      payload: {
        loading: false,
        list: allCustomers,
      },
    });
  }, (error) => {
    store.dispatch({
      type: GET_CUSTOMERS,
      payload: { ...error, loading: false }
    })
  });

export const createSubuser = (body, adminId) =>
  catchAsync(async (dispatch) => {
    dispatch({
      type: GET_CUSTOMERS,
      payload: {
        loading: true,
      },
    });
    const {
      data: { data: allCustomers },
    } = await api.post(`/users/${adminId}/subuser`, body);

    dispatch({
      type: GET_CUSTOMERS,
      payload: {
        loading: false,
        list: allCustomers,
      },
    });
  }, (error) => {
    store.dispatch({
      type: GET_CUSTOMERS,
      payload: { ...error, loading: false }
    })
  });


export const updateSubuser = (body, adminId) =>
  catchAsync(async (dispatch) => {
    dispatch({
      type: GET_CUSTOMERS,
      payload: {
        loading: true,
      },
    });
    const {
      data: { data: allCustomers },
    } = await api.put(`/users/${adminId}/subuser`, body);
    dispatch(addNotification({
      dateTime: Date.now(),
      color: 'success',
      title: i18n.t('Saved'),
      content: i18n.t('success_saved'),
    }))
    dispatch({
      type: GET_CUSTOMERS,
      payload: {
        loading: false,
        list: allCustomers,
      },
    });
  }, (error) => {
    store.dispatch({
      type: GET_CUSTOMERS,
      payload: { ...error, loading: false }
    })
  });

export const deleteSubuser = (body, adminId) =>
  catchAsync(async (dispatch) => {
    dispatch({
      type: GET_CUSTOMERS,
      payload: {
        loading: true,
      },
    });
    const {
      data: { data: allCustomers },
    } = await api.delete(`/users/${adminId}/subuser`, { params: { subId: body.id } });

    dispatch({
      type: GET_CUSTOMERS,
      payload: {
        loading: false,
        list: allCustomers,
      },
    });
  }, (error) => {
    store.dispatch({
      type: GET_CUSTOMERS,
      payload: { ...error, loading: false }
    })
  });



