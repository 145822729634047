import { Grid } from "@mui/material";
import MDBox from "components/MDBox";
import MDDialog from "components/MDDialog";
import MDInput from "components/MDInput";
import React, { useEffect, useMemo, useState } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import { apiAuth } from "api/config";
import { api } from "api/config";
import MDTypography from "components/MDTypography";
import Copy from "components/Copy";


const schema = yup.object({
    name: yup.string().required('field is required'),
    url: yup.string().required('field is required'),
    license: yup.string().required('field is required'),
});

export default function Modal({ openModal, setOpenModal }) {
    const { t } = useTranslation()
    const [isLoading, setIsLoading] = useState(false)
    const [isChecked, setIsChecked] = useState(false)
    const [softwareId, setSoftwareId] = React.useState('')
    const initValues = {
        name: "",
        url: "",
        license: ""
    };

    const { values, handleChange, touched, errors, handleSubmit, setFieldValue, setErrors, setFieldTouched } = useFormik({
        initialValues: initValues,
        validationSchema: schema,
        onSubmit: async (values, { setErrors }) => {
            try {
                setIsLoading(true)
                try {
                    await api.post("/license", { license: values.license, server: openModal.type, softwareId: softwareId })
                } catch (error) {
                    throw { 'license': error?.response?.data?.errorMsg }
                }
                const { data } = await apiAuth.get(`${values.url}/system`)
                openModal?.onSave({ ...values, historyStatus: { ...data, date: Date.now() } });
                setOpenModal({ bool: false });
                setIsLoading(false)
            } catch (error) {
                if (error.isAxiosError) {
                    setErrors({ 'url': 'Host is unreachable'})
                }else{
                    setErrors(error)
                }
                setIsLoading(false)
            }
        },
    });

    useEffect(() => {
        if (openModal?.modalValues) {
            Object.keys(initValues).forEach((key) => {
                setFieldValue(key, openModal?.modalValues?.[key] ?? '');
            });
        }
    }, [openModal?.modalValues]);

    useEffect(() => {
        const check = async () => {
            try {
                setIsLoading(true)
                const result = await apiAuth.get(`${values.url}/system/serverInfo`)
                setSoftwareId(result.data.serverInfo)
                const softwareId = result.data.serverInfo
                const data = { license: openModal?.modalValues?.license, server: openModal.type, softwareId }
                const res = await api.post("/license", data)
                setIsLoading(false)
                setIsChecked(true)
            } catch (error) {
                console.log({ error });
                await setFieldTouched('license')
                setErrors({ 'license': error?.response?.data?.errorMsg })
                setIsLoading(false)
                setIsChecked(false)
                return
            }
        }
        if (openModal.bool && openModal?.modalValues?.license && values.url) {

            check()
        }
    }, [openModal.bool, values.url])

    const getButtonName = useMemo(() => {
        const type = openModal?.type || 'Transcoder'
        switch (type) {
            case 'Archiver':
                return t('archiver.update')
            case 'Transcoder':
                return t('transcoder.update')
            case 'Streamer':
                return t('streamer.update')
            case 'VODs':
                return t('VODServer.update')
            default:
                throw new Error('host type not specified')
        }
    }, [openModal.type])



    

        const getSoftwareId = async (e) => {
            try {
                const result = await apiAuth.get(`${e.target.value}/system/serverInfo`)
                setSoftwareId(result.data.serverInfo)
            } catch (error) { 
                console.log(error);
             }
        }

    return (
        <MDDialog
            maxWidth="md"
            fullWidth
            dialogHeight="fit-content"
            open={openModal?.bool}
            setOpen={setOpenModal}
            onSave={handleSubmit}
            loading={isLoading}
            submitText={getButtonName}
        >
            <MDBox>
                <Grid rowSpacing={2} container>
                    <Grid item xs={3}>
                        {t("Name")}:
                    </Grid>
                    <Grid item xs={9}>
                        <MDInput
                            fullWidth
                            value={values.name}
                            id="name"
                            name="name"
                            error={touched.name && errors.name}
                            helperText={touched.name && errors.name}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        {t("server_URL")}:
                    </Grid>
                    <Grid item xs={9}>
                        <MDInput
                            disabled={true}
                            fullWidth
                            value={values.url}
                            placeholder="http://"
                            id="url"
                            name="url"
                            error={touched.url && errors.url}
                            helperText={touched.url && errors.url}
                            onChange={(e)=>{getSoftwareId(e); handleChange(e)}}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        {t('Software ID')}:
                    </Grid>
                    <Grid item xs={9}>
                        <MDTypography variant='caption' sx={{whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow:'hidden', display: 'inline-block', width: '480px'}} >{softwareId}</MDTypography>
                        <Copy str={softwareId} />
                    </Grid>
                    <Grid item xs={3}>
                        {t("License")}:
                    </Grid>
                    <Grid item xs={9}>
                        <MDInput
                            multiline
                            fullWidth
                            rows={5}
                            value={values.license}
                            id="license"
                            name="license"
                            placeholder=""
                            success={isChecked && !errors.license && !isLoading}
                            error={errors.license}
                            helperText={isLoading ? 'Checking license' : (touched.license && errors.license) || (isChecked && 'License is valid')}
                            onChange={(e) => { setIsChecked(false); handleChange(e) }}
                        />
                    </Grid>
                </Grid>
            </MDBox>
        </MDDialog>
    );
}