import Cookies from 'universal-cookie';
import jwt_decode from 'jwt-decode'

const cookies = new Cookies();
export const isLogged = () => !!cookies.get('OTT_TOKEN')
export const setToken = (token) => {
    const decoded = jwt_decode(token)
    cookies.set('OTT_TOKEN', token, {path: '/', expires: new Date(decoded.exp * 1000)})
}
export const getToken = () => cookies.get('OTT_TOKEN')
export const removeToken = () => cookies.remove('OTT_TOKEN')