/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import App from "App";
import { Provider } from 'react-redux';
import store from "redux/store";
import i18n from './i18n';
import './global.css'
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';



// Material Dashboard 2 React Context Provider
import { MaterialUIControllerProvider } from "context";
import { I18nextProvider } from "react-i18next";

ReactDOM.render(
  <I18nextProvider i18n={i18n}>
    <Provider store={store} >
      <BrowserRouter>
        <MaterialUIControllerProvider>
          <App />
        </MaterialUIControllerProvider>
      </BrowserRouter>
    </Provider>
  </I18nextProvider>,
  document.getElementById("root")
);
