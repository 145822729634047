import axios from "axios";
import { statusChecker } from "redux/reduxHelpers";
import store from "redux/store";
import { UPDATE_CRONS } from "redux/types";
import { GET_VODS } from "redux/types";
import { catchAsync } from "utils/catchAsync";
import { api } from "../../api/config";

const intervals = {}

export const getVODs = (options) =>

catchAsync(async (dispatch) => {
  options?.initial && dispatch({
    type: GET_VODS,
    payload: {
      loading: true,
    },
  });
  const {
    data: { result }
  } = await api.get("/VODs");
  dispatch({
      type: GET_VODS,
      payload: {
        loading: false,
        list: result
      },
    });
  });


  export const addVODs = (values) =>
  async (dispatch) => {
    try {
      dispatch({
        type: GET_VODS,
        payload: {
          loading: true,
        },
      });
      const {
        data: {result},
      } = await api.post("/VODs", values);
      dispatch({
        type: GET_VODS,
        payload: {
          loading: false,
          list: result,
        },
      });
    } catch (error) {
      console.log('error', {error});
    }
  };

  export const updateVODs = (values) =>
  catchAsync(async (dispatch) => {
    dispatch({
      type: GET_VODS,
      payload: {
        loading: true,
      },
    });
    const {
      data: { result },
    } = await api.put(`/VODs/${values._id}`, values);
    dispatch({
      type: GET_VODS,
      payload: {
        loading: false,
        list: result,
      },
    });
  }, (error) => {
    store.dispatch({
      type: GET_VODS,
      payload: {
        ...error,
        loading: false,
      },
    });
  });

  // export const refreshStream = (values, cb) =>
  // catchAsync(async (dispatch) => {
  //   const res = await apiVODs.post('/stream/refresh', values )
  //   const intervalId = statusChecker({
  //       name: values.name, 
  //       dispatchCb: (result)=>{
  //         dispatch({
  //           type: GET_VODS,
  //           payload: {
  //             loading: false,
  //             list: result, 
  //           },
  //         })}, 
  //       cb, 
  //       pathVODs:'stream', 
  //       pathapi:'streams', 
  //       apiAxios: apiVODs
  //     })
  //   if(intervals.hasOwnProperty(values._id)){
  //     clearInterval(intervals[values._id])
  //   }
  //   intervals[values._id] = intervalId
  // });

  // export const stopStream = (values, cb) => async (dispatch) => {
  //     const res = await apiVODs.post('/stream/destroy', values )
  //     const intervalId = statusChecker({
  //       name: values.name, 
  //       dispatchCb: (result)=>{
  //         dispatch({
  //           type: GET_VODS,
  //           payload: {
  //             loading: false,
  //             list: result, 
  //           },
  //         })}, 
  //       cb, 
  //       pathVODs:'stream', 
  //       pathapi:'streams', 
  //       apiAxios: apiVODs
  //     })
  //     if(intervals.hasOwnProperty(values._id)){
  //       clearInterval(intervals[values._id])
  //     }
  //     intervals[values._id] = intervalId
  //   }

  export const deleteVODs = (id) =>
  catchAsync(async (dispatch) => {
    dispatch({
      type: GET_VODS,
      payload: {
        loading: true,
      },
    });
    const {data: { result } } = await api.delete(`/VODs/${id}`);
    dispatch({
      type: GET_VODS,
      payload: {
        loading: false,
        list: result
      },
    });
  });
