import { Add } from '@mui/icons-material'
import { Card, Grid } from '@mui/material'
import MDBox from 'components/MDBox'
import MDButton from 'components/MDButton'
import MDTypography from 'components/MDTypography'
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import Modal from './modal'
import HostCard from 'components/HostCard'
import TemporaryDrawer from 'components/Drawer'
import Archives from './archives'
import { addArchiver } from 'redux/actions/archivers'
import { getArchivers } from 'redux/actions/archivers'
import { deleteArchiver } from 'redux/actions/archivers'
import MainLoader from 'components/loader/loader'
import { getArchives } from 'redux/actions/archiver'
import { AddPermission } from 'pages/settings/Admins/constants'
import WithPermission from 'components/withPermission/WithPermission'
import { checkPermission } from 'utils/checkPermission'

export default function Archivers() {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const archivers = useSelector(store => store?.archivers?.list)
    const loading = useSelector(store => store?.archivers?.loading)
    const allArchives = useSelector(store => store?.archiver?.list)
    const [openModal, setOpenModal] = useState({ bool: false })
    const [openArchives, setOpenArchives] = useState(false)



    useEffect(() => {
        dispatch(getArchivers({ initial: true }))
        dispatch(getArchives())
        const id = setInterval(() => {
            dispatch(getArchivers())
        }, 15000)
        return () => {
            clearInterval(id)
        }
    }, [])

    // const handleDelete = (values) => {
    //     dispatch(deleteStreamer(values))
    // }


    const handleCreate = (values) => {
        dispatch(addArchiver(values))
    }

    // const handleUpdate = () => {

    // }

    // const handleStopStream = (item) => {
    //     dispatcher({ type: 'add', id: item._id })
    //     dispatch(stopStream(item, () => dispatcher({ type: 'remove', id: item._id })))
    // }

    // const handleStartStream = (item) => {
    //     dispatcher({ type: 'add', id: item._id })
    //     dispatch(refreshStream(item, ()=> dispatcher({ type: 'remove', id: item._id })))
    // }

    // const handleRefreshStream = (item) => {
    //     dispatcher({ type: 'add', id: item._id })
    //     dispatch(refreshStream(item, ()=> dispatcher({ type: 'remove', id: item._id })))
    // }


    const handleOpenModal = (item, isUpdate) => {
        setOpenModal({
            bool: true,
            onSave: isUpdate ? handleUpdate.bind(item) : handleCreate,
            modalValues: item,
            type: "Archiver"
        });
    }

    const handleOpenArchives = (archives) => {
        setOpenArchives(archives)
    }

    const handleDeleteArchiver = (id) => {
        if (!checkHaveArchives(id)) dispatch(deleteArchiver(id))
    }

    const checkHaveArchives = (id) => {
        const archives = allArchives?.filter(item => item.archiverId === id)
        return archives?.length > 0
    }

    return (
        <DashboardLayout>
            <MDBox pt={6} pb={3}>
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Card>
                            <MDBox
                                mx={2}
                                mt={-3}
                                py={3}
                                px={2}
                                sx={{ display: "flex", justifyContent: "space-between" }}
                                variant="gradient"
                                bgColor="info"
                                borderRadius="lg"
                                coloredShadow="info"
                            >
                                <MDTypography variant="h6" color="white">
                                    {t("archiver.header")}
                                </MDTypography>
                                <WithPermission disable={true} permissionsList={[AddPermission]} permissionKey={'archiver'}>
                                    <MDButton disabled={!checkPermission([AddPermission], 'archiver')} onClick={handleOpenModal} withIcon={<Add />} loading={false}>
                                        {t("archiver.addNewArchiver")}
                                    </MDButton>
                                </WithPermission>
                            </MDBox>
                            <MDBox pt={3}>
                                <Grid container>
                                    {loading && !archivers.length ?
                                        <Grid item xs={12}>
                                            <MDBox sx={{ margin: '30vh auto', width: 'fit-content' }} >
                                                <MainLoader />
                                            </MDBox>
                                        </Grid>
                                        :
                                        archivers?.map(item => (
                                            <Grid key={item._id} item xs={12}>
                                                <HostCard permissionKey={'archiver'} type='Archiver' deleteCB={!checkHaveArchives(item._id) && handleDeleteArchiver} id={item._id} host={item.url} name={item.name} systemInfo={item} actionText={`${t("View")} ${t("Archives")}`} actionClick={handleOpenArchives} />
                                            </Grid>
                                        ))}
                                </Grid>
                            </MDBox>
                        </Card>
                    </Grid>
                </Grid>
                <Modal openModal={openModal} setOpenModal={setOpenModal} />
                <TemporaryDrawer paperStyles={"drawerInfoPanelServers"} open={openArchives} setOpen={setOpenArchives} >
                    <Archives archiverInfo={openArchives} />
                </TemporaryDrawer>
            </MDBox>
        </DashboardLayout>
    )
}
