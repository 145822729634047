import {
    Add,
    PlayCircle,
    Delete,
    Edit,
    Sync as SyncIcon,
    Warning as WarningIcon,
    CheckBox as CheckBoxIcon,
    IndeterminateCheckBox as IndeterminateCheckBoxIcon,
    // Troubleshoot as TroubleshootIcon,
    Stop as StopIcon,
    PlayArrow as PlayArrowIcon,
    MonitorHeart
} from '@mui/icons-material'
import { Card, FormControlLabel, Grid, IconButton, Switch, Tooltip } from '@mui/material'
import MDBox from 'components/MDBox'
import MDButton from 'components/MDButton'
import MDTypography from 'components/MDTypography'
import DataTable from 'examples/Tables/DataTable'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import CreateTranscode from './CreateTranscode'
import { createTranscode } from 'redux/actions/transcoder'
import { getTranscodes } from 'redux/actions/transcoder'
import { refreshTranscode } from 'redux/actions/transcoder'
import { ClipLoader } from 'react-spinners'
import { deleteTranscode } from 'redux/actions/transcoder'
import { stopTranscode } from 'redux/actions/transcoder'
import ModalPlayer from 'components/playerModal'
import MDSelect from 'components/MDSelect'
import { updateTranscode } from 'redux/actions/transcoder'
import Copy from 'components/Copy'
import { EditPermission } from 'pages/settings/Admins/constants'
import WithPermission from 'components/withPermission/WithPermission'
import { PlayPermission } from 'pages/settings/Admins/constants'
import { DeletePermission } from 'pages/settings/Admins/constants'
import { RefreshPermission } from 'pages/settings/Admins/constants'
import { AddPermission } from 'pages/settings/Admins/constants'
import { checkPermission } from 'utils/checkPermission'
import { infoModalState } from 'redux/actions/infoModal'
import styles from "./styles.module.css";
import { ViewPermission } from 'pages/settings/Admins/constants'
import TruncateString from 'components/TrancateString/TruncateString'
import AnalyzerModal from 'components/Analyzer/AnalyzerModal'
import FlowModal from '../../../components/CreateAllFlow/flowModal'
import MDDialog from 'components/MDDialog'
import CreateAllFlow from 'components/CreateAllFlow/CreateAllFlow'

export default function Transcoder({ transcoderInfo }) {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const { settings } = useSelector(store => store.settings)
    const [openPlayerModal, setOpenPlayerModal] = useState({ bool: false });
    const [selectedResolution, setSelectedResolution] = useState('')
    const [modalResolutions, setModalResolutions] = useState([])

    const [openModal, setOpenModal] = useState({ bool: false })
    const [openFullModal, setOpenFullModal] = useState({ bool: false })
    const [showFullModal, setShowFullModal] = useState(false)
    const [openAnalyzeModal, setOpenAnalyzeModal] = useState({ bool: false })

    const [labelHighlight, setLabelHighlight] = useState()
    const transcoder = useSelector(store => store?.transcoder)
    const transcodes = transcoder?.list?.filter(item => item.transcoderId === transcoderInfo.id)

    const handleRefreshTranscode = (item) => {
        dispatch(refreshTranscode({ ...item, defaultCommand: settings.defaults.transcoderCommand }))
    }

    const handleStopTranscode = (item) => {
        dispatch(stopTranscode({ ...item, defaultCommand: settings.defaults.transcoderCommand }))
    }

    const handleStartTranscode = (item) => {
        dispatch(refreshTranscode({ ...item, defaultCommand: settings.defaults.transcoderCommand }))
    }

    const handleDelete = (item) => {
        dispatch(deleteTranscode({ ...item, defaultCommand: settings.defaults.transcoderCommand }))
    }

    function handleOpenPlayerModal(item) {
        const options = item.resolution.map(item => item.resolution.replaceAll(':', "x"))
        setSelectedResolution(options[0])
        setModalResolutions(options)
        let url = `${item.transcoderInfo.host}/ramdisk/${item.name}/${options[0]}/index.m3u8`
        setOpenPlayerModal({
            bool: true,
            modalValues: { url: url },
        });
    }


    useEffect(() => {
        if (openPlayerModal?.modalValues?.url) {
            const arr = openPlayerModal.modalValues.url.split('/')
            arr.pop()
            arr.pop()
            arr.push(`${selectedResolution}/index.m3u8`)
            let url = arr.join("/")
            setOpenPlayerModal({
                bool: false,
                modalValues: { url: url },
            });
            setTimeout(() => {
                setOpenPlayerModal({
                    bool: true,
                    modalValues: { url: url },
                });
            }, 1)
        }
    }, [selectedResolution])


    const columns = [
        { Header: t("Name"), accessor: "Name", align: "left" },
        { Header: t("Output"), accessor: "Input", align: "left" },
        { Header: t("action"), accessor: "action", align: "center" },
    ];

    const handleOpenErrorInfo = (item) => {
        dispatch(infoModalState({
            open: true,
            header: `${t('errorOccuired')}`,
            info: item.output
        }))
    }

    const rows = transcodes?.map(item => {
        const isTranscodeActive = item.isActive;
        const isLoading = item.loading
        const output = item.output
        return {
            Name: item.name,
            Input: item.resolution.map(it => {
                const url = `${item.transcoderInfo.host}/ramdisk/${item.name}/${it.resolution.replaceAll(':', 'x')}/index.m3u8`
                return <MDBox sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: 500 }}>
                    <TruncateString text={url} maxLength={60} truncatePosition={"middle"} />
                    <MDBox >
                        <Copy str={`${item.transcoderInfo.host}/ramdisk/${item.name}/${it.resolution.replaceAll(':', 'x')}/index.m3u8`} />
                        <WithPermission disable={true} permissionsList={[ViewPermission]} permissionKey={'transcoder'}>
                            <Tooltip title={item?.keepAnalyzing?.includes(it.resolution.replaceAll(':', 'x')) ? t("Analyzing in background") : ""}>
                                <IconButton
                                    color={item?.keepAnalyzing?.includes(it.resolution.replaceAll(':', 'x')) ? "success" : "default"}
                                    disabled={isLoading || !checkPermission([ViewPermission], 'transcoder')}
                                    onClick={() => {
                                        handleOpenAnalyzeModal(item, it.resolution.replaceAll(':', 'x'));
                                    }}
                                >
                                    <MonitorHeart />
                                </IconButton>
                            </Tooltip>
                        </WithPermission>
                    </MDBox>

                </MDBox>
            }),
            action: (
                <MDBox className={styles.actions}>
                    <WithPermission disable={true} permissionsList={[EditPermission]} permissionKey={'transcoder'}>
                        <IconButton
                            disabled={isLoading || !checkPermission([EditPermission], 'transcoder')}
                            onClick={() => {
                                handleOpenModal(item, true);
                            }}
                        >
                            <Edit />
                        </IconButton>
                    </WithPermission>
                    <WithPermission disable={true} permissionsList={[PlayPermission]} permissionKey={'transcoder'}>
                        <IconButton disabled={isLoading || !checkPermission([PlayPermission], 'transcoder')} onClick={() => handleOpenPlayerModal(item)} color="success">
                            {" "}
                            <PlayCircle />{" "}
                        </IconButton>
                    </WithPermission>
                    <WithPermission disable={true} permissionsList={[DeletePermission]} permissionKey={'transcoder'}>

                        <Tooltip title={t('dblClick')} >
                            <IconButton
                                disabled={isLoading || !checkPermission([DeletePermission], 'transcoder')}
                                onDoubleClick={() => {
                                    confirm(`${t('askDelete')}`) && handleDelete(item);
                                }}
                            >
                                <Delete />
                            </IconButton>
                        </Tooltip>
                    </WithPermission>
                    <WithPermission disable={true} permissionsList={[RefreshPermission]} permissionKey={'transcoder'}>

                        <IconButton
                            disabled={isLoading || !checkPermission([RefreshPermission], 'transcoder')}
                            onClick={() => {
                                isTranscodeActive ? handleStopTranscode(item) : handleStartTranscode(item);
                            }}
                        >
                            {isTranscodeActive ? <StopIcon /> : <PlayArrowIcon />}
                        </IconButton>
                    </WithPermission>
                    <WithPermission disable={true} permissionsList={[RefreshPermission]} permissionKey={'transcoder'}>
                        <Tooltip title={isLoading ? t('loading') : t('updateNow')} >
                            <IconButton
                                disabled={isLoading || !checkPermission([RefreshPermission], 'transcoder')}
                                onClick={() => {
                                    !isLoading && handleRefreshTranscode(item);
                                }}
                            >
                                {isLoading ? <ClipLoader color='#338BED' size={20} /> : <SyncIcon />}
                            </IconButton>
                        </Tooltip>
                    </WithPermission>
                    <Tooltip title={isTranscodeActive ? t('active') : t('inactive')} >
                        <IconButton>
                            {isTranscodeActive ? <CheckBoxIcon sx={{ color: 'success.main' }} /> : <IndeterminateCheckBoxIcon sx={{ color: 'error.main' }} />}
                        </IconButton>
                    </Tooltip>
                    {!!output && <Tooltip title={output.length > 100 ? output?.substring?.(0, 100) + '...' : output} >
                        <MDButton onClick={() => handleOpenErrorInfo(item)} withIcon={<WarningIcon sx={{ color: 'error.main' }} />} variant='outlined' color='error' sx={{ padding: '5px 10px' }} >
                            {t('error')}
                        </MDButton>
                    </Tooltip>}
                </MDBox>
            ),
        }
    })

    useEffect(() => {
        if (transcoderInfo.label) {
            setLabelHighlight(transcoderInfo.label)
        }
    }, [transcoderInfo])


    useEffect(() => {
        dispatch(getTranscodes())
        const id = setInterval(() => {
            dispatch(getTranscodes())
        }, 10000);
        return () => {
            clearInterval(id)
        }
    }, [])

    const handleCreate = (values) => {
        dispatch(createTranscode({ ...values, transcoderId: transcoderInfo.id }))
    }

    const handleUpdate = function (values) {
        dispatch(updateTranscode(this._id, { ...values, transcoderId: transcoderInfo.id }))
    }

    const handleOpenModal = (item, isUpdate) => {
        setOpenModal({
            bool: true,
            onSave: isUpdate ? handleUpdate.bind(item) : handleCreate,
            modalValues: item,
            isUpdate
        });
    }

    const handleOpenAnalyzeModal = (item, resolution) => {
        setOpenAnalyzeModal({
            bool: true,
            modalValues: { ...item, currentResolution: resolution }
        });
    }




    return (
        <MDBox mx={2} my={2} pt={6} pb={3}>
            <Grid container spacing={6}>
                <Grid item xs={12}>
                    <Card>
                        <MDBox
                            mx={2}
                            mt={-3}
                            py={3}
                            px={2}
                            sx={{ display: "flex", justifyContent: "space-between" }}
                            variant="gradient"
                            bgColor="info"
                            borderRadius="lg"
                            coloredShadow="info"
                        >
                            <MDTypography variant="h6" color="white">
                                {t("transcodes.header", { transcoder: transcoderInfo.name })}
                            </MDTypography>
                            <WithPermission disable={true} permissionsList={[AddPermission]} permissionKey={'transcoder'}>
                                <MDButton disabled={!checkPermission([AddPermission], 'transcoder')} onClick={() => { handleOpenModal() }} withIcon={<Add />} loading={false}>
                                    {t("transcodes.addNew")}
                                </MDButton>
                            </WithPermission>
                        </MDBox>
                        <MDBox pt={3}>
                            {/* <MDBox sx={{display: 'flex', justifyContent: 'flex-end', padding: '0px 30px'}} >
                                    <MDButton onClick={()=>{}} variant='contained' sx={{ color: 'info.main', border: '1px solid' }} >{t('transcoder.updateFullList')}</MDButton>
                                </MDBox> */}
                            {rows && (
                                <DataTable
                                    table={{ columns, rows }}
                                    isSorted={false}
                                    showTotalEntries={false}
                                    canSearch={true}
                                    entriesPerPageBox={false}
                                    noEndBorder
                                    initialLabelHighlight={labelHighlight}
                                />
                            )}
                        </MDBox>
                    </Card>
                </Grid>
            </Grid>
            <MDDialog
                maxWidth="md"
                fullWidth
                dialogHeight="fit-content"
                open={openModal?.bool}
                setOpen={setOpenModal}
                suppressActions
                title={<MDBox sx={{ display: "flex", justifyContent: "flex-end" }}>
                    <FormControlLabel
                        control={
                            <Switch
                                color="primary"
                                checked={showFullModal}
                                onChange={(e) => { setShowFullModal(e.target.checked) }}
                            />
                        }
                        label={t("FullFlow")}
                        labelPlacement="end"
                        checked={showFullModal}
                    />
                </MDBox>}
            >
                {showFullModal ?
                <CreateAllFlow />
                :
                <CreateTranscode openModal={openModal} setOpenModal={setOpenModal} transcoderInfo={transcoderInfo} transcodes={transcodes} />}
            </MDDialog>
            
            {openAnalyzeModal.bool && <AnalyzerModal openModal={openAnalyzeModal} setOpenModal={setOpenAnalyzeModal} serverInfo={transcoderInfo} />}

            {openPlayerModal.bool && (
                <ModalPlayer openModal={openPlayerModal} setOpenModal={setOpenPlayerModal} selectedResolution={selectedResolution} headerComponent={
                    <MDSelect
                        sx={{ margin: 1 }}
                        label="Resolution"
                        value={selectedResolution}
                        onChange={(e) => setSelectedResolution(e)}
                        options={modalResolutions}
                        getOptionLabel={(option) => option}
                    />}
                />
            )}
        </MDBox>
    )
}
