import { api } from "api/config";
import { GET_ADMINS } from "redux/types";
import { catchAsync } from "utils/catchAsync";

export const getAdmins = () => catchAsync(async dispatch => {
  dispatch({
    type: GET_ADMINS,
    payload: { loading: true },
  });
  const { data: res } = await api.get('/admins');
  dispatch({
    type: GET_ADMINS,
    payload: { ...res, loading: false },
  });
}
);

export const createAdmin = (body) => catchAsync(async dispatch => {
  dispatch({
    type: GET_ADMINS,
    payload: { loading: true },
  });
  const { data: res } = await api.post('/admins', body, { params: { redirectURL: window.location.origin } });
  dispatch({
    type: GET_ADMINS,
    payload: { ...res, loading: false },
  });
}, ({ store }) => {
  store.dispatch({
    type: GET_ADMINS,
    payload: { loading: false },
  });
}
);

export const deleteAdmin = (id) => catchAsync(async dispatch => {
  dispatch({
    type: GET_ADMINS,
    payload: { loading: true },
  });
  const { data: res } = await api.delete(`/admins/${id}`);
  dispatch({
    type: GET_ADMINS,
    payload: { ...res, loading: false },
  });
}, ({ store }) => {
  store.dispatch({
    type: GET_ADMINS,
    payload: { loading: false },
  });
}
);

export const updateAdminPasswords = ({ body, token }) => catchAsync(async dispatch => {
  dispatch({
    type: GET_ADMINS,
    payload: { loading: true },
  });
  const { data: res } = await api.put('/admins', body, { headers: { Authorization: token } });
  dispatch({
    type: GET_ADMINS,
    payload: { ...res, loading: false },
  });
}, ({ store }) => {
  store.dispatch({
    type: GET_ADMINS,
    payload: { loading: false },
  });
}
);

export const updateAdmin = (id, body) => catchAsync(async dispatch => {
  dispatch({
    type: GET_ADMINS,
    payload: { loading: true },
  });
  const { data: res } = await api.put(`/admins/${id}`, body);
  dispatch({
    type: GET_ADMINS,
    payload: { ...res, loading: false },
  });
}, ({ store }) => {
  store.dispatch({
    type: GET_ADMINS,
    payload: { loading: false },
  });
}
);

export const getVisibleWidgets = (id) => catchAsync(async dispatch => {
  dispatch({
    type: GET_ADMINS,
    payload: { loading: true },
  });
  const { data: res } = await api.get(`/admins/${id}/widgets`);
  dispatch({
    type: GET_ADMINS,
    payload: { ...res, loading: false },
  });
}, ({ store }) => {
  store.dispatch({
    type: GET_ADMINS,
    payload: { loading: false },
  });
})

export const updateAdminWdigets = (id, body) => catchAsync(async dispatch => {
  dispatch({
    type: GET_ADMINS,
    payload: { loading: true },
  });
  const { data: res } = await api.patch(`/admins/${id}/widgets`, body);
  dispatch({
    type: GET_ADMINS,
    payload: { ...res, loading: false },
  });
}, ({ store }) => {
  store.dispatch({
    type: GET_ADMINS,
    payload: { loading: false },
  });
}
);

