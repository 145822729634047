import store from "redux/store"

export const checkPermission = (permissionsList, permissionKey) => {
    const state = store.getState()
    const permissions = state.auth.permissions
    if (!permissions?.[permissionKey]) {
        return (
          true
        )
      }
      if (permissions[permissionKey]?.length !== 0 && permissionsList?.every(item => permissions?.[permissionKey]?.includes(item))) {
        return (
          true
        )
      } else {
        return false
      }
}