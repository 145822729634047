/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/** 
  All of the routes for the Material Dashboard 2 React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that has other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// Material Dashboard 2 React layouts
import Dashboard from "pages/dashboard";
import Tables from "layouts/tables";
import Billing from "layouts/billing";
import RTL from "layouts/rtl";
import Notifications from "layouts/notifications";
import Profile from "layouts/profile";
import SignIn from "layouts/authentication/sign-in";
import SignUp from "layouts/authentication/sign-up";

// @mui icons
import Icon from "@mui/material/Icon";
import Tariffs from "pages/tariffs";
import Channels from "pages/channels";
import { PlayCircle, Settings as SettingsIcon } from "@mui/icons-material";
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import Customers from "pages/customers";
import Epg from "pages/epg";
import Streamers from "pages/streamers";
import TvIcon from '@mui/icons-material/Tv';
import Transcoders from "pages/transcoders";
import TerminalIcon from '@mui/icons-material/Terminal';
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';
import MovieIcon from '@mui/icons-material/Movie';
import Archiver from "pages/archivers";
import Settings from "pages/settings";
import VODs from "pages/VODs";

const routes = (t) => [
  {
    type: "collapse",
    name: t("Dashboard"),
    key: "dashboard",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/dashboard",
    component: <Dashboard />,
  },
  {
    type: "collapse",
    name: t("Tariffs"),
    key: "tariffs",
    // collapse: ['/asd', '/dsa'],
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/tariffs",
    component: <Tariffs />,
  },
  {
    type: "collapse",
    name: t("Channels"),
    key: "channels",
    // collapse: ['/asd', '/dsa'],
    icon: <PlayCircle />,
    route: "/channels",
    component: <Channels />,
  },
  {
    type: "collapse",
    name: t("Customers"),
    key: "customers",
    // collapse: ['/asd', '/dsa'],
    icon: <Icon fontSize="small">person</Icon>,
    route: "/customers",
    component: <Customers />,
  },
  {
    type: "collapse",
    name: t("Epg"),
    key: "EPG",
    // collapse: ['/asd', '/dsa'],
    icon: <LibraryBooksIcon/>,
    route: "/EPG",
    component: <Epg />,
  },
  {
    type: "collapse",
    name: t("Streamers"),
    key: "streamer",
    icon: <TvIcon />,
    route: "/streamer",
    component: <Streamers />,
  },
  {
    type: "collapse",
    name: t("Transcoders"),
    key: "transcoder",
    icon: <TerminalIcon />,
    route: "/transcoder",
    component: <Transcoders />,
  },
  {
    type: "collapse",
    name: t("Archivers"),
    key: "archiver",
    icon: <OndemandVideoIcon />,
    route: "/archiver",
    component: <Archiver />,
  },
  {
    type: "collapse",
    name: t("VODRoute"),
    key: "VOD",
    icon: <MovieIcon />,
    route: "/VOD",
    component: <VODs />,
  },
  {
    type: "collapse",
    name: t("Settings"),
    key: "settings",
    // collapse: ['/asd', '/dsa'],
    icon: <SettingsIcon />,
    route: "/settings",
    component: <Settings />,
  },
  // ****************************
  // {
  //   type: "collapse",
  //   name: t("Billing"),
  //   key: "billing",
  //   icon: <Icon fontSize="small">receipt_long</Icon>,
  //   route: "/billing",
  //   component: <Billing />,
  // },
  // {
  //   type: "collapse",
  //   name: t("RTL"),
  //   key: "rtl",
  //   icon: <Icon fontSize="small">format_textdirection_r_to_l</Icon>,
  //   route: "/rtl",
  //   component: <RTL />,
  // },
  // {
  //   type: "collapse",
  //   name: t("Notifications"),
  //   key: "notifications",
  //   icon: <Icon fontSize="small">notifications</Icon>,
  //   route: "/notifications",
  //   component: <Notifications />,
  // },
  // {
  //   type: "collapse",
  //   name: t("Profile"),
  //   key: "profile",
  //   icon: <Icon fontSize="small">person</Icon>,
  //   route: "/profile",
  //   component: <Profile />,
  // },
  // {
  //   type: "collapse",
  //   name: t("Sign )In",
  //   key: "sign-in",
  //   icon: <Icon fontSize="small">login</Icon>,
  //   route: "/authentication/sign-in",
  //   component: <SignIn />,
  //   publicRoute: true,
  // },
  // {
  //   type: "collapse",
  //   name: t("Sign )Up",
  //   key: "sign-up",
  //   icon: <Icon fontSize="small">assignment</Icon>,
  //   route: "/authentication/sign-up",
  //   component: <SignUp />,
  //   publicRoute: true,
  // },
];

export default routes;
