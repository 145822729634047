import { Add } from '@mui/icons-material'
import { Card, Grid, IconButton, Tooltip } from '@mui/material'
import MDBox from 'components/MDBox'
import MDButton from 'components/MDButton'
import MDTypography from 'components/MDTypography'
import DataTable from 'examples/Tables/DataTable'
import React, { useEffect, useState } from 'react'
import { Delete, Edit } from "@mui/icons-material";
import SyncIcon from '@mui/icons-material/Sync';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import WarningIcon from '@mui/icons-material/Warning';
import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox';
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import CreateArchive from './CreateArchive'
import { ClipLoader } from 'react-spinners'
import { deleteArchive, updateArchive, stopArchive, refreshArchive, getArchives, createArchive } from 'redux/actions/archiver'
import StopIcon from '@mui/icons-material/Stop';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import { EditPermission } from 'pages/settings/Admins/constants'
import WithPermission from 'components/withPermission/WithPermission'
import { checkPermission } from 'utils/checkPermission'
import { DeletePermission } from 'pages/settings/Admins/constants'
import { RefreshPermission } from 'pages/settings/Admins/constants'
import { AddPermission } from 'pages/settings/Admins/constants'
import styles from "./styles.module.css";
import { infoModalState } from 'redux/actions/infoModal'
import MDDialog from 'components/MDDialog'

export default function Archiver({ archiverInfo }) {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const { settings } = useSelector(store => store.settings)
    const [openModal, setOpenModal] = useState({ bool: false })
    const [openPlayerModal, setOpenPlayerModal] = useState({ bool: false });
    const archiver = useSelector(store => store?.archiver)
    const archives = archiver?.list?.filter(item => item.archiverId === archiverInfo.id)

    const handleRefreshArchive = (item) => {
        dispatch(refreshArchive({ ...item, defaultCommand: settings.defaults.archiverCommand }))
    }

    const handleStopArchive = (item) => {
        dispatch(stopArchive({ ...item, defaultCommand: settings.defaults.archiverCommand }))
    }

    const handleStartArchive = (item) => {
        dispatch(refreshArchive({ ...item, defaultCommand: settings.defaults.archiverCommand }))
    }

    const handleDelete = (item) => {
        dispatch(deleteArchive({ ...item, defaultCommand: settings.defaults.archiverCommand }))
    }

    function handleOpenPlayerModal(item) {
        // const options = item.resolution.map(item=>item.resolution.replaceAll(':',"x"))
        // setSelectedResolution(options[0])
        // setModalResolutions(options)
        // let url = `${item.transcoderInfo.host}/ramdisk/${item.name}/${options[0]}/index.m3u8`
        // setOpenPlayerModal({
        //   bool: true,
        //   modalValues: { url: url },
        // });
    }

    const columns = [
        { Header: t("Name"), accessor: "Name", align: "left" },
        { Header: t("Source"), accessor: "Input", align: "left" },
        { Header: t("action"), accessor: "action", align: "center" },
    ];

    const handleOpenErrorInfo = (item) => {
        dispatch(infoModalState({
            open: true,
            header: `${t('errorOccuired')}`,
            info: item.output
        }))
    }

    const rows = archives?.map(item => {
        const isArchiveActive = item.isActive;
        const isLoading = item.isLoading
        const output = item.output
        return {
            Name: item.name,
            Input: item?.input,
            action: (
                <MDBox>
                    <WithPermission disable={true} permissionsList={[EditPermission]} permissionKey={'archiver'}>
                        <IconButton
                            disabled={isLoading || !checkPermission([EditPermission], 'archiver')}
                            onClick={() => {
                                handleOpenModal(item, true);
                            }}
                        >
                            <Edit />
                        </IconButton>
                    </WithPermission>
                    {/* <IconButton onClick={() => handleOpenPlayerModal(item)} color="success">
                        {" "}
                        <PlayCircle />{" "}
                        </IconButton> */}
                    <WithPermission disable={true} permissionsList={[DeletePermission]} permissionKey={'archiver'}>

                        <Tooltip title={t('dblClick')} >
                            <IconButton
                                disabled={isLoading || !checkPermission([DeletePermission], 'archiver')}
                                onDoubleClick={() => {
                                    confirm(`${t('askDelete')}`) && handleDelete(item);
                                }}
                            >
                                <Delete />
                            </IconButton>
                        </Tooltip>
                    </WithPermission>
                    <WithPermission disable={true} permissionsList={[RefreshPermission]} permissionKey={'archiver'}>

                        <IconButton
                            disabled={isLoading || !checkPermission([RefreshPermission], 'archiver')}
                            onClick={() => {
                                isArchiveActive ? handleStopArchive(item) : handleStartArchive(item);
                            }}
                        >
                            {isArchiveActive ? <StopIcon /> : <PlayArrowIcon />}
                        </IconButton>
                    </WithPermission>
                    <WithPermission disable={true} permissionsList={[RefreshPermission]} permissionKey={'archiver'}>

                        <Tooltip title={isLoading ? t('loading') : t('updateNow')} >
                            <IconButton
                                disabled={isLoading || !checkPermission([RefreshPermission], 'archiver')}
                                onClick={() => {
                                    !isLoading && handleRefreshArchive(item);
                                }}
                            >
                                {isLoading ? <ClipLoader color='#338BED' size={20} /> : <SyncIcon />}
                            </IconButton>
                        </Tooltip>
                    </WithPermission>
                    <Tooltip title={isArchiveActive ? t('active') : t('inactive')} >
                        <IconButton>
                            {isArchiveActive ? <CheckBoxIcon sx={{ color: 'success.main' }} /> : <IndeterminateCheckBoxIcon sx={{ color: 'error.main' }} />}
                        </IconButton>
                    </Tooltip>
                    {!!output && <Tooltip title={output.length > 100 ? output?.substring?.(0, 100) + '...' : output} >
                        <MDButton onClick={() => handleOpenErrorInfo(item)} withIcon={<WarningIcon sx={{ color: 'error.main' }} />} variant='outlined' color='error' sx={{ padding: '5px 10px' }} >
                            {t('error')}
                        </MDButton>
                    </Tooltip>}
                </MDBox>
            ),
        }
    })

    useEffect(() => {
        dispatch(getArchives())
        const id = setInterval(() => {
            dispatch(getArchives())
        }, 10000);
        return () => {
            clearInterval(id)
        }
    }, [])

    const handleCreate = (values) => {
        dispatch(createArchive({ ...values, archiverId: archiverInfo.id, defaultCommand: settings.defaults.archiverCommand }))
    }

    const handleUpdate = function (values) {
        dispatch(updateArchive(this?._id, { ...values, archiverId: archiverInfo.id, defaultCommand: settings.defaults.archiverCommand }))
    }

    const handleOpenModal = (item, isUpdate) => {
        setOpenModal({
            bool: true,
            onSave: isUpdate ? handleUpdate.bind(item) : handleCreate,
            modalValues: item,
            isUpdate
        });
    }



    return (
        <MDBox mx={2} my={2} pt={6} pb={3}>
            <Grid container spacing={6}>
                <Grid item xs={12}>
                    <Card>
                        <MDBox
                            mx={2}
                            mt={-3}
                            py={3}
                            px={2}
                            className={'serverProcessesHeader'}
                            variant="gradient"
                            bgColor="info"
                            borderRadius="lg"
                            coloredShadow="info"
                        >
                            <MDTypography variant="h6" color="white">
                                {t("archives.header", { archiver: archiverInfo.name })}
                            </MDTypography>
                            <WithPermission disable={true} permissionsList={[AddPermission]} permissionKey={'archiver'}>
                                <MDButton disabled={!checkPermission([AddPermission], 'archiver')} onClick={handleOpenModal} withIcon={<Add />} loading={false}>
                                    {t("archives.addNew")}
                                </MDButton>
                            </WithPermission>
                        </MDBox>
                        <MDBox pt={3}>
                            {/* <MDBox sx={{display: 'flex', justifyContent: 'flex-end', padding: '0px 30px'}} >
                                    <MDButton onClick={()=>{}} variant='contained' sx={{ color: 'info.main', border: '1px solid' }} >{t('archiver.updateFullList')}</MDButton>
                                </MDBox> */}
                            {rows && (
                                <DataTable
                                    table={{ columns, rows }}
                                    isSorted={false}
                                    showTotalEntries={false}
                                    canSearch={true}
                                    entriesPerPageBox={false}
                                    noEndBorder
                                />
                            )}
                        </MDBox>
                    </Card>
                </Grid>
            </Grid>

            <MDDialog
                suppressActions
                maxWidth="md"
                fullWidth
                dialogHeight="fit-content"
                open={openModal?.bool}
                setOpen={setOpenModal}
                submitText={openModal?.isUpdate ? t('archives.update') : t('archives.create')}
            >
                <CreateArchive openModal={openModal} setOpenModal={setOpenModal} archiverInfo={archiverInfo} archives={archives} />
            </MDDialog>
            {openPlayerModal.bool && (
                <ModalPlayer openModal={openPlayerModal} setOpenModal={setOpenPlayerModal} />
            )}
        </MDBox>
    )
}
