export const resolutionsList = [
    {resolution: '720:576', bitrate: '2.5M', maxRate: '3M', minRate: '2M', bufSize: '2.5M', id: 0, name: '720p'},
    {resolution: '1920:1080', bitrate: '5M', maxRate: '5.5M', minRate: '4M', bufSize: '5M', id: 1, name: '1080p'},        
]

export const defaultResolution = { resolution: 'Auto (master)', id: 'DEFAULT', name: 'Auto' }

export const movieGenres = [
    { id: "1", label: "Action" },
    { id: "2", label: "Adventure" },
    { id: "3", label: "Animation" },
    { id: "4", label: "Biography" },
    { id: "5", label: "Comedy" },
    { id: "6", label: "Crime" },
    { id: "7", label: "Documentary" },
    { id: "8", label: "Drama" },
    { id: "9", label: "Family" },
    { id: "10", label: "Fantasy" },
    { id: "11", label: "Film Noir" },
    { id: "12", label: "History" },
    { id: "13", label: "Horror" },
    { id: "14", label: "Music" },
    { id: "15", label: "Musical" },
    { id: "16", label: "Mystery" },
    { id: "17", label: "Romance" },
    { id: "18", label: "Sci-Fi" },
    { id: "19", label: "Sport" },
    { id: "20", label: "Thriller" },
    { id: "21", label: "War" },
    { id: "22", label: "Western" }
];


export const months = {
    en: [
      { index: "01", label: "Jan" },
      { index: "02", label: "Feb" },
      { index: "03", label: "Mar" },
      { index: "04", label: "Apr" },
      { index: "05", label: "May" },
      { index: "06", label: "Jun" },
      { index: "07", label: "Jul" },
      { index: "08", label: "Aug" },
      { index: "09", label: "Sep" },
      { index: "10", label: "Oct" },
      { index: "11", label: "Nov" },
      { index: "12", label: "Dec" },
    ],
    ru: [
      { index: "01", label: "Янв" },
      { index: "02", label: "Фев" },
      { index: "03", label: "Мар" },
      { index: "04", label: "Апр" },
      { index: "05", label: "Май" },
      { index: "06", label: "Июн" },
      { index: "07", label: "Июл" },
      { index: "08", label: "Авг" },
      { index: "09", label: "Сен" },
      { index: "10", label: "Окт" },
      { index: "11", label: "Ноя" },
      { index: "12", label: "Дек" },
    ],
    am: [
      { index: "01", label: "Հուն" },
      { index: "02", label: "Փետ" },
      { index: "03", label: "Մար" },
      { index: "04", label: "Ապր" },
      { index: "05", label: "Մայ" },
      { index: "06", label: "Հուն" },
      { index: "07", label: "Հուլ" },
      { index: "08", label: "Օգ" },
      { index: "09", label: "Սեպ" },
      { index: "10", label: "Հոկ" },
      { index: "11", label: "Նոյ" },
      { index: "12", label: "Դեկ" },
    ],
  };


export const ROLES = ['SuperAdmin', 'Manager', 'Editor', 'Cashier']
  
export const COMPANY_NAME = "RippleTV"


export const allDashboardWidgets = [
  "channels",
  "customers",
  "VOD",
  "revenue",
  "MonthlyActiveUsers",
  "MonthlyPayments",
  "TotalUserAccounts",
  "MonthlyActiveDevices",
  "MostViewedChannels",
]