import { PlayCircle } from '@mui/icons-material'
import { Grid, IconButton } from '@mui/material'
import MDAvatar from 'components/MDAvatar'
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'
import ModalPlayer from 'components/playerModal'
import WithPermission from 'components/withPermission/WithPermission'
import { PlayPermission } from 'pages/settings/Admins/constants'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { checkPermission } from 'utils/checkPermission'
import { getSingleItemByAnOption } from 'utils/helpers'

export default function Tariff({ tariff }) {
    const { t } = useTranslation()
    const [openPlayerModal, setOpenPlayerModal] = useState({ bool: false });
    const fullChannels = useSelector((store) => store?.channels?.list);

    const channels = tariff?.channels
        ?.map((channel) => getSingleItemByAnOption(fullChannels, "_id", channel))
        ?.filter(item => item)

    function handleOpenPlayerModal(item) {
        setOpenPlayerModal({
            bool: true,
            modalValues: { url: item?.url ?? "" },
        });
    }
    return (
        <Grid container>
            <Grid item xs={4}>
                <div style={{ display: 'flex', gap: '10px', flexDirection: 'column', marginBottom: '10px' }} >
                    <MDTypography variant={'body1'} >{`${t('Price')}: ${tariff?.price}`}</MDTypography>
                    <MDTypography variant={'body1'} >{`${t('Tariff_Type')}: ${tariff?.billType}`}</MDTypography>
                </div>
            </Grid>
            <Grid item xs={8}>
                <MDBox>
                    <Grid
                        container
                        spacing={2}
                        sx={{
                            overflow: 'auto',
                            height: '330px',
                        }}
                    >
                        <Grid item xs={12}>
                            <MDBox sx={{ textAlign: 'center', fontWeight: 'bold', marginBottom: '20px' }}>
                                <MDTypography variant={'body1'} >{t('Channels')} ({channels?.length})</MDTypography>
                            </MDBox>
                        </Grid>
                        {!!channels?.length ?
                            channels.map(item => (
                                <Grid item xs={4}>
                                    <MDBox sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", borderBottom: '1px solid lightgrey' }}>
                                        <MDAvatar
                                            src={`${process.env.REACT_APP_BASE_ORIGIN}/img/channels/${item?.image}`}
                                            name={item.email}
                                            size="sm"
                                        />
                                        <MDTypography variant={'body2'} >
                                            {item.name}
                                        </MDTypography>
                                        <WithPermission permissionsList={[PlayPermission]} permissionKey={'channels'} >
                                            <IconButton disabled={!checkPermission([PlayPermission], 'channels')} onClick={() => handleOpenPlayerModal(item)} color="success">
                                                <PlayCircle />
                                            </IconButton>
                                        </WithPermission>
                                    </MDBox>
                                </Grid>
                            ))
                            :
                            <MDBox sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', fontWeight: 'bold', paddingLeft: '20px', marginBottom: '20px', width: '100%', height: '100%' }}>
                                No channels
                            </MDBox>

                        }
                    </Grid>
                </MDBox>
                {openPlayerModal.bool && (
                    <ModalPlayer
                        openModal={openPlayerModal}
                        setOpenModal={setOpenPlayerModal}
                    />
                )}
            </Grid>
        </Grid>
    )
}
