import colors from 'assets/theme/base/colors';
import React from "react"
import ReactApexChart from "react-apexcharts"
import { convertBytes } from "utils/helpers"
import styles from './styles.module.css'

export default function BarChart({series, labels, onChartClick}) {
    const getColor = (value) => {
        const success = 420*1024*1024
        const warning = 550*1024*1024
        if(value<success){
            return colors.success.main
        }else if(value<warning){
            return colors.warning.main
        }else{
            return colors.error.main
        }
    }

    var options = {
      chart: {
        height: 450,
        type: 'bar',
        events: {
          dataPointSelection: (event, chartContext, config) => {
            const index = config.dataPointIndex
            const label = chartContext.grid.xaxisLabels[index]
            onChartClick(label)
          }
        }
      
      },
      plotOptions: {
        bar: {
          columnWidth: '45%',
          distributed: true,
        }
      },
      dataLabels: {
        enabled: false
      },
      legend: {
        show: false
      },
      colors: series.map(item=>getColor(item)),
      yaxis: {
        labels:{
            formatter: (val) => convertBytes(val),
        },
        tickAmount: 5.12,
        max: 1024*1024*1024,
      },
      xaxis: {
        categories: labels,
        labels: {
          style: {
            fontSize: '12px'
          }
        }
      }
      };


    return (
        <div className={styles.chart}>
            <ReactApexChart options={options} series={[{data: series, name: 'Memory used'}]} type='bar' height={200} width={'100%'}/>
        </div>
    )
}