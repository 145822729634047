import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import MDBox from "components/MDBox";
import MDDialog from "components/MDDialog";
import { useFormik } from "formik";
import * as yup from "yup";
import { Grid, IconButton, Popover, Skeleton, Tooltip } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import MDAvatar from "components/MDAvatar";
import { api } from "api/config";
import DataTable from "examples/Tables/DataTable";
import moment from "moment";
import InfoIcon from '@mui/icons-material/Info';
import FullInfoModal from "./FullInfoModal";
import styles from './styles.module.css'
import { truncateString } from "utils/helpers";
import MDSelectMui from "components/MDSelectMui";
import { addDays, startOfMonth } from 'date-fns';

import { DateRangePicker } from "react-date-range";
import MDButton from "components/MDButton";
import { getDayStartTimestamp } from "utils/helpers";
import { getDayEndTimestamp } from "utils/helpers";
import { ClipLoader } from "react-spinners";

const schema = yup.object({
    firstName: yup.string("Enter Admin name").required("Firstname is required"),
    lastName: yup.string("Enter Admin name").required("Lastname is required"),
    email: yup.string("Enter Email").required("Email is required"),
});

export default function HistoryModal({ openModal, setOpenModal }) {
    const { t } = useTranslation()
    const { i18n } = useTranslation()
    const [data, setData] = useState([])
    const { list, loading } = useSelector(store => store.admins)
    const [searching, setSearching] = useState(false)
    const [openFullInfoModal, setOpenFullInfoModal] = useState({ bool: false })
    const [indexes, setIndexes] = useState({ start: 0, end: 20 })
    const [selectedAdmins, setSelectedAdmins] = useState([])
    const [dates, setDates] = useState([
        {
            startDate: startOfMonth(new Date()),
            endDate: new Date(),
            key: 'selection'
        }
    ]);
    const [dateText, setDateText] = useState(`${moment(dates[0].startDate).format("MMM DD YYYY")} - ${moment(dates[0].endDate).format("MMM DD YYYY")}`)
    const [anchorEl, setAnchorEl] = React.useState(null);


    const methods = [
        { label: 'Created', id: 'POST' },
        { label: 'Deleted', id: 'DELETE' },
        { label: 'Updated', id: 'PUT,PATCH' },
    ]
    const [selectedMethods, setSelectedMethods] = useState(methods)



    useEffect(() => {
        setSelectedAdmins([openModal?.modalValues])
    }, [openModal?.modalValues])

    const lang = i18n.language
    const initValues = {
        firstName: openModal?.modalValues?.firstName ?? "",
        lastName: openModal?.modalValues?.lastName ?? undefined,
        email: openModal?.modalValues?.email ?? "",
        permissions: openModal?.modalValues?.permissions ?? {
            transcoder: [],
            archiver: [],
            streamer: [],
            VOD: [],
            customers: [],
            EPG: [],
            channels: [],
            tariffs: [],
            settings: []
        },
        avatar: openModal?.modalValues?.avatar ?? "",
    }

    const [now, setNow] = useState(Date.now())
    const id = useRef(null)
    useEffect(() => {
        if (!openModal.open) return id.current && clearInterval(id.current)
        id.current = setInterval(() => {
            setNow(Date.now())
        }, 1000)
        return () => {
            clearInterval(id.current)
        }
    }, [openModal.open])

    const skip = useRef(0)
    const take = useRef(20)

    const [total, setTotal] = useState(100)

    const getHistory = async (indexes) => {
        try {
            const startDate = getDayStartTimestamp(dates[0].startDate)
            const endDate = getDayEndTimestamp(dates[0].endDate)
            const { data: { data } } = await api.get(`/history/admins?from=${startDate}&to=${endDate}&methods=${selectedMethods.map(item => item.id).join()}&admins=${selectedAdmins.map(item => item._id)}&skip=${indexes.start}&take=${take.current}`)
            setIndexes(indexes)
            setData(data.list)
            setTotal(data.total)
            skip.current += take.current
        } catch (error) {
            console.log({ error });
        }
    }

    useEffect(() => {
        const getbySearch = async () => {
            setSearching(true)
            await getHistory(indexes)
            setSearching(false)
        }
        if (timeId.current) clearTimeout(timeId.current)
        timeId.current = setTimeout(() => {
            getbySearch()
        }, 200)

    }, [selectedAdmins, dates, selectedMethods])

    // useEffect(() => {
    //     setTimeout(() => {
    //         getHistory({ start: 0, end: 20 })
    //     }, 500)
    // }, [])

    const handleOpenFullModal = (item, actionText) => {
        setOpenFullInfoModal({
            bool: true,
            modalValues: { item, actionText },
        })
    }

    const columns = [
        { Header: "Admin", accessor: "Admin", align: "left" },
        { Header: "Action", accessor: "Action", align: "left" },
        { Header: "Date", accessor: "Date", align: "center" },
        { Header: "", accessor: "action", align: "center" },
    ];

    const getActionText = ({ method, collection, actionInfo, oldData, newData }) => {
        const aciton = method === "POST" ?
            t('Created') :
            method === "PUT" || method === "PATCH" ?
                t('Updated') :
                method === "DELETE" ?
                    t('Deleted') :
                    t('Viewed')

        const actionItem = collection?.toLocaleLowerCase()?.includes('channel') ?
            t('channel') :
            collection?.toLocaleLowerCase()?.includes('epg') ?
                t('EPG') :
                collection?.toLocaleLowerCase()?.includes('tariff') ?
                    t('tariff') :
                    collection?.toLocaleLowerCase()?.includes('user') ?
                        t('customer') :
                        collection?.toLocaleLowerCase()?.includes('stream') ?
                            t('stream') :
                            collection?.toLocaleLowerCase()?.includes('archive') ?
                                t('archive') :
                                collection?.toLocaleLowerCase()?.includes('transcode') ?
                                    t('transcode') :
                                    collection?.toLocaleLowerCase()?.includes('vod') ?
                                        t('VOD_') :
                                        collection?.toLocaleLowerCase()?.includes('settings') ?
                                            t('settings') : ''

        let actionItemName = actionInfo?.name || newData?.name || ''
        if (!actionItemName) {
            if (actionInfo?.firstName || actionInfo?.lastName) {
                actionItemName = `${actionInfo?.firstName} ${actionInfo?.lastName}`
            } else if (newData?.firstName || newData?.lastName) {
                actionItemName = `${newData?.firstName} ${newData?.lastName}`
            }
        }
        // ${isVowel(actionItem[0]) ? 'an' : 'a'}
        const updatedFields = (newData && typeof newData === 'object' && Object.keys(newData).map(item => t(item)).join(', ')) || ''

        return method === "POST" || method === "DELETE" ? `${aciton} ${actionItem} ${actionItemName}` : method === "PUT" || method === "PATCH" ? `${aciton} ${actionItem} ${actionItemName} ${updatedFields || ''}` : ''

    }

    const rows = [...new Array(indexes.start).fill(null), ...data, ...new Array(Math.max((total - indexes.start - data.length), 0)).fill(null)]?.map((item, i) => {
        if (!item) {
            return {
                Admin: <div style={{ display: 'flex', width: '100%', justifyContent: 'center', alignItems: 'center' }}>
                    <strong>{i + 1}.</strong>
                    <Skeleton variant="rectangular" animation="wave" width={36} height={25} sx={{ marginRight: '10px', borderRadius: '10px' }} />
                    <Skeleton variant="rectangular" animation="wave" width={110} sx={{ borderRadius: '5px' }} />
                </div>,
                Action: <Skeleton variant="rectangular" animation="wave" width={380} sx={{ borderRadius: '5px' }} />,
                Date: <Skeleton variant="rectangular" animation="wave" width={110} sx={{ borderRadius: '5px' }} />,
                action: <MDBox>
                    <Tooltip title={'Show detailed info'} >
                        {/* <WithPermission disable={true} permissionsList={[EditPermission]} permissionKey={'settings'} > */}
                        <IconButton
                            // disabled={!checkPermission([EditPermission], 'settings')}
                            color="info"
                        >
                            <InfoIcon />
                        </IconButton>
                        {/* </WithPermission> */}
                    </Tooltip>
                </MDBox>
            }
        }
        const isArchiveActive = item.isActive;
        const isLoading = item.isLoading
        const admin = item.admin
        const actionText = getActionText(item)
        return {
            Admin: <MDBox sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                <strong>{i + 1}.</strong>
                <MDAvatar
                    src={admin?.avatar ? `${process.env.REACT_APP_BASE_ORIGIN}/img/users/${admin?.avatar}` : `${process.env.REACT_APP_BASE_ORIGIN}/img/default.png`}
                    name={admin.email}
                    size="sm"
                />{" "}
                &nbsp; {`${admin.firstName} ${admin.lastName}`}
            </MDBox>,
            Action: <Tooltip title={actionText} >
                <div style={{ width: '380px' }}>
                    {truncateString(actionText, 50)}
                </div>
            </Tooltip>,
            Date: now - item.date > 2592000000 ? moment(item.date).format("DD/MM/YYYY HH:mm:ss") : moment?.duration(item.date - now, 'milliseconds').humanize(true),
            action: <MDBox>
                <Tooltip title={t('Show detailed info')} >
                    {/* <WithPermission disable={true} permissionsList={[EditPermission]} permissionKey={'settings'} > */}
                    <IconButton
                        // disabled={!checkPermission([EditPermission], 'settings')}
                        color="info"
                        onClick={() => {
                            handleOpenFullModal(item, actionText);
                        }}
                    >
                        <InfoIcon />
                    </IconButton>
                    {/* </WithPermission> */}
                </Tooltip>
            </MDBox>
        }
    })

    const timeId = useRef(null)

    const handleTableScroll = (e) => {
        const rowScroll = e.nativeEvent.target.scrollHeight / total
        const rowHeight = e.nativeEvent.target.clientHeight / total
        const currentIndex = e.nativeEvent.target.scrollTop / rowScroll
        // e.nativeEvent.target.scrollTop
        if (timeId.current) clearTimeout(timeId.current)
        timeId.current = setTimeout(() => {
            getHistory({
                start: Math.max((Math.floor(currentIndex) - 10), 0),
                end: Math.max((Math.floor(currentIndex) + take), 0)
            })
        }, 600)
    }

    const handleSelectAdmins = (e, newVal) => {
        if (newVal.length > 0)
            setSelectedAdmins(newVal)
    }

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const idPop = open ? 'simple-popover' : undefined;


    const handleDateChange = (item) => {
        setDates([item.selection])
        setDateText(`${moment(item.selection.startDate).format("MMM DD YYYY")} - ${moment(item.selection.endDate).format("MMM DD YYYY")}`)
    }


    const handleSelectMethods = (e, newVal) => {
        if (newVal.length > 0)
            setSelectedMethods(newVal)
    }
    return (
        <MDDialog
            maxWidth="lg"
            fullWidth
            open={openModal?.bool}
            setOpen={setOpenModal}
            title={
                <MDBox sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    <div style={{ display: "flex", justifyContent: "flex-start", alignItems: "center", flexWrap: 'nowrap' }}>
                        <MDAvatar
                            src={initValues.avatar ?
                                `${process.env.REACT_APP_BASE_ORIGIN}/img/users/${initValues.avatar}` :
                                `${process.env.REACT_APP_BASE_ORIGIN}/img/default.png`}
                            name={initValues.email}
                            size="sm"
                        />{" "}
                        &nbsp; {`${initValues.firstName} ${initValues.lastName}`}
                    </div>
                    <div className={styles.label}>
                        {t('Total entries')} {total}
                    </div>
                </MDBox>
            }
        >
            <MDBox sx={{ height: '70vh', width: '100%' }} my={1}>
                {searching && <div className="loader">
                    <ClipLoader />
                </div>}
                <Grid container >
                    <Grid container spacing={2} item xs={12} alignItems={'center'} sx={{ margin: '10px 0px' }} >
                        <Grid item xs={4}>
                            <MDSelectMui
                                multiple
                                label="Admins"
                                value={selectedAdmins}
                                onChange={handleSelectAdmins}
                                labelKey="firstName"
                                valueKey="_id"
                                options={list}
                                getOptionLabel={(option) => `${option.firstName} ${option.lastName}`}
                                renderTags={(value) => { return value.map((item, index) => <span style={{ padding: "5px" }}>{`${item.firstName} ${item.lastName}`}{(index !== value.length - 1) && ","}</span>) }}
                            />
                        </Grid>
                        <Grid item xs={4} >
                            <MDButton aria-describedby={idPop} variant="outlined" fullWidth color="secondary" onClick={handleClick}>
                                {dateText}
                            </MDButton>
                            <Popover
                                id={id}
                                open={open}
                                anchorEl={anchorEl}
                                onClose={handleClose}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'center',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'center',
                                }}
                            >
                                <div
                                    style={{ width: '90wv', overflow: 'auto' }}
                                >
                                    <DateRangePicker
                                        onChange={handleDateChange}
                                        showSelectionPreview={true}
                                        moveRangeOnFirstSelection={false}
                                        months={2}
                                        ranges={dates}
                                        direction="horizontal"
                                        maxDate={new Date()}
                                        preventSnapRefocus={true}
                                        calendarFocus="backwards"

                                    />
                                </div>
                            </Popover>

                        </Grid>
                        <Grid item xs={4}>
                            <MDSelectMui
                                multiple
                                label="Action"
                                value={selectedMethods}
                                onChange={handleSelectMethods}
                                labelKey="label"
                                valueKey="id"
                                options={methods}
                            />
                        </Grid>
                    </Grid>
                    {/* <Grid item xs={12} >
                        <VirtualizedTable endpoint={`/history/admins?from=0&to=${Date.now()}`} rowHeight={25} rowCount={149} pageSize={10} />
                    </Grid> */}

                    <Grid item xs={12}>
                        <div className={styles.table} onScroll={handleTableScroll} >
                            {rows && (
                                <DataTable
                                    table={{ columns, rows }}
                                    isSorted={false}
                                    showTotalEntries={false}
                                    canSearch={false}
                                    entriesPerPageBox={false}
                                    noEndBorder
                                    suppressPagination={true}
                                />
                            )}
                        </div>
                    </Grid>
                </Grid>
            </MDBox>
            {
                openFullInfoModal.bool &&
                <FullInfoModal openModal={openFullInfoModal} setOpenModal={setOpenFullInfoModal} />
            }


        </MDDialog>
    );
}
