import axios from "axios";
import { UPDATE_TRANSCODE_STATUS } from "redux/types";
import { GET_TRANSCODER } from "redux/types";
import { catchAsync } from "utils/catchAsync";
import { api, apiAuth } from "../../api/config";
import { addNotification } from "./notifications";


export const getTranscodes = () =>
  async (dispatch) => {
    // dispatch({
    //   type: GET_TRANSCODER,
    //   payload: {
    //     loading: true,
    //   },
    // });
    const {
      data: { result }
    } = await api.get("/transcodes");

    dispatch({
      type: GET_TRANSCODER,
      payload: {
        loading: false,
        list: result.map(item => ({ ...item, loading: false }))
      },
    });
  };


export const createTranscode = (values) =>
  async (dispatch) => {
    try {

      const {
        data: { result },
      } = await api.post("/transcodes", values);

      dispatch({
        type: UPDATE_TRANSCODE_STATUS,
        payload: { ...values, loading: true }
      })

      dispatch({
        type: GET_TRANSCODER,
        payload: {
          loading: false,
          list: result,
        },
      });
    } catch (error) {
      console.log(error);
      dispatch(addNotification({
        color: 'error',
        icon: 'error',
        title: `Error`,
        content: error?.response?.data?.errorMsg,
        dateTime: Date.now(),
    }))
    }


  };

export const updateTranscode = (id, values) => async (dispatch) => {
  dispatch({
    type: UPDATE_TRANSCODE_STATUS,
    payload: { ...values, _id: id, loading: true }
  })

  const {
    data: { result },
  } = await api.put(`/transcodes/${id}`, values);

  dispatch({
    type: GET_TRANSCODER,
    payload: {
      loading: false,
      list: result
    },
  });
};


export const refreshTranscode = (values, cb) =>
  async (dispatch) => {
    dispatch({
      type: UPDATE_TRANSCODE_STATUS,
      payload: { ...values, loading: true }
    })
    await apiAuth.post(`${values?.transcoderInfo?.host}/transcode/refresh`, values)
  };

export const stopTranscode = (values, cb) =>
  async (dispatch) => {
    dispatch({
      type: UPDATE_TRANSCODE_STATUS,
      payload: { ...values, loading: true }
    })
    await apiAuth.post(`${values?.transcoderInfo?.host}/transcode/destroy`, values)
  };


export const deleteTranscode = (values) =>
  async (dispatch) => {
    dispatch({
      type: GET_TRANSCODER,
      payload: {
        loading: true,
      },
    });
    const { data: { result } } = await api.delete(`/transcodes/${values._id}`);
    dispatch({
      type: GET_TRANSCODER,
      payload: {
        loading: false,
        list: result
      },
    });
    // apiAuth.post(`${values?.transcoderInfo?.host}/transcode/destroy`, {...values, isDelete: true} )

  };
