import { useDispatch } from "react-redux";
import { addNotification } from "redux/actions/notifications";

const { useRef, useEffect, useState } = require("react");

export function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    }, [value]);
    return ref.current;
  }

  export const useRequestHandler = (callback) => {
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false)
    const fn = async () => {
      try {
        setLoading(true)
        await callback()
        dispatch(addNotification({
          dateTime: Date.now(),
          color: 'success',
          title: 'Success',
          content: "Saved Successfully",
        }))
        setLoading(false)
      } catch (error) {
        setLoading(false)
        console.log(error)
        dispatch(addNotification({
          dateTime: Date.now(),
          color: 'error',
          title: 'Error',
          content: error.toString(),
        }))
      }
    }
    return {
      loading,
      requestHandler: fn
    }
  }