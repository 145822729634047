import { Grid, IconButton } from "@mui/material";
import MDBox from "components/MDBox";
import MDDialog from "components/MDDialog";
import MDInput from "components/MDInput";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useFormik } from "formik";
import * as yup from "yup";
import Tooltip from "@mui/material/Tooltip";
import { useTranslation } from "react-i18next";
import MDTypography from "components/MDTypography";
import Copy from "components/Copy";
import MainLoader from "components/loader/loader";
import MDButton from "components/MDButton";
import RefreshIcon from '@mui/icons-material/Refresh';
import { apiAuth } from "api/config";
import { api } from "api/config";

const schema = yup.object({
    name: yup.string().required('field is required'),
    url: yup.string().required('field is required'),
    license: yup.string().required('field is required'),
});

export default function Modal({ openModal, setOpenModal }) {
    const { t } = useTranslation()
    const archivers = useSelector(store => store?.archivers?.list)
    const [isLoading, setIsLoading] = useState(false)
    const [isChecked, setIsChecked] = useState(false)
    const initValues = {
        name: "Archiver-1",
        url: "",
        license: ""
    };

    const { values, handleChange, touched, errors, handleSubmit, resetForm } = useFormik({
        initialValues: initValues,
        validationSchema: schema,
        onSubmit: async (values, { setErrors }) => {
            try {
                setIsLoading(true)
                if (archivers.some(item => item.name === values.name)) {
                    setErrors({ 'name': `an Archiver with name "${values.name}" already exist` })
                    setIsLoading(false)
                    return
                }
                try {
                    await api.post("/license", { license: values.license, server: openModal.type, softwareId: softwareId })
                } catch (error) {
                    throw { 'license': error?.response?.data?.errorMsg }
                }
                const { data } = await apiAuth.get(`${values.url}/system`)
                openModal?.onSave({ ...values, historyStatus: { ...data, date: Date.now() } });
                setOpenModal({ bool: false });
                setIsLoading(false)
            } catch (error) {
                if (error.isAxiosError) {
                    setErrors({ 'url': 'Host is unreachable'})
                }else{
                    setErrors(error)
                }
                setIsLoading(false)
            }
        },
    });

    // useEffect(() => {
    //     if (openModal?.modalValues) {
    //         Object.keys(initValues).forEach((key) => {
    //             setFieldValue(key, openModal?.modalValues?.[key] ?? '');
    //         });
    //     }
    // }, [openModal?.modalValues]);

    useEffect(() => {
        if (openModal.bool) {
            resetForm()
        }
    }, [openModal.bool])

    const [softwareId, setSoftwareId] = React.useState('')
    const [softwareIdLoading, setSoftwareIdLoading] = React.useState(false)
    

    const getSoftwareId = async () => {
        try {
            setSoftwareId(null)
            setSoftwareIdLoading(true)
            const result = await apiAuth.get(`${values.url}/system/serverInfo`)
            setSoftwareId(result.data.serverInfo)
            setTimeout(()=>{
                setSoftwareIdLoading(false)
            }, 2000)
        } catch (error) { 
            setSoftwareIdLoading(false)
        }
    }

    React.useEffect(() => {
        getSoftwareId()
    }, [values.url])

    return (
        <MDDialog
            maxWidth="md"
            fullWidth
            dialogHeight="fit-content"
            open={openModal?.bool}
            setOpen={setOpenModal}
            onSave={handleSubmit}
            loading={isLoading}
            submitText={t('archiver.addNewArchiver')}
        >
            <MDBox>
                <Grid rowSpacing={2} container>
                    <Grid item xs={3}>
                        {t('Name')}:
                    </Grid>
                    <Grid item xs={9}>
                        <MDInput
                            fullWidth
                            value={values.name}
                            id="name"
                            name="name"
                            error={touched.name && errors.name}
                            helperText={touched.name && errors.name}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        {t('archiver_URL')}:
                    </Grid>
                    <Grid item xs={9}>
                        <MDInput
                            fullWidth
                            value={values.url}
                            placeholder="http://"
                            id="url"
                            name="url"
                            error={values.url && errors.url}
                            helperText={values.url ? errors.url : t('toGetSoftwareId')}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        {t('Software ID')}:
                    </Grid>
                    <Grid item xs={9}>
                    {
                            softwareIdLoading ? <MainLoader size={30}/> :
                            softwareId ? <div style={{display: "flex", alignItems: "center"}}>
                                <MDTypography variant='caption' sx={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden', display: 'inline-block' }} >{softwareId}</MDTypography>
                                <Copy str={softwareId} />
                                <IconButton
                                    onClick={getSoftwareId}
                                >
                                    <RefreshIcon />
                                </IconButton>
                            </div> :
                            <Tooltip title={values.url ? errors.url : t('toGetSoftwareId')} >
                                <MDButton
                                    variant="outlined"
                                    color="info"
                                    onClick={getSoftwareId}
                                    disabled={!values.url}
                                    
                                >
                                    {t('getSoftwareId')}
                                </MDButton>
                            </Tooltip>
                        }
                    </Grid>
                    <Grid item xs={3}>
                        {t('License')}:
                    </Grid>
                    <Grid item xs={9}>
                        <MDInput
                            multiline
                            fullWidth
                            rows={5}
                            value={values.license}
                            id="license"
                            name="license"
                            placeholder="A_"
                            error={touched.license && errors.license}
                            success={isChecked && !errors.license && !isLoading}
                            helperText={isLoading ? 'Checking license' : (touched.license && errors.license) || (isChecked && 'License is valid')}
                            onChange={e => { setIsChecked(false); handleChange(e) }}
                        />
                    </Grid>
                </Grid>
            </MDBox>
        </MDDialog>
    );
}
