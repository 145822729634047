import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, useNavigate, useLocation } from 'react-router-dom';
import { isLogged } from 'utils';

const PrivateRoute = ({children}) => {
    const { settings, isFetched, errorMessage} = useSelector(store=>store.settings)
    const navigate = useNavigate()
    const location = useLocation()
    useEffect(()=>{
        if(isFetched && (!settings.license || errorMessage) ){
            navigate('/settings', {state: {licenseError: settings.license ? errorMessage : 'Please enter your license'}})
        }
    }, [settings, location.pathname, isFetched])
    return (
            isLogged() ? children : <Navigate to="/authentication/sign-in" />
    );
};

export default PrivateRoute;