import { Grid } from "@mui/material";
import MDBox from "components/MDBox";
import MDDialog from "components/MDDialog";
import MDInput from "components/MDInput";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import * as yup from "yup";
import { getTariffs } from "redux/actions/tariffs";
import { useTranslation } from "react-i18next";

const schema = yup.object({
  name: yup.string("Enter Name").required("name is required"),
  pin: yup.number('PIN must be 4 digits').max(9999).min(1000),
});

export default function Modal({ openModal, setOpenModal }) {
  const { t } = useTranslation()
  const dispatch = useDispatch();
  const tariffs = useSelector((store) => store?.tariffs?.list);
  const initValues = {
    name: openModal?.modalValues?.name ?? "",
    pin: "",
  };

  const { values, handleChange, touched, errors, handleSubmit, setFieldValue } = useFormik({
    initialValues: initValues,
    validationSchema: schema,
    onSubmit: (values) => {
      openModal?.onSave({ ...values });
      setOpenModal({ bool: false });
    },
  });

  const [openDropzone, setOpenDropZone] = useState(false)
  const [file, setFile] = useState()
  const [image, setImage] = useState()


  useEffect(()=>{
    if(file){
      const reader = new FileReader();
  
      reader.addEventListener("load", function () {
        // convert image file to base64 string
        setImage(reader.result);
      }, false);

      reader.readAsDataURL(file);
  
      setFieldValue('image', file)
    }

  }, [file])



  const handleSelectChannels = (e) => {
    setFieldValue("tariff", [e[e.length - 1]]);
  };

  useEffect(() => {
    if (openModal?.modalValues) {
      Object.keys(initValues).forEach((key) => {
        if (key === "tariff") {
          setFieldValue('tariff', openModal?.modalValues?.tariff ?? ['']);
        }
        setFieldValue(key, openModal?.modalValues?.[key] ?? '');
      });
    }
  }, [openModal?.modalValues]);
  useEffect(() => {
    if (!tariffs.length) {
      dispatch(getTariffs());
    }
  }, []);


  return (
    <MDDialog
      maxWidth="sm"
      fullWidth
      dialogHeight="fit-content"
      open={openModal?.bool}
      setOpen={setOpenModal}
      onSave={handleSubmit}
    >
      <MDBox>
        <Grid rowSpacing={2} container>
         
          <Grid item xs={3}>
            {t('Name')}:
          </Grid>
          <Grid item xs={9}>
            <MDInput
              fullWidth
              value={values.name}
              id="name"
              name="name"
              error={touched.name && errors.name}
              helperText={touched.name && errors.name}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={3}>
            PIN:
          </Grid>
          <Grid item xs={9}>
            <MDInput
              fullWidth
              value={values.pin}
              type="pin"
              id="pin"
              name="pin"
              error={touched.pin && errors.pin}
              helperText={touched.pin && errors.pin}
              onChange={handleChange}
            />
          </Grid>
            {/* <Grid item xs={3}>
              Avatar:
            </Grid>
            <Grid sx={{display: 'flex', flexDirection: 'column'}} item xs={9}>
              <MDButton onClick={()=>{setOpenDropZone(true)}} mt={2} variant="gradient" color="secondary">
                Upload image
              </MDButton>
              <img style={{ width: '180px', height: 'auto', margin: '20px auto' }} src={image ? image : `${process.env.REACT_APP_BASE_ORIGIN}/img/users/${initValues.image}`} alt={'user'} />
              <DropzoneDialog
                acceptedFiles={['image/*']}
                cancelButtonText={"cancel"}
                submitButtonText={"submit"}
                filesLimit={1}
                maxFileSize={5000000}
                open={openDropzone}
                onClose={() => setOpenDropZone(false)}
                onSave={(files) => {
                  setFile(files[0])
                  setOpenDropZone(false);
                }}
                showPreviews={true}
                showFileNamesInPreview={true}
              />
            </Grid> */}
        </Grid>
      </MDBox>
    </MDDialog>
  );
}
