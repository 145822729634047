import { Chip, FormControlLabel, Grid, IconButton, Switch, TextField, Tooltip } from '@mui/material'
import MDBox from 'components/MDBox'
import MDDialog from 'components/MDDialog'
import MDInput from 'components/MDInput'
import { useFormik } from 'formik'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import MDSelectMui from "components/MDSelectMui";
import { useSelector } from 'react-redux'
import MobileModal from './mobileModal'
import { R_saveNotification } from 'api/Notifications/service'
import MUITable from 'components/MuiTable'
import { api } from 'api/config'
import MDButton from 'components/MDButton'
import AddNotificationsModal from './AddNotificationsModal'
import TruncateString from '../../../components/TrancateString/TruncateString'
import WithPermission from 'components/withPermission/WithPermission'
import { EditPermission } from 'pages/settings/Admins/constants'
import { DeletePermission } from 'pages/settings/Admins/constants'
import { Delete, Edit } from '@mui/icons-material'
import { checkPermission } from 'utils/checkPermission'

export default function NotificationsList({ isOpen, setOpenModal }) {
    const { t } = useTranslation()
    const [popups, setPopups] = useState([])
    const customers = useSelector((store) => store?.customer?.list);
    const [loading, setLoading] = useState(false)
    const [openAddModal, setOpenAddModal] = useState({ bool: false })

    const R_GetAdminNotifications = async () => {
        const res = await api.get(`/notifications/users`);
        const combinedData = res.data.data.reduce((acc, item) => {
            const customerFound = customers.find(customer => customer._id === item.customerId)
            if (acc[item.title + item.message]) {
                acc[item.title + item.message].customers.push(customerFound)
            } else {
                acc[item.title + item.message] = { ...item, customers: [customerFound] }
            }
            return acc
        }, {})
        setPopups(Object.values(combinedData))
    }

    useEffect(() => {
        R_GetAdminNotifications()
    }, [isOpen.bool])

    const MessageRenderer = ({ value }) => {
        return value && <TruncateString text={value} />
    }

    const CustomerRenderer = ({ value }) => {
        const visibleCount = 2
        const valueVisible = value.slice(0, visibleCount)
        const invisibleCount = Math.max(value.length - visibleCount, 0)
        return <Tooltip title={value?.map(item => item?.email).join(", ")}>
            <MDBox>
                {valueVisible?.map(item => <Chip label={item?.email} />)} {invisibleCount > 0 && `+${invisibleCount}`}
            </MDBox>
        </Tooltip>
    }

    // const handleOpenNotificationModal = () => {

    // }

    const handleDeleteNotification = async (popup) => {
        await api.delete(`/notifications/users`, {params: {customers: popup.customers.map(item=>item._id)}});
        R_GetAdminNotifications()
    }

    const ActionsRenderer = ({ value, row }) => {
        return <MDBox>
            {/* <WithPermission permissionsList={[EditPermission]} permissionKey={'customers'} >
                <IconButton
                    disabled={!checkPermission([EditPermission], 'customers')}
                    onClick={() => {
                        handleOpenNotificationModal(row, true);
                    }}
                >
                    <Edit />
                </IconButton>
            </WithPermission> */}
            <WithPermission permissionsList={[DeletePermission]} permissionKey={'customers'} >
                <Tooltip title={t('dblClick')} >
                    <IconButton
                        disabled={!checkPermission([DeletePermission], 'customers')}
                        onDoubleClick={() => {
                            handleDeleteNotification(row);
                        }}
                    >
                        <Delete />
                    </IconButton>
                </Tooltip>
            </WithPermission>
        </MDBox>
    }

    const columns = [
        { header: t('Title'), accessor: "title" },
        { header: t('message'), accessor: "message", renderer: MessageRenderer },
        { header: t('Customers'), accessor: "customers", renderer: CustomerRenderer },
        { header: t('action'), accessor: "actions", renderer: ActionsRenderer },
    ];

    return (
        <MDDialog
            maxWidth="lg"
            fullWidth
            dialogHeight="fit-content"
            open={isOpen.bool}
            setOpen={setOpenModal}
            loading={loading}
        >
            <MDBox>
                <MDButton onClick={() => setOpenAddModal({ bool: true })} variant="outlined" color="info" >{t("addNotifications")}</MDButton>
                <MUITable
                    columns={columns}
                    data={popups}
                />
            </MDBox>
            <AddNotificationsModal isOpen={openAddModal} setOpenModal={setOpenAddModal} R_GetAdminNotifications={R_GetAdminNotifications} />
        </MDDialog>
    )
}
