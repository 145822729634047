import { Add, ArrowLeft, ArrowRight, Close, PlayCircle } from '@mui/icons-material'
import { Card, Grid, IconButton, Switch, Tooltip } from '@mui/material'
import { api } from 'api/config'
import MDBox from 'components/MDBox'
import MDButton from 'components/MDButton'
import MDTypography from 'components/MDTypography'
import DataTable from 'examples/Tables/DataTable'
import React, { useEffect, useRef, useState } from 'react'
import { Delete, Edit } from "@mui/icons-material";
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import Modal from './modal'
import ModalPlayer from '../../../components/playerModal'
import Copy from 'components/Copy'
import { getVOD } from 'redux/actions/VOD'
import { deleteVOD } from 'redux/actions/VOD'
import { createVOD } from 'redux/actions/VOD'
import { updateVOD } from 'redux/actions/VOD'
import { stopVOD } from 'redux/actions/VOD'
import { refreshVOD } from 'redux/actions/VOD'
import styles from './styles.module.css'
import classNames from 'classnames'
import { EditPermission } from 'pages/settings/Admins/constants'
import WithPermission from 'components/withPermission/WithPermission'
import { PlayPermission } from 'pages/settings/Admins/constants'
import { DeletePermission } from 'pages/settings/Admins/constants'
import { ActivatePermission } from 'pages/settings/Admins/constants'
import { checkPermission } from 'utils/checkPermission'
import { AddPermission } from 'pages/settings/Admins/constants'
import { getActiveVODs } from 'redux/actions/VOD'
import { usePrevious } from 'utils/customHooks'
import MDAvatar from '../../../components/MDAvatar'
import { baseURLpublic } from 'api/config'
import VodInfoModal from './VodinfoModal'

export default function VODServer({ VODServerInfo }) {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [openPlayerModal, setOpenPlayerModal] = useState({ bool: false });
    const allVODs = useSelector(store => store?.VOD)
    const VODs = allVODs?.list?.filter(item => item.VODServerId === VODServerInfo.id)
    const activeVods = useSelector(store => store?.VOD?.activeVods)
    const prevActiveVods = usePrevious(activeVods)
    const [openModal, setOpenModal] = useState({ bool: false })
    const [openInfoModal, setOpenInfoModal] = useState({ bool: false })
    const timeoutId = useRef(null)

    const lang = 'en'

    useEffect(() => {
        dispatch(getVOD())
    }, [])

    useEffect(() => {
        if (activeVods?.length) {
            if (timeoutId.current) clearTimeout(timeoutId.current)
            timeoutId.current = setTimeout(() => {
                dispatch(getActiveVODs())
            }, 5000)
        }
    }, [activeVods])


    function handleOpenPlayerModal(item) {
        let url = item.type === 'serial' ? item?.seasons?.[0]?.episodes?.[0]?.url : item.url
        setOpenPlayerModal({
            bool: true,
            modalValues: { url: url, item: {...item, subtitles: item?.seasons?.[0]?.episodes?.[0].subtitles}, activeEp: item?.seasons?.[0]?.episodes?.[0] },
        });
    }


    const handleDelete = (values) => {
        dispatch(deleteVOD(values))
    }


    const handleCreate = async (values) => {
        await dispatch(createVOD({ ...values, VODServerId: VODServerInfo.id }))
        dispatch(getVOD())
    }

    const handleUpdate = async function (values) {
        await dispatch(updateVOD(this._id, { ...values, VODServerId: VODServerInfo.id }))
        await dispatch(getVOD())
    }

    const handleStopVOD = (item) => {
        dispatch(stopVOD({ ...item, VODServerInfo }))
    }

    const handleStartVOD = (item) => {
        dispatch(refreshVOD({ ...item, VODServerInfo }))
    }

    const handleRefreshVOD = (item) => {
        dispatch(refreshVOD({ ...item, VODServerInfo }))
    }


    const handleOpenModal = (item, isUpdate) => {
        setOpenModal({
            bool: true,
            onSave: isUpdate ? handleUpdate.bind(item) : handleCreate,
            modalValues: item,
            isUpdate
        });
    }

    const handleStatusChange = async (item, e) => {
        await api.patch(`/VOD/${item._id}`, { isActive: e.target.checked })
        dispatch(getVOD())
    }


    const columns = [
        { Header: t("Name"), accessor: "Title", align: "left" },
        { Header: t("type"), accessor: "Type", align: "left" },
        { Header: t("Stream_URL"), accessor: "URL", align: "left" },
        { Header: t("action"), accessor: "action", align: "center" },
    ];
    
    const rows = VODs?.map(item => {
        const isVODActive = item.isActive;
        const isLoading = item.loading
        const url = item.url
        const activeEpisodes = []
        if (item.type === 'serial') {
            item.seasons.forEach(season => {
                season.episodes.forEach(episode => {
                    const index = activeVods?.findIndex(vod => vod.episodeId === episode.id)
                    if (index !== -1) {
                        activeEpisodes.push({ status: activeVods[index], episode, season })
                    }
                })
            })
        }
        const activeVod = item.type === 'movie' && activeVods.find(vod => vod._id === item._id)
        const canDelete = item.type === 'serial' ? item?.seasons.some(item => item.episodes.filter(item => item.url).length) : !!item.url
        const status = activeVod?.uploadStatus < 100 ? activeVod?.uploadStatus : activeVod?.transcodeStatus
        const textStat = activeVod?.uploadStatus < 100 ? t('Uploading') : t('Transcoding')

        return {
            Title: <Tooltip title={item.title.en} >
                <MDBox sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <MDAvatar
                        src={`${baseURLpublic}${item?.posterlg}`}
                        name={item.title.en}
                        size="sm"
                    />{" "}
                    &nbsp;
                    <div>{item.title.en.substring(0, 15) + (item.title.en.length > 15 ? '...' : '')}</div>
                </MDBox>
            </Tooltip>,
            Type: item.type,
            URL: item.type === 'serial' ? <MDBox>{item?.seasons.map(season => <div>{`${season.label} (Episodes: ${season?.episodes.length})`}</div>)}</MDBox> : <Tooltip title={url} ><MDBox>{url.substring(0, 25) + (url.length > 25 ? '...' : '')} <Copy str={url} /></MDBox></Tooltip>,
            action: item.type === 'serial' && activeEpisodes.length ?
                <MDBox>
                    {activeEpisodes.map(ep => {
                        const status = ep.status?.uploadStatus < 100 ? ep.status?.uploadStatus : ep.status?.transcodeStatus
                        let textStat = ep.status?.uploadStatus < 100 ? t('Uploading') : t('Transcoding')
                        textStat = ep.season.label + ' ' + ep.episode.label + ' ' + textStat
                        return <>
                            <div>
                                <div style={{ textAlign: "center" }} > {+status ? `${textStat} ${+status || 0}%` : `${textStat} 0%`} </div>
                                <progress style={{ width: '100%' }} value={+status ?? 0} max="100" />
                            </div>
                            <IconButton onDoubleClick={async () => {await api.patch(`/VOD/${item?._id}/processUpdate`, { episodeId: ep.episode.id, removeProgress: 'null' }); dispatch(getActiveVODs()) }} >
                                <Close />
                            </IconButton>
                        </>
                    })}
                </MDBox>
                : activeVod && status ?
                    (
                        <MDBox sx={{ display: 'flex', flexDirection: 'row' }}>
                            <div>
                                <div style={{ textAlign: "center" }} > {+status ? `${textStat} ${+status || 0}%` : `${textStat} 0%`} </div>
                                <progress style={{ width: '100%' }} value={+status ?? 0} max="100" />
                            </div>
                            <IconButton onDoubleClick={async () => { await api.patch(`/VOD/${item?._id}/processUpdate`, { removeProgress: 'null' }); dispatch(getActiveVODs()) }} >
                                <Close />
                            </IconButton>
                        </MDBox>
                    )
                    :
                    (
                        <MDBox>
                            <WithPermission disable={true} permissionsList={[EditPermission]} permissionKey={'VOD'}>
                                <IconButton
                                    disabled={isLoading || !checkPermission([EditPermission], 'VOD')}
                                    onClick={() => {
                                        handleOpenModal(item, true);
                                    }}
                                >
                                    <Edit />
                                </IconButton>
                            </WithPermission>
                            <WithPermission disable={true} permissionsList={[PlayPermission]} permissionKey={'VOD'}>
                                <IconButton disabled={!checkPermission([PlayPermission], 'VOD')} onClick={() => handleOpenPlayerModal(item)} color="success">
                                    {" "}
                                    <PlayCircle />{" "}
                                </IconButton>
                            </WithPermission>
                            <WithPermission disable={true} permissionsList={[DeletePermission]} permissionKey={'VOD'}>
                                <Tooltip title={t('dblClick')} >
                                    <IconButton
                                        disabled={isLoading || !checkPermission([DeletePermission], 'VOD')}
                                        onDoubleClick={() => {
                                            confirm(`${t('askDelete')}`) && handleDelete(item);
                                        }}
                                    >
                                        <Delete />
                                    </IconButton>
                                </Tooltip>
                            </WithPermission>
                            <WithPermission disable={true} permissionsList={[ActivatePermission]} permissionKey={'VOD'}>
                                <Tooltip title={isVODActive ? t('active') : t('inactive')} >
                                    <Switch disabled={!checkPermission([ActivatePermission], 'VOD')} defaultChecked={isVODActive} onChange={(e) => handleStatusChange(item, e)} />
                                </Tooltip>
                            </WithPermission>
                        </MDBox>
                    ),
        }
    })

    return (
        <MDBox mx={2} my={2} pt={6} pb={3}>
            <Grid container spacing={6}>
                <Grid item xs={12}>
                    <Card>
                        <MDBox
                            mx={2}
                            mt={-3}
                            py={3}
                            px={2}
                            sx={{ display: "flex", justifyContent: "space-between" }}
                            variant="gradient"
                            bgColor="info"
                            borderRadius="lg"
                            coloredShadow="info"
                        >
                            <MDTypography variant="h6" color="white">
                                {t("VODServer.header", { VOD: VODServerInfo.name })}
                            </MDTypography>
                            <WithPermission disable={true} permissionsList={[AddPermission]} permissionKey={'VOD'}>
                                <MDButton disable={!checkPermission([AddPermission], 'VOD')} onClick={() => { handleOpenModal() }} withIcon={<Add />} loading={false}>
                                    {t("VODServer.addNew")}
                                </MDButton>
                            </WithPermission>
                        </MDBox>
                        <MDBox pt={3}>
                            {/* <MDBox sx={{display: 'flex', justifyContent: 'flex-end', padding: '0px 30px'}} >
                                    <MDButton onClick={()=>{}} variant='contained' sx={{ color: 'info.main', border: '1px solid' }} >{t('streams.updateFullList')}</MDButton>
                                </MDBox> */}
                            {rows && (
                                <DataTable
                                    table={{ columns, rows }}
                                    isSorted={false}
                                    showTotalEntries={false}
                                    canSearch={true}
                                    entriesPerPageBox={false}
                                    noEndBorder
                                />
                            )}
                        </MDBox>
                    </Card>
                </Grid>
            </Grid>
            {openModal?.bool &&
                <Modal
                    VODServerInfo={VODServerInfo}
                    openModal={openModal}
                    setOpenModal={setOpenModal}
                    VODs={VODs}
                    currentVod={activeVods.find(vod => vod._id === openModal?.modalValues?._id)}
                    openInfoModal={openInfoModal}
                    setOpenInfoModal={setOpenInfoModal}
                />
            }
            {openInfoModal?.bool &&
                <VodInfoModal
                    openModal={openInfoModal}
                    setOpenModal={setOpenInfoModal}
                    VODServerInfo={VODServerInfo}
                />
            }
            {openPlayerModal.bool && (
                <ModalPlayer openModal={openPlayerModal} primaryUrl={openPlayerModal?.modalValues?.url} setOpenModal={setOpenPlayerModal} rightComponent={<SelectSerie openModal={openPlayerModal} setOpenPlayerModal={setOpenPlayerModal} seasons={openPlayerModal?.modalValues?.item?.seasons} />} />
            )}
        </MDBox>
    )
}


const SelectSerie = ({ seasons, setOpenPlayerModal, openModal }) => {
    const [activeSeason, setActiveSeason] = useState(seasons?.[0])
    const [activeEpisode, setActiveEpisode] = useState(activeSeason?.episodes?.[0])
    const seasonsRef = useRef([])
    const handleSeasonClick = (season) => {
        setActiveSeason(season)
    }
    const handleEpisodeClick = (episode) => {
        setOpenPlayerModal({ ...openModal, modalValues: { ...openModal.modalValues, item: {...openModal.modalValues.item, subtitles: episode?.subtitles}, url: episode.url, activeEp: episode } })
        setActiveEpisode(episode)
    }

    const handleSelectSeason = (delta) => {
        const index = seasons.findIndex(item => item.id === activeSeason.id)
        if (index > -1) {
            let nextIndex = index + delta
            nextIndex = nextIndex < 0 ? 0 : nextIndex > seasons.length - 1 ? seasons.length - 1 : nextIndex
            seasonsRef.current[nextIndex].scrollIntoView({ behavior: "smooth", block: "center", inline: "center" })
            setActiveSeason(seasons[nextIndex])
        } else {
            setActiveSeason(seasons[0])
        }
    }
    if (!seasons.length) {
        return null
    }

    return <MDBox sx={{ maxWidth: '290px' }}>
        <div style={{ display: 'flex', margin: '10px 0px', gap: '10px' }} >
            <MDButton onClick={() => { handleSelectSeason(-1) }} color="info" variant='outlined' sx={{ padding: '2px', minWidth: '44px', padding: '0px 10px', margin: '0px' }} ><ArrowLeft fontSize='20px' /></MDButton>
            <div className={classNames(styles.seasonsButtons, styles.hideScrollbar)} style={{ width: 'calc(100% - 100px)', padding: '0px 10px', margin: '0px' }} >
                {seasons.map((season, i) => (
                    <div ref={(e) => { seasonsRef.current[i] = e }}>
                        <MDButton
                            variant={activeSeason?.id === season.id ? 'gradient' : 'outlined'}
                            color="info"
                            key={season.id}
                            sx={{ minWidth: '140px', width: '140px' }}
                            onClick={() => handleSeasonClick(season)}
                        >
                            {season.label}
                        </MDButton>
                    </div>
                ))}
            </div>
            <MDButton onClick={() => { handleSelectSeason(1) }} color="info" variant='outlined' sx={{ padding: '2px', minWidth: '44px' }} ><ArrowRight fontSize='20px' /></MDButton>
        </div>
        <div className={styles.episodesButtons}>
            {activeSeason?.episodes?.map((episode) => (
                <div className={styles.episodeButton}>
                    <Tooltip placement='right' title={episode?.url ? episode?.url : 'Episode is not uploaded'}>
                        <MDButton
                            variant={activeEpisode?.id === episode.id ? 'gradient' : 'outlined'}
                            color="info"
                            key={episode.id}
                            sx={{ minWidth: '100%', width: '100%' }}
                            onClick={() => episode?.url && handleEpisodeClick(episode)}
                        >
                            {episode.label}
                            {episode?.url && <PlayCircle />}
                        </MDButton>
                    </Tooltip>
                </div>
            ))}
        </div>
    </MDBox>
}