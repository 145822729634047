import { Cancel, Check, DeleteForever, Edit, ExpandLess, ExpandMore } from '@mui/icons-material'
import { Collapse, IconButton, List, ListItemButton, ListItemText, TextField, Tooltip } from '@mui/material'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import styles from './styles.module.css';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import MDBox from 'components/MDBox';
import MDButton from 'components/MDButton';
import { v4 } from 'uuid';
import { useDispatch } from 'react-redux';
import { ClipLoader } from 'react-spinners';
import { updateCategories } from 'redux/actions/settings';
import { useTranslation } from 'react-i18next';

function createData(en, am, ru) {
    return { en, am, ru };
}


function Categories({ settings, loading }) {
    const dispatch = useDispatch()
    const { t } = useTranslation()
    const [open, setOpen] = useState(null)
    const [inEdit, setInEdit] = useState(null)
    const [categoriesChannels, setCategoriesChannels] = useState(settings?.categoriesChannels)
    const [categoriesVOD, setCategoriesVOD] = useState(settings?.categoriesVOD)
    const refEn = useRef(null)
    const refAm = useRef(null)
    const refRu = useRef(null)

    useEffect(() => {
        setCategoriesChannels(settings?.categoriesChannels)
    }, [settings?.categoriesChannels])

    useEffect(() => {
        setCategoriesVOD(settings?.categoriesVOD)
    }, [settings?.categoriesVOD])

    const handleClick = (val) => {
        if (open === val) {
            setOpen(null)
        } else {
            setOpen(val)
        }
    }

    const cahnnelsRows = useMemo(() => categoriesChannels.map(item => createData(item.en, item.am, item.ru)), [categoriesChannels])
    const VodRows = useMemo(() => categoriesVOD.map(item => createData(item.en, item.am, item.ru)), [categoriesVOD])

    const handleEdit = (item, i, data) => {
        setInEdit(`${data}_${i}`)
    }

    const handleSave = async (item, i, type) => {
        if (refAm.current.value && refRu.current.value && refEn.current.value) {
            const row = { am: refAm.current.value, ru: refRu.current.value, en: refEn.current.value }
            const data = type === 'c' ? [...categoriesChannels] : [...categoriesVOD]
            data.splice(i, 1, { ...data[i], ...row })
            const body = {
                endpoint: type === 'c' ? 'channels' : 'vod',
                key: type === 'c' ? 'categoriesChannels' : 'categoriesVOD',
                data: data
            }
            const onSuccess = () => { setInEdit(null) }
            await dispatch(updateCategories(body, onSuccess))
        }
    }

    const handleCancel = (type) => {
        const data = type === 'c' ? categoriesChannels : categoriesVOD
        const setter = type === 'c' ? setCategoriesChannels : setCategoriesVOD
        if (!data?.[0]?.am && !data?.[0]?.en && !data?.[0]?.ru) {
            setter(data.slice(1))
        }
        setInEdit(null)
    }

    const handleDelete = async (item, i, type) => {
        const data = type === 'c' ? [...categoriesChannels] : [...categoriesVOD]
        data.splice(i, 1)
        const body = {
            endpoint: type === 'c' ? 'channels' : 'vod',
            key: type === 'c' ? 'categoriesChannels' : 'categoriesVOD',
            data: data
        }
        const onSuccess = () => { setInEdit(null) }
        await dispatch(updateCategories(body, onSuccess))
    }

    const handleAddRow = (type) => {
        const data = type === 'c' ? categoriesChannels : categoriesVOD
        if (inEdit) {
            return
        }
        if (!data?.[0]?.am && !data?.[0]?.en && !data?.[0]?.ru) {
            const setter = type === 'c' ? setCategoriesChannels : setCategoriesVOD
            setter([{ id: v4(), am: '', ru: '', en: '' },])
            setInEdit(`${type}_0`)
            return
        }
        const setter = type === 'c' ? setCategoriesChannels : setCategoriesVOD
        setter([{ id: v4(), am: '', ru: '', en: '' }, ...data,])
        setInEdit(`${type}_0`)

    }

    return (
        <List sx={{ position: 'relative' }} component={Paper}>
            {loading && <div className='loader' ><ClipLoader /></div>}
            <ListItemButton sx={{ border: '1px solid lightgray', color: '#000' }} focusVisibleClassName={styles.focused} onClick={() => handleClick('c')}>
                <ListItemText primary={t("Channels_Categories")} />
                {open === 'c' ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={open === 'c'} timeout="auto" unmountOnExit>
                <Table padding='none' size="medium" sx={{ minWidth: 650 }} aria-label="a dense table">
                    <TableRow>
                        <TableCell align="left"><strong>English</strong></TableCell>
                        <TableCell align="left"><strong>Հայերեն</strong></TableCell>
                        <TableCell align="left"><strong>Русский</strong></TableCell>
                        <TableCell align="left"><MDButton onClick={() => handleAddRow('c')} variant='outlined' color='info'>{t('Add_new')}</MDButton></TableCell>
                    </TableRow>
                    <TableBody>
                        {cahnnelsRows.map((row, i) => (
                            <TableRow key={row.en} >
                                <TableCell width={'29%'} align="left">{inEdit === `c_${i}` ? <TextField margin='none' inputRef={refEn} fullWidth defaultValue={row.en} /> : row.en}</TableCell>
                                <TableCell width={'29%'} align="left">{inEdit === `c_${i}` ? <TextField margin='none' inputRef={refAm} fullWidth defaultValue={row.am} /> : row.am}</TableCell>
                                <TableCell width={'29%'} align="left">{inEdit === `c_${i}` ? <TextField margin='none' inputRef={refRu} fullWidth defaultValue={row.ru} /> : row.ru}</TableCell>
                                <TableCell align="right">
                                    <MDBox>
                                        {inEdit === `c_${i}` ?
                                            <>
                                                <IconButton
                                                    color="success"
                                                    onClick={() => {
                                                        handleSave(row, i, 'c');
                                                    }}
                                                >
                                                    <Check />
                                                </IconButton>
                                                <IconButton
                                                    onClick={() => {
                                                        handleCancel('c');
                                                    }}
                                                >
                                                    <Cancel />
                                                </IconButton>
                                            </>
                                            :
                                            <>
                                                <IconButton
                                                    onClick={() => {
                                                        handleEdit(row, i, 'c');
                                                    }}
                                                >
                                                    <Edit />
                                                </IconButton>
                                                <Tooltip title={t('dblClick')} >
                                                    <IconButton
                                                        onDoubleClick={() => {
                                                            handleDelete(row, i, 'c');
                                                        }}
                                                        color="error"
                                                    >
                                                        <DeleteForever />
                                                    </IconButton>
                                                </Tooltip>
                                            </>
                                        }

                                    </MDBox>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </Collapse>

            <ListItemButton sx={{ border: '1px solid lightgray', color: '#000' }} onClick={() => handleClick('v')}>

                <ListItemText primary={t("VOD_Categories")} />
                {open === 'v' ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={open === 'v'} timeout="auto" unmountOnExit>
                <TableContainer>
                    <Table size="small" sx={{ minWidth: 650 }} aria-label="a dense table">
                        <TableRow>
                            <TableCell align="left"><strong>English</strong></TableCell>
                            <TableCell align="left"><strong>Հայերեն</strong></TableCell>
                            <TableCell align="left"><strong>Русский</strong></TableCell>
                            <TableCell align="left"><MDButton onClick={() => handleAddRow('v')} variant='outlined' color='info'>Add new</MDButton></TableCell>
                        </TableRow>
                        <TableBody>
                            {VodRows.map((row, i) => (
                                <TableRow
                                    key={row.en}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell width={'29%'} align="left">{inEdit === `v_${i}` ? <TextField margin='none' inputRef={refEn} fullWidth defaultValue={row.en} /> : row.en}</TableCell>
                                    <TableCell width={'29%'} align="left">{inEdit === `v_${i}` ? <TextField margin='none' inputRef={refAm} fullWidth defaultValue={row.am} /> : row.am}</TableCell>
                                    <TableCell width={'29%'} align="left">{inEdit === `v_${i}` ? <TextField margin='none' inputRef={refRu} fullWidth defaultValue={row.ru} /> : row.ru}</TableCell>
                                    <TableCell fullWidth align="left">
                                        <MDBox>
                                            {inEdit === `v_${i}` ?
                                                <>
                                                    <IconButton
                                                        color="success"
                                                        onClick={() => {
                                                            handleSave(row, i, 'v');
                                                        }}
                                                    >
                                                        <Check />
                                                    </IconButton>
                                                    <IconButton
                                                        onClick={() => {
                                                            handleCancel('v');
                                                        }}
                                                    >
                                                        <Cancel />
                                                    </IconButton>
                                                </>
                                                :
                                                <>
                                                    <IconButton
                                                        onClick={() => {
                                                            handleEdit(row, i, 'v');
                                                        }}
                                                    >
                                                        <Edit />
                                                    </IconButton>
                                                    <Tooltip title={t('dblClick')} >
                                                        <IconButton
                                                            onDoubleClick={() => {
                                                                handleDelete(row, i, 'v');
                                                            }}
                                                            color="error"
                                                        >
                                                            <DeleteForever />
                                                        </IconButton>
                                                    </Tooltip>
                                                </>
                                            }

                                        </MDBox>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Collapse>
        </List>
    )
}

export default Categories