import React from 'react';
import { ClipLoader } from 'react-spinners';

const MainLoader = ({color, size, borderWidth}) => {
    return (
        <ClipLoader color={color || '#1A73E8'} size={size || 60} cssOverride={{"border": `${borderWidth || 5}px solid`}} />
    );
}

export default MainLoader;
