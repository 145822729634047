import axios from "axios";
import { addNotification } from "redux/actions/notifications";
import store from "redux/store";
import { removeToken } from "utils";
import { isLogged, getToken } from "utils";

const baseURL = process.env.REACT_APP_BASE_URL;

export const baseURLpublic = process.env.REACT_APP_BASE_ORIGIN

const api = axios.create({ baseURL });
const apiAuth = axios.create()
apiAuth.interceptors.request.use((request) => {
  const headers = { 'Cache-Control': 'no-cache', 'Content-Type': 'application/json' };
  if (isLogged()) {
    headers.Authorization = `JWT ${getToken()}`;
  }
  const newRequest = { ...request, headers };
  return newRequest;

});
const errorHandler = (error) => {
  console.log("{Request Error}", error);
  if(error?.response?.status === 401 && !error?.request?.responseURL?.includes?.("loginadmin")){
    removeToken()
    window.location.reload()
  }else{
    throw error
  }
  // store.dispatch(addNotification({
  //   color: 'error',
  //   icon: 'warning',
  //   title: 'API Error',
  //   content: error.message,
  //   dateTime: Date.now(),
  // }))
}

api.interceptors.request.use((request) => {
    const headers = { 'Cache-Control': 'no-cache', 'Content-Type': 'application/json' };
    if (isLogged()) {
      headers.Authorization = `JWT ${getToken()}`;
    }
    const newRequest = { ...request, headers };
    return newRequest;
  
});


api.interceptors.response.use(null, errorHandler)
// apiStreamer.interceptors.response.use(null, errorHandler)
// apiTranscoder.interceptors.response.use(null, errorHandler)
// apiArchiver.interceptors.response.use(null, errorHandler)

export { api, apiAuth };