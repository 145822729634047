import React, { useEffect, useRef, useState } from "react";
import style from "./style.module.css";
import { useTranslation } from "react-i18next";
import { api } from "api/config";
import { ClipLoader } from "react-spinners";
import MDBox from "components/MDBox";
import { addDays, startOfMonth } from 'date-fns';
import MDAvatar from "components/MDAvatar";
import DividerMui from "components/dividerMui/dividerMui";
import { Grid, Popover, Tooltip } from "@mui/material";
import MDTypography from "components/MDTypography";
import styles from './programs.module.css'
import { getDayStartTimestamp } from "utils/helpers";
import { getDayEndTimestamp } from "utils/helpers";
import MDButton from "components/MDButton";
import { DateRangePicker } from "react-date-range";
import moment from "moment";
import PiePercent from "components/charts/percentPieChart";
import { formatDuration } from "utils/helpers";
import { colorByPercent } from "utils/helpers";

export default function WatchHistory({ list }) {
    const { t } = useTranslation()
    const [channels, setChannels] = useState([])
    const [loading, setLoading] = useState(false)

    const [dates, setDates] = useState([
        {
            startDate: startOfMonth(new Date()),
            endDate: new Date(),
            key: 'selection'
        }
    ]);
    const [dateText, setDateText] = useState(`${moment(dates[0].startDate).format("MMM DD YYYY")} - ${moment(dates[0].endDate).format("MMM DD YYYY")}`)
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [now, setNow] = useState(Date.now())

    useEffect(() => {
        const getData = async () => {
            try {
                const search = {
                    from: getDayStartTimestamp(dates[0].startDate),
                    to: getDayEndTimestamp(dates[0].endDate),
                    type: 'channel'
                }
                setLoading(true)
                const res = await api.get('/history/watch/byChannels', { params: search })
                const totalWatchedDuration = Object.entries(res.data.result).reduce((acc, [key, value]) => {
                    acc += value.duration
                    return acc
                }, 0)
                const entries = Object.entries(res.data.result).map(([key, value]) => {
                    const found = list.find(channel => channel._id === key)
                    return { ...value, channel: found, percent: (value.duration * 100 / totalWatchedDuration) }
                })

                setChannels(entries.sort((a, b) => b.duration - a.duration))
                setLoading(false)
            } catch (error) {
                setLoading(false)
                console.log(error);
            }
        }
        if (list.length) {
            getData()
        }
    }, [list, dates])

    const id = useRef(null)
    // useEffect(() => {
    //     if (!openModal.open) return id.current && clearInterval(id.current)
    //     id.current = setInterval(() => {
    //         setNow(Date.now())
    //     }, 1000)
    //     return () => {
    //         clearInterval(id.current)
    //     }
    // }, [openModal.open])

    const open = Boolean(anchorEl);

    const idPop = open ? 'simple-popover' : undefined;
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };


    const handleDateChange = (item) => {
        setDates([item.selection])
        setDateText(`${moment(item.selection.startDate).format("MMM DD YYYY")} - ${moment(item.selection.endDate).format("MMM DD YYYY")}`)
    }

    

    return (
        <div style={{ padding: '40px 20px' }}>
            {loading && <div className="loader" >
                <ClipLoader />
            </div>}
            <Grid container alignItems={'center'}>
                <Grid item xs={12}>
                    <MDButton aria-describedby={idPop} variant="outlined" fullWidth color="secondary" onClick={handleClick}>
                        {dateText}
                    </MDButton>
                    <Popover
                        id={id}
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                            vertical: 'left',
                            horizontal: 'left',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                    >
                        <div
                            style={{ width: '90wv', overflow: 'auto' }}
                        >
                            <DateRangePicker
                                onChange={handleDateChange}
                                showSelectionPreview={true}
                                moveRangeOnFirstSelection={false}
                                months={2}
                                ranges={dates}
                                direction={window.innerWidth < 620 ? "vertical" : "horizontal"}
                                maxDate={new Date()}
                                preventSnapRefocus={true}
                                calendarFocus="backwards"
                            />
                        </div>
                    </Popover>

                </Grid>
                {!channels.length && <MDBox
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '100%',
                        height: '500px',
                        textAlign: 'center',

                    }}
                >
                    {dateText} <br /> {t('noViews')}
                </MDBox>}
                {channels?.map((item, i) =>
                    <>
                        <Grid item xs={6} sx={{ marginTop: '10px' }}>
                            <MDBox sx={{ display: "flex", paddingLeft: '20px', justifyContent: "flex-start", alignItems: "center" }}>
                                <MDTypography>
                                    {i + 1}.
                                </MDTypography>
                                &nbsp;
                                <MDAvatar
                                    src={`${process.env.REACT_APP_BASE_ORIGIN}/img/channels/${item.channel?.image}`}
                                    name={item?.channel?.name}
                                    size="sm"
                                />
                                &nbsp; <div style={{ overflow: "hidden", textOverflow: "ellipsis", width: '11rem' }}>
                                    <MDTypography variant="subtitle2" noWrap >{item?.channel?.name}</MDTypography>
                                </div>
                            </MDBox>
                        </Grid>
                        <Grid item xs={4} sx={{ marginTop: '10px' }}>
                            <MDBox sx={{ display: "flex", paddingLeft: '20px', justifyContent: "center", alignItems: "center" }}>
                                <Tooltip title={`${(item.duration / 1000 / 60 / 60).toFixed(7)} (${t('hours')})`} >
                                    <MDTypography variant="subtitle2" >{(formatDuration(item.duration))}</MDTypography>
                                </Tooltip>
                            </MDBox>
                        </Grid>
                        <Grid item xs={2} sx={{ marginTop: '10px' }}>
                            <MDBox sx={{ display: "flex", paddingLeft: '20px', justifyContent: "flex-end", alignItems: "center" }}>
                                <Tooltip title={`${(item.percent).toFixed(7)} (${t('%')})`} >
                                    <MDTypography variant="subtitle2" >
                                        <PiePercent percent={(item.percent).toFixed(0)} color={colorByPercent(item.percent)} round={true} animate={true} />
                                    </MDTypography>
                                </Tooltip>
                            </MDBox>
                        </Grid>
                        <MDBox sx={{ width: '100%', padding: '0px 20px', }}>
                            <DividerMui classes={{ root: styles.divider }} />
                        </MDBox>
                    </>
                )}
            </Grid>
        </div>
    );
}