import React, { useEffect, useState } from 'react';

const ImageWithFallback = ({ src, alt, fallbackSrc=`${process.env.REACT_APP_BASE_ORIGIN}/img/channels/default.png`, style }) => {
  const [imageSrc, setImageSrc] = useState(src);

  useEffect(()=>{
    setImageSrc(src)
  }, [src])

  const handleImageError = () => {
    setImageSrc(fallbackSrc);
  };
  return (
    <img
    // src={imageSrc?.startsWith?.("data:image") ? imageSrc : `${imageSrc}?timestamp=${Date.now()}`}
    src={imageSrc}
    alt={alt}
      onError={handleImageError}
      style={style} // Example styles
    />
  );
};

export default ImageWithFallback;