import { ARCHIVE_NOTIFICATION } from "redux/types";
import { ADD_NOTIFICATION } from "redux/types";

const defaults = {
    list: [],
    archives: []
}

export default (state = defaults, action) => {
    switch (action.type) {
      case ADD_NOTIFICATION:
        return { ...state, list: [...state.list, action.payload] };
      case ARCHIVE_NOTIFICATION:
        return { ...state, list: state.list.slice(0, state.list.length-1), archives: [...state.archives, state.list[state.list.length-1]]} 
      default:
        return state;
    }
  };
  