import React, { useEffect, useRef, useState } from "react";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDDialog from "components/MDDialog";
import MDInput from "components/MDInput";
import { useFormik } from "formik";
import * as yup from "yup";
import { DropzoneDialog } from 'material-ui-dropzone';
import MDSelect from "components/MDSelect";
import { Grid, Tab, Tabs, IconButton, TextField } from "@mui/material";
import { AddOutlined, CloseRounded, Remove } from "@mui/icons-material";
import MDSelectMui from "components/MDSelectMui";
import { useDispatch, useSelector } from "react-redux";
import { getSettings } from "redux/actions/settings";
import { useTranslation } from "react-i18next";
import { EditSourcePermission } from "pages/settings/Admins/constants";
import WithPermission from "components/withPermission/WithPermission";
import { checkPermission } from "utils/checkPermission";
import ImageWithFallback from "components/ImgWithFallback";
import { getEpgIds } from "api/epgApi";
import { getArchives } from "redux/actions/archiver";
import { getChannels } from "redux/actions/channels";
import { getStreams } from "redux/actions/streams";
import { defaultResolution } from "utils/constants";

const schema = yup.object({
  name: yup.string("Enter channel name").required("Name is required"),
  url: yup.string("Enter stream URL").required("stream URL is required"),
  // epgId: yup.array("Enter channel Epg").of(yup.object()),
});


export default function CreateChannel({ openModal, setOpenModal, handleCancel, handleConfirm }) {
  const dispatch = useDispatch()
  const { i18n, t } = useTranslation()
  const resolutions = useSelector(store => store.settings.settings.resolutions)
  const resolutionsList = [...resolutions, defaultResolution]
  const categoryChannels = useSelector(store => store.settings?.settings?.categoriesChannels)
  const [openDropzone, setOpenDropZone] = useState(false)
  const [file, setFile] = useState(null)
  const [image, setImage] = useState();
  const [isFromDB, setIsFromDB] = useState(0);
  const [selectedStream, setSelectedStream] = useState()
  const fetchedCategories = useRef(false)
  const lang = i18n.language
  const [epgIds, setEpgIds] = useState([]);
  const streamURLs = useSelector(store => store?.streams?.list)
  const archives = useSelector(store => store?.archiver?.list)

  useEffect(() => {
    dispatch(getStreams())
  }, [])

  const storeEpgIds = async () => {
    const res = await getEpgIds();
    setEpgIds(res.data.epgIds);
  };

  useEffect(() => {
    dispatch(getChannels());
    storeEpgIds();
    dispatch(getArchives())
  }, []);


  useEffect(() => {
    if (!fetchedCategories.current && (!categoryChannels || !categoryChannels.length)) {
      fetchedCategories.current = true
      dispatch(getSettings())
    }
  }, [categoryChannels])


  const emptyURL = { input: "", resolution: {} }
  const initialURL = { input: openModal?.modalValues?.url ?? "", resolution: openModal?.modalValues?.url ? defaultResolution : {} }

  const [URLs, setURLs] = useState(openModal?.modalValues?.resolutions.length ? openModal?.modalValues?.resolutions : [initialURL])

  const initValues = {
    name: openModal?.modalValues?.name ?? "",
    image: openModal?.modalValues?.image ?? undefined,
    url: openModal?.modalValues?.url ?? "",
    epgId: openModal?.modalValues?.epgId ?? [],
    reserveEpgId: (openModal?.modalValues?.epgId && Object.keys(openModal?.modalValues?.epgId)?.length) ? openModal?.modalValues?.reserveEpgId.length ? [openModal?.modalValues?.epgId, ...openModal?.modalValues?.reserveEpgId] :
      [openModal?.modalValues?.epgId] : [],
    archive: openModal?.modalValues?.archive ?? [],
    category: openModal?.modalValues?.category ?? [],
  }

  const { values, handleChange, touched, errors, handleSubmit, setFieldValue } = useFormik({
    initialValues: initValues,
    validationSchema: schema,
    onSubmit: (values) => {
      const data = { ...values, category: values.category.map(item => item?.value || item), resolutions: URLs }
      handleConfirm ? handleConfirm(data) : openModal?.onSave(data);
      setOpenModal?.({ bool: false });
    },
  });
  // useEffect(()=>{
  //   if(streamURLs?.every(item=>`${process.env.REACT_APP_STREAMER}/streams/${item.name}/index.m3u8` !== values.url))
  //   setFieldValue("url", isFromDB ? streamURLs.find(item => item.input === initValues.url) : initValues.url);
  // }, [isFromDB])
  const handleAddInputUrl = () => {
    setURLs([...URLs, emptyURL])
    setSelectedStream()
  }

  const handleRemoveInputUrl = (i) => {
    const inputsClone = JSON.parse(JSON.stringify(URLs))
    inputsClone.splice(i, 1)
    setURLs(inputsClone)
    setSelectedStream()
  }

  const handleInputsChange = (e, i) => {
    const inps = JSON.parse(JSON.stringify(URLs))
    inps[i].input = e.target.value;
    setURLs(inps)
    setSelectedStream()
  }

  const handleResolution = (newValue, i) => {
    const res = resolutionsList.find(item => newValue.value === item.id)
    const inps = JSON.parse(JSON.stringify(URLs))
    inps[i].resolution = res;
    setURLs(inps)
  }

  useEffect(() => {
    const found = URLs.find(item => item.resolution.id === 'DEFAULT')
    if (found) {
      setFieldValue('url', found?.input)
    } else {
      setFieldValue('url', URLs[0].input)
    }
  }, [URLs])


  const handleSelectEpg = (e, newVal) => {
    setFieldValue("reserveEpgId", newVal);
  };

  const handleSelectCategories = (e, newVal) => {
    setFieldValue("category", newVal);
  };

  const handleSelectURL = (e) => {
    const urls = e.inputs.map(item => ({
      input: `${e.streamerInfo.host}/streams/${e.name}/${item.resolution.name}/index.m3u8`,
      resolution: item.resolution
    }))
    setSelectedStream(e)
    setURLs(urls)
    setFieldValue("url", urls[0].input);
  }

  const handleSelectArchive = (e) => {
    setFieldValue("archive", e);
  }

  const handleTabChange = (e, val) => {
    setIsFromDB(val)
  }

  useEffect(() => {
    if (file) {
      const reader = new FileReader();

      reader.addEventListener("load", function () {
        // convert image file to base64 string
        setImage(reader.result);
      }, false);

      reader.readAsDataURL(file);

      setFieldValue('image', file)
    }

  }, [file])


  return (
    <>
      <MDBox my={1}>
        {/* <Tabs
          value={isFromDB}
          onChange={handleTabChange}
          textColor="secondary"
          indicatorColor="secondary"
          aria-label="secondary tabs example"
        >
          <Tab value={0} label="Input Stream URL" />
          <Tab value={1} label="Select from streams" />
        </Tabs> */}
        <Grid container>
          <Grid item xs={4} sx={{ display: 'flex', flexDirection: 'column', marginBottom: "10px" }} >
            <ImageWithFallback alt={'channel'} style={{ width: '200px', height: 'auto', margin: '20px auto' }} src={image ? image : `${process.env.REACT_APP_BASE_ORIGIN}/img/channels/${initValues.image}`} />
            <MDButton onClick={() => { setOpenDropZone(true) }} mt={2} variant="gradient" color="secondary">
              Upload image
            </MDButton>
          </Grid>
          <Grid container item xs={8} justifyContent={'center'} alignItems={'center'}>
            <Grid item sx={{ padding: '0px 10px', textAlign: "center" }} xs={3}>
              Name:
            </Grid>
            <Grid item xs={9}>
              <MDInput
                name="name"
                id="name"
                fullWidth
                value={values.name}
                error={!!touched.name && !!errors.name}
                // helperText={touched.name && errors.name}
                onChange={handleChange}
              />
            </Grid>
            <Grid item sx={{ padding: '0px 10px', textAlign: "center" }} xs={3}>
              Category:
            </Grid>
            <Grid item xs={9}>
              <MDSelectMui
                size="small"
                name="category"
                id="category"
                multiple={true}
                value={values.category}
                onChange={handleSelectCategories}
                options={categoryChannels?.map((category) => ({
                  label: category?.[lang],
                  value: category?.id,
                }))}
                withChip
              />
            </Grid>
            <Grid item xs={3} sx={{ padding: '0px 10px', textAlign: "center" }} >
              EPG:
            </Grid>
            <Grid item xs={9} >
              <MDSelectMui
                withChip
                size="small"
                multiple={true}
                value={values.reserveEpgId}
                onChange={handleSelectEpg}
                options={epgIds}
                valueKey="epgId"
                labelKey="epgId"
                nestedKeyToCompare="epgId"
              />
              {/* <MDSelect
                value={values.epgId}
                error={!!touched.epgId && !!errors.epgId}
                helperText={touched.epgId && errors.epgId}
                onChange={handleSelectEpg}
                options={epgIds}
                getOptionLabel={(option) => option.epgId || ""}
              /> */}

            </Grid>
            <Grid item xs={3} sx={{ padding: '0px 10px', textAlign: "center" }} >
              Archive:
            </Grid>
            <Grid item xs={9} >
              <MDSelect
                value={values.archive}
                error={!!touched.archive && !!errors.archive}
                helperText={touched.archive && errors.archive}
                onChange={handleSelectArchive}
                options={archives}
                getOptionLabel={(option) => option.name || ""}
              />
            </Grid>
          </Grid>

          {isFromDB ?
            <Grid container >
              <Grid item sx={{ padding: '10px' }} xs={2}>
                Stream:
              </Grid>
              <Grid sx={{ marginBottom: "20px" }} item xs={10}>
                <MDSelect
                  size={"small"}
                  label="select from streams"
                  value={selectedStream}
                  onChange={handleSelectURL}
                  options={streamURLs}
                  getOptionLabel={(option) => option.name || ""}
                />
              </Grid>
            </Grid>
            :
            <Grid container sx={{ marginBottom: "20px" }} >
              <Grid item sx={{ padding: '10px' }} xs={2}>
                Stream URL:
              </Grid>
              <Grid item xs={10}>
                <Grid container>
                  {/* <Grid item xs={9}>
                  <MDInput
                    sx={{ marginBottom: "5px" }}
                    label="Stream URL"
                    name="url"
                    id="url"
                    fullWidth
                    value={values.url}
                    error={!!touched.url && !!errors.url}
                    // helperText={touched.url && errors.url}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid xs={3}>
                  <MDSelectMui
                    disabled={true}
                    label="Resolution"
                    value={['auto']}
                    // error={!!touched.resolution && !!errors.resolution}
                    // helperText={touched.resolution && errors.resolution}
                    options={[{
                        label: 'auto',
                        value: 'auto',
                    }]}
                  />
                </Grid> */}
                  <Grid item xs={12}>
                    <WithPermission disable={true} permissionsList={[EditSourcePermission]} permissionsKey={'channels'} >
                      {URLs.map((e, i, arr) => {
                        return <Grid container sx={{ marginBottom: "5px" }}>
                          <Grid item xs={8}  >
                            <TextField
                              size={"small"}
                              fullWidth
                              disabled={!checkPermission([EditSourcePermission], 'channels')}
                              value={e.input}
                              onChange={(e) => { handleInputsChange(e, i) }}
                            // error={touched.input?.[i] && errors.input?.[i]}
                            // helperText={touched.input?.[i] && errors.input?.[i]}
                            />
                          </Grid>
                          <Grid item xs={4} sx={{ display: 'flex' }}>
                            <MDSelect
                              size={"small"}
                              disabled={!checkPermission([EditSourcePermission], 'channels')}
                              value={URLs[i].resolution}
                              onChange={(ids) => { handleResolution(ids, i) }}
                              getOptionDisabled={(option) => option.disabled}
                              options={resolutionsList.map((res, i) => ({
                                label: res.name,
                                name: res.name,
                                value: res.id,
                                disabled: URLs.some((item) => (item?.resolution?.id === res?.id))
                              }))}
                              getOptionLabel={(option) => option.name || ""}
                            />

                            {i === arr.length - 1 && <IconButton disabled={!checkPermission([EditSourcePermission], 'channels')} onClick={handleAddInputUrl} >
                              <AddOutlined />
                            </IconButton>}
                            {arr.length > 1 && <IconButton disabled={!checkPermission([EditSourcePermission], 'channels')} onClick={() => { handleRemoveInputUrl(i) }} >
                              <Remove />
                            </IconButton>}
                          </Grid>
                        </Grid>
                      })}
                    </WithPermission>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>}
        </Grid>
        <MDBox sx={{ display: "flex", justifyContent: "flex-end", gap: "10px" }}>
          {(handleCancel || setOpenModal) && <MDButton variant="outlined" color="info" onClick={() => handleCancel ? handleCancel() : setOpenModal?.({ bool: false })}>
            {t('Close')}
          </MDButton>}
          <MDButton variant="gradient" color="info" onClick={handleSubmit}>
            {openModal?.isUpdate ? t('channel.update') : t('channel.create')}
          </MDButton>
        </MDBox>
      </MDBox>
      <DropzoneDialog
        acceptedFiles={['image/*']}
        cancelButtonText={"cancel"}
        submitButtonText={"submit"}
        filesLimit={1}
        maxFileSize={5000000}
        open={openDropzone}
        onClose={() => setOpenDropZone(false)}
        onSave={(files) => {
          setFile(files[0])
          setOpenDropZone(false);
        }}
        showPreviews={true}
        showFileNamesInPreview={true}
      />

    </>
  );
}
