import { Button, FormControlLabel, FormGroup, FormHelperText, Grid, IconButton, InputAdornment, Switch, TextField } from "@mui/material";
import MDBox from "components/MDBox";
import MDDialog from "../../../components/MDDialog";
import MDInput from "../../../components/MDInput";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import * as yup from "yup";
import { getTariffs } from "redux/actions/tariffs";
import { DropzoneDialog } from "material-ui-dropzone";
import MDSelect from "components/MDSelect";
import Tooltip from "@mui/material/Tooltip";
import { useTranslation } from "react-i18next";
import styles from "./styles.module.css";
import { AddOutlined, CloseRounded, PlayArrow, Remove } from "@mui/icons-material";
import PlayDisabledIcon from '@mui/icons-material/PlayDisabled';
import { usePrevious } from "utils/customHooks";
import MDSelectMui from "components/MDSelectMui";
import MDButton from "components/MDButton";
import { api } from "api/config";
import { baseURLpublic } from "api/config";
import { ClipLoader } from "react-spinners";
import { addNotification } from "redux/actions/notifications";
import UploadProgress from "./uploadMovie";
import { getVOD } from "redux/actions/VOD";
import AddIcon from "@mui/icons-material/Add";
import { Delete } from "@mui/icons-material";
import UploadProgressForSeries from "./uploadSeries";
import { v4 as uuidv4 } from 'uuid';
import ImageWithFallback from "../../../components/ImgWithFallback";
import { apiAuth } from "api/config";



export default function Modal({ openModal, setOpenModal, VODServerInfo, VODs, openInfoModal, setOpenInfoModal, currentVod }) {
    const uniqueNameError = 'Name must be unique for a streamer'
    const uniqueInputError = 'Input must be unique for a streamer'
    const dispatch = useDispatch()
    const langs = {
        en: {
            en: "English",
            ru: "Russian",
            am: "Armenian"
        },
        ru: {
            en: "Английский",
            ru: "Русский",
            am: "Армянский"
        },
        am: {
            en: "Անգլերեն",
            ru: "Ռուսերեն",
            am: "Հայերեն"
        }
    }

    const priceSymbols = ['$', '֏', '₽']

    const schema = yup.object({
        title: yup.object().shape({
            en: yup.string()
                .required('field is required')
                .test('test', uniqueNameError, function (value) {
                    if (openModal?.modalValues) {
                        return VODs.filter(item => item._id !== openModal?.modalValues?._id).every(item => item.name !== value)
                    }
                    return VODs.every(item => item.name !== value)
                }),
            ru: yup.string(),
            am: yup.string()
        }),
        description: yup.object().shape({
            en: yup.string(),
            ru: yup.string(),
            am: yup.string()
        }),
        price: yup.number(),
        year: yup.number(),
        raiting: yup.number(),
    });
    const { t, i18n } = useTranslation()
    const { settings } = useSelector(store => store.settings)
    const movieGenres = settings?.categoriesVOD
    const [withCmd, setWithCmd] = useState(false)
    const [loading, setLoading] = useState(false)
    const [primaryImage, setPrimaryImage] = useState("uploaded")
    const [selectedResIds, setSelectedResIds] = useState([])
    const [poster, setPoster] = useState('')
    const [uploadedImageBase64, setUploadedImageBase64] = useState('')
    const [movieUploaded, setMovieUploaded] = useState(false)
    const [vodInfo, setVodInfo] = useState()
    const [seasons, setSeasons] = useState([{ label: "Season 1", id: 1, episodes: [{ label: "Episode 1", id: 1 }] }]);
    const [activeSeason, setActiveSeason] = useState(seasons?.[0]);

    const [activeEpisode, setActiveEpisode] = useState(seasons?.[0]);
    const [uploadingEpisodes, setUploadingEpisodes] = useState([]);
    const [openDropZone, setOpenDropZone] = useState(false);
    const [VODImage, setVODImage] = useState();
    const isImageBase64 = useRef(false)


    const emptyInput = { input: "", resolution: {} }

    const [movie, setMovie] = useState([emptyInput])


    const handleSelectGenress = (_, e) => {
        setFieldValue('genre', e.map(item => item.value || item))
    }
    const initValues = {
        title: { en: openModal?.modalValues?.title?.en || "", ru: openModal?.modalValues?.title?.ru || "", am: openModal?.modalValues?.title?.am || "" },
        description: { en: "", ru: "", am: "" },
        price: 0,
        year: 0,
        raiting: 0,
        genre: [],
        VODServerInfo,
        type: 'movie',
        priceSymbol: priceSymbols[0]
    };

    const { values, handleChange, touched, errors, handleSubmit, setFieldValue, setValues, resetForm } = useFormik({
        initialValues: initValues,
        validationSchema: schema,
        onSubmit: async (values) => {
            try {

                const safeName = encodeURIComponent(values?.title?.en?.replaceAll(' ', "_")).replaceAll('%', '')
                let url = `${values.VODServerInfo?.host}/movies/${safeName}/index.m3u8`
                const imageKey = isImageBase64.current ? 'image' : 'poster'
                if (selectedVODType.id === 'serial') {
                    const safeSeason = activeSeason.label.replaceAll(" ", '_')
                    const safeEpisode = activeEpisode.label.replaceAll(" ", '_')
                    const subtitles = openInfoModal?.selecteds?.selectedsSubtitle.map((item, index) => {
                        return {
                            title: item.title,
                            language: item.language,
                            type: "text/vtt",
                            uri: `/movies/${safeName}/${safeSeason}/${safeEpisode}/subtitle/${item.language || "Track"}_${index + 1}/subtitles.vtt`
                        }
                    }) || []

                    const uploadedSubtitles = openInfoModal?.selecteds?.selectedsSubtitleUploaded?.map((item, index) => {
                        return {
                            title: item.title,
                            language: item.language,
                            type: "text/vtt",
                            uri: `/movies/${safeName}/${safeSeason}/${safeEpisode}/subtitle/${item.language || "Track"}_${index + 1}_uploaded/subtitles.vtt`
                        }
                    }) || []

                    const subtitlesCombined = [...subtitles, ...uploadedSubtitles]
                    const newSeasons = [...seasons];
                    const index = newSeasons.findIndex(item => item.id === activeSeason.id)
                    const newEpisodes = [...newSeasons[index].episodes]
                    const episodeIndex = newEpisodes.findIndex(item => item.id === activeEpisode.id)
                    newEpisodes.splice(episodeIndex, 1, { ...activeEpisode, subtitles: subtitlesCombined });
                    newSeasons.splice(index, 1, { ...activeSeason, episodes: newEpisodes });
                    openModal?.onSave({ ...values, [imageKey]: poster, seasons: newSeasons });
                } else {
                    const subtitles = openInfoModal?.selecteds?.selectedsSubtitle.map((item, index) => {
                        return {
                            title: item.title,
                            language: item.language,
                            type: "text/vtt",
                            uri: `/movies/${safeName}/subtitle/${item.language || "Track"}_${index + 1}/subtitles.vtt`
                        }
                    }) || []

                    const uploadedSubtitles = openInfoModal?.selecteds?.selectedsSubtitleUploaded?.map((item, index) => {
                        return {
                            title: item.title,
                            language: item.language,
                            type: "text/vtt",
                            uri: `/movies/${safeName}/subtitle/${item.language || "Track"}_${index + 1}_uploaded/subtitles.vtt`
                        }
                    }) || []

                    openModal?.onSave({ ...values, [imageKey]: poster, url: movieUploaded ? url : values.url || url, subtitles: [...subtitles, ...uploadedSubtitles] });
                }
                if (openModal?.movieUpload && movieUploaded) {
                    await openModal?.movieUpload(openInfoModal?.selecteds)
                } else {
                    setOpenModal({ bool: false });
                }
                dispatch(getVOD())
            } catch (error) {
                console.log(error);
            }
        },
    });

    const VODTypes = [{ id: "movie", label: "Movie" }, { id: "serial", label: "Series" }]

    const [selectedVODType, setSelectedVODType] = useState(openModal?.modalValues?.type === 'serial' ? VODTypes[1] : VODTypes[0])

    const handleVODTypeChange = (e) => {
        setSelectedVODType(e)
        setFieldValue("type", e.id)
    }

    const handleAutofillData = async () => {
        try {
            setPrimaryImage("poster")
            setLoading(true)
            const { data: movieData } = await api.get(`/VOD/movieData`, { params: { title: values.title.en, lang: 'es' } })
            setPoster(movieData.data.Poster);
            setFieldValue('description.en', movieData.data.Plot)
            setFieldValue('year', +movieData.data.Year)
            setFieldValue('duration', parseInt(movieData.data.Runtime) * 60)
            setFieldValue('genre', movieData.data.Genre.split(", ").map(item => movieGenres.find(it => it.en.includes(item))?.id).filter(i => i))
            setFieldValue('raiting', +movieData.data.imdbRating)
            setFieldValue('title.en', movieData.data.Title)
            setFieldValue('title.am', movieData.data.Title)
            setFieldValue('title.ru', movieData.data.Title)
            setLoading(false)
        } catch (error) {
            setLoading(false)
            dispatch(addNotification({
                color: 'error',
                icon: 'warning',
                title: '',
                content: error.response.data.data,
                dateTime: Date.now(),
            }))
        }
    }

    useEffect(() => {
        if (openModal?.modalValues) {
            setPrimaryImage("poster")
            setPoster(`${baseURLpublic}${openModal?.modalValues?.posterlg}`);
            setFieldValue('description.en', openModal?.modalValues?.description?.en)
            setFieldValue('description.ru', openModal?.modalValues?.description?.ru)
            setFieldValue('description.am', openModal?.modalValues?.description?.am)
            setFieldValue('year', +openModal?.modalValues?.year)
            setFieldValue('duration', parseInt(openModal?.modalValues?.duration))
            setFieldValue('genre', openModal?.modalValues?.genre.map(item => +item) || [])
            setFieldValue('raiting', +openModal?.modalValues?.raiting)
            setFieldValue('title.en', openModal?.modalValues?.title.en)
            setFieldValue('title.am', openModal?.modalValues?.title.am)
            setFieldValue('title.ru', openModal?.modalValues?.title.ru)
            setMovieUploaded(!!openModal?.modalValues?.url)
            setFieldValue('url', openModal?.modalValues?.url)
            setSeasons(openModal?.modalValues?.seasons)
            setActiveSeason(openModal?.modalValues?.seasons?.[0])
            handleVODTypeChange(openModal?.modalValues?.type === 'serial' ? VODTypes[1] : VODTypes[0])
            isImageBase64.current = false
        } else {
            setPoster('')
            setMovieUploaded(false)
            setValues(initValues)
            const emptySeason = [{ label: "Season 1", id: 1, episodes: [{ label: "Episode 1", id: 1 }] }]
            setSeasons(emptySeason)
            setActiveSeason(emptySeason[0])
            handleVODTypeChange(VODTypes[0])
            isImageBase64.current = false
            if (!openModal?.bool) {
                resetForm()
            }
        }
    }, [openModal?.modalValues, openModal?.bool])

    useEffect(() => {
        if (openModal?.modalValues && openModal?.bool) {
            const name = encodeURIComponent(openModal?.modalValues?.title?.en?.replaceAll(' ', '_')).replaceAll('%', '')
            if (name) {
                apiAuth.get(`${VODServerInfo.host}/VOD/Check/${name}`).then((res) => {
                    if (res.data.info) {
                        setVodInfo(res.data.info)
                    }
                    setMovieUploaded(true)
                }).catch(() => {
                    setMovieUploaded(false)
                })
            }
        }
    }, [openModal?.modalValues?.title?.en, openModal?.bool])

    useEffect(() => {
        if (VODImage) {
            const reader = new FileReader();
            reader.addEventListener("load", function () {
                // convert image VODImage to base64 string
                setPrimaryImage("uploaded")
                setUploadedImageBase64(reader.result);
                isImageBase64.current = true
            }, false);
            reader.readAsDataURL(VODImage);
            setPoster(VODImage)
        }
    }, [VODImage])

    const [symIdex, setSymIdex] = useState(0)
    const handleChangePriceSymbol = () => {
        setSymIdex((prev) => {
            const i = prev + 1 === priceSymbols.length ? 0 : prev + 1
            setFieldValue("priceSymbol", priceSymbols[i])
            return i
        })

    }

    const handleDeleteVOD = () => {
        setMovieUploaded(false)
        let data = {}
        if (selectedVODType?.id === 'serial') {
            const newSeasons = handleSetEpisodeUrl('')
            data = { seasons: newSeasons }
        } else if (selectedVODType.id === 'movie') {
            data = { url: '' }
        }
        const seasonA = activeSeason
        const episodeA = activeEpisode
        setOpenModal({ ...openModal, modalValues: { ...openModal?.modalValues, ...data } })
        if (openModal?.modalValues && openModal?.onSave) {
            openModal?.onSave({ ...openModal?.modalValues, poster, ...data });

        }
    }

    const handleEpisodes = (episodes) => {
        const newSeasons = [...seasons];
        const index = newSeasons.findIndex(item => item.id === activeSeason.id)
        newSeasons.splice(index, 1, { ...activeSeason, episodes });
        setSeasons(newSeasons)
        setActiveSeason({ ...activeSeason, episodes })
    }

    const prevActiveSeason = usePrevious(activeSeason)


    useEffect(() => {
        if (prevActiveSeason?.id !== activeSeason?.id) {
            activeSeason && setActiveEpisode(activeSeason?.episodes?.[0])
        }
    }, [activeSeason])

    const handleSetEpisodeUrl = (url) => {
        const newSeasons = [...seasons];
        const index = newSeasons.findIndex(item => item.id === activeSeason.id)
        const newEpisodes = [...newSeasons[index].episodes]
        const episodeIndex = newEpisodes.findIndex(item => item.id === activeEpisode.id)
        newEpisodes.splice(episodeIndex, 1, { ...activeEpisode, url });
        newSeasons.splice(index, 1, { ...activeSeason, episodes: newEpisodes });
        setSeasons(newSeasons)
        setActiveSeason({ ...activeSeason, episodes: newEpisodes })
        setActiveEpisode({ ...activeEpisode, url })
        return newSeasons
    }

    const handleSetEpisodeSubtitles = (subtitles) => {
        const newSeasons = [...seasons];
        const index = newSeasons.findIndex(item => item.id === activeSeason.id)
        const newEpisodes = [...newSeasons[index].episodes]
        const episodeIndex = newEpisodes.findIndex(item => item.id === activeEpisode.id)
        newEpisodes.splice(episodeIndex, 1, { ...activeEpisode, subtitles });
        newSeasons.splice(index, 1, { ...activeSeason, episodes: newEpisodes });
        setSeasons(newSeasons)
        setActiveSeason({ ...activeSeason, episodes: newEpisodes })
        setActiveEpisode({ ...activeEpisode, subtitles })
        return newSeasons
    }

    return (
        <MDDialog
            maxWidth="lg"
            fullWidth
            dialogHeight="fit-content"
            open={openModal?.bool}
            setOpen={setOpenModal}
            onSave={handleSubmit}
            sx={{ overflowX: 'hidden' }}
            loading={openModal?.loading}
            submitText={openModal?.movieUpload ? openModal?.isUpdate ? t('VOD.processNupdate') : t('VOD.processNcreate') : openModal?.isUpdate ? t('VOD.update') : t('VOD.create')}
        >
            {loading && <MDBox
                className={styles.loadingOverlay}
            >
                <ClipLoader />
            </MDBox>}
            <MDBox style={{ padding: '20px' }}>
                <MDBox
                    style={{
                        position: "absolute",
                        top: 10,
                        right: 10,
                        width: '250px',
                        display: 'flex',
                        zIndex: 9000,
                        backgroundColor: '#fff',
                    }}
                >

                    <MDSelect
                        sx={{ marginTop: '7px' }}
                        size='small'
                        label={`${t("type")}`}
                        value={selectedVODType}
                        disabled={openModal?.isUpdate}
                        onChange={handleVODTypeChange}
                        options={VODTypes}
                        getOptionLabel={(option) => option.label}
                        isOptionEqualToValue={(option, value) => option.id === value}
                    />
                    <MDButton
                        style={{ marginLeft: '17px' }}
                        variant="gradient"
                        color="info"
                        disabled={!values.title.en}
                        onClick={handleAutofillData}
                    >
                        {t('Autofill')}
                    </MDButton>
                </MDBox>

                <Grid container spacing={5} sx={{ marginTop: '1px' }} >
                    <Grid item xs={3} sx={{ display: "flex", justifyContent: "flex-start", gap: "10px", alignItems: "center", flexDirection: "column" }} >
                        <ImageWithFallback src={primaryImage === "uploaded" ? uploadedImageBase64 : poster} style={{ width: 'auto', height: 'auto', maxHeight: "200px", maxWidth: "200px", }} alt={values.title.en} />
                        <MDButton variant='gradient' color='info' fullWidth onClick={() => setOpenDropZone(true)} >{t("Upload")}</MDButton>
                    </Grid>
                    <Grid item xs={9} rowSpacing={2} container>

                        <Grid container item xs={12} spacing={5} >
                            {Object.keys(initValues.title).map((key) => <Grid item xs={4}>
                                <MDInput
                                    label={langs[i18n.language][key]}
                                    fullWidth
                                    value={values.title[key]}
                                    id={`title.${key}`}
                                    name={`title.${key}`}
                                    error={touched.title?.[key] && errors.title?.[key]}
                                    helperText={touched.title?.[key] && errors.title?.[key]}
                                    onChange={handleChange}
                                    sx={{ margin: '5px' }}

                                />
                            </Grid>)}
                        </Grid>

                        <Grid container item xs={12} spacing={5} >
                            {Object.keys(initValues.description).map((key) => <Grid item xs={4}>

                                <MDInput
                                    label={langs[i18n.language][key]}
                                    fullWidth
                                    value={values.description[key]}
                                    id={`description.${key}`}
                                    name={`description.${key}`}
                                    error={touched.description?.[key] && errors.description?.[key]}
                                    helperText={touched.description?.[key] && errors.description?.[key]}
                                    onChange={handleChange}
                                    style={{ width: "100%" }}
                                    multiline
                                    rows={5}
                                />
                            </Grid>)}
                        </Grid>

                        <Grid container spacing={5} item xs={12} >
                            <Grid item xs={3}>
                                <MDInput
                                    label={t('Year')}
                                    fullWidth
                                    value={values.year}
                                    type="number"
                                    id={"year"}
                                    name={"year"}
                                    error={touched.year && errors.year}
                                    helperText={touched.year && errors.year}
                                    onChange={handleChange}

                                />
                            </Grid>

                            <Grid item xs={3}>
                                <MDInput
                                    label={t('Price')}
                                    fullWidth
                                    value={values.price}
                                    type="number"
                                    id={"price"}
                                    name={"price"}
                                    error={touched.price && errors.price}
                                    helperText={touched.price && errors.price}
                                    onChange={handleChange}

                                    InputProps={{
                                        endAdornment: <InputAdornment position="start"><IconButton className={styles.priceSym} onClick={handleChangePriceSymbol}>{priceSymbols[symIdex]}</IconButton></InputAdornment>,
                                    }}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <MDInput
                                    label={t('Raiting')}
                                    fullWidth
                                    value={values.raiting}
                                    type="number"
                                    id={"raiting"}
                                    name={"raiting"}
                                    inputProps={{ max: 10, min: 0 }}
                                    error={touched.raiting && errors.raiting}
                                    helperText={touched.raiting && errors.raiting}
                                    onChange={handleChange}

                                />
                            </Grid>
                            <Grid item xs={3}>
                                <MDInput
                                    label={t('Length_(min)')}
                                    fullWidth
                                    value={values.duration / 60}
                                    type="number"
                                    id={"duration"}
                                    name={"duration"}
                                    error={touched.duration && errors.duration}
                                    helperText={touched.duration && errors.duration}
                                    onChange={handleChange}

                                />
                            </Grid>

                        </Grid>

                        <Grid item xs={2}>

                        </Grid>
                        <Grid container item xs={12} >
                            <Grid item xs={3} spacing={5} >
                                <MDSelectMui
                                    size="small"
                                    name="genre"
                                    id="genre"
                                    multiple={true}
                                    value={values.genre}
                                    onChange={handleSelectGenress}
                                    options={movieGenres.map((genre) => ({
                                        label: genre?.[i18n.language],
                                        value: genre.id,
                                    }))}
                                    withChip
                                />
                            </Grid>
                        </Grid>
                        {selectedVODType?.id === 'movie' && <Grid container>
                            <Grid item xs={12} spacing={5}>
                                <UploadProgress
                                    openModal={openModal}
                                    setOpenModal={setOpenModal}
                                    onDelete={handleDeleteVOD}
                                    movieUploaded={movieUploaded}
                                    vodInfo={vodInfo}
                                    setVodInfo={setVodInfo}
                                    url={openModal?.modalValues?.url}
                                    setMovieUploaded={setMovieUploaded}
                                    title={values?.title?.en}
                                    name={encodeURIComponent(values?.title?.en.replaceAll(' ', '_')).replaceAll('%', '')}
                                    serverInfo={VODServerInfo}
                                    setOpenInfoModal={setOpenInfoModal}
                                    openInfoModal={openInfoModal}
                                />
                            </Grid>
                        </Grid>}

                    </Grid>
                    {selectedVODType?.id === 'serial' && <Grid item xs={12}>
                        <Grid container>
                            <Grid item xs={12} >
                                <ButtonsGroup setActive={setActiveSeason} activeButton={activeSeason} buttons={seasons} setButtons={setSeasons} label='Season' containerClassname={styles.seasonsButtons} />
                            </Grid>
                            <Grid item xs={12} >
                                <ButtonsGroup uploadingEpisodes={uploadingEpisodes} setActive={setActiveEpisode} activeButton={activeEpisode} buttons={activeSeason?.episodes} setButtons={handleEpisodes} label='Episode' containerClassname={styles.seasonsButtons} />
                            </Grid>
                            <Grid item xs={12} spacing={5}>
                                <UploadProgressForSeries
                                    setOpenInfoModal={setOpenInfoModal}
                                    poster={poster}
                                    values={values}
                                    seasons={seasons}
                                    isImageBase64={isImageBase64}
                                    onSave={openModal?.onSave}
                                    uploadingEpisodes={uploadingEpisodes}
                                    setUploadingEpisodes={setUploadingEpisodes}
                                    openModal={openModal}
                                    episode={activeEpisode}
                                    season={activeSeason}
                                    handleSetUrl={handleSetEpisodeUrl}
                                    handleSetEpisodeSubtitles={handleSetEpisodeSubtitles}
                                    setOpenModal={setOpenModal}
                                    onDelete={handleDeleteVOD}
                                    movieUploaded={movieUploaded}
                                    url={openModal?.modalValues?.url}
                                    setMovieUploaded={setMovieUploaded}
                                    title={values?.title?.en}
                                    name={encodeURIComponent(values?.title?.en.replaceAll(' ', '_')).replaceAll('%', '')}
                                    serverInfo={VODServerInfo}
                                    vodInfo={vodInfo}
                                    setVodInfo={setVodInfo}
                                />
                            </Grid>
                        </Grid>
                    </Grid>}
                </Grid>
            </MDBox>
            <DropzoneDialog
                acceptedFiles={['image/*']}
                cancelButtonText={"cancel"}
                submitButtonText={"submit"}
                filesLimit={1}
                maxFileSize={5000000}
                open={openDropZone}
                onClose={() => setOpenDropZone(false)}
                onSave={(files) => {
                    setVODImage(files[0])
                    setOpenDropZone(false);
                }}
                showPreviews={true}
                showFileNamesInPreview={true}
            />
        </MDDialog>
    );
}



const ButtonsGroup = ({ buttons, setButtons, setActive, activeButton, label, containerClassname, addButton, uploadingEpisodes }) => {


    const handleAddButton = () => {
        const newData = { label: `${label} ${buttons.length + 1}`, id: uuidv4() }
        if (label === 'Season') {
            newData.episodes = [{ label: `Episode 1`, id: uuidv4() }]
        }
        setButtons([...buttons, newData]);
    };
    const handleDeleteButton = (index) => {
        const newButtons = [...buttons];
        newButtons.splice(index, 1);
        setButtons(newButtons);
    };

    const handleSeasonClick = (season) => {
        setActive(season)
    }



    return (
        <div className={containerClassname}>
            {buttons.map((season, index) => (
                <>
                    <MDButton
                        variant={activeButton?.id === season.id ? 'gradient' : 'outlined'}
                        color="info"
                        key={season.id}
                        sx={{ minWidth: '165px', width: '155px', display: 'flex', alignItems: 'center' }}
                        onClick={() => handleSeasonClick(season)}
                    >
                        <span style={{ marginRight: '5px' }}>{season.label}</span>
                        {label !== 'Season' && (season.url ? <PlayArrow /> : <PlayDisabledIcon />)}
                        {uploadingEpisodes?.includes(season.id) ? <ClipLoader size={15} /> :
                            buttons.length > 1 && index === buttons.length - 1 && (label === 'Season' ? season.episodes.every(item => !item.url) : !season.url) && (

                                <Delete color="error" onClick={(e) => { e.stopPropagation(); handleDeleteButton(index) }} />
                            )}
                    </MDButton>
                </>
            ))}
            {addButton === 'outline' ?
                <MDButton
                    variant={'outlined'}
                    color="secondary"
                    sx={{ minWidth: '140px', width: '140px' }}
                    onClick={handleAddButton}
                >
                    <AddIcon />
                </MDButton>
                :
                <IconButton onClick={handleAddButton}><AddIcon /></IconButton>
            }
        </div>
    );
}
