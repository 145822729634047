import { Card, Grid, IconButton, Popover } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import DataTable from "examples/Tables/DataTable";
import React, { useEffect, useRef, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import { Delete, Edit } from "@mui/icons-material";
import ModalSubUser from '../SubUserModal';
import { updateSubuser, createSubuser } from "redux/actions/customers";
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox';
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { deleteSubuser } from "redux/actions/customers";
import WithPermission from "components/withPermission/WithPermission";
import { AddSubuserPermission } from "pages/settings/Admins/constants";
import { checkPermission } from "utils/checkPermission";
import { DeleteSubuserPermission } from "pages/settings/Admins/constants";
import moment from "moment";
import { api } from "api/config";
import { DateRangePicker } from "react-date-range";
import { addDays, startOfYear } from 'date-fns';
import { getSingleItemByAnOption } from "utils/helpers";
import { ClipLoader } from "react-spinners";
import { getDayStartTimestamp } from "utils/helpers";
import { getDayEndTimestamp } from "utils/helpers";

export default function Payments({ data, tariff }) {

    const dispatch = useDispatch()
    const { t } = useTranslation();
    const { list: adminsList } = useSelector(store => store.admins)

    const [openSubuserModal, setOpenSubuserModal] = useState({ bool: false });
    const [userPayments, setUserPayments] = useState([])
    const [dates, setDates] = useState([
        {
            startDate: startOfYear(new Date()),
            endDate: new Date(),
            key: 'selection'
        }
    ]);
    const id = useRef(null)
    const [dateText, setDateText] = useState(`${moment(dates[0].startDate).format("MMM DD YYYY")} - ${moment(dates[0].endDate).format("MMM DD YYYY")}`)
    const [anchorEl, setAnchorEl] = React.useState(null);

    const [loading, setLoading] = useState(false)

    useEffect(() => {
        const getPayments = async () => {
            try {
                setLoading(true)
                const result = await api.get(`/billing/history/${data._id}?start=${(getDayStartTimestamp(dates[0].startDate))}&end=${(getDayEndTimestamp(dates[0].endDate))}`)
                setUserPayments(result.data.data);
                setLoading(false)
            } catch (error) {
                console.log({ error });
                setLoading(false)
            }
        }
        if (data?._id) {
            getPayments()
        }
    }, [data, dates])


    const columns = [
        { Header: t("Amount"), accessor: "amount", align: "left" },
        { Header: t("Balance_Before"), accessor: "balanceBefore", align: "left" },
        { Header: t("Balance_After"), accessor: "balanceAfter", align: "left" },
        { Header: t("Made_By"), accessor: "madeBy", align: "left" },
        { Header: t("Date"), accessor: "Date", align: "left" },
        { Header: t("Tariff"), accessor: "tariff", align: "center" },
    ];
    const rows = userPayments?.map((item) => {
        const admin = adminsList ? getSingleItemByAnOption(adminsList, "_id", item?.madeBy?._id) : null
        return {
            amount: <>
                <IconButton>
                    {<CheckBoxIcon sx={{ color: 'success.main' }} />}
                </IconButton>
                {item?.amount}
            </>,
            balanceBefore: `${item?.balanceBeforePayment}`,
            balanceAfter: `${item?.balanceAfterPayment}`,
            madeBy: adminsList ? admin ? `${admin?.firstName} ${admin?.lastName}` : `${item?.madeBy?.firstName} ${item?.madeBy?.lastName}` : '-',
            Date: item.timeStamp ? moment(item.timeStamp).format('DD/MM/YYYY HH:mm:ss') : '-',
            tariff: tariff?.name,
        }
    });


    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const idPop = open ? 'simple-popover' : undefined;


    const handleDateChange = (item) => {
        setDates([item.selection])
        setDateText(`${moment(item.selection.startDate).format("MMM DD YYYY")} - ${moment(item.selection.endDate).format("MMM DD YYYY")}`)
    }

    return (
        <MDBox sx={{ position: 'relative' }} >
            {loading && <div className="loader">
                <ClipLoader />
            </div>}
            <Grid container spacing={0}>
                <Grid item xs={12}>
                    <MDBox
                        pl={2}
                        pr={2}
                        sx={{ display: "flex", justifyContent: "space-between", alignItems: 'center' }}
                    >
                        <MDButton aria-describedby={idPop} variant="outlined" fullWidth color="secondary" onClick={handleClick}>
                            {dateText}
                        </MDButton>
                        <Popover
                            id={id}
                            open={open}
                            anchorEl={anchorEl}
                            onClose={handleClose}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'center',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'center',
                            }}
                        >
                            <div
                                style={{ width: '90wv', overflow: 'auto' }}
                            >
                                <DateRangePicker
                                    onChange={handleDateChange}
                                    showSelectionPreview={true}
                                    moveRangeOnFirstSelection={false}
                                    months={2}
                                    ranges={dates}
                                    direction="horizontal"
                                    maxDate={new Date()}
                                    preventSnapRefocus={true}
                                    calendarFocus="backwards"

                                />
                            </div>
                        </Popover>
                    </MDBox>
                    <MDBox sx={{ height: '275px', overflow: 'auto' }}>
                        {rows && (
                            <DataTable
                                table={{ columns, rows }}
                                isSorted={false}
                                showTotalEntries={false}
                                canSearch={false}
                                entriesPerPageBox={false}
                                suppressPagination={true}
                            />
                        )}
                    </MDBox>
                </Grid>
            </Grid>
            <ModalSubUser openModal={openSubuserModal} setOpenModal={setOpenSubuserModal} />
        </MDBox>
    )
}
