import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import MDButton from "components/MDButton";
import { useTranslation } from "react-i18next";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function MDDialog({
  open,
  setOpen,
  handleClose,
  title,
  onSave,
  children,
  dialogHeight,
  dialogWidth,
  submitText,
  loading,
  suppressActions,
  suppressClose,
  error,
  disabled,
  ...rest
}) {
  const handleCloseModal = () => {
    if(handleClose){
      handleClose?.()
    }else{
      setOpen({ bool: false });
    }
  };
  const { t } = useTranslation()
  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted={false}
        onClose={handleCloseModal}
        fullScreen={window.innerWidth<600}
        aria-describedby="alert-dialog-slide-description"
        {...rest}
      >
        <DialogTitle>{title}</DialogTitle>
        <DialogContent sx={{ height: dialogHeight, width: dialogWidth }}>{children}</DialogContent>
        <MDBox sx={{display: "flex", justifyContent: "flex-end", width: "100%", padding: "0px 20px"}}>
          {error && <MDTypography color={"error"} variant="subtitle2" >{error}</MDTypography>}
        </MDBox>
        {!suppressActions && <DialogActions>
          {!suppressClose && <MDButton disabled={loading} variant="outlined" color="info" onClick={handleCloseModal}>
            {t('Close')}
          </MDButton>}
          {onSave && (
            <MDButton disabled={disabled || loading} loading={loading} variant="gradient" color="info" onClick={onSave}>
              {submitText ? submitText : t('Save')}
            </MDButton>
          )}
        </DialogActions>}
      </Dialog>
    </div>
  );
}

MDDialog.defaults = {
  dialogHeight: "20vh",
  dialogWidth: "fit-content"
};
