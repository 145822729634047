export default {
  translations: {
    appTitle: "RippleTV",
    noDataText: "Нет строк для отображения",
    updateNow: "нажмите, чтобы обновить сейчас",
    loading: "Загрузка",
    noNotifications: "Нет уведомлений",
    noPermission: "У вас нет соответствующего разрешения",
    active: 'Активный',
    inactive: 'Неактивный',

    VOD_Categories: 'Категории VOD',
    Channels_Categories: 'Категории каналов',
    getSoftwareId: 'Получить Software ID',
    Full_Name: 'Полное имя',
    Email: 'Логин',
    Created_By: 'Сделано',
    Role: 'Роль',

    Admin_Info: "Информация администратора",
    Permissions: "Разрешения",
    VOD_: "VOD",
    First_Name: "Имя",
    Last_Name: "Фамилия",

    ChangePassword: "Изменить пароль",

    "Create_": "Создать",
    "View_": "Посмотреть",
    "Update_": "Обновить",
    "Delete_": "Удалить",
    "Advanced Mode_": "Расширенный режим",
    "Billing_": "Биллинг",
    "Add Subuser_": "Добавить субпользователя",
    "Update Subuser_": "Обновить субпользователя",
    "Delete Subuser_": "Удалить субпользователя",
    "Refresh_": "Обновить процесс",
    "justRefresh_": "Обновить",
    "Play_": "Смотреть видео",
    "Update Source_": "Обновление Источник",
    "Update Server Settings_": "Обновить параметры сервера",
    "Change Active Status_": "Изменить активный статус",


    "Title": "Заголовок",
    "message": "Сообщение",
    "confirmText": "Подтвердить",
    "cancelText": "Отменить",
    "notifyAllCustomers": "Уведомить всех клиентов",
    "addNotifications": "Добавить уведомления",
    "notifications": "Уведомления",

    "Global Admin_": "Глобальный администратор",
    "Moderator_": "Модератор",
    "Analyst_": "Аналитик",
    "Cashier_": "Кассир",


    "FullFlow": "Ամբողջ հոսքը",



    "createStream": "Создать поток",
    "createChannel": "Создать канал",
    "createArchiver": "Создать архиватор",
    createTranscode: "Создать Транскод",

    "creatingTranscode": "Создание транскода",
    "creatingStream": "Создание потока",
    "creatingArchive": "Создание архива",
    "creatingChannel": "Создание канала",
    "AllStepscompleted": "Все шаги завершены",
    Notes: "Заметки",

    advanced_settings: 'Расширенные настройки',
    create: 'Создавать',
    forbidDelete: 'У хоста есть сохраненные процессы, сначала удалите их, если хотите удалить хост.',
    EmailisnotValidated: 'Электронная почта не подтверждена',
    EmailisValidated: 'Электронная почта подтверждена',
    askDelete: 'Вы уверены, что удалите',
    Delete: 'Удалить',
    Total_watched_channels: 'Самые популярные каналы',
    views: 'просмотра',
    Saved: 'Сохранено',
    success_saved: 'Изменения успешно сохранены',
    view_all: 'Увидеть все',
    Defaults: 'По умолчанию',
    noViews: 'Нет просмотров за указанный период',
    Clipboard: 'Буфер',
    Copied: 'Скопировано',

    Add_new: 'Добавить',
    General: 'Генеральная',
    Categories: 'Категории',
    Admins: 'Админы',

    dblClick: 'Дважды щелкните, чтобы удалить',
    server_URL: 'URL-адрес сервера',
    Name: 'Имя',
    Output: 'Выходной URL-адрес',
    Resolutions: 'Разрешение',
    'archive_duration_(hours)': 'Длительность (час)',
    'HLS_Time_(seconds)': 'Размер сегмента (секунды)',
    archiver_URL: 'URL-адрес архиватора',
    archive_duration_hours: 'продолжительность архива (часы)',
    HLS_Time_seconds: 'Время HLS в секундах',
    Amount: 'Количество',
    Upload_image: 'Загрузить изображение',
    Transcoder: 'Транскодер',
    Archiver: 'Архиватор',
    Streamer: 'Стример',
    Configurator: 'Конфигуратор',
    "Sidenav Colors": 'Цвет боковой панели',
    "Sidenav Type": 'Тип боковой панели',
    sendResetEmail: "Отправить ссылку для сброса пароля",
    "Transparent": 'Прозрачный',
    "White": 'Белый',
    "Navbar Fixed": 'Навбар исправлен',
    "Light": 'Свет',
    "Dark": 'Темный',
    Reset: "Изменять",
    passwordChanged: "Пароль изменен",
    Customers: 'Клиенты',
    Channels: 'Каналы',
    Tariff: 'Тариф',
    EPG: 'EPG',
    Settings: 'Настройки',
    License: 'Лицензия',
    Item_Four: 'Пункт четвертый',
    Item_Five: 'Пункт пятый',
    Item_Six: 'Пункт шестой',
    Item_Seven: 'Пункт седьмой',
    Save: 'Сохранять',
    streamer_URL: 'URL стримера',
    transcoder_URL: 'URL транскодера',
    VOD_Server_URL: 'URL-адрес VOD-сервера',
    Create_Account: 'Зарегистрироваться',
    Account_created_successfully: 'Учетная запись успешно создана',
    Go_to_login: 'Перейти к входу',
    Create: 'Создавать',
    watch_history: 'История просмотра',
    reorder_channels: 'Изменить порядок',
    firstName: "Имя",
    lastName: "Фамилия",
    password: "Пароль",
    passwordConfirm: "Повторить пароль",
    Subusers_count: "Количество пользователей",
    tariff: "Тариф",
    Avatar: "Аватар",
    Upload_image: "Загрузить изображение",
    type: 'Тип',
    Autofill: 'Автозаполнение',
    Upload: 'Загрузить',
    Stream_URL: 'URL-адрес потока',
    Category: 'Категория',
    Archive: 'Архиватор',

    Year: 'год',
    Price: 'Цена',
    Raiting: 'Рейтинг',
    'Length_(min)': 'Длительность (минут)',
    Genre: 'Жанр',

    Total_channels: 'Всего каналов',
    Total_subuser_accounts: 'Всего учетных записей субпользователей',
    Total_active_VODs: 'Всего активных видео по запросу',
    Revenue_this_month: 'Выручка в этом месяце',
    Total_payments_count: 'Общее количество платежей',
    monthly_active_users: 'активных пользователей в месяц',
    monthly_active_devices: 'Активные устройства за месяц',
    active_devices: 'Активные устройства',
    daily_active_devices: 'Активные устройства за день',
    Monthly_payments: 'Ежемесячные платежи',
    Total_users_usage: "Лучшие пользователи по использованию",
    Total_User_Accounts: 'Всего учетных записей пользователей',
    Monthly_User_Accounts: 'Учетные записи пользователей за месяц',
    addedBy: 'Добавлено',
    Type: 'Способ оплаты',

    'channel(s)': "Канал(ы)",

    Space: 'Место',
    Brand: 'Бренд',
    "Fan speed": 'Скорость вентилятора',
    "Power/limit": 'Мощность/предел',
    'GPU Temp': 'Темп. граф. процессора',
    "Software ID": 'Идентификатор программы',
    "attached Gpus": "Граф. процессоры",
    "cuda Version": "Cuda версия",
    "driver Version": "Версия драйвера",
    learnMore: "Узнать больше",

    ShowingOfEntries: 'Показаны от {{entriesStart}} до {{entriesEnd}} из {{length}} записей',
    errorOccuired: "Ошибка с сервера",
    UserName: "Имя пользователя",
    Fullname: "Полное имя",
    Search: 'Поиск',
    Close: 'Отменить',
    Dashboard: "Панель приборов",
    Tariffs: "Тарифы",
    Channels: "Каналы",
    Customers: "Клиенты",
    Epg: "ЭТПГ",
    Streamers: "Стримеры",
    Transcoders: "Транскодеры",
    Archivers: "Архиваторы",
    VODRoute: "видео по запросу",
    Settings: "Настройки",
    updated: 'Обновлено',
    Firstname: 'Имя',
    Lastname: 'Фамилия',
    Tariff: 'Тариф',
    Max_Subusers: 'Макс. количество подпользователей',
    Balance: 'Баланс',
    Last_Payment_Date: 'Дата последнего платежа',
    Created_At: 'Создан в',

    Source: 'Входящий URL',
    Interval: 'Интервал',
    hours: 'час',
    minutes: 'минут',
    seconds: 'секунд',

    activeCron: 'Задание Cron активно',
    inactiveCron: 'Задание Cron неактивно',

    CPU_Temp: 'Температура процессора',
    CPU_average_load: 'Средняя загрузка процессора',
    platform: 'Платформа',
    distro: 'Дистрибутив',
    release: 'Выпускать',
    kernel: 'Ядро',
    manufacturer: 'Производитель',
    brand: 'Бренд',
    vendor: 'Продавец',
    cores: 'Ядра',
    physicalCores: 'Физические ядра',
    RAM: 'ОЗУ',
    Used: 'Исп.',
    Free: 'Своб.',

    Advanced_mode: 'Расширенный режим',
    FFmpeg: 'Команда',
    Streams: 'Стримы',
    Archives: 'Архивы',
    Transcodes: 'Транскоды',
    View: 'Посмотреть',

    epgHintHour: 'используйте число, чтобы установить часы для задания cron',
    epgHintCron: 'используйте синтаксис cron для установки интервала, например. 0 */6 * * * * (каждые 6 часов)',

    Amount: 'Сумма',
    Balance_Before: 'Баланс до',
    Balance_After: 'Баланс после',
    Made_By: 'Сделан',
    Date: 'Дата',

    Name: "Имя",
    Favorite_channels: "Любимые каналы",
    Language: "Язык",
    Last_online_date: "Последняя дата онлайн",
    action: "действие",

    IP: "IP",
    Device_Brand: "Марка устройства",
    Device_Model: "Модель устройства",
    OS: "ОС",

    Subusers: "Субпользователи",
    Tariff: "Тариф",
    Payments: "Платежи",
    ChannelsWatched: "Просмотренные каналы",
    usageHistory: "История использования",


    Important: 'Внимание',
    own_risk_TEXT: "Используйте это на свой страх и риск. это может привести к краху сервера",
    own_risk_suggest: "заполните все входные данные выше, прежде чем изменять команду",

    en: 'Английский',
    ru: 'Русский',
    am: 'Армянский»',

    Created: 'Создано',
    Updated: 'Обновлено',
    Deleted: 'Удалено',
    Viewed: 'Просмотрено',
    channel: 'канал',
    tariff: 'тариф',
    customer: 'клиент',
    stream: 'стрим',
    archive: 'архив',
    transcode: 'транскод',
    settings: 'настройки',

    "Show inline": 'Показать встроенный',
    Old: 'Старый',
    New: 'Новый',


    Tariff_Type: "Тип тарифа",
    Price: "Цена",
    "Show detailed info": 'Показать подробную информацию',
    "Total entries": 'Всего записей',
    tariffs: {
      header: "Тарифные планы",
      addNew: "Создать новый тариф",
      freeBillTypeNoPrice: "Тип оплаты установлен на бесплатный, это не вступит в силу."
    },
    channels: {
      header: "каналы",
      addNew: "Создать новый канал",
      emptyChannels: "Нет каналов для показа",
    },
    customers: {
      header: "Customers",
      addNew: "Добавить нового клиента",
      empty: "Нет клиентов для отображения",
    },
    epg: {
      header: "EPG (Электронная программа передач)",
      addNew: "Добавить новый источник или файл",
      empty: "Нет информации для показа",
      updateFullList: "Обновить полный список",
      inactiveCron: "Крон неактивен",
      activeCron: "Крон активен",
    },
    streamer: {
      header: "Стримеры",
      addNew: "Добавить новый поток",
      addNewStreamer: "Добавить нового стримера",
      empty: "Нет информации для показа",
      updateFullList: "Обновить полный список",
      create: 'Создать стримера',
      update: 'Обновить стримера',
    },
    streams: {
      header: "Трансляции {{streamer}}",
      addNew: "Добавить новый поток",
      empty: "Нет информации для показа",
      updateFullList: "Обновить полный список",
      create: 'Создать поток',
      update: 'Обновить поток',

    },
    VOD: {
      header: "VOD-серверы",
      addNew: "Добавить новый VOD-сервер",
      addNewVOD: "Добавить новый VOD-сервер",
      empty: "Нет информации для показа",
      updateFullList: "Обновить полный список",
      create: 'Создать видео по запросу',
      update: 'Обновить видео по запросу',
      processNupdate: 'Обработка и обновление VOD',
      processNcreate: 'Обработать и создать VOD',
    },
    VODServer: {
      header: "Видео по запросу {{VOD}}",
      addNew: "Добавить новый видео по запросу",
      empty: "Нет информации для показа",
      updateFullList: "обновить полный список",
      create: 'Создать VOD',
      update: 'Обновить VOD',
    },
    archiver: {
      header: "архиватор",
      addNew: "Добавить новый архив",
      empty: "Нет информации для показа",
      updateFullList: "Обновить полный список",
      addNewArchiver: "добавить новый архиватор",
      update: 'Архиватор обновлений',
    },

    archives: {
      header: "Архивы {{archiver}}",
      addNew: "Добавить новый архив",
      empty: "Нет информации для показа",
      updateFullList: "Обновить полный список",
      addNewArchiver: "Добавить новый архив",
      create: 'Создать архив',
      update: 'Обновить архив',

    },

    channel: {
      create: 'Создать канал',
      update: 'Обновить канал',
    },

    transcoder: {
      header: "Transcoder",
      addNew: "Add new transcoder",
      empty: "Нет информации для показа",
      updateFullList: "Обновить полный список",
      addNewTranscoder: "Добавить новый транскодер",
      update: 'Обновить транскодер',
    },
    transcodes: {
      header: "Транскоды {{transcoder}}",
      addNew: "Добавить новый транскод",
      empty: "Нет информации для показа",
      updateFullList: "Обновить полный список",
      addNewTranscoder: "Добавить новый транскод",
      update: 'Обновить транскод',
      create: 'Создать транскод',
    },
    advices: {
      cpuHighTempErr: 'Рассмотрите возможность улучшения систем охлаждения процессора.',
      cpuHighTempWarn: 'Мониторьте температуру процессора, потенциально высокая.',
      heatsink: 'Проверьте равномерность нанесения термопасты или наличие пыли в радиаторе.',
      lowRam: 'Рассмотрите возможность увеличения объема оперативной памяти в вашей системе.',
      Optimize: 'Оптимизируйте работающие приложения для освобождения памяти.',
      highNetwork: 'Обнаружена высокая сетевая активность. Убедитесь, что никакие неожиданные процессы не используют сеть.',
      GPUTempErr: 'Температура GPU ({{gpuName}}) высокая, рассмотрите возможность улучшения системы охлаждения.',
      GPUTempWarn: 'Высокая загрузка памяти GPU ({{gpuName}}). Рассмотрите оптимизацию нагрузки на GPU.',
      unreachable: 'Сервер недоступен!',
      unreachableLong: 'Сервер недоступен. Ниже представлена информация о последнем состоянии сервера, когда он был в рабочем состоянии.',
      cpuHighUsageErr: 'Загрузка ЦП критически высока в среднем {{avgLoad}}%. Требуется немедленное действие.',
      cpuHighUsageWarn: 'Загрузка ЦП высока в среднем {{avgLoad}}%. Следите за работой и рассмотрите возможность оптимизации процессов.'
    },
    "Email is required": "Требуется электронная почта",
    "provided email is incorrect": "Предоставленный адрес электронной почты неверен",
    "firstname is required": "Требуется имя",
    "lastName is required": "Требуется фамилия",
    "plan is required": "Требуется план",
    "Subusers count is required": "Требуется количество подпользователей",
    "password is required": "Требуется пароль",
    "password confirmation is required": "Требуется подтверждение пароля",
    "Passwords do not match": "Пароли не совпадают",
    accessibleDevices: "Доступ",
    Device: "Устройства",
    devicesCount: "Максимальное количество устройств",
    versions: "Версии",

    Default_devices: "Устройства по умолчанию",
    DeviceType: "Типы устройств",
    "codec_name": "Название кодека",
    "codec_long_name": "Полное название кодека",
    "display_aspect_ratio": "Соотношение сторон дисплея",
    "avg_frame_rate": "Средняя частота кадров",
    "coded_width": "ширина",
    "coded_height": "высота",
    "language": "Язык",
    "title": "Название",
    "channel_layout": "Расположение каналов",
    "content_of_vod": "Контент VOD {{name}}",
    Content: "Контент",
    selectvideoaudio: "Выберите по крайней мере одно аудио и одно видео.",
    Uploaded_successfully: "Загружено успешно!",
    Uploading: "Загрузка",
    Transcoded_successfully: "Транскодировано успешно!",
    Transcoding: "Транскодирование",
    Upload_subtitle: "Загрузить субтитры",
    Subtitle: "субтитры",
    phone: "Телефон",
    rowsPerPage: "Строк на странице",
    customersOnline: "{{count}} Клиенты онлайн",
    refresh: "Обновить",
    AllWidgetsAdded: "Виджеты не найдены",
    SaveWidgets: "Сохранить виджеты",
    EditWidgets: "Редактировать виджеты"
  },
};
