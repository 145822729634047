import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

export default function ControllableStates({
  label,
  value,
  error,
  helperText,
  onChange,
  options,
  getOptionLabel,
  sx,
  size,
  disabled,
  isOptionEqualToValue,
  getOptionDisabled
}) {

  return (
      <Autocomplete
        disabled={disabled}
        size={size ?? 'small'}
        value={value}
        onChange={(event, newValue) => {
          onChange(newValue)
        }}
        isOptionEqualToValue={isOptionEqualToValue}
        getOptionDisabled={getOptionDisabled}
        options={options}
        getOptionLabel={getOptionLabel}
        sx={{ width: '100%', ...sx }}
        renderInput={(params) => <TextField error={error} helperText={helperText} {...params} label={label} />}
      />
  );
}
