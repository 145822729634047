import { FormControlLabel, FormGroup, FormHelperText, Grid, Switch } from "@mui/material";
import MDBox from "components/MDBox";
import MDDialog from "components/MDDialog";
import MDInput from "components/MDInput";
import React, { memo, useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import * as yup from "yup";
import { getTariffs } from "redux/actions/tariffs";
import { DropzoneDialog } from "material-ui-dropzone";
import MDSelect from "components/MDSelect";
import Tooltip from "@mui/material/Tooltip";
import { useTranslation } from "react-i18next";
import MDSelectMui from "components/MDSelectMui";
import styles from "./styles.module.css";
import { AddOutlined, CloseRounded } from "@mui/icons-material";
import { usePrevious } from "utils/customHooks";
import WithPermission from "components/withPermission/WithPermission";
import { AdvancedModePermission } from "pages/settings/Admins/constants";
import MDButton from "components/MDButton";
// import { resolutionsList } from "utils/constants";



function CreateTranscode({ openModal, setOpenModal, transcoderInfo, handleConfirm, handleCancel }) {
    const resolutionsList = useSelector(store => store.settings.settings.resolutions)
    const { settings } = useSelector(store => store.settings)
    const uniqueNameError = 'Name must be unique for a trascoder'
    const uniqueInputError = 'Input must be unique for a trascoder'
    const transcodersList = useSelector(store => store?.transcoders?.list)
    const initSelectedTranscoder = {
        host: transcoderInfo?.host || transcodersList[0]?.url,
        id: transcoderInfo?.id || transcodersList[0]?._id,
        _id: transcoderInfo?.id || transcodersList[0]?._id,
        name: (transcoderInfo || transcodersList[0])?.name
    }
    const [selectedTranscoder, setSelectedTranscoder] = useState(initSelectedTranscoder)
    const transcoder = useSelector(store => store?.transcoder)
    const transcodes = transcoder?.list?.filter(item => item.transcoderId === (selectedTranscoder?._id || selectedTranscoder?.id))
    const handleChangeSelectedTranscoder = (_, transcoder) => {
        setSelectedTranscoder({
            host: transcoder.url,
            id: transcoder._id,
            _id: transcoder._id,
            name: transcoder.name
        })
    }

    const schema = yup.object({
        name: yup.string()
            .required('field is required')
            .test('test', uniqueNameError, function (value) {
                if (openModal?.isUpdate) {
                    return transcodes.filter(item => item._id !== openModal?.modalValues?._id).every(item => item.name !== value)
                }
                return transcodes.every(item => item.name !== value)
            }),
        input: yup.string()
            .required('field is required')
            .test('test', uniqueInputError, function (value) {
                if (openModal?.isUpdate) {
                    return transcodes.filter(item => item._id !== openModal?.modalValues?._id).every(item => item.input !== value)
                }
                return transcodes.every(item => item.input !== value)
            }),
        command: yup.string()
            .required('field is required'),
        resolution: yup.array().min(1, 'The error message if length === 0 | 1'),
    });

    const { t } = useTranslation()
    const [selectedResIds, setSelectedResIds] = useState([])
    const [withCmd, setWithCmd] = useState(false)
    const dispatch = useDispatch();
    const initValues = {
        name: openModal?.modalValues?.name ?? "ff_",
        input: openModal?.modalValues?.input ?? "",
        command: openModal?.modalValues?.command ?? "",
        resolution: openModal?.modalValues?.resolution?.length ? openModal?.modalValues?.resolution : [],
        transcoderInfo: selectedTranscoder,
    };

    const { values, handleChange, touched, errors, handleSubmit, setFieldValue } = useFormik({
        initialValues: initValues,
        validationSchema: schema,
        onSubmit: (values) => {
            if (withCmd) {
                const arr = values.command
                    .split('\n')
                    .map(item => item.trim())
                    .filter(item => item)
                    const data = { ...values, transcoderInfo: selectedTranscoder, withCmd, command: arr.join('\n') }
                    handleConfirm ? handleConfirm(data) : openModal?.onSave(data);
            } else {
                console.log({selectedTranscoder});
                const data = { ...values, transcoderInfo: selectedTranscoder, withCmd }
                handleConfirm ? handleConfirm(data) : openModal?.onSave(data);
            }
            setOpenModal?.({
                bool: false
            })
        },
    });


    const handleResolution = (_, ids) => {
        const res = resolutionsList.filter(item => ids.some(itemId => itemId.id === item.id))
        setFieldValue("resolution", res);
        setSelectedResIds(ids)
    }

    const prevName = usePrevious(values?.name)
    const prevInput = usePrevious(values?.input)

    const generateTranscoderCommand = (defaultCommand, values) => {
        const keywordsMapping = [
            { value: values.name, keyword: '{{Name}}' },
            { value: values.input, keyword: '{{INPUT}}' }
        ]

        let transcoderCommand = defaultCommand
        let split = transcoderCommand.split('\n')
        const index = split.findIndex(item => item.includes('{{INPUT}}'))
        const mainPart = split.slice(0, index + 1).join('\n')
        const repeatingPart = split.slice(index + 1).join('\n')
        transcoderCommand = mainPart
        keywordsMapping.forEach((item) => {
            transcoderCommand = transcoderCommand.replaceAll(item.keyword, item.value)
        })

        transcoderCommand += values?.resolution?.reduce((acc, item) => {
            let startString = ''
            const keywordsMappingRepeating = [
                { value: item.resolution, keyword: '{{RESOLUTION}}' },
                { value: item.bitrate, keyword: '{{BITRATE}}' },
                { value: item.maxRate, keyword: '{{MAXRATE}}' },
                { value: item.minRate, keyword: '{{MINRATE}}' },
                { value: item.bufSize, keyword: '{{BUFSIZE}}' },
                { value: `/ramdisk/${values.name}/${item.resolution}/index.m3u8`, keyword: '{{OUTPUT}}' },

            ]
            startString = repeatingPart
            keywordsMappingRepeating.forEach((item) => {
                startString = startString.replaceAll(item.keyword, item.value)
            })
            acc += startString
            return acc
        }, '')
        transcoderCommand = transcoderCommand.split('\n').filter(item => item.trim()).join('\n')
        return transcoderCommand
    }


    useEffect(() => {
        if (withCmd) {
            let command = values.command
            if (prevName) { command = command.replaceAll(`/${prevName}`, `/${values?.name}`) }
            if (prevInput) command = command.replaceAll(`/${prevInput}`, `/${values?.input}`)
            setFieldValue('command', command)
        
        } else {
            const transcoderCommand = generateTranscoderCommand(settings?.defaults?.transcoderCommand, values)

            setFieldValue('command', transcoderCommand)
        }

    }, [values?.name, values?.input, values?.resolution, withCmd])

    useEffect(() => {
        const initValues = {
            name: "",
            input: "",
            command: "",
            resolution: [],
        };
        Object.keys(initValues).forEach((key) => {
            if (key === 'resolution') {
                let ids = openModal?.modalValues?.[key]
                setSelectedResIds(ids ?? [])
                setFieldValue(key, openModal?.modalValues?.[key] ?? []);
            } else if (key === 'command' && !openModal?.modalValues?.command) {
                const transcoderCommand = generateTranscoderCommand(settings.defaults.transcoderCommand, values)
                setFieldValue('command', transcoderCommand)
            } else {
                setFieldValue(key, openModal?.modalValues?.[key] ?? initValues[key]);
            }
        });
        setWithCmd(openModal?.modalValues?.withCmd ?? false)
    }, [openModal?.bool]);


    const handleRemoveLine = (i) => {
        const arr = values?.command.split('\n')
        arr.splice(i, 1)
        setFieldValue('command', arr.join('\n'))
    }

    const handleAddLine = (i) => {
        const arr = values?.command.split('\n')
        arr.splice(i + 1, 0, ' ')
        setFieldValue('command', arr.join('\n'))
    }

    const handleCommandChange = (val, i) => {
        const arr = values?.command.split('\n')
        arr[i] = val
        setFieldValue('command', arr.join('\n'))
    }

    const willDisable = (i) => {
        const arr = values?.command.split('\n')
        const modifiedResolutions = values?.resolution.map(item => `-f hls /ramdisk/${values?.name}/${item.resolution}/index.m3u8`)
        const disabledCommands = [...modifiedResolutions, `-i '${values?.input}' -y`]
        const disabledIndexes = [0]
        return disabledIndexes.includes(i) || (disabledCommands.includes(arr[i]?.trim()))
    }

    return (
        <MDBox>
            <Grid rowSpacing={2} container>
                <Grid item xs={3}>
                    {t("Transcoder")}:
                </Grid>
                <Grid item xs={9}>
                    <MDSelectMui
                        value={selectedTranscoder}
                        error={!selectedTranscoder}
                        onChange={handleChangeSelectedTranscoder}
                        labelKey={"name"}
                        valueKey="_id"
                        options={transcodersList}

                    />
                </Grid>
                <Grid item xs={3}>
                    {t("Name")}:
                </Grid>
                <Grid item xs={9}>
                    <MDInput
                        disabled={withCmd}
                        fullWidth
                        value={values.name}
                        id="name"
                        name="name"
                        error={touched.name && errors.name}
                        helperText={touched.name && errors.name}
                        onChange={handleChange}
                    />
                </Grid>
                <Grid item xs={3}>
                    {t("Source")}:
                </Grid>
                <Grid item xs={9}>
                    <MDInput
                        disabled={withCmd}
                        fullWidth
                        value={values.input}
                        id="input"
                        name="input"
                        error={touched.input && errors.input}
                        helperText={touched.input && errors.input}
                        onChange={handleChange}
                    />
                </Grid>
                <Grid item xs={3}>
                    {t('Resolutions')}:
                </Grid>
                <Grid item xs={9}>
                    <MDSelectMui
                        multiple
                        disabled={withCmd}
                        label="select resolutions"
                        value={selectedResIds}
                        error={!!touched.resolution && !!errors.resolution}
                        helperText={touched.resolution && errors.resolution}
                        onChange={handleResolution}
                        labelKey={"name"}
                        valueKey="id"
                        options={resolutionsList}

                    />
                </Grid>

                <Grid item xs={3}>
                    {t("FFmpeg")}:
                </Grid>
                <Grid item xs={6}>
                    <WithPermission disable={true} permissionsList={[AdvancedModePermission]} permissionKey={'transcoder'} >
                        <FormGroup>
                            <FormControlLabel
                                control={
                                    <Switch color="primary" checked={withCmd} onChange={(e) => setWithCmd(e.target.checked)} />
                                }
                                checked={withCmd}
                                label={t("Advanced_mode")}
                                labelPlacement="end"
                            />
                            {withCmd && <FormHelperText error filled>{t('own_risk_TEXT')} <br /> <strong> *{t('Important')}* </strong> <br /> {t('own_risk_suggest')}</FormHelperText>}
                        </FormGroup>
                    </WithPermission>
                </Grid>

                <Grid item xs={12}>
                    {withCmd ?
                        <WithPermission disable={true} permissionsList={[AdvancedModePermission]} permissionKey={'transcoder'} >
                            <MDBox className={styles.inputsCont}>
                                {values?.command.split('\n').map((item, i) => (
                                    <div className={styles.inputCont}>
                                        <div className={styles.toolsLeft} >
                                            <button disabled={willDisable(i)} onClick={() => { handleRemoveLine(i) }} className={styles.delete}><CloseRounded /></button>
                                            <span className={styles.index}>{i + 1}</span>
                                            <button onClick={() => { handleAddLine(i) }} className={styles.add}><AddOutlined /></button>
                                        </div>
                                        <input disabled={willDisable(i)} key={item} className={styles.input} defaultValue={item} onBlur={(e) => handleCommandChange(e.target.value, i)} />
                                    </div>
                                ))}

                            </MDBox>
                        </WithPermission>

                        :
                        <textarea
                            disabled
                            rows={15}
                            style={{ width: '100%' }}
                            value={values.command}
                            id="command"
                            name="command"
                            error={touched.command && errors.command}
                            helperText={touched.command && errors.command}
                            onChange={handleChange}
                        />}
                </Grid>
            </Grid>
            <MDBox sx={{ display: "flex", justifyContent: "flex-end", gap: "10px" }}>
                {(handleCancel || setOpenModal) && <MDButton variant="outlined" color="info" onClick={() => handleCancel? handleCancel() : setOpenModal?.({ bool: false })}>
                    {t('Close')}
                </MDButton>}
                <MDButton variant="gradient" color="info" onClick={handleSubmit}>
                    {openModal?.isUpdate ? t('transcodes.update') : t('transcodes.create')}
                </MDButton>
            </MDBox>
        </MDBox>
    );
}

export default memo(CreateTranscode)
