import { INFO_MODAL_STATE } from "redux/types";

const defaults = {
    open: false,
    header: '',
    info:'',
}

export default (state = defaults, action) => {
    switch (action.type) {
      case INFO_MODAL_STATE:
        return { ...state, ...action.payload };
      default:
        return state;
    }
  };
  