import { api } from "api/config";
import { GET_ACTIIVE_USERS } from "redux/types";
import { catchAsync } from "utils/catchAsync";


export const getActiveUsers = () =>
  catchAsync(async (dispatch) => {
    dispatch({
      type: GET_ACTIIVE_USERS,
      payload: {
        loading: true,
      },
    });
    const {
      data: { data: list },
    } = await api.get(`/history/days`);

    dispatch({
      type: GET_ACTIIVE_USERS,
      payload: {
        loading: false,
        list: list,
      },
    });
  });
