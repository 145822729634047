import moment from "moment";
import React from "react";
import ReactApexChart from "react-apexcharts";
import styles from './styles.module.css'
import { useTranslation } from "react-i18next";
import { convertBytes } from "utils/helpers";

export default function LineChart({ series, customOptions }) {
  const { t } = useTranslation()
  const options = {
    chart: {
      stacked: true,
      width: '100%',
      height: 200,
      type: 'line',
      animations: {
        enabled: true,
        easing: 'linear',
        dynamicAnimation: {
          speed: 1000
        }
      },
      toolbar: {
        show: false
      },
      zoom: {
        enabled: false,
      }
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      curve: 'straight',
      width: 1,
    },
    fill: {
      colors: [({ value, seriesIndex, w }) => {
        if (value > 80) {
          return '#7E36AF'
        } else if (value >= 10 && value < 80) {
          return '#164666'
        } else {
          return '#D9534F'
        }
      }]
    },
    title: {
      text: t('CPU_average_load'),
      align: 'left'
    },
    markers: {
      size: 0
    },
    xaxis: {
      type: 'datetime',
      labels: {
        formatter: (value, timestamp) => {
          return moment(timestamp).format('MM/DD HH:mm:ss')
        },
      },
        // range: XAXISRANGE,
    },
    yaxis: {
      min: 0,
      max: 100,
      tickAmount: 5,
    },
    legend: {
      show: false
    },
  }


  //   window.setInterval(() => {
  //     getNewSeries(lastDate, {
  //       min: 10,
  //       max: 90
  //     })

  //     ApexCharts.exec('realtime', 'updateSeries', [{
  //       data: data
  //     }])
  //   }, 1000)


  return (
    <div className={styles.chart}>
      <ReactApexChart options={customOptions || options} series={series} type="line" height={200} />
    </div>
  )
}
