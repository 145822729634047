import { Grid, } from "@mui/material";
import MDBox from "components/MDBox";
import MDDialog from "components/MDDialog";
import MDInput from "components/MDInput";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import * as yup from "yup";
import { getTariffs } from "redux/actions/tariffs";
import MDSelect from "components/MDSelect";
import Tooltip from "@mui/material/Tooltip";
import { useTranslation } from "react-i18next";


const schema = yup.object({
    source: yup.string().required('field is required'),
    interval: yup.string().required('field is required'),
});



export default function Modal({ openModal, setOpenModal }) {
    const dispatch = useDispatch();
    const { t } = useTranslation()
    const intervalTypes = [
        {
            label: t('hours'),
            value: 'hour',
        },
        {
            label: t('Cron'),
            value: 'cron',
        },
    ]
    const cronDefault = "0*/6****"
    const hourDefault = 6
    const tariffs = useSelector((store) => store?.tariffs?.list);
    const initValues = {
        source: "",
        interval: cronDefault,
        file: null,
    };

    const { values, handleChange, touched, errors, handleSubmit, setFieldValue } = useFormik({
        initialValues: initValues,
        validationSchema: schema,
        onSubmit: (values) => {
            openModal?.onSave({ ...values });
            setOpenModal({ bool: false });
        },
    });


    const [intervalType, setIntervalType] = useState(intervalTypes[1])

    useEffect(() => {
        if (openModal?.modalValues) {
            Object.keys(initValues).forEach((key) => {
                setFieldValue(key, openModal?.modalValues?.[key] ?? '');
            });
        }
        setIntervalType(+openModal?.modalValues?.interval ? intervalTypes[0] : intervalTypes[1])
    }, [openModal?.modalValues]);


    useEffect(() => {
        if (!tariffs.length) {
            dispatch(getTariffs());
        }
    }, []);

    return (
        <MDDialog
            maxWidth="md"
            fullWidth
            dialogHeight="200px"
            open={openModal?.bool}
            setOpen={setOpenModal}
            onSave={handleSubmit}
        >
            <MDBox>
                <Grid rowSpacing={2} container>
                    <Grid item xs={3}>
                        {t('Source')}:
                    </Grid>
                    <Grid item xs={9}>
                        <MDInput
                            fullWidth
                            value={values.source}
                            id="source"
                            name="source"
                            error={touched.source && errors.source}
                            helperText={touched.source && errors.source}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        {t('Interval')}:
                    </Grid>
                    <Grid item xs={9} sx={{ display: 'flex', flexWrap: 'nowrap' }}>
                        <Tooltip title={intervalType[0] === 'hour' ? t('epgHintHour') : t('epgHintCron')} >
                            <MDInput
                                fullWidth
                                value={values.interval}
                                id="interval"
                                name="interval"
                                type={intervalType[0] === 'hour' ? 'number' : 'text'}
                                error={touched.interval && errors.interval}
                                helperText={touched.interval && errors.interval}
                                onChange={handleChange}
                            />
                        </Tooltip>
                        <MDSelect
                            sx={{ maxWidth: '200px', margin: '0px', borderRadius: '5px' }}
                            label="Type"
                            value={intervalType}
                            onChange={(e) => { e.value === "cron" ? setFieldValue('interval', cronDefault) : setFieldValue('interval', +hourDefault); setIntervalType(e) }}
                            options={intervalTypes}
                        />

                    </Grid>
                </Grid>
            </MDBox>
        </MDDialog>
    );
}
