import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import en from "./locales/en";
import ru from "./locales/ru";
import am from "./locales/am";

i18n.use(initReactI18next).init({
 resources: { en, ru, am },
 fallbackLng: "en",
 debug: false,
 ns: ["translations"],
 defaultNS: "translations",
 keySeparator: ".",
 interpolation: {
  escapeValue: false,
  formatSeparator: ",",
 },
 react: {
  useSuspense: false,
 },
});

i18n.languages = ["en-US"];

export default i18n;
