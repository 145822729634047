/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import CoverLayout from "layouts/authentication/components/CoverLayout";

// Images
import bgImage from "assets/images/bg-reset-cover.jpeg";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { removeToken } from "utils";

function CreatePassword() {
    const dispatch = useDispatch()
    const { t } = useTranslation()
    const navigate = useNavigate()
    const [searchParams] = useSearchParams()
    const [values, setValues] = useState({
        firstName: searchParams.get('firstName'),
        lastName: searchParams.get('lastName'),
        password: '',
        confirmPassword: '',
        email: searchParams.get('email'),
        isResetPassword: searchParams.get("resetPassword")
    })

    useEffect(()=>{
        removeToken();
    }, [])

    const [error, setError] = useState('')
    const [success, setSuccess] = useState(false)

    const handleChange = (e, key) => {
        setError('')
        setValues(values => ({ ...values, [key]: e.target.value }))
    }

    const handleLogin = () => {
        navigate('/authentication/sign-in')
    }

    const handleCreate = async () => {
        if (values.password !== values.confirmPassword) {
            setError('Passwords do not match')
        } else if (values.password.length < 8) {
            setError('Password is too short (min 8 symbols)')
        } else {
            try {
                const token = searchParams.get('token')
                const { data: res } = await axios.put(`${process.env.REACT_APP_BASE_URL}/admins`, values, {headers: {Authorization: token}});
                setSuccess(true)
            } catch (error) {
                console.log({error});
                if(error.response.status === 401) {
                    setError('Link is expired')
                }else{
                    setError(error?.response?.data?.message || error.toString())
                }
            }
        }
    }

    return (
        <CoverLayout coverHeight="50vh" image={bgImage}>
            <Card>
                <MDBox
                    variant="gradient"
                    bgColor="info"
                    borderRadius="lg"
                    coloredShadow="success"
                    mx={2}
                    mt={-3}
                    py={2}
                    mb={1}
                    textAlign="center"
                >{!success ?
                    <MDTypography variant="h3" fontWeight="medium" color="white" mt={1}>
                        {values.isResetPassword ? t('Reset') : t('Create_Account')}
                    </MDTypography>
                    :
                    <MDTypography display="block" variant="button" color="white" my={1}>
                        {values.isResetPassword ? t('passwordChanged') : t('Account_created_successfully')}
                    </MDTypography>
                    }
                </MDBox>
                <MDBox pt={4} pb={3} px={3}>
                    {success ?
                        <MDBox mt={error ? 3 : 6} mb={1}>
                            <MDButton variant="gradient" color="info" onClick={handleLogin} fullWidth>
                                {t('Go_to_login')}
                            </MDButton>
                        </MDBox>
                        :
                        <MDBox component="form" role="form">
                            <MDBox mb={3}>
                                <MDInput type="text" value={values.firstName} onChange={(e) => handleChange(e, 'firstName')} label="Firstname" variant="standard" fullWidth />
                            </MDBox>
                            <MDBox mb={3}>
                                <MDInput type="text" value={values.lastName} onChange={(e) => handleChange(e, 'lastName')} label="Lastname" variant="standard" fullWidth />
                            </MDBox>
                            <MDBox mb={3}>
                                <MDInput type="password" value={values.password} onChange={(e) => handleChange(e, 'password')} label="Password" variant="standard" fullWidth />
                            </MDBox>
                            <MDBox mb={error ? 1 : 3}>
                                <MDInput type="password" value={values.confirmPassword} onChange={(e) => handleChange(e, 'confirmPassword')} label="Confirm Password" variant="standard" fullWidth />
                            </MDBox>
                            {error && <MDTypography variant="button" color="error" >
                                {error}
                            </MDTypography>}
                            <MDBox mt={error ? 3 : 6} mb={1}>
                                <MDButton variant="gradient" color="info" onClick={handleCreate} fullWidth>
                                    {values.isResetPassword ? t('Reset') : t('Create')}
                                </MDButton>
                            </MDBox>
                        </MDBox>}
                </MDBox>
            </Card>
        </CoverLayout>
    );
}

export default CreatePassword;
