import React from "react"
import ReactApexChart from "react-apexcharts"
import { convertBytes } from "utils/helpers"
import styles from './styles.module.css'
import { useTranslation } from "react-i18next"

export default function BarChart({series, labels, width, type, title, deltaL= 0}) {
    const {t} = useTranslation()  
    const options = {
          chart: {
            width: 300,
            type: 'pie',
            title
          },
          labels: labels || [`${t("Used")} ` + convertBytes(series[0], deltaL), `${t("Free")} ` + convertBytes(series[1], deltaL)],
          tooltip: {
            x: {
                show: true,
                format: 'dd MMM',
                formatter: undefined,
            },
            y: {
                formatter: (item) => convertBytes(item, deltaL),
                title: {
                    formatter: (seriesName) => seriesName,
                },
            }
          }
        }

    return (
        <div className={styles.chart}>
            <div style={{textAlign: 'center', fontSize: '14px'}}>{title}</div>
            <ReactApexChart title={title} options={options} series={series} type="pie" height={150} width={280} />
        </div>
    )
}