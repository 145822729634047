/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect, useMemo } from "react";

// react-router components
import { Routes, Route, Navigate, useLocation } from "react-router-dom";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import Sidenav from "examples/Sidenav";
import Configurator from "examples/Configurator";

// Material Dashboard 2 React themes
import theme from "assets/theme";

// Material Dashboard 2 React Dark Mode themes
import themeDark from "assets/theme-dark";

// Material Dashboard 2 React routes
import routes from "routes";

// Material Dashboard 2 React contexts
import { useMaterialUIController, setMiniSidenav, setOpenConfigurator } from "context";

import SignIn from "layouts/authentication/sign-in";

// Images
import brandWhite from "assets/images/logo-ct.png";
import brandDark from "assets/images/logo-ct-dark.png";
import PublicRoute from "routes/PublicRoutes";
import PrivateRoute from "routes/PrivateRoutes";
import { useDispatch, useSelector } from "react-redux";
import { isLogged } from "utils";
import { setUser } from "redux/actions/auth";
import { getToken } from "utils";
import { useTranslation } from "react-i18next";
import { getSettings } from "redux/actions/settings";
import CreatePassword from "layouts/authentication/CreatePassword";
import { changeMomentLanguage } from "utils/changeMomentLanguage";
import { getTranscoders } from "redux/actions/transcoders";
import { getArchivers } from "redux/actions/archivers";
import { getStreamers } from "redux/actions/streamers";
import { getVODs } from "redux/actions/VODs";


export default function App() {
  const [controller, dispatch] = useMaterialUIController();
  const {
    miniSidenav,
    layout,
    openConfigurator,
    sidenavColor,
    transparentSidenav,
    whiteSidenav,
    darkMode,
  } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const { pathname } = useLocation();
  const dispatchAction = useDispatch();
  const { t, i18n } = useTranslation();

  useEffect(() => {
    const key = localStorage.getItem('lang')
    i18n.changeLanguage(key ?? 'en');
    changeMomentLanguage(key ?? 'en')
    if (isLogged()) {
      dispatchAction(setUser(getToken()))
      dispatchAction(getSettings())

      setTimeout(() => {
        if (!window.location.pathname.includes("transcoder")) {
          dispatchAction(getTranscoders({ initial: true }))
        }
        if (!window.location.pathname.includes("archiver")) {
          dispatchAction(getArchivers({ initial: true }))
        }
        if (!window.location.pathname.includes("streamer")) {
          dispatchAction(getStreamers({ initial: true }))
        }
        if (!window.location.pathname.includes("VOD")) {
          dispatchAction(getVODs({ initial: true }))
        }
      }, 3000)
    }
  }, [])

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  // Change the openConfigurator state
  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const routesTranslated = routes(t)


  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        if (route.publicRoute) {
          return <Route exact path={route.route} element={<PublicRoute> {route.component}</PublicRoute>} key={route.key} />
        } else {
          return <Route exact path={route.route} element={<PrivateRoute>{route.component}</PrivateRoute>} key={route.key} />
        };
      }

      return null;
    });

  // const configsButton = (
  //   <MDBox
  //     display="flex"
  //     justifyContent="center"
  //     alignItems="center"
  //     width="3.25rem"
  //     height="3.25rem"
  //     bgColor="white"
  //     shadow="sm"
  //     borderRadius="50%"
  //     position="fixed"
  //     right="2rem"
  //     bottom="2rem"
  //     zIndex={99}
  //     color="dark"
  //     sx={{ cursor: "pointer" }}
  //     onClick={handleConfiguratorOpen}
  //   >
  //     <Icon fontSize="small" color="inherit">
  //       settings
  //     </Icon>
  //   </MDBox>
  // );

  return (
    <ThemeProvider theme={darkMode ? themeDark : theme}>
      <CssBaseline />
      {layout === "dashboard" && (
        <>
          <Sidenav
            color={sidenavColor}
            brand={(transparentSidenav && !darkMode) || whiteSidenav ? brandDark : brandWhite}
            brandName={t('appTitle')}
            routes={routesTranslated}
            onMouseEnter={handleOnMouseEnter}
            onMouseLeave={handleOnMouseLeave}
          />
          <Configurator />
          {/* {configsButton} */}
        </>
      )}
      {layout === "vr" && <Configurator />}
      <Routes>
        {getRoutes(routesTranslated)}

        <Route path="/authentication/sign-in" element={<SignIn />} />
        <Route path="/adminPassword" element={<CreatePassword />} />
        <Route path="*" element={<Navigate to="/dashboard" />} />
      </Routes>
    </ThemeProvider>
  );
}
