import { combineReducers } from "redux";

import auth from "redux/reducers/auth";
import tariffs from "redux/reducers/tariffs";
import notifications from "redux/reducers/notifications";
import channels from "redux/reducers/channels";
import customer from "redux/reducers/customer";
import epg from "redux/reducers/epg";
import streamer from "redux/reducers/streamer";
import streamers from "redux/reducers/streamers";
import VOD from "redux/reducers/VOD";
import VODs from "redux/reducers/VODs";
import archiver from "redux/reducers/archiver";
import archivers from "redux/reducers/archivers";
import transcoder from "redux/reducers/transcoder";
import transcoders from "redux/reducers/transcoders";
import systems from "redux/reducers/systemStatus";
import billingHistory from "redux/reducers/billing";
import activeUsersHistory from "redux/reducers/activeUsers";
import activeDevicesHistory from "redux/reducers/activeDevices";
import usersByMonth from "redux/reducers/usersByMonth";
import settings from "redux/reducers/settings";
import admins from "redux/reducers/admins";
import infoModal from "redux/reducers/infoModal";
import serverAdvices from "redux/reducers/serverAdvices";

const rootReducer = combineReducers({
  auth,
  tariffs,
  notifications,
  channels,
  customer,
  epg,
  streams: streamer,
  streamers,
  VOD,
  VODs,
  archiver,
  archivers,
  transcoder,
  transcoders,
  systems,
  billingHistory,
  activeUsersHistory,
  activeDevicesHistory,
  usersByMonth,
  settings,
  admins,
  infoModal,
  serverAnalyze: serverAdvices
});

export default rootReducer;
