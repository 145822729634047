import React, { useCallback, useEffect, useRef, useState } from "react";
import { Card, Grid, IconButton, ToggleButtonGroup, ToggleButton, Tabs, Tab, Button, Tooltip } from "@mui/material";

import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import AddIcon from "@mui/icons-material/Add";
import ViewComfyIcon from "@mui/icons-material/ViewComfy";
import TableRowsIcon from "@mui/icons-material/TableRows";
import CreateChannel from "./CreateChannel";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getChannels } from "redux/actions/channels";
import { createChannel } from "redux/actions/channels";
import { deleteChannel } from "redux/actions/channels";
import { updateChannel } from "redux/actions/channels";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { DeleteForever, Edit, PlayCircle } from "@mui/icons-material";
import MDBadge from "components/MDBadge";
import ModalPlayer from "../../components/playerModal";
import { getEpgIds } from "api/epgApi";
import MDAvatar from "components/MDAvatar";
import DataTable from "examples/Tables/DataTable";
import { getStreams } from "redux/actions/streams";
import { getArchives } from "redux/actions/archiver";
import { getSingleItemByAnOption } from "utils/helpers";
import { api } from "api/config";
import moment from "moment";
import { parse } from "utils/helpers";
import { ClipLoader } from "react-spinners";
import cx from 'classnames'
import s from './programs.module.css'
import { generateM3U8URL } from "utils/helpers";
import { addNotification } from "redux/actions/notifications";
import axios from "axios";
import { shiftEpgTime } from "utils/helpers";
import { truncateString } from "utils/helpers";
import { PlayPermission } from "pages/settings/Admins/constants";
import { DeletePermission } from "pages/settings/Admins/constants";
import WithPermission from "components/withPermission/WithPermission";
import { EditPermission } from "pages/settings/Admins/constants";
import { AddPermission } from "pages/settings/Admins/constants";
import { checkPermission } from "utils/checkPermission";
import ReorderChannels from "./ReorderChannels";
import TemporaryDrawer from "components/Drawer";
import LowPriorityIcon from '@mui/icons-material/LowPriority';
import styles from './channelsStyles.module.css'
import AvTimerIcon from '@mui/icons-material/AvTimer';
import WatchHistory from "./WatchHistory";
import { ViewPermission } from "pages/settings/Admins/constants";
import { apiAuth } from "api/config";
import TruncateString from "components/TrancateString/TruncateString";
import MDDialog from "components/MDDialog";

export default function Channels() {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const loading = useSelector((store) => store?.channels?.loading);
  const channels = useSelector((store) => store?.channels?.list);
  const categoryChannels = useSelector(store => store.settings?.settings?.categoriesChannels)
  const [openModal, setOpenModal] = useState({ bool: false });
  const [openPlayerModal, setOpenPlayerModal] = useState({ bool: false });
  const [epgIds, setEpgIds] = useState([]);
  const [viewMode, setViewMode] = useState(localStorage.getItem("channelsViewType") ?? "table");
  const streamURLs = useSelector(store => store?.streams?.list)
  const archives = useSelector(store => store?.archiver?.list)
  const [openEpg, setOpenEpg] = useState(false)
  const [index, setIndex] = useState('')
  const [openReorder, setOpenReorder] = useState(false)
  const [openWatchHistory, setOpenWatchHistory] = useState(false)

  useEffect(() => {
    dispatch(getStreams())
  }, [])

  const storeEpgIds = async () => {
    const res = await getEpgIds();
    setEpgIds(res.data.epgIds);
  };

  useEffect(() => {
    dispatch(getChannels());
    storeEpgIds();
    dispatch(getArchives())
  }, []);

  function handleCreateChannel(vals) {
    const epgId = vals.reserveEpgId[0]
    const reserveEpgId = vals.reserveEpgId.slice(1)

    dispatch(createChannel({ ...vals, epgId, reserveEpgId }));
  }

  function handleDeleteChannel(item) {
    dispatch(deleteChannel(item._id));
  }

  function handleUpdateChannel(vals) {
    const epgId = vals.reserveEpgId[0]
    const reserveEpgId = vals.reserveEpgId.slice(1)
    dispatch(updateChannel(this._id, { ...vals, epgId, reserveEpgId }));
  }

  function handleOpenChannelModal(item, isUpdate) {
    setOpenModal({
      bool: true,
      onSave: isUpdate ? handleUpdateChannel.bind(item) : handleCreateChannel,
      isUpdate,
      modalValues: {
        name: item?.name ?? "",
        image: item?.image ?? "",
        url: item?.url ?? "",
        resolutions: item?.resolutions ?? [],
        category: item?.category ?? [],
        epgId: item?.epgId ?? {},
        reserveEpgId: item?.reserveEpgId ?? [],
        archive: item?.archive ?? {},
      },
    });
  }

  function handleOpenPlayerModal(item) {
    setOpenPlayerModal({
      bool: true,
      modalValues: { url: item?.url ?? "" },
      currentChannel: item
    });
  }

  const columns = [
    { Header: t("Name"), accessor: "Name", align: "left" },
    { Header: t("Stream_URL"), accessor: "StreamURL", align: "left" },
    { Header: t("Category"), accessor: "category", align: "left" },
    { Header: t("EPG"), accessor: "EPG", align: "center" },
    { Header: t("Archive"), accessor: "Archive", align: "center" },
    { Header: t("action"), accessor: "action", align: "center" },
  ];

  const rows = channels?.map((item) => {
    const categories = item?.category.map(id => categoryChannels?.find(item => item.id === id)?.[i18n.language] || null).filter(item => !!item)
    return {
      Name: (
        <MDBox sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
          <MDAvatar
            src={`${process.env.REACT_APP_BASE_ORIGIN}/img/channels/${item?.image}`}
            name={item.email}
            size="sm"
          />{" "}
          &nbsp; {item.name}
        </MDBox>
      ),
      StreamURL: <TruncateString text={item?.url} copy maxLength={15} truncatePosition="end" />,
      category: <Tooltip title={categories.join(', ')} ><div>{truncateString(categories.join(', '), 30)}</div></Tooltip>,
      EPG: <Tooltip title={[item?.epgId?.epgId, ...(item?.reserveEpgId?.map?.(epg => epg.epgId) || [])].join(', ')} ><div>{`${item?.epgId?.epgId ?? ""}`}  {item?.reserveEpgId?.length ? `+${item?.reserveEpgId?.length}` : ""}</div></Tooltip>,
      Archive: `${item?.archive?.name ?? ""}`,
      action: (
        <MDBox>
          <WithPermission permissionsList={[PlayPermission]} permissionKey={'channels'} >
            <IconButton disabled={!checkPermission([PlayPermission], 'channels')} onClick={() => handleOpenPlayerModal(item)} color="success">
              {" "}
              <PlayCircle />{" "}
            </IconButton>
          </WithPermission>
          <WithPermission permissionsList={[EditPermission]} permissionKey={'channels'} >
            <IconButton
              disabled={!checkPermission([EditPermission], 'channels')}
              onClick={() => {
                handleOpenChannelModal(item, true);
              }}
            >
              <Edit />
            </IconButton>
          </WithPermission>
          <WithPermission permissionsList={[DeletePermission]} permissionKey={'channels'} >
            <Tooltip title={t('dblClick')} >
              <IconButton
                disabled={!checkPermission([DeletePermission], 'channels')}
                onDoubleClick={() => {
                  handleDeleteChannel(item);
                }}
                color="error"
              >
                <DeleteForever />
              </IconButton>
            </Tooltip>
          </WithPermission>
        </MDBox>
      ),
    }
  });

  return (
    <DashboardLayout>
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                sx={{ display: "flex", justifyContent: "space-between" }}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  {t("channels.header")}
                </MDTypography>
                <div>
                  <ToggleButtonGroup
                    value={viewMode}
                    exclusive
                    sx={{ margin: "0px 20px" }}
                    onChange={(e, val) => {
                      localStorage.setItem("channelsViewType", val);
                      setViewMode(val);
                    }}
                    aria-label="text alignment"
                  >
                    <ToggleButton value="table" aria-label="left aligned">
                      <TableRowsIcon htmlColor="#fff" />
                    </ToggleButton>
                    <ToggleButton value="cards" aria-label="centered">
                      <ViewComfyIcon htmlColor="#fff" />
                    </ToggleButton>
                  </ToggleButtonGroup>
                  <WithPermission permissionsList={[AddPermission]} permissionKey={'channels'} >
                    <MDButton
                      disabled={!checkPermission([AddPermission], 'channels')}
                      onClick={handleOpenChannelModal}
                      withIcon={<AddIcon />}
                      loading={loading}
                    >
                      {t("channels.addNew")}
                    </MDButton>
                  </WithPermission>
                </div>
              </MDBox>
              <MDBox
                sx={{ display: "flex", justifyContent: "flex-end" }}
                mb={-3}
                py={3}
                px={4}
              >
                <WithPermission disable={true} permissionsList={[ViewPermission]} permissionKey={'channels'} >
                  <MDButton
                    variant="outlined"
                    color="info"
                    sx={{ marginTop: '10px', marginRight: '10px' }}
                    disabled={!checkPermission([ViewPermission], 'channels')}
                    onClick={() => { setOpenWatchHistory(true) }}
                    withIcon={<AvTimerIcon />}
                  >
                    {t('watch_history')}
                  </MDButton>
                </WithPermission>
                <WithPermission disable={true} permissionsList={[EditPermission]} permissionKey={'channels'} >
                  <MDButton
                    variant="outlined"
                    color="info"
                    sx={{ marginTop: '10px' }}
                    disabled={!checkPermission([EditPermission], 'channels')}
                    onClick={() => { setOpenReorder(true) }}
                    withIcon={<LowPriorityIcon />}
                  >
                    {t('reorder_channels')}
                  </MDButton>
                </WithPermission>
              </MDBox>
              <TemporaryDrawer paperStyles={"drawerInfoPanel"} open={openReorder} setOpen={setOpenReorder} >
                <ReorderChannels onAfterAction={() => setOpenReorder(false)} list={channels} />
              </TemporaryDrawer>

              <TemporaryDrawer paperStyles={"drawerInfoPanel"} open={openWatchHistory} setOpen={setOpenWatchHistory} >
                {openWatchHistory && <WatchHistory list={channels} />}
              </TemporaryDrawer>

              {channels?.length ? (
                viewMode === "table" ? (
                  rows && (
                    <DataTable
                      table={{ columns, rows }}
                      isSorted={false}
                      noEndBorder
                    />
                  )
                ) : (
                  <MDBox
                    sx={{
                      display: "flex",
                      flexWrap: "wrap",
                    }}
                  >
                    {channels?.map((item) => (
                      <MDBox
                        key={item.name}
                        mx={2}
                        my={4}
                        py={3}
                        px={2}
                        sx={{
                          width: "300px",
                          height: "300px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "flex-end",
                          position: "relative",
                          backgroundImage: `url('${process.env.REACT_APP_BASE_ORIGIN}/img/channels/${item.image}')`,
                          backgroundSize: "contain",
                          backgroundRepeat: "no-repeat",
                          backgroundPosition: "center",
                        }}
                        variant="gradient"
                        borderRadius="lg"
                        coloredShadow="info"
                      >
                        <WithPermission permissionsList={[PlayPermission]} permissionKey={'channels'} >
                          <IconButton
                            disabled={!checkPermission([PlayPermission], 'channels')}
                            onClick={() => handleOpenPlayerModal(item)}
                            color="success"
                            sx={{ position: "absolute", top: "10px", right: "60px" }}
                          >
                            {" "}
                            <PlayCircle />{" "}
                          </IconButton>
                        </WithPermission>
                        <WithPermission permissionsList={[EditPermission]} permissionKey={'channels'} >
                          <IconButton
                            disabled={!checkPermission([EditPermission], 'channels')}
                            onClick={() => handleOpenChannelModal(item, true)}
                            color="info"
                            sx={{ position: "absolute", top: "10px", right: "30px" }}
                          >
                            {" "}
                            <Edit />{" "}
                          </IconButton>
                        </WithPermission>
                        <WithPermission permissionsList={[DeletePermission]} permissionKey={'channels'} >
                          <IconButton
                            disabled={!checkPermission([DeletePermission], 'channels')}
                            onClick={() => handleDeleteChannel(item)}
                            color="error"
                            sx={{ position: "absolute", top: "10px", right: "0px" }}
                          >
                            {" "}
                            <DeleteForever />{" "}
                          </IconButton>
                        </WithPermission>
                        {/* <MDBadge
                          badgeContent="online"
                          color="success"
                          variant="gradient"
                          size="sm"
                          sx={{ position: "absolute", top: "20px", left: "10px" }}
                        /> */}
                        <MDTypography>{item.name}</MDTypography>
                      </MDBox>
                    ))}
                  </MDBox>
                )
              ) : (
                <MDBox
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    minHeight: "80vh",
                  }}
                >
                  {" "}
                  <MDTypography>{t("channels.emptyChannels")}</MDTypography>
                </MDBox>
              )}
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      {openModal.bool && (
        <MDDialog
          suppressActions
          maxWidth="md"
          fullWidth
          open={openModal?.bool}
          setOpen={setOpenModal}
        >
          <CreateChannel archives={archives} streamURLs={streamURLs} epgIds={epgIds} openModal={openModal} setOpenModal={setOpenModal} />
        </MDDialog>
      )}
      {openPlayerModal.bool && (
        <ModalPlayer
          primaryUrl={index}
          openModal={openPlayerModal}
          setOpenModal={setOpenPlayerModal}
          rightComponent={<PlayerEpg setIndex={setIndex} openEpg={openEpg} epgIds={epgIds} openPlayerModal={openPlayerModal} />}
          headerComponent={openPlayerModal?.currentChannel?.epgId && <div style={{ position: 'relative' }} ><Button className={s.epgOpener} onClick={() => setOpenEpg(val => !val)}>{openEpg ? <ArrowForwardIosIcon /> : <ArrowBackIosIcon />} {openEpg ? 'CLOSE' : 'OPEN'} EPG </Button></div>}
        />
      )}
    </DashboardLayout>
  );
}


const PlayerEpg = ({ openPlayerModal, epgIds, openEpg, setIndex }) => {

  const dispatch = useDispatch()
  const [epg, setEpg] = useState()
  const [selectedDay, setSelectedDay] = useState();
  const [days, setDays] = useState([]);
  const [programs, setPrograms] = useState([]);
  const [loadingID, setLoadingID] = useState(false)
  const currentProgramRef = useRef()
  const [currentVOD, setCurrentVOD] = useState('')

  const archives = openPlayerModal?.currentChannel?.archive
  const epgId = openPlayerModal?.currentChannel?.epgId


  const getEpg = async () => {
    if (!epgId?.epgId) return
    const {
      data: { epg: fullEpg },
    } = await api.post(`/epg/${epgId?.epgId}/${epgId?.dbId}`, { reserveEpg: openPlayerModal?.currentChannel?.reserveEpgId });

    setEpg(fullEpg.map(item => ({
      ...item,
      start: shiftEpgTime(item.start),
      stop: shiftEpgTime(item.stop)
    })));
  };

  const getDays = useCallback(() => {
    const days = [
      {
        epgValue: moment().format('YYYYMMDD'),
        label: moment().format('DD/MM ddd'),
      },
    ];
    setSelectedDay(moment().format('YYYYMMDD'));
    for (let i = 1; i < 14; i++) {
      days.push({
        epgValue: moment().add(i, 'days').format('YYYYMMDD'),
        label: moment().add(i, 'days').format('DD/MM ddd'),
      });
      days.unshift({
        epgValue: moment().subtract(i, 'days').format('YYYYMMDD'),
        label: moment().subtract(i, 'days').format('DD/MM ddd'),
      });
    }
    return days;
  }, []);


  const getPrograms = useCallback(() => {
    if (!epg) return;
    const filteredEpg = epg.filter(item =>
      item.start.startsWith(selectedDay)
    );

    const result = filteredEpg.map(single => {
      const dateStart = parse(single.start);
      const dateStop = parse(single.stop);
      let isCurrent;
      if (moment().isSameOrAfter(moment(single.start, 'YYYYMMDDHHmmss')) && moment().isSameOrBefore(moment(single.stop, 'YYYYMMDDHHmmss'))) {
        isCurrent = true
      }
      // const time = `${dateStart.h}:${dateStart.m} - ${dateStop.h}:${dateStop.m}`
      // // console.log('parse(single.start)',parse(single.start));
      // // console.log('Intl.DateTimeFormat()', Intl.DateTimeFormat().resolvedOptions());
      return {
        start: `${dateStart.h}:${dateStart.m}`,
        stop: `${dateStop.h}:${dateStop.m}`,
        isCurrent,
        startEpgTime: single.start,
        endEpgTime: single.stop,
        title: single?.title?.[0]?.value,
      };
    });

    return result;
  }, [epg, selectedDay]);

  useEffect(() => {
    setDays(getDays());
  }, []);



  useEffect(() => {
    if (epg && selectedDay) setPrograms(getPrograms());
  }, [epg, selectedDay]);



  const handleSelectDay = (e, day) => {
    setSelectedDay(day);
  };


  useEffect(() => {
    !epg && getEpg()

  }, [])

  const isArchived = (start) => {
    const duration = archives?.duration
    if (duration) {
      return moment(start, 'YYYYMMDDHHmmss').isAfter(moment().subtract(duration, 'hours')) && moment(start, 'YYYYMMDDHHmmss').isBefore(moment())
    }
  }

  const handleOpenArchive = async (programs, i) => {
    const program = programs[i]
    try {
      const options = {
        startTime: `${program.start.replace(':', '')}00`,
        endTime: `${program.stop.replace(':', '')}00`,
        day: selectedDay.substr(6),
        date: selectedDay,
        offset: new Date().getTimezoneOffset(),
        archiveName: archives?.name,
        baseArchiver: archives.archiverInfo.host
      }
      if (options.archiveName) {
        setLoadingID(i)
        const index = await apiAuth.get(generateM3U8URL(options))
        if (!index?.data || index?.data === '#EXT-X-ENDLIST' || index?.data.includes('undefined') || index?.data?.status === 404) {
          throw new Error()
        }

        setIndex(generateM3U8URL(options))
        setLoadingID(false)
        setCurrentVOD(`${selectedDay}_${i}`)
      }
    } catch (error) {
      setLoadingID(false)
      dispatch(addNotification({
        dateTime: Date.now(),
        color: 'error',
        title: 'Error',
        content: `file for ${program.start}-${program.stop} does not found`,
      }))

      console.log(error)
    }
  }

  if (!epgId) {
    return null
  }

  return <div style={openEpg ? { width: '500px', height: '590px' } : {}} className={s.epgCont}>


    <Tabs
      // variant="scrollable"
      scrollButtons="auto"
      variant="scrollable"
      // value={value}
      // onChange={handleChange}
      aria-label="Vertical tabs example"
      sx={{ borderRight: 1, borderColor: 'divider' }}
      value={selectedDay}
      onChange={handleSelectDay}
    >
      {days.map(item => <Tab value={item.epgValue} sx={{ width: '70px', flex: 'none' }} label={item.label} />)}
    </Tabs>
    <div className={s.programs}>
      {programs?.map((item, i) => {
        const rest = item?.isCurrent ? { ref: currentProgramRef } : {}
        return <div
          key={item.start + item.stop + selectedDay.label}
          className={s.programCont}
        >
          <div  {...rest} id={i} className={s.program}>
            {/* currentChannel?.archive */}
            <span className={s.time}>
              {item.start} - {item.stop}
            </span>
            <span className={cx(s.progName, { [s.currentProgram]: currentVOD === `${selectedDay}_${i}` || (!currentVOD && item.isCurrent) })} >{item.title}</span>
            {loadingID === i ?
              <IconButton > <ClipLoader /></IconButton> :
              isArchived(item.endEpgTime) ?
                <IconButton onClick={() => { handleOpenArchive(programs, i) }} color="success" > <PlayCircle /> </IconButton> :
                <span></span>}
          </div>
        </div>
      })}
    </div>
  </div>

}
