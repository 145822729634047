import { GET_EPG, UPDATE_CRONS } from "redux/types";

const defaults = {
  loading: false,
  list: [],
  activeCrons: []
};

export default (state = defaults, action) => {
  switch (action.type) {
    case GET_EPG:
      return { ...state, ...action.payload };
    case UPDATE_CRONS: 
      return { ...state, activeCrons: action.payload }
    default:
      return state;
  }
};
