import { Add } from '@mui/icons-material'
import { Card, Grid, IconButton, Tooltip } from '@mui/material'
import { api } from 'api/config'
import MDBox from 'components/MDBox'
import MDButton from 'components/MDButton'
import MDTypography from 'components/MDTypography'
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout'
import DataTable from 'examples/Tables/DataTable'
import React, { useEffect, useReducer, useState } from 'react'
import { Delete, Edit } from "@mui/icons-material";
import SyncIcon from '@mui/icons-material/Sync';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox';
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import Modal from './modal'
import axios from 'axios'
import {
    addStreamer,
    getStreamers,
} from 'redux/actions/streamers'
import { ClipLoader } from 'react-spinners'
import StopIcon from '@mui/icons-material/Stop';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import HostCard from 'components/HostCard'
import TemporaryDrawer from 'components/Drawer'
import Streamer from './streams'
import { deleteStreamer } from 'redux/actions/streamers'
import { getStreams } from 'redux/actions/streams'
import MainLoader from 'components/loader/loader'
import { AddPermission } from 'pages/settings/Admins/constants'
import WithPermission from 'components/withPermission/WithPermission'
import { checkPermission } from 'utils/checkPermission'

export default function Streamers() {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const allStreams = useSelector(store => store?.streams?.list)
    const streamers = useSelector(store => store?.streamers?.list)
    const loading = useSelector(store => store?.streamers?.loading)
    const [openModal, setOpenModal] = useState({ bool: false })
    const [openStreams, setOpenStreams] = useState(false)



    useEffect(() => {
        dispatch(getStreams())
        dispatch(getStreamers({ initial: true }))
        const id = setInterval(() => {
            dispatch(getStreamers())
        }, 15000)
        return () => {
            clearInterval(id)
        }
    }, [])

    // const handleDelete = (values) => {
    //     dispatch(deleteStreamer(values))
    // }


    const handleCreate = (values) => {
        dispatch(addStreamer(values))
    }

    // const handleUpdate = () => {

    // }

    // const handleStopStream = (item) => {
    //     dispatcher({ type: 'add', id: item._id })
    //     dispatch(stopStream(item, () => dispatcher({ type: 'remove', id: item._id })))
    // }

    // const handleStartStream = (item) => {
    //     dispatcher({ type: 'add', id: item._id })
    //     dispatch(refreshStream(item, ()=> dispatcher({ type: 'remove', id: item._id })))
    // }

    // const handleRefreshStream = (item) => {
    //     dispatcher({ type: 'add', id: item._id })
    //     dispatch(refreshStream(item, ()=> dispatcher({ type: 'remove', id: item._id })))
    // }


    const handleOpenModal = (item, isUpdate) => {
        setOpenModal({
            bool: true,
            onSave: isUpdate ? handleUpdate.bind(item) : handleCreate,
            modalValues: item,
            type: "Streamer"
        });
    }

    const handleOpenStreams = (streamer) => {
        setOpenStreams(streamer)
    }

    const handleDeleteStreamer = (id) => {
        if (!checkHaveStreams(id)) dispatch(deleteStreamer(id))
    }

    const checkHaveStreams = (id) => {
        const streams = allStreams?.filter(item => item.streamerId === id)
        return streams.length > 0
    }


    return (
        <DashboardLayout>
            <MDBox pt={6} pb={3}>
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Card>
                            <MDBox
                                mx={2}
                                mt={-3}
                                py={3}
                                px={2}
                                sx={{ display: "flex", justifyContent: "space-between" }}
                                variant="gradient"
                                bgColor="info"
                                borderRadius="lg"
                                coloredShadow="info"
                            >
                                <MDTypography variant="h6" color="white">
                                    {t("streamer.header")}
                                </MDTypography>
                                <WithPermission disable={true} permissionsList={[AddPermission]} permissionKey={'streamer'} >
                                    <MDButton disabled={!checkPermission([AddPermission], 'streamer')} onClick={handleOpenModal} withIcon={<Add />} loading={false}>
                                        {t("streamer.addNewStreamer")}
                                    </MDButton>
                                </WithPermission>
                            </MDBox>
                            <MDBox pt={3}>
                                <Grid container>
                                    {loading && !streamers.length ?
                                        <Grid item xs={12}>
                                            <MDBox sx={{ margin: '30vh auto', width: 'fit-content' }} >
                                                <MainLoader />
                                            </MDBox>
                                        </Grid>
                                        :
                                        streamers?.map(item => (
                                            <Grid key={item._id} item xs={12}>
                                                <HostCard permissionKey={'streamer'} type='Streamer' deleteCB={!checkHaveStreams(item._id) && handleDeleteStreamer} id={item._id} host={item.url} name={item.name} systemInfo={item} actionText={t('View') + " " + t('Streams')} actionClick={handleOpenStreams} />
                                            </Grid>
                                        ))}
                                </Grid>
                            </MDBox>
                        </Card>
                    </Grid>
                </Grid>
                <Modal openModal={openModal} setOpenModal={setOpenModal} />
                <TemporaryDrawer paperStyles={"drawerInfoPanelServers"} open={openStreams} setOpen={setOpenStreams} >
                    <Streamer streamerInfo={openStreams} />
                </TemporaryDrawer>
            </MDBox>
        </DashboardLayout>
    )
}
