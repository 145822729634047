import { useSelector } from 'react-redux'
import styles from './styles.module.css'
import { Tooltip } from '@mui/material'
import { useTranslation } from 'react-i18next'
import React from 'react'

function WithPermission({ children, permissionsList, permissionKey, disable=true, withBg }) {
  const { t } = useTranslation()
  const permissions = useSelector(store => store.auth.permissions)

  if (!permissions?.[permissionKey]) {
    return (
      children
    )
  }
  if (permissions[permissionKey]?.length !== 0 && permissionsList?.every(item => permissions?.[permissionKey]?.includes(item))) {
    return (
      children
    )
  } else {
    return disable ? <span className={styles.cont}>
    <Tooltip title={t('noPermission')} >
      <span className={styles.overlay} style={{backgroundColor: withBg ? '#d3d3d342' : 'inherit'}} />
    </Tooltip>
    {children}
  </span> : null
  }
}

export default (WithPermission)
