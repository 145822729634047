import React, { useState } from "react";
import videojs from "video.js";
import "video.js/dist/video-js.css";
import '@videojs/http-streaming';


export function VideoJS(props) {
  const videoRef = React.useRef(null);
  const playerRef = React.useRef(null);
  const { options, onReady } = props;
  const [audioTracks, setAudioTracks] = useState([]);


  React.useEffect(() => {
    // make sure Video.js player is only initialized once
    if (!playerRef.current) {
      const videoElement = videoRef.current;
      if (!videoElement) return;
      const player = (playerRef.current = videojs(videoElement, options, () => {
        onReady && onReady(player);
        // Add the track to the player's audio track list.
        var audioTrackList = player.audioTracks();
        setAudioTracks(audioTrackList.tracks_)
        // player.hls.xhr.beforeRequest = function(options) {
        //   console.log(options);
        //   options.uri = options.uri.replace('example.com', 'foo.com');
        
        //   return options;
        // };
        
      }));
      // player.tech_.hls.xhr = videojs.Hls.xhr
    } else {
      // you can update player here [update player through props]
      // const player = playerRef.current;
      // player.autoplay(options.autoplay);
      // player.src(options.sources);
    }
  }, [options, videoRef.current]);

  // Dispose the Video.js player when the functional component unmounts
  React.useEffect(() => {
    const player = playerRef.current;
    

    return () => {
      if (player) {
        player.dispose();
        playerRef.current = null;
      }
    };
  }, [playerRef]);



  return (
    <div data-vjs-player>
      <video ref={videoRef} className="video-js vjs-big-play-centered" />
    </div>
  );
}

export default VideoJS;
