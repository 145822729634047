import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { Chip } from '@mui/material';

export default function ComboBox({
  name,
  id,
  value,
  onChange,
  options,
  withChip,
  sx,
  multiple,
  labelKey = "label",
  valueKey = "value",
  nestedKeyToCompare,
  size,
  label,
  getOptionLabel,
  renderTags,
  disabled
}) {
  return (
    <Autocomplete
      disabled={disabled}
      sx={sx}
      multiple={multiple}
      name={name}
      size={size || 'small'}
      id={id}
      label={label}
      value={value}
      onChange={onChange}
      options={options}
      isOptionEqualToValue={(option, value) => (value?.[valueKey] || value) === (option?.[valueKey] || option)}
      renderTags={renderTags || ((value, getTagProps) => {
        return <> 
        { 
          value.slice(0,3).map((option, index) => {
          return withChip ? <Chip
            key={nestedKeyToCompare ? option?.[nestedKeyToCompare] : option}
            variant="outlined"
            label={option?.[labelKey] || options.find(item => item[valueKey] === option)?.[labelKey]} {...getTagProps({ index })}
          /> : (
            <span
              style={{ padding: "0px 5px" }}
            >
              {option?.[labelKey]}{index !== value.length - 1 && ","}
            </span> ||
            <span style={{ padding: "0px 5px" }} >
              {options.find(item => item[valueKey] === option)?.[labelKey]}{index !== value.length - 1 && ","}
            </span>)
        })
      } {value.length - 3 > 0 && `+${value.length - 3}`}
      </>
      })
      }
      getOptionLabel={getOptionLabel || ((option) => option?.[labelKey] || option)}
      renderInput={(params) => <TextField sx={window.innerWidth < 600 ? {
        maxHeight: 200,
        overflow: "auto"
      } : {}} {...params} />}
    />
  );
}