import { FINISH_SINGLE_VOD_STATUS } from "redux/types";
import { UPDATE_SINGLE_VOD_STATUS } from "redux/types";
import { GET_VOD, UPDATE_VOD_STATUS } from "redux/types";

const defaults = {
  loading: false,
  list: [],
  activeVods: []
};

export default (state = defaults, action) => {
  switch (action.type) {
    case GET_VOD:
      return { ...state, ...action.payload };
    case UPDATE_VOD_STATUS:
      const index = state.list.findIndex(item => item._id === action.payload._id)
      let newList = state.list
      if (index !== -1) {
        newList = [
          ...state.list.slice(0, index),
          action.payload,
          ...state.list.slice(index + 1)
        ];
      } else if (state.list.length) {
        newList = [
          ...state.list,
          action.payload,
        ];
      } else {
        newList = [action.payload]
      }

      return { ...state, list: newList }
    case FINISH_SINGLE_VOD_STATUS:
      const indexVODFinish = state.activeVods.findIndex(item => item._id === action.payload)
      let newListVODFinish = [...state.activeVods]
      if (indexVODFinish !== -1) {
        newListVODFinish.splice(indexVODFinish, 1)
      }
      return { ...state, activeVods: newListVODFinish }
    case UPDATE_SINGLE_VOD_STATUS:
      const indexVOD = state.activeVods.findIndex(item => item._id === action.payload._id)
      let newListVOD = state.activeVods
      if (indexVOD !== -1) {
        newListVOD = [
          ...state.activeVods.slice(0, indexVOD),
          { ...state.activeVods[indexVOD], ...action.payload },
          ...state.activeVods.slice(indexVOD + 1)
        ];
      } else if (state.activeVods.length) {
        newListVOD = [
          ...state.activeVods,
          action.payload,
        ];
      } else {
        newListVOD = [action.payload]
      }
      return { ...state, activeVods: newListVOD }
    default:
      return state;
  }
};
