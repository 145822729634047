import MDInput from 'components/MDInput';
import MUITable from '../../../components/MuiTable';
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import MDButton from 'components/MDButton';
import { Box, TextField } from '@mui/material';
import { addNotification } from 'redux/actions/notifications';
import { useDispatch } from 'react-redux';
import { api } from 'api/config';

function Versions({ loading, settings }) {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const devices = settings.customerSettings.accessibleDevices
    const [versions, setVersions] = useState(settings.versions)
    const [errors, setErrors] = useState([])

    const CellRenderer = ({ value, row }) => {
        const handleChange = (e) => {
            setVersions(prev => ({ ...prev, [row.id]: e.target.value }))
        }
        const haveError = errors.includes(row.id)
        return <TextField helperText={haveError && t("version_error")} error={haveError} onChange={handleChange} defaultValue={versions?.[row?.id]} />
    }
    const handleSubmit = async () => {
        if (versions) {
            let haveErrors = []
            const versionsArray = Object.entries(versions)
            versionsArray.forEach(([id, version]) => {
                if(version){
                    const splited = version.split(".")
                    if (splited.length !== 3 || !splited.every(item => Number.isInteger(+item))) {
                        if(!haveErrors.includes(+id)){
                            haveErrors.push(+id)
                        }
                    }
                }
            })
            setErrors(haveErrors)
            if(!haveErrors.length){
                await api.put("/settings/versions", versions)
                dispatch(addNotification({
                    color: 'success',
                    icon: 'success',
                    title: 'Success',
                    content: 'Successfully Saved',
                    dateTime: Date.now(),
                }))
            }
        } else {
            dispatch(addNotification({
                color: 'error',
                icon: 'error',
                title: 'An error occuired',
                content: "Please Fill All Values",
                dateTime: Date.now(),
            }))
        }
    }

    const columns = [
        { header: t('DeviceType'), accessor: "name" },
        { header: t('Version'), accessor: "version", renderer: CellRenderer },
    ];

    return (
        <Box>
            <MUITable
                columns={columns}
                data={devices}
                suppressPagination
            />
            <Box sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center", mt: 2 }}>
                <MDButton disabled={loading} loading={loading} variant="gradient" color="info" onClick={handleSubmit}>
                    {t('Save')}
                </MDButton>
            </Box>
        </Box>
    )
}

export default Versions