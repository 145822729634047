import axios from "axios";
import { GET_CHANNELS } from "redux/types";
import { catchAsync } from "utils/catchAsync";
import { api } from "../../api/config";

export const getChannels = () =>
  catchAsync(async (dispatch) => {
    dispatch({
      type: GET_CHANNELS,
      payload: {
        loading: true,
      },
    });
    const {
      data: { data: allChannels },
    } = await api.get("/channels");
    dispatch({
      type: GET_CHANNELS,
      payload: {
        loading: false,
        list: allChannels,
      },
    });
  });

export const deleteChannel = (id) =>
  catchAsync(async (dispatch) => {
    dispatch({
      type: GET_CHANNELS,
      payload: {
        loading: true,
      },
    });
    const {
      data: { data: allChannels },
    } = await api.delete(`/channels/${id}`);

    dispatch({
      type: GET_CHANNELS,
      payload: {
        loading: false,
        list: allChannels,
      },
    });
  });

export const updateChannel = (id, body) =>
  catchAsync(async (dispatch) => {
    dispatch({
      type: GET_CHANNELS,
      payload: {
        loading: true,
      },
    });
    const formData = new FormData();
    for(let key in body){
      if(body[key] instanceof File) {
        formData.append(key, body[key], body[key].name)
      }else if(body[key] instanceof Object) {
        if(body[key].hasOwnProperty('_id')){
          delete body[key]._id
        }
        if(body[key].hasOwnProperty('__v')){
          delete body[key].__v
        }
        formData.append(key, JSON.stringify(body[key]))
      }else if(Array.isArray(body[key])){
        formData.append(key, JSON.stringify(body[key]))
      }else{
        formData.append(key, body[key])
      }
    }
    const {
      data: { data: allChannels },
    } = await api.patch(`/channels/${id}`, formData);

    dispatch({
      type: GET_CHANNELS,
      payload: {
        loading: false,
        list: allChannels,
      },
    });
  });

export const createChannel = (body) =>
  catchAsync(async (dispatch) => {
    dispatch({
      type: GET_CHANNELS,
      payload: {
        loading: true,
      },
    });
    const formData = new FormData();
    for(let key in body){
      if(body[key] instanceof File) {
        formData.append(key, body[key], body[key].name)
      }else if(body[key] instanceof Object) {
        formData.append(key, JSON.stringify(body[key]))
      }else{
        formData.append(key, body[key])
      }
    }
    const {
      data: { data: allChannels },
    } = await api.post(`/channels`, formData);

    dispatch({
      type: GET_CHANNELS,
      payload: {
        loading: false,
        list: allChannels,
      },
    });
  });
