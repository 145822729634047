import axios from "axios";
import { statusChecker } from "redux/reduxHelpers";
import store from "redux/store";
import { UPDATE_CRONS } from "redux/types";
import { GET_ARCHIVERS } from "redux/types";
import { catchAsync } from "utils/catchAsync";
import { api } from "../../api/config";

const intervals = {}

export const getArchivers = (options) =>

  catchAsync(async (dispatch) => {
    options?.initial && dispatch({
      type: GET_ARCHIVERS,
      payload: {
        loading: true,
      },
    });
    const {
      data: { result }
    } = await api.get("/archivers");
    dispatch({
      type: GET_ARCHIVERS,
      payload: {
        loading: false,
        list: result
      },
    });
  });


export const addArchiver = (values) =>
  async (dispatch) => {
    try {
      dispatch({
        type: GET_ARCHIVERS,
        payload: {
          loading: true,
        },
      });
      const {
        data: { result },
      } = await api.post("/archivers", values);
      dispatch({
        type: GET_ARCHIVERS,
        payload: {
          loading: false,
          list: result,
        },
      });
    } catch (error) {
      console.log('error', { error });
    }
  };

export const updateArchiver = (values) =>
  catchAsync(async (dispatch) => {
    dispatch({
      type: GET_ARCHIVERS,
      payload: {
        loading: true,
      },
    });
    const {
      data: { result },
    } = await api.put(`/archivers/${values._id}`, values);
    dispatch({
      type: GET_ARCHIVERS,
      payload: {
        loading: false,
        list: result,
      },
    });
  }, (error) => {
    store.dispatch({
      type: GET_ARCHIVERS,
      payload: {
        ...error,
        loading: false,
      },
    });
  });

// export const refreshStream = (values, cb) =>
// catchAsync(async (dispatch) => {
//   const res = await apiArchiver.post('/stream/refresh', values )
//   const intervalId = statusChecker({
//       name: values.name, 
//       dispatchCb: (result)=>{
//         dispatch({
//           type: GET_ARCHIVERS,
//           payload: {
//             loading: false,
//             list: result, 
//           },
//         })}, 
//       cb, 
//       pathArchiver:'stream', 
//       pathapi:'streams', 
//       apiAxios: apiArchiver
//     })
//   if(intervals.hasOwnProperty(values._id)){
//     clearInterval(intervals[values._id])
//   }
//   intervals[values._id] = intervalId
// });

// export const stopStream = (values, cb) => async (dispatch) => {
//     const res = await apiArchiver.post('/stream/destroy', values )
//     const intervalId = statusChecker({
//       name: values.name, 
//       dispatchCb: (result)=>{
//         dispatch({
//           type: GET_ARCHIVERS,
//           payload: {
//             loading: false,
//             list: result, 
//           },
//         })}, 
//       cb, 
//       pathArchiver:'stream', 
//       pathapi:'streams', 
//       apiAxios: apiArchiver
//     })
//     if(intervals.hasOwnProperty(values._id)){
//       clearInterval(intervals[values._id])
//     }
//     intervals[values._id] = intervalId
//   }

export const deleteArchiver = (id) =>
  catchAsync(async (dispatch) => {
    dispatch({
      type: GET_ARCHIVERS,
      payload: {
        loading: true,
      },
    });
    const { data: { result } } = await api.delete(`/archivers/${id}`);
    dispatch({
      type: GET_ARCHIVERS,
      payload: {
        loading: false,
        list: result
      },
    });
    // if(intervals.hasOwnProperty(values._id)){
    //   clearInterval(intervals[values._id]);
    //   delete intervals[values._id];
    //   await apiArchiver.post('/stream/destroy', values )
    // }
  });
