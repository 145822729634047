import { Add, CopyAllOutlined, PlayCircle } from '@mui/icons-material'
import { Card, Grid, IconButton, Tooltip } from '@mui/material'
import { api } from 'api/config'
import MDBox from 'components/MDBox'
import MDButton from 'components/MDButton'
import MDTypography from 'components/MDTypography'
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout'
import DataTable from 'examples/Tables/DataTable'
import React, { useEffect, useReducer, useRef, useState } from 'react'
import { Delete, Edit } from "@mui/icons-material";
import SyncIcon from '@mui/icons-material/Sync';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import WarningIcon from '@mui/icons-material/Warning';
import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox';
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import CreateStream from './CreateStream'
import axios from 'axios'
import { createStream } from 'redux/actions/streams'
import { getStreams } from 'redux/actions/streams'
import { deleteStream } from 'redux/actions/streams'
import { ClipLoader } from 'react-spinners'
import { refreshStream } from 'redux/actions/streams'
import { stopOrStartStream } from 'redux/actions/streams'
import StopIcon from '@mui/icons-material/Stop';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import { stopStream } from 'redux/actions/streams'
import ModalPlayer from 'components/playerModal'
import { updateStream } from 'redux/actions/streams'
import { copyToClipboard } from 'utils/helpers'
import { addNotification } from 'redux/actions/notifications'
import Copy from 'components/Copy'
import { EditPermission } from 'pages/settings/Admins/constants'
import WithPermission from 'components/withPermission/WithPermission'
import { PlayPermission } from 'pages/settings/Admins/constants'
import { DeletePermission } from 'pages/settings/Admins/constants'
import { RefreshPermission } from 'pages/settings/Admins/constants'
import { AddPermission } from 'pages/settings/Admins/constants'
import { checkPermission } from 'utils/checkPermission'
import styles from "./styles.module.css";
import { infoModalState } from 'redux/actions/infoModal'
import MDDialog from 'components/MDDialog'

function Streamer({ streamerInfo }) {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [openPlayerModal, setOpenPlayerModal] = useState({ bool: false });
    const allStreams = useSelector(store => store?.streams)
    const streams = allStreams?.list?.filter(item => item.streamerId === streamerInfo.id)
    const [openModal, setOpenModal] = useState({ bool: false })
    const { settings } = useSelector(store => store.settings)


    useEffect(() => {
        dispatch(getStreams())
        const id = setInterval(() => {
            dispatch(getStreams())
        }, 10000);
        return () => {
            clearInterval(id)
        }
    }, [])


    function handleOpenPlayerModal(item) {
        let url = `${item.streamerInfo.host}/streams/${item.name}/master.m3u8`
        setOpenPlayerModal({
            bool: true,
            modalValues: { url: url },
        });
    }


    const handleDelete = (values) => {
        dispatch(deleteStream(values))
    }


    const handleCreate = (values) => {
        dispatch(createStream({ ...values, streamerId: streamerInfo.id, defaultCommand: settings.defaults.streamerCommand }))
    }

    const handleUpdate = function (values) {
        dispatch(updateStream(this._id, { ...values, streamerId: streamerInfo.id, defaultCommand: settings.defaults.streamerCommand }))
    }

    const handleStopStream = (item) => {
        dispatch(stopStream({ ...item, streamerInfo, defaultCommand: settings.defaults.streamerCommand }))
    }

    const handleStartStream = (item) => {
        dispatch(refreshStream({ ...item, streamerInfo, defaultCommand: settings.defaults.streamerCommand }))
    }

    const handleRefreshStream = (item) => {
        dispatch(refreshStream({ ...item, streamerInfo, defaultCommand: settings.defaults.streamerCommand }))
    }


    const handleOpenModal = (item, isUpdate) => {
        setOpenModal({
            bool: true,
            onSave: isUpdate ? handleUpdate.bind(item) : handleCreate,
            modalValues: item,
            isUpdate
        });
    }


    const columns = [
        { Header: t("Name"), accessor: "Name", align: "left" },
        { Header: t("Stream_URL"), accessor: "URL", align: "left" },
        { Header: t("action"), accessor: "action", align: "center" },
    ];

    const handleOpenErrorInfo = (item) => {
        dispatch(infoModalState({
            open: true,
            header: `${t('errorOccuired')}`,
            info: item.output
        }))
    }

    const rows = streams?.map(item => {
        const isStreamActive = item.isActive;
        const isLoading = item.loading
        const output = item.output
        return {
            Name: item.name,
            URL: <MDBox>{`${item.streamerInfo.host}/streams/${item.name}/master.m3u8`} <Copy str={`${item.streamerInfo.host}/streams/${item.name}/master.m3u8`} /></MDBox>,
            action: (
                <MDBox className={styles.actions}>
                    <WithPermission disable={true} permissionsList={[EditPermission]} permissionKey={'streamer'} >
                        <IconButton
                            disabled={isLoading || !checkPermission([EditPermission], 'streamer')}
                            onClick={() => {
                                handleOpenModal(item, true);
                            }}
                        >
                            <Edit />
                        </IconButton>
                    </WithPermission>
                    <WithPermission disable={true} permissionsList={[PlayPermission]} permissionKey={'streamer'} >
                        <IconButton
                            disabled={!checkPermission([PlayPermission], 'streamer')} onClick={() => handleOpenPlayerModal(item)} color="success">
                            {" "}
                            <PlayCircle />{" "}
                        </IconButton>
                    </WithPermission>
                    <WithPermission disable={true} permissionsList={[DeletePermission]} permissionKey={'streamer'} >
                        <Tooltip title={t('dblClick')} >
                            <IconButton
                                disabled={isLoading || !checkPermission([DeletePermission], 'streamer')}
                                onDoubleClick={() => {
                                    confirm(`${t('askDelete')}`) && handleDelete(item);
                                }}
                            >
                                <Delete />
                            </IconButton>
                        </Tooltip>
                    </WithPermission>
                    <WithPermission disable={true} permissionsList={[RefreshPermission]} permissionKey={'streamer'} >
                        <IconButton
                            disabled={isLoading || !checkPermission([RefreshPermission], 'streamer')}
                            onClick={() => {
                                isStreamActive ? handleStopStream(item) : handleStartStream(item);
                            }}
                        >
                            {isStreamActive ? <StopIcon /> : <PlayArrowIcon />}
                        </IconButton>
                    </WithPermission>
                    <WithPermission disable={true} permissionsList={[RefreshPermission]} permissionKey={'streamer'} >
                        <Tooltip title={isLoading ? t('loading') : t('updateNow')} >
                            <IconButton
                                disabled={!checkPermission([RefreshPermission], 'streamer')}
                                onClick={() => {
                                    !isLoading && handleRefreshStream(item);
                                }}
                            >
                                {isLoading ? <ClipLoader color='#338BED' size={20} /> : <SyncIcon />}
                            </IconButton>
                        </Tooltip>
                    </WithPermission>
                    <Tooltip title={isStreamActive ? t('active') : t('inactive')} >
                        <IconButton>
                            {isStreamActive ? <CheckBoxIcon sx={{ color: 'success.main' }} /> : <IndeterminateCheckBoxIcon sx={{ color: 'error.main' }} />}
                        </IconButton>
                    </Tooltip>
                    {!!output && <Tooltip title={output.length > 100 ? output?.substring?.(0, 100) + '...' : output} >
                        <MDButton onClick={() => handleOpenErrorInfo(item)} withIcon={<WarningIcon sx={{ color: 'error.main' }} />} variant='outlined' color='error' sx={{ padding: '5px 10px' }} >
                            {t('error')}
                        </MDButton>
                    </Tooltip>}
                </MDBox>
            ),
        }
    })

    return (
        <MDBox mx={2} my={2} pt={6} pb={3}>
            <Grid container spacing={6}>
                <Grid item xs={12}>
                    <Card>
                        <MDBox
                            mx={2}
                            mt={-3}
                            py={3}
                            px={2}
                            className={'serverProcessesHeader'}
                            variant="gradient"
                            bgColor="info"
                            borderRadius="lg"
                            coloredShadow="info"
                        >
                            <MDTypography variant="h6" color="white">
                                {t("streams.header", { streamer: streamerInfo.name })}
                            </MDTypography>
                            <WithPermission disable={true} permissionsList={[AddPermission]} permissionKey={'streamer'} >
                                <MDButton disabled={!checkPermission([AddPermission], 'streamer')} onClick={() => { handleOpenModal() }} withIcon={<Add />} loading={false}>
                                    {t("streams.addNew")}
                                </MDButton>
                            </WithPermission>
                        </MDBox>
                        <MDBox pt={3}>
                            {/* <MDBox sx={{ display: 'flex', justifyContent: 'flex-end', padding: '0px 30px' }} >
                                <MDButton onClick={() => { }} variant='contained' sx={{ color: 'info.main', border: '1px solid' }} >{t('streams.updateFullList')}</MDButton>
                            </MDBox> */}
                            {rows && (
                                <DataTable
                                    table={{ columns, rows }}
                                    isSorted={false}
                                    showTotalEntries={false}
                                    canSearch={true}
                                    entriesPerPageBox={false}
                                    noEndBorder
                                />
                            )}
                        </MDBox>
                    </Card>
                </Grid>
            </Grid>
            {openModal.bool &&

                <MDDialog
                    maxWidth="lg"
                    fullWidth
                    dialogHeight="fit-content"
                    open={openModal?.bool}
                    setOpen={setOpenModal}
                    // onSave={handleSubmit}
                    suppressActions
                    submitText={openModal?.isUpdate ? t('streams.update') : t('streams.create')}
                >
                    <CreateStream streamerInfo={streamerInfo} openModal={openModal} setOpenModal={setOpenModal} streams={streams} />
                </MDDialog>}
                    {openPlayerModal.bool && (
                        <ModalPlayer openModal={openPlayerModal} setOpenModal={setOpenPlayerModal} />
                    )}
                </MDBox>
    )
}

            export default React.memo(Streamer)