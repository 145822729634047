import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import colors from 'assets/theme/base/colors';
import { getVideoDuration } from "utils/helpers";
import MDButton from "components/MDButton";
import { addNotification } from "redux/actions/notifications";
import { useDispatch, useSelector } from "react-redux";
import { ClipLoader } from "react-spinners";
import { Tooltip } from "@mui/material";
import { api } from "api/config";
import { DropzoneDialog } from "material-ui-dropzone";
import { updateVODStatus } from "redux/actions/VOD";
import { useTranslation } from "react-i18next";
import { apiAuth } from "api/config";

const UploadProgressForSeries = ({ setUploadingEpisodes, onSave, values, poster, seasons, handleSetEpisodeSubtitles, vodInfo, setVodInfo, isImageBase64, uploadingEpisodes, serverInfo, name, handleSetUrl, setMovieUploaded, season, episode, movieUploaded, title, onDelete, setOpenModal, openModal, setOpenInfoModal }) => {
    const dispatch = useDispatch()
    const { t } = useTranslation()
    const [progress, setProgress] = useState({});
    const [uploadProgress, setUploadProgress] = useState(0);
    const [transcodeProgress, setTranscodeProgress] = useState({});
    // const [duration, setDuration] = useState(null)
    const duration = useRef(null)
    const [deleting, setDeleting] = useState(false)
    const [haveMovie, setHaveMovie] = useState(false)
    const activeVods = useSelector(store => store?.VOD?.activeVods)
    const activeVod = activeVods.find(item => item.episodeId === episode?.id)

    const url = `${serverInfo?.host}/movies/${name}/${season.label.replaceAll(" ", '_')}/${episode.label.replaceAll(" ", '_')}/index.m3u8`
    const fileName = `${name}_${season.label.replaceAll(" ", '_')}_${episode.label.replaceAll(" ", '_')}`
    const handleFileInputChange = async (event) => {
        const uploadedFile = event.target.files[0];
        const fileName = `${name}_${season.label.replaceAll(" ", '_')}_${episode.label.replaceAll(" ", '_')}`
        const file = new File([uploadedFile], fileName, { type: uploadedFile?.type });
        // const formData = new FormData();
        // formData.append("video", file);
        // formData.append("name", name);
        // formData.append("season", season.label.replaceAll(" ", '_'));
        // formData.append("episode", episode.label.replaceAll(" ", '_'));
        // movieUpload(formData)
        // const season = season.label.replaceAll(" ", '_')
        // const episode = episode.label.replaceAll(" ", '_')
        const chunkSize = 1024 * 1024 * 5; // 5MB
        const totalChunks = Math.ceil(file.size / chunkSize);
        let startByte = 0;
        setUploadingEpisodes([...uploadingEpisodes, episode.id])
        setOpenModal(prev => ({ ...prev, loading: true }))
        for (let i = 1; i <= totalChunks; i++) {
            const endByte = Math.min(startByte + chunkSize, file.size);
            const chunk = file.slice(startByte, endByte);
            await uploadChunk(chunk, totalChunks, i, name, fileName);
            const percentComplete = Math.round(Math.min(((chunkSize * i) / file.size) * 100, 100));
            setUploadProgress(percentComplete)
            startByte = endByte;
        }
        handleSetUrl(url)
        const newData = [...uploadingEpisodes]
        const index = newData.find(item => item === episode.id)
        newData.splice(index, 1)
        setUploadingEpisodes(newData)
    };

    
    useEffect(() => {
        if (openModal?.modalValues && openModal?.bool) {
            const name = encodeURIComponent(openModal?.modalValues?.title?.en?.replaceAll(' ', '_')).replaceAll('%', '')
            const fileName = `${name}_${season.label.replaceAll(" ", '_')}_${episode.label.replaceAll(" ", '_')}`
            if (name) {
                apiAuth.get(`${serverInfo?.host}/VOD/Check/${fileName}`).then((res) => {
                    if (res.data.info) {
                        console.log(res.data.info);
                        setVodInfo(res.data.info)
                    }
                    setMovieUploaded(true)
                    setUploadProgress(100)
                }).catch(() => {
                    setMovieUploaded(false)
                    setUploadProgress(0)
                    setVodInfo()
                })
            }
        }
    }, [season, episode, openModal?.bool])

    // const movieUpload = async (formData) => {
    //     try {
    //         const imageKey = isImageBase64.current ? 'image' : 'poster'
    //         onSave && onSave({ ...values, [imageKey]: poster, seasons });
    //         setUploadingEpisodes([...uploadingEpisodes, episode.id])

    //         const { data } = await apiAuth.post(`${serverInfo.host}/upload`, formData, {
    //             onUploadProgress: (progressEvent) => {
    //                 const percentCompleted = Math.round(
    //                     (progressEvent.loaded * 100) / progressEvent.total
    //                 );
    //                 setProgress((prev) => ({ ...prev, [episode.id]: percentCompleted }));
    //                 if (percentCompleted % 20 === 0) {
    //                     api.patch(`/VOD/${openModal?.modalValues?._id}/processUpdate`, {
    //                         _id: episode.id,
    //                         episodeId: episode.id,
    //                         seasonId: season.id,
    //                         uploadStatus: percentCompleted,
    //                         transcodeStatus: 0
    //                     })
    //                 }
    //             },
    //         })
    //         const vofInfo = data.info.streams.find(item => item.codec_type === 'video')
    //         if (vofInfo.duration) {
    //             duration.current = +vofInfo.duration
    //         } else if (vofInfo.tags.DURATION) {
    //             try {
    //                 const [h, m, s] = vofInfo.tags.DURATION.split('.')[0].split(':')
    //                 duration.current = +h * 3600 + +m * 60 + +s
    //             } catch (error) {
    //                 console.log(error);
    //                 duration.current = 100
    //             }
    //         }
    //         const aspectRation = vofInfo.display_aspect_ratio
    //         setMovieUploaded(true)
    //         dispatch(addNotification({
    //             color: 'success',
    //             icon: 'success',
    //             title: `Uploaded`,
    //             content: `${title} Uploaded successfully`,
    //             dateTime: Date.now(),
    //         }))
    //         await api.patch(`/VOD/${openModal?.modalValues?._id}/process`, {
    //             episodeId: episode.id,
    //             seasonId: season.id,
    //             uploadStatus: 100,
    //             duration: duration.current,
    //             url: `${serverInfo.host}/transcodeStatus/${name}/${season.label.replaceAll(" ", '_')}/${episode.label.replaceAll(" ", '_')}`
    //         })
    //         dispatch(addNotification({
    //             color: 'success',
    //             icon: 'success',
    //             title: `Transcode completed`,
    //             content: `${title} Transcode completed`,
    //             dateTime: Date.now(),
    //         }))
    //         // await getTranscodeStatus()
    //     } catch (error) {
    //         console.log(error);
    //         api.patch(`/VOD/${openModal?.modalValues?._id}/processUpdate`, { removeProgress: 'null' })
    //         dispatch(addNotification({
    //             color: 'error',
    //             icon: 'error',
    //             title: `Upload Error`,
    //             content: `${title} an error occuired`,
    //             dateTime: Date.now(),
    //         }))
    //     }

    // }

    async function uploadChunk(chunk, totalChunks, currentChunk, name, fileName) {
        const formData = new FormData();
        formData.append('file', chunk);
        formData.append('totalChunks', totalChunks);
        formData.append('currentChunk', currentChunk);
        formData.append('name', name);
        formData.append('fileName', fileName);
        const seasonSafeName = season.label.replaceAll(" ", '_')
        const episodeSafeName = episode.label.replaceAll(" ", '_')
        formData.append("season", seasonSafeName);
        formData.append("episode", episodeSafeName);
        try {
            const response = await apiAuth.post(`${serverInfo.host}/upload`, formData);
            if (response.data.fullFile) {
                const data = response.data
                setVodInfo(data?.info)
                setOpenInfoModal(prev => ({ ...prev, title: name, bool: true, info: data?.info }))
                const vofInfo = data?.info?.streams?.find?.(item => item.codec_type === 'video')
                if (data.info.duration) {
                    duration.current = +data.info.duration
                } else if (vofInfo?.duration) {
                    duration.current = +vofInfo.duration
                } else if (vofInfo?.tags?.DURATION) {
                    try {
                        const [h, m, s] = vofInfo.tags.DURATION.split('.')[0].split(':')
                        duration.current = +h * 3600 + +m * 60 + +s
                    } catch (error) {
                        console.log(error);
                        duration.current = 100
                    }
                }
                setMovieUploaded(true)
                dispatch(addNotification({
                    color: 'success',
                    icon: 'success',
                    title: `Uploaded`,
                    content: `${title} Uploaded successfully`,
                    dateTime: Date.now(),
                }))
                setOpenModal({
                    ...openModal,
                    loading: false,
                    movieUpload: (codecs) => {
                        movieTranscode({ filename: fileName, name, codecs, season: seasonSafeName, episode: episodeSafeName })
                    }
                })
            }
        } catch (error) {
            console.log(error);
            await new Promise((resolve) => {
                setTimeout(() => {
                    uploadChunk(chunk, totalChunks, currentChunk, name)
                        .then((res) => {
                            resolve(res)
                        })
                        .catch(err => {
                            console.log(err);
                            api.patch(`/VOD/${openModal?.modalValues?._id}/processUpdate`, { removeProgress: 'null' })
                            dispatch(addNotification({
                                color: 'error',
                                icon: 'error',
                                title: `Upload Error`,
                                content: `${title} an error occuired`,
                                dateTime: Date.now(),
                            }))
                        })
                }, 2000)
            })
        }
    }


    const handleDeleteMovie = async () => {
        try {
            setDeleting(true)
            if (haveMovie) {
                await apiAuth.delete(`${serverInfo.host}/VOD/${name}/${season.label.replaceAll(" ", '_')}/${episode.label.replaceAll(" ", '_')}`)
            } else if (movieUploaded) {
                await apiAuth.delete(`${serverInfo.host}/VOD/upload/${fileName}`)
            }
            setVodInfo()
            setDeleting(false)
            onDelete()
            setTranscodeProgress(null)
            setProgress(null)
            setHaveMovie(false)
        } catch (error) {
            setVodInfo()
            setDeleting(false)
            onDelete()
            setTranscodeProgress(null)
            setProgress(null)
            setHaveMovie(false)
        }
    }

    const movieTranscode = async (body) => {
        setOpenModal(prev => ({ ...prev, loading: true }))
        const { data } = await apiAuth.post(`${serverInfo.host}/transcode`, body)
        //         await api.patch(`/VOD/${openModal?.modalValues?._id}/process`, {
        //             episodeId: episode.id,
        //             seasonId: season.id,
        //             uploadStatus: 100,
        //             duration: duration.current,
        //             url: `${serverInfo.host}/transcodeStatus/${name}/${season.label.replaceAll(" ", '_')}/${episode.label.replaceAll(" ", '_')}`
        //         })
        api.patch(`/vod/${openModal?.modalValues?._id}/process`, {
            uploadStatus: 100,
            duration: duration.current,
            host: serverInfo.host,
            episodeId: episode.id,
            seasonId: season.id,
            url: `${serverInfo.host}/transcodeStatus/${name}/${season.label.replaceAll(" ", '_')}/${episode.label.replaceAll(" ", '_')}`
        })
        let timeoutId;
        const getStatusOfTranscode = () => {
            axios.get(`${serverInfo.host}/transcodeStatus/${name}/${season.label.replaceAll(" ", '_')}/${episode.label.replaceAll(" ", '_')}`)
                .then((res) => {
                    const durationVideo = Math.round(+duration.current)
                    const durationTranscoded = Math.round(+res.data.length)
                    if (durationTranscoded === 0 || durationVideo === 0 || durationTranscoded < durationVideo) {
                        timeoutId && clearTimeout(timeoutId)
                        timeoutId = setTimeout(async () => {
                            getStatusOfTranscode()
                        }, 5000)
                    } else {
                        dispatch(addNotification({
                            color: 'success',
                            icon: 'success',
                            title: `Transcode completed`,
                            content: `${title} Transcode completed`,
                            dateTime: Date.now(),
                        }))
                        dispatch(getVOD())
                    }
                })
                .catch(error => {
                    // console.log("Error During Transcoding !!!!!!!", { error });
                    // // api.patch(`/VOD/${openModal?.modalValues?._id}/processUpdate`, null)
                    // dispatch(addNotification({
                    //     color: 'error',
                    //     icon: 'error',
                    //     title: `Upload Error`,
                    //     content: `${title} an error occuired`,
                    //     dateTime: Date.now(),
                    // }))
                })
        }
        getStatusOfTranscode()
    }


    useEffect(() => {
        if (movieUploaded && !openModal?.movieUpload && name && season.label && episode.label) {
            const seasonSafeName = season.label.replaceAll(" ", '_')
            const episodeSafeName = episode.label.replaceAll(" ", '_')
            setOpenModal({
                ...openModal,
                movieUpload: (codecs) => {
                    movieTranscode({ filename: fileName, name, codecs, season: seasonSafeName, episode: episodeSafeName })
                }
            })
        }
    }, [movieUploaded, openModal])
    // const getTranscodeStatus = async () => {

    //     const { data } = await apiAuth.get(`${serverInfo.host}/transcodeStatus/${name}/${season.label.replaceAll(" ", '_')}/${episode.label.replaceAll(" ", '_')}`)
    //     const maxCount = Math.floor(duration.current / 10)
    //     setTranscodeProgress((prev) => ({ ...prev, [episode.id]: Math.floor(data.count * 100 / maxCount) }))
    //     dispatch(updateVODStatus({
    //         _id: episode.id,
    //         episodeId: episode.id,
    //         seasonId: season.id,
    //         uploadStatus: 100,
    //         transcodeStatus: Math.floor(data.count * 100 / maxCount)
    //     }))

    //     console.log({maxCount});
    //     if (data.count < maxCount) {
    //         setTimeout(async () => {
    //             await getTranscodeStatus()
    //         }, data.count === 0 ? 600 : 5000)
    //     } else {
    //         dispatch(finishVODStatus(episode.id))
    //         dispatch(addNotification({
    //             color: 'success',
    //             icon: 'success',
    //             title: `Transcode completed`,
    //             content: `${title} Transcode completed`,
    //             dateTime: Date.now(),
    //         }))
    //         handleSetUrl(url)
    //         const newData = [...uploadingEpisodes]
    //         const index = newData.find(item => item === episode.id)
    //         newData.splice(index, 1)
    //         setUploadingEpisodes(newData)
    //         setHaveMovie(true)
    //     }
    // }

    useEffect(() => {
        const checkData = async () => {
            try {
                const res = await api.get(url)
                if (res) setHaveMovie(true)
                else setHaveMovie(false)
            } catch (error) {
                setHaveMovie(false)
            }
        }
        if (url) {
            checkData()
        }
    }, [url])


    const transcodeProgressTitle = uploadingEpisodes.includes(episode.id) ?
        `Transcoding ${transcodeProgress?.[episode.id] ?? 0}%` : haveMovie ? "Transcoded successfully!" : `Transcoding 0%`


    if (!name || !openModal?.modalValues?._id) {
        return null
    }

    return (
        <>

            {deleting ?
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                    <ClipLoader color={colors.error.main} />
                    <div>Deleting ...</div>
                </div>
                :
                <div>
                    {
                        // !!progress && progress<100 &&
                        movieUploaded ? <div>
                            <div style={{ textAlign: "center" }} > {t("Uploaded_successfully")} </div>
                            <progress style={{ width: '100%' }} value={100} max="100" />
                        </div> :
                            <div>
                                <div style={{ textAlign: "center" }} > {+uploadProgress || (+activeVod?.uploadStatus && +activeVod?.uploadStatus < 100) ? `${t("Uploading")} ${+uploadProgress || +activeVod?.uploadStatus || 0}%` : haveMovie ? t("Uploaded_successfully") : `${t("Uploading")} 0%`} </div>
                                <progress style={{ width: '100%' }} value={uploadProgress || ((haveMovie ? 100 : 0))} max="100" />
                            </div>
                    }
                    {
                        // progress>=100 && transcodeProgress<100 && 
                        <div>
                            <div style={{ textAlign: "center" }} > {+activeVod?.transcodeStatus && +activeVod?.transcodeStatus < 100 ? `Transcoding ${+activeVod?.transcodeStatus || 0}%` : haveMovie || +activeVod?.transcodeStatus >= 100 ? "Transcoded successfully!" : 'Transcoding 0%'} </div>
                            <progress style={{ width: '100%' }} value={+activeVod?.transcodeStatus || (haveMovie ? 100 : 0)} max="100" />
                        </div>}
                </div>}
            <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end', gap: '10px' }}>
                {/* {movieUploaded && <span style={{ color: colors.success.main }} >Uploaded successfully!</span>}  */}
                {/* {transcodeProgress>=100 && <span style={{ color: colors.success.main }} >Transcoded successfully!</span>}  */}

                <Tooltip title={(movieUploaded || haveMovie) ? t('dblClick') : ''} >
                    <MDButton disabled={!(movieUploaded || haveMovie)} variant="outlined" color="error" onDoubleClick={handleDeleteMovie}>
                        {t("Delete")}
                    </MDButton>
                </Tooltip>
                <MDButton disabled={(movieUploaded || haveMovie)} variant="gradient" color="info" component="label" >
                    {t("Upload")}
                    <input hidden accept="video/*,video/x-matroska" type="file" value={null} onChange={(event) => handleFileInputChange(event)} />
                </MDButton>
                {vodInfo && <MDButton disabled={haveMovie} variant="outlined" color="info" onClick={() => setOpenInfoModal(prev => ({ ...prev, title: name, bool: true, info: vodInfo }))} >
                    {t("Content")}
                </MDButton>}

            </div>
        </>
    );
};

export default UploadProgressForSeries;
