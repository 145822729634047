import React from 'react';
import './mobileModal.css';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@mui/icons-material/Close';

const MobileModal = ({ onClose, children, headerText, saveText, onSave, isDelete, cancelText }) => {
    const { t } = useTranslation();

    return (
        <div className='screen' >
            {(children ||
                headerText ||
                saveText ||
                cancelText) && <div
                    className="modalView"
                >
                    <div className="header">
                        <h2 className="headerText">{headerText}</h2>
                        <div className="closeButton" onClick={onClose}>
                            <CloseIcon size={24} color={'#000'} />
                        </div>
                    </div>
                    <div className="content">{children}</div>
                    <div className="footer">
                        {cancelText && (
                            <div
                                className="cancelText"
                                onClick={onClose}
                                style={{ border: '1px solid #ccc', marginRight: '10px' }}
                            >
                                {cancelText}
                            </div>
                        )}
                        {saveText && (
                            <div
                                className="saveButton"
                                onClick={onSave}
                            >
                                {saveText || t('Save')}
                            </div>
                        )}
                    </div>
                </div>}
        </div>
    );
};

export default MobileModal;
