import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import ReportsBarChart from "examples/Charts/BarCharts/ReportsBarChart";
import ReportsLineChart from "../../examples/Charts/LineCharts/ReportsLineChart";
import ComplexStatisticsCard from "../../examples/Cards/StatisticsCards/ComplexStatisticsCard";
import { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCustomers } from "redux/actions/customers";
import { getChannels } from "redux/actions/channels";
import { getVOD } from "redux/actions/VOD";
import { getBilling } from "redux/actions/billing";
import { addThousandSeparator } from "utils/helpers";
import { AddCircle, Check, Edit, PlayCircle, RemoveCircle } from "@mui/icons-material";
import ErrorIcon from '@material-ui/icons/Error';
import LeaderboardIcon from '@mui/icons-material/Leaderboard';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import MovieIcon from '@mui/icons-material/Movie';
import moment from "moment";
import { months as monthsTranslations } from "utils/constants";
import { getActiveUsers } from "../../redux/actions/activeUsers";
import { getCustomersByMonth } from "redux/actions/usersByMonth";
import { useTranslation } from "react-i18next";
import { api } from "api/config";
import MDButton from "components/MDButton";
import TemporaryDrawer from "components/Drawer";
import WatchHistory from "pages/channels/WatchHistory";
import { checkPermission } from "utils/checkPermission";
import WithPermission from "components/withPermission/WithPermission";
import { ViewPermission } from "pages/settings/Admins/constants";
import SystemAnalyzeResult from "components/SystemAnalyzeResult";
import { getServerAdvices } from "redux/actions/serverAdvices";
import { generateDays } from "utils/helpers";
import { getActiveDevices } from "redux/actions/activeDevices";
import { Fab, IconButton, Paper, Skeleton, Tooltip } from "@mui/material";
import { updateAdminWdigets } from "redux/actions/admins";
import { allDashboardWidgets } from "utils/constants";
import { getVisibleWidgets } from "redux/actions/admins";
import HostCard from "components/HostCard";
import MDTypography from "components/MDTypography";
import { getActiveDevicesMonths } from "redux/actions/activeDevices";
import UsersWatchHistory from "pages/customers/UsersWatchHistory";

function Dashboard() {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation()
  const [channelsPercents, setChannelsPercents] = useState([])
  const [usersWatchtimePercents, setUsersWatchtimePercents] = useState([])

  const { list: customers, loading: loadingCustomers } = useSelector(store => store?.customer)
  const { list: channels, loading: loadingChannels } = useSelector(store => store?.channels)
  const { list: VODsList, loading: loadingVods } = useSelector(store => store?.VOD)
  const { list: billingHistoryByMonths, loading: loadingBilling } = useSelector(store => store?.billingHistory)
  const { list: activeUsersHistoryByMonths } = useSelector(store => store?.activeUsersHistory)
  const { list: activeDevicesHistoryByDays, listMonths: activeDevicesHistoryByMonths, } = useSelector(store => store?.activeDevicesHistory)
  const { list: usersHistoryByMonths } = useSelector(store => store?.usersByMonth)

  const transcoders = useSelector(store => store?.transcoders?.list)
  const archivers = useSelector(store => store?.archivers?.list)
  const streamers = useSelector(store => store?.streamers?.list)
  const VODs = useSelector(store => store?.VODs?.list)

  const [editMode, setEditMode] = useState(false)
  const { id: adminId, } = useSelector(store => store.auth)
  const adminsData = useSelector(store => store.admins)
  const [visibleDashboardWidgets, setVisibleDashboardWidgets] = useState([])
  const SStatusInterval = useRef(null)
  const [openWatchHistory, setOpenWatchHistory] = useState(false)
  const [openUsersWatchHistory, setOpenUsersWatchHistory] = useState(false)
  const currentMonth = moment().format('MM')
  const currentDay = moment().format('DD')
  const billingHistory = billingHistoryByMonths?.[currentMonth] || []
  const months = monthsTranslations[i18n.language]
  const days = generateDays(+currentDay < 7 ? (+currentDay + (7 - +currentDay)) : +currentDay, -1, "DD/MM ddd")
  useEffect(() => {
    if (!adminsData.visibleDashboardWidgets) {
      adminId && dispatch(getVisibleWidgets(adminId))
    }
  }, [adminId])

  useEffect(() => {
    if (adminsData.hasOwnProperty("visibleDashboardWidgets")) {
      setVisibleDashboardWidgets(adminsData.visibleDashboardWidgets || allDashboardWidgets)
    }
  }, [adminsData.visibleDashboardWidgets])

  useEffect(() => {
    dispatch(getServerAdvices())
    SStatusInterval.current = setInterval(() => {
      dispatch(getServerAdvices())
    }, 10000)

    dispatch(getCustomers())
    dispatch(getChannels())
    dispatch(getVOD())
    dispatch(getBilling())
    dispatch(getActiveUsers())
    dispatch(getActiveDevices())
    dispatch(getActiveDevicesMonths())
    dispatch(getCustomersByMonth())

    // dispatch(getTranscoders({ initial: true }))
    // dispatch(getArchivers({ initial: true }))
    // dispatch(getStreamers({ initial: true }))
    // dispatch(getVODs({ initial: true }))

    return () => {
      SStatusInterval.current && clearInterval(SStatusInterval.current)
    }
  }, [])

  const [paymentChartData, setPaymentsChartData] = useState({ labels: [], datasets: {} })

  const [activeUsersChartData, setActiveUsersChartData] = useState({ labels: [], datasets: {} })
  const [activeDevicesChartData, setActiveDevicesChartData] = useState({ labels: [], datasets: {} })
  const [activeDevicesChartDataByMonths, setActiveDevicesChartDataByMonths] = useState({ labels: [], datasets: {} })
  const [usersChartData, setUsersChartData] = useState({ labels: [], datasets: {} })
  const [allUsersChartData, setAllUsersChartData] = useState({ labels: [], datasets: {} })

  const [totalWatchedChannels, setTotalWatchedChannels] = useState({ labels: [], datasets: {} })
  const [mostValuableUsers, setMostValuableUsers] = useState({ labels: [], datasets: {} })

  useEffect(() => {
    setTotalWatchedChannels({
      labels: channelsPercents?.slice(0, 10)?.map?.(item => item?.channel?.name),
      datasets: { label: t("views"), data: channelsPercents?.slice(0, 10)?.map?.(item => item?.percent) },
    })
  }, [
    months,
    currentMonth,
    channelsPercents
  ])

  useEffect(() => {
    setMostValuableUsers({
      labels: usersWatchtimePercents?.slice(0, 10)?.map?.(item => `${item?.customer?.firstName} ${item?.customer?.lastName}`),
      datasets: { label: t("views"), data: usersWatchtimePercents?.slice(0, 10)?.map?.(item => item?.percent) },
    })
  }, [
    months,
    currentMonth,
    usersWatchtimePercents
  ])

  useEffect(() => {
    setPaymentsChartData({
      labels: months.filter(item => +item.index <= +currentMonth).map(item => item.label),
      datasets: {
        label: "Payments", data: months.filter(item => +item.index <= +currentMonth).map(item => {
          return billingHistoryByMonths?.[item.index] ?
            billingHistoryByMonths[item.index].reduce((acc, item) => { acc += +item.amount; return acc }, 0)
            :
            0
        })
      },
    })
  }, [
    months,
    currentMonth,
    billingHistoryByMonths,
  ])

  useEffect(() => {
    const chartData = {
      labels: days.map(item => item.label),
      datasets: {
        label: "Active users",
        data: days.map(item => {
          return activeUsersHistoryByMonths?.[item.day] ?
            activeUsersHistoryByMonths[item.day].length
            :
            0
        })
      },
    }
    setActiveUsersChartData(chartData)
  }, [
    months,
    currentDay,
    activeUsersHistoryByMonths,
  ])


  useEffect(() => {
    const dataByMonths = {
      labels: months.filter(item => +item.index <= +currentMonth).map(item => item.label),
      datasets: {
        label: t("active_devices"),
        data: months.filter(item => +item.index <= +currentMonth).map(item => {
          return (activeDevicesHistoryByMonths?.[item.index] ?
            activeDevicesHistoryByMonths[item.index].length
            :
            0)
        })
      },
    }


    setActiveDevicesChartDataByMonths(dataByMonths)
    const chartData = {
      labels: days.map(item => item.label),
      datasets: {
        label: t("active_devices"),
        data: days.map(item => {
          return activeDevicesHistoryByDays?.[item.day] ?
            activeDevicesHistoryByDays[item.day].length
            :
            0
        })
      },
    }
    setActiveDevicesChartData(chartData)
  }, [
    months,
    currentDay,
    activeDevicesHistoryByDays,
  ])


  useEffect(() => {
    setUsersChartData({
      labels: months.filter(item => +item.index <= +currentMonth).map(item => item.label),
      datasets: [
        {
          label: "Users Count",
          data: months.filter(item => +item.index <= +currentMonth).map(item => {
            return usersHistoryByMonths?.[item.index] ?
              usersHistoryByMonths[item.index].length
              :
              0
          })
        },
        {
          label: "Subusers Count",
          data: months.filter(item => +item.index <= +currentMonth).map(item => {
            return usersHistoryByMonths?.[item.index] ?
              usersHistoryByMonths[item.index].reduce((acc, item) => {
                acc += item.subusers.length
                return acc
              }, 0)
              :
              0
          })
        }
      ],
    })
    let thisMonthUsers = 0
    let thisMonthSubUsers = 0
    const totalSubusersAccounts = months.filter(item => +item.index <= +currentMonth).map(item => {
      thisMonthSubUsers = (usersHistoryByMonths?.[item.index] ?
        usersHistoryByMonths[item.index].reduce((acc, item) => {
          acc += item.subusers.length
          return acc
        }, 0)
        :
        0) + (thisMonthSubUsers || 0)
      return thisMonthSubUsers
    })
    setAllUsersChartData({
      labels: months.filter(item => +item.index <= +currentMonth).map(item => item.label),
      datasets: [
        {
          label: "Total Users Count",
          data: months.filter(item => +item.index <= +currentMonth).map(item => {
            thisMonthUsers = (usersHistoryByMonths?.[item.index] ?
              usersHistoryByMonths[item.index].length
              :
              0) + (thisMonthUsers || 0)
            return thisMonthUsers
          })
        },
        {
          label: "Total Subusers Count",
          data: totalSubusersAccounts
        }
      ],
    })
  }, [
    months,
    currentMonth,
    usersHistoryByMonths,
  ])


  const getChannelsPercents = async () => {
    try {
      const search = {
        from: 0,
        to: Date.now(),
        type: 'channel'
      }
      const res = await api.get('/history/watch/byChannels', { params: search })
      const totalWatchedDuration = Object.entries(res.data.result).reduce((acc, [key, value]) => {
        acc += value.duration
        return acc
      }, 0)
      const entries = Object.entries(res.data.result).map(([key, value]) => {
        const found = channels.find(channel => channel._id === key)
        return { ...value, channel: found, percent: (value.duration * 100 / totalWatchedDuration) }
      })

      setChannelsPercents(entries.sort((a, b) => b.duration - a.duration))
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    if (channels.length && !channelsPercents.list) {
      getChannelsPercents()
    }
  }, [channels.length])

  const getUsersWatchtimePercents = async () => {
    try {
      const search = {
        from: 0,
        to: Date.now(),
        type: 'channel'
      }
      const res = await api.get('/history/watch/byUsers', { params: search })
      const totalWatchedDuration = Object.entries(res.data.result).reduce((acc, [key, value]) => {
        acc += value.duration
        return acc
      }, 0)
      const entries = Object.entries(res.data.result).map(([key, value]) => {
        const found = customers.find(customer => customer._id === key)
        return { ...value, customer: found, percent: (value.duration * 100 / totalWatchedDuration) }
      })
      setUsersWatchtimePercents(entries.sort((a, b) => b.duration - a.duration))
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {

    if (customers.length && !usersWatchtimePercents.list) {
      getUsersWatchtimePercents()
    }
  }, [customers.length])


  const smallWidgets = [
    {
      key: "channels",
      widget: <ComplexStatisticsCard
        color="dark"
        icon={<PlayCircle fontSize="large" />}
        title={t("Channels")}
        loading={loadingChannels}
        count={channels.length}
        percentage={{
          color: "success",
          amount: "",
          label: t("Total_channels"),
        }}
      />
    },
    {
      key: "customers",
      widget: <ComplexStatisticsCard
        icon={<PersonAddIcon fontSize="large" />}
        title={t("Customers")}
        count={customers.length}
        loading={loadingCustomers}
        percentage={{
          color: "success",
          amount: "",
          label: `${t('Total_subuser_accounts')} ${customers.reduce((acc, item) => acc += item.subusers.length, 0)}`,
        }}
      />
    },
    {
      key: "VOD",
      widget: <ComplexStatisticsCard
        color="primary"
        icon={<MovieIcon fontSize='large' />}
        loading={loadingVods}
        title={t("VODs")}
        count={VODsList.length}
        percentage={{
          color: "success",
          amount: "",
          label: `${t('Total_active_VODs')} ${VODsList.filter(item => item.isActive).length}`,
          onRefresh: () => dispatch(getVOD())
        }}
      />
    },
    {
      key: "revenue",
      widget: <ComplexStatisticsCard
        color="success"
        icon={<LeaderboardIcon fontSize="large" />}
        title={t("Revenue_this_month")}
        loading={loadingBilling}
        count={'+' + addThousandSeparator(billingHistory.reduce((acc, item) => { acc += +item.amount; return acc }, 0))}
        percentage={{
          color: "success",
          amount: "",
          label: `${t('Total_payments_count')} ${billingHistory.length}`,
          onRefresh: () => dispatch(getBilling())
        }}
      />
    },
  ]

  const mediumWidgets = [
    {
      key: "MonthlyActiveUsers",
      widget: <ReportsLineChart
        color="info"
        title={t("monthly_active_users")}
        description=""
        withUpdateTime={true}
        chart={activeUsersChartData}
        onRefresh={() => dispatch(getActiveUsers())}
      />
    },
    {
      key: "MonthlyPayments",
      widget: <ReportsLineChart
        color="success"
        title={t("Monthly_payments")}
        description={''}
        withUpdateTime={true}
        chart={paymentChartData}
        onRefresh={() => dispatch(getBilling())}
      />
    },
    {
      key: "UserAccountsMonthly",
      widget: <ReportsLineChart
        color="dark"
        title={t("Monthly_User_Accounts")}
        description=""
        withUpdateTime={true}
        chart={usersChartData}
        onRefresh={() => dispatch(getCustomersByMonth())}
      />
    },
    {
      key: "TotalUserAccounts",
      widget: <ReportsLineChart
        color="dark"
        title={t("Total_User_Accounts")}
        description=""
        withUpdateTime={true}
        chart={allUsersChartData}
        onRefresh={() => dispatch(getCustomersByMonth())}
      />
    },

    {
      key: "MonthlyActiveDevices",
      widget: <ReportsLineChart
        color="info"
        title={t("daily_active_devices")}
        description=""
        withUpdateTime={true}
        chart={activeDevicesChartData}
        onRefresh={() => dispatch(getActiveDevices())}
      />
    },
    {
      key: "MonthlyActiveDevicesAll",
      widget: <ReportsLineChart
        color="info"
        title={t("monthly_active_devices")}
        description=""
        withUpdateTime={true} 
        chart={activeDevicesChartDataByMonths}
        onRefresh={() => dispatch(getActiveDevices())}
      />
    },

  ]

  const largeWidgets = [
    {
      key: "MostViewedChannels",
      widget: <ReportsBarChart
        color="dark"
        title={t("Total_watched_channels")}
        description=""
        withUpdateTime={true}
        withSign={'%'}
        chart={totalWatchedChannels}
        onRefresh={() => getChannelsPercents()}
        rightComponent={
          <WithPermission disable={true} permissionsList={[ViewPermission]} permissionKey={'channels'} >
            <MDButton
              disabled={!checkPermission([ViewPermission], 'channels')}
              color="success"
              variant="gradient"
              onClick={() => setOpenWatchHistory(true)}
            >
              {t('view_all')}
            </MDButton>
          </WithPermission>
        }
      />
    },
    {
      key: "MostValuableUsers",
      widget: <ReportsBarChart
        color="dark"
        title={t("Total_users_usage")}
        description=""
        withUpdateTime={true}
        withSign={'%'}
        chart={mostValuableUsers}
        onRefresh={() => getUsersWatchtimePercents()}
        rightComponent={
          <WithPermission disable={true} permissionsList={[ViewPermission]} permissionKey={'channels'} >
            <MDButton
              disabled={!checkPermission([ViewPermission], 'channels')}
              color="success"
              variant="gradient"
              onClick={() => setOpenUsersWatchHistory(true)}
            >
              {t('view_all')}
            </MDButton>
          </WithPermission>
        }
      />
    },

  ]

  const hostcardWidgets = [
    ...transcoders?.map(item => ({
      key: item._id,
      widget: <HostCard
        widget
        widgetToAdd={!visibleDashboardWidgets.includes(item._id)}
        permissionKey='transcoder'
        type='Transcoder'
        id={item._id}
        host={item.url}
        name={item.name}
        systemInfo={item}
        actionText={`${t("View")} ${t("Transcodes")}`}
      />
    })),
    ...streamers?.map(item => ({
      key: item._id,
      widget: <HostCard
        widget
        widgetToAdd={!visibleDashboardWidgets.includes(item._id)}
        permissionKey={'streamer'}
        type='Streamer'
        id={item._id}
        host={item.url}
        name={item.name}
        systemInfo={item}
        actionText={t('View') + " " + t('Streams')}
      />
    })),
    ...archivers?.map(item => ({
      key: item._id,
      widget: <HostCard
        widget
        widgetToAdd={!visibleDashboardWidgets.includes(item._id)}
        permissionKey={'archiver'}
        type='Archiver'
        id={item._id}
        host={item.url}
        name={item.name}
        systemInfo={item}
        actionText={`${t("View")} ${t("Archives")}`}
      />
    })),
    ...VODs?.map(item => ({
      key: item._id,
      widget: <HostCard
        widget
        widgetToAdd={!visibleDashboardWidgets.includes(item._id)}
        permissionKey={'VOD'}
        type='VODs'
        id={item._id}
        host={item.url}
        name={item.name}
        systemInfo={item}
        actionText={`${t("View")} VOD`}
      />
    })),
  ]



  const handleRemoveWidget = (key) => {
    setVisibleDashboardWidgets(prev => prev.filter(item => item !== key))
  }

  const handleAddWidget = (key) => {
    setVisibleDashboardWidgets(prev => [...prev, key])
  }

  const handleEditMode = () => {
    if (editMode) {
      dispatch(updateAdminWdigets(adminId, { visibleDashboardWidgets }))
    }

    setEditMode(prev => !prev)
  }

  const haveWidgetsAvailable = useMemo(() => {
    return [
      ...smallWidgets.filter(item => !visibleDashboardWidgets.includes(item.key)),
      ...mediumWidgets.filter(item => !visibleDashboardWidgets.includes(item.key)),
      ...largeWidgets.filter(item => !visibleDashboardWidgets.includes(item.key)),
      ...hostcardWidgets.filter(item => !visibleDashboardWidgets.includes(item.key)),
    ].length > 0
  }, [
    smallWidgets,
    visibleDashboardWidgets,
    mediumWidgets,
    largeWidgets,
    hostcardWidgets,
  ])
  if (adminsData.loading) {
    return (
      <DashboardLayout>
        <Grid container spacing={2}>
          <Grid item xs={12} md={editMode ? 9 : 12}>
            <MDBox py={3}>
              <Grid container spacing={3}>
                {(new Array(4)).fill(null).map((item, index) => (
                  <Grid key={index} item xs={12} md={6} lg={3}>
                    <MDBox mb={1.5} sx={{ position: "relative" }}>
                      <Skeleton variant="rect" width={"100%"} height={136} sx={{ borderRadius: 5 }} />
                    </MDBox>
                  </Grid>
                ))}
              </Grid>
              <MDBox mt={4.5}>
                <Grid container spacing={3}>
                  {(new Array(4)).fill(null).map((item, index) => (
                    <Grid key={index} item xs={12} md={6} lg={6}>
                      <MDBox mb={1.5} sx={{ position: "relative" }}>
                        <Skeleton variant="rect" width={"100%"} height={323} sx={{ borderRadius: 5 }} />
                      </MDBox>
                    </Grid>
                  ))}
                  {(new Array(4)).fill(null).map((item, index) => (
                    <Grid key={index} item xs={12}>
                      <MDBox mb={1.5} sx={{ position: "relative" }}>
                        <Skeleton variant="rect" width={"100%"} height={323} sx={{ borderRadius: 5 }} />
                      </MDBox>
                    </Grid>
                  ))}
                </Grid>
              </MDBox>
            </MDBox>

          </Grid>  
        </Grid>
      </DashboardLayout >
    )
  }

  return (
    <DashboardLayout>
      <Grid container spacing={2}>
        <Grid item xs={12} md={editMode ? 9 : 12}>
          <MDBox py={3}>
            <SystemAnalyzeResult />
            <Grid container spacing={3}>
              {smallWidgets.filter(item => visibleDashboardWidgets.includes(item.key)).map(item => (
                <Grid key={item.key} item xs={12} md={6} lg={3}>
                  <MDBox mb={1.5} sx={{ position: "relative" }}>
                    {editMode && <IconButton onClick={() => handleRemoveWidget(item.key)} size="large" sx={{
                      position: "absolute",
                      top: -30,
                      right: -20,
                      zIndex: 100
                    }}>
                      <RemoveCircle fontSize="large" color="error" />
                    </IconButton>}
                    {item.widget}
                  </MDBox>
                </Grid>
              ))}
            </Grid>
            <MDBox mt={4.5}>
              <Grid container spacing={3}>
                {mediumWidgets.filter(item => visibleDashboardWidgets.includes(item.key)).map(item => (
                  <Grid key={item.key} item xs={12} md={6} lg={6}>
                    <MDBox mb={1.5} sx={{ position: "relative" }}>
                      {editMode && <IconButton onClick={() => handleRemoveWidget(item.key)} size="large" sx={{
                        position: "absolute",
                        top: -30,
                        right: -20,
                        zIndex: 100
                      }}>
                        <RemoveCircle fontSize="large" color="error" />
                      </IconButton>}
                      {item.widget}
                    </MDBox>
                  </Grid>
                ))}
                {largeWidgets.filter(item => visibleDashboardWidgets.includes(item.key)).map(item => (
                  <Grid key={item.key} item xs={12}>
                    <MDBox mb={1.5} sx={{ position: "relative" }}>
                      {editMode && <IconButton onClick={() => handleRemoveWidget(item.key)} size="large" sx={{
                        position: "absolute",
                        top: -30,
                        right: -20,
                        zIndex: 100
                      }}>
                        <RemoveCircle fontSize="large" color="error" />
                      </IconButton>}
                      {item.widget}
                    </MDBox>
                  </Grid>
                ))}
                {hostcardWidgets.filter(item => visibleDashboardWidgets.includes(item.key)).map(item => (
                  <Grid key={item.key} item xs={12}>
                    <MDBox mb={1.5} sx={{ position: "relative" }}>
                      {editMode && <IconButton onClick={() => handleRemoveWidget(item.key)} size="large" sx={{
                        position: "absolute",
                        top: -30,
                        right: -20,
                        zIndex: 100
                      }}>
                        <RemoveCircle fontSize="large" color="error" />
                      </IconButton>}
                      {item.widget}
                    </MDBox>
                  </Grid>
                ))}
              </Grid>
            </MDBox>
            {/* <MDBox>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={8}>
              <Projects />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <OrdersOverview />
            </Grid>
          </Grid>
        </MDBox> */}
          </MDBox>
          <TemporaryDrawer
            paperStyles={"drawerInfoPanel"}
            open={openWatchHistory}
            setOpen={setOpenWatchHistory}
          >
            {openWatchHistory && <WatchHistory list={channels} />}
          </TemporaryDrawer>
          <TemporaryDrawer
            paperStyles={"drawerInfoPanel"}
            open={openUsersWatchHistory}
            setOpen={setOpenUsersWatchHistory}
          >
            {openUsersWatchHistory && <UsersWatchHistory list={customers} />}
          </TemporaryDrawer>

        </Grid>
        {editMode && <Grid item xs={12} md={3} >
          <Paper elevation={10} sx={{ p: 2, pt: 5, position: "sticky", top: 100, backgroundColor: "ButtonShadow", height: "calc(100vh - 120px)", overflowY: "auto", overflowX: "hidden" }}>
            <Grid container spacing={3}>
              {smallWidgets.filter(item => !visibleDashboardWidgets.includes(item.key)).map(item => (
                <Grid key={item.key} item xs={12}>
                  <MDBox mb={1.5} sx={{ position: "relative" }}>
                    <IconButton onClick={() => handleAddWidget(item.key)} size="large" sx={{
                      position: "absolute",
                      top: -30,
                      right: -20,
                      zIndex: 100
                    }}>
                      <AddCircle fontSize="large" color="success" />
                    </IconButton>
                    {item.widget}
                  </MDBox>
                </Grid>
              ))}
              {mediumWidgets.filter(item => !visibleDashboardWidgets.includes(item.key)).map(item => (
                <Grid key={item.key} item xs={12}>
                  <MDBox mb={1.5} sx={{ position: "relative" }}>
                    <IconButton onClick={() => handleAddWidget(item.key)} color="success" size="large" sx={{
                      position: "absolute",
                      top: -30,
                      right: -20,
                      zIndex: 100
                    }} >
                      <AddCircle fontSize="large" color="success" />
                    </IconButton>
                    {item.widget}
                  </MDBox>
                </Grid>
              ))}
              {largeWidgets.filter(item => !visibleDashboardWidgets.includes(item.key)).map(item => (
                <Grid key={item.key} item xs={12}>
                  <MDBox mb={1.5} sx={{ position: "relative" }}>
                    <IconButton onClick={() => handleAddWidget(item.key)} size="large" sx={{
                      position: "absolute",
                      top: -30,
                      right: -20,
                      zIndex: 100
                    }} >
                      <AddCircle fontSize="large" color="success" />
                    </IconButton>
                    {item.widget}
                  </MDBox>
                </Grid>
              ))}
              {hostcardWidgets.filter(item => !visibleDashboardWidgets.includes(item.key)).map(item => (
                <Grid key={item.key} item xs={12}>
                  <MDBox mb={1.5} sx={{ position: "relative" }}>
                    <IconButton onClick={() => handleAddWidget(item.key)} size="large" sx={{
                      position: "absolute",
                      top: -30,
                      right: -20,
                      zIndex: 100
                    }} >
                      <AddCircle fontSize="large" color="success" />
                    </IconButton>
                    {item.widget}
                  </MDBox>
                </Grid>
              ))}
              {!haveWidgetsAvailable && <Grid item xs={12}>
                <MDBox sx={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }} >
                  <ErrorIcon fontSize="large" />
                  <MDTypography sx={{ textAlign: "center" }}> {t("AllWidgetsAdded")} </MDTypography>
                </MDBox>
              </Grid>}
            </Grid>
          </Paper>
        </Grid>}
      </Grid>
      <MDBox sx={{
        position: "fixed",
        bottom: 10,
        right: 10
      }}>
        <Tooltip title={t(editMode ? "SaveWidgets" : "EditWidgets")} >
          <Fab onClick={handleEditMode}>
            {editMode ? <Check color="success" fontSize="large" /> : <Edit color="action" fontSize="large" />}
          </Fab>
        </Tooltip>
      </MDBox>
    </DashboardLayout >
  );
}

export default Dashboard;
