import { FormControlLabel, Grid, Switch, TextField } from '@mui/material'
import MDBox from 'components/MDBox'
import MDDialog from 'components/MDDialog'
import MDInput from 'components/MDInput'
import { useFormik } from 'formik'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import MDSelectMui from "components/MDSelectMui";
import { useSelector } from 'react-redux'
import MobileModal from './mobileModal'
import { R_saveNotification } from 'api/Notifications/service'
import * as yup from "yup";

const schema = yup.object({
    message: yup.string().required('field is required'),
    title: yup.string().required('field is required').max(15),
  });

export default function AddNotificationsModal({ isOpen, setOpenModal, R_GetAdminNotifications }) {
    const { t } = useTranslation()
    const [allCustomers, setAllCustomers] = useState(true)
    const customers = useSelector((store) => store?.customer?.list);
    const [loading, setLoading] = useState(false)
    const initValues = {
        title: "",
        message: "",
        confirmText: "",
        cancelText: "",
        selectedCustomers: []
    };
    const { values, handleChange, touched, errors, handleSubmit, setFieldValue, setErrors } = useFormik({
        initialValues: initValues,
        validationSchema: schema,
        onSubmit: async (values) => {
            await handleSave(values)
            setOpenModal({ bool: false });
        },
    });

    const handleSelectCustomers = (e, newVal) => {
        setFieldValue("selectedCustomers", newVal)
    }

   async function handleSave(values) {
        try {
            setLoading(true)
            await R_saveNotification({ ...values, selectedCustomers: allCustomers ? customers.map(item => item._id) : values.selectedCustomers.map(item => item._id) })
            R_GetAdminNotifications()
            setLoading(false)
        } catch (error) {
            setLoading(false)
        }
    }

    return (
        <MDDialog
            maxWidth="md"
            fullWidth
            dialogHeight="fit-content"
            open={isOpen.bool}
            setOpen={setOpenModal}
            onSave={handleSubmit}
            loading={loading}
        >
            <MDBox>
                <Grid container spacing={2}>
                    <Grid item xs={6} rowSpacing={2} container>
                        
                        <Grid item xs={12}>
                        {t('Title')}:
                            <MDInput
                                fullWidth
                                value={values.title}
                                id="title"
                                name="title"
                                error={touched.title && errors.title}
                                helperText={touched.title && errors.title}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            {t('message')}:
                            <TextField
                                multiline
                                rows={10}
                                fullWidth
                                value={values.message}
                                id="message"
                                name="message"
                                error={touched.message && errors.message}
                                helperText={touched.message && errors.message}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            {t('confirmText')}:
                            <MDInput
                                fullWidth
                                value={values.confirmText}
                                id="confirmText"
                                name="confirmText"
                                error={touched.confirmText && errors.confirmText}
                                helperText={touched.confirmText && errors.confirmText}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            {t('cancelText')}:
                            <MDInput
                                fullWidth
                                value={values.cancelText}
                                id="cancelText"
                                name="cancelText"
                                error={touched.cancelText && errors.cancelText}
                                helperText={touched.cancelText && errors.cancelText}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            {t('Customers')}:
                            <MDSelectMui
                                disabled={allCustomers}
                                size="small"
                                name="customers"
                                id="customers"
                                valueKey={"_id"}
                                labelKey={"email"}
                                multiple={true}
                                value={values.customers}
                                onChange={handleSelectCustomers}
                                options={customers}
                                withChip
                            />
                            <FormControlLabel control={<Switch checked={allCustomers} onChange={() => setAllCustomers(prev => !prev)} />} label={t('notifyAllCustomers')} />

                        </Grid>
                    </Grid>
                    <Grid item xs={6}>
                        <MobileModal
                            headerText={values.title}
                            cancelText={values.cancelText}
                            saveText={values.confirmText}
                        >
                            {values.message}
                        </MobileModal>
                    </Grid>
                </Grid>
            </MDBox>
        </MDDialog>
    )
}
