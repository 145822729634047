import { PlayCircle } from "@mui/icons-material";
import { Grid, IconButton } from "@mui/material";
import MDAvatar from "components/MDAvatar";
import MDBox from "components/MDBox";
import MDDialog from "components/MDDialog";
import ModalPlayer from "components/playerModal";
import WithPermission from "components/withPermission/WithPermission";
import { PlayPermission } from "pages/settings/Admins/constants";
import React, { useState } from "react";

export default function ChannelsModal({ openModal, setOpenModal }) {
  const channels = openModal?.channels;
  const [openPlayerModal, setOpenPlayerModal] = useState({ bool: false });


  function handleOpenPlayerModal(item) {
    setOpenPlayerModal({
      bool: true,
      modalValues: { url: item?.url ?? "" },
    });
  }

  return (
    <MDDialog
      maxWidth="md"
      fullWidth
      dialogHeight="30vh"
      open={openModal?.bool}
      setOpen={setOpenModal}
      onClose={() => {
        setOpenModal({ bool: false });
      }}
    >
      <MDBox>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <MDBox sx={{ textAlign: 'center', fontWeight: 'bold', marginBottom: '20px' }}>
              Channels ({channels?.length})
            </MDBox>
          </Grid>
          {!!channels?.length ?
            channels.map(item => (
              <Grid item xs={4}>
                <MDBox sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", borderBottom: '1px solid lightgrey' }}>
                  <MDAvatar
                    src={`${process.env.REACT_APP_BASE_ORIGIN}/img/channels/${item?.image}`}
                    name={item.email}
                    size="sm"
                  />
                  {item.name}
                  <WithPermission permissionsList={[PlayPermission]} permissionKey={'channels'} >
                    <IconButton onClick={() => handleOpenPlayerModal(item)} color="success">
                      <PlayCircle />
                    </IconButton>
                  </WithPermission>
                </MDBox>
              </Grid>
            ))
            :
            <MDBox sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', fontWeight: 'bold', paddingLeft: '20px', marginBottom: '20px', width: '100%', height: '100%' }}>
              No channels
            </MDBox>

          }
        </Grid>
      </MDBox>
      {openPlayerModal.bool && (
        <ModalPlayer
          openModal={openPlayerModal}
          setOpenModal={setOpenPlayerModal}
        />
      )}
    </MDDialog>
  );
}
