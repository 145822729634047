import { UPDATE_SETTINGS_CATEGORIES } from "redux/types";
import { UPDATE_SETTINGS_DEFAULT_COMMANDS } from "redux/types";
import { UPDATE_SETTINGS_RESOLUTIONS } from "redux/types";
import { UPDATE_SETTINGS } from "redux/types";
import { GET_SETTINGS } from "redux/types";

const defaults = {
  loading: false,
  isFetched: false,

  settings: {
    categoriesVOD: [],
    categoriesChannels: [],
    license: null,
    defaults: {
      transcoderCommand: `/usr/local/bin/{{Name}} \n -nostats \n -hide_banner \n -hwaccel_device 0 \n -frame_drop_threshold -1.1 \n -hwaccel cuda \n -hwaccel_output_format cuda \n -deint 2 \n -drop_second_field 1 \n -i '{{INPUT}}' -y \n \n-map 0:v:0 \n -map 0:a:0 \n -vf scale_npp='{{RESOLUTION}}' \n -c:v h264_nvenc -g 25 \n -preset fast \n -profile:v high \n  -c:a copy \n -level 4.0 \n -colorspace bt709 \n -color_trc bt709 \n -color_primaries bt709 \n -color_range tv \n -b:v {{BITRATE}} \n -maxrate {{MAXRATE}} \n -minrate {{MINRATE}} \n -bufsize {{BUFSIZE}} \n -sn -start_number 1 \n -hls_time 4 \n -hls_list_size 30 \n -hls_flags delete_segments \n -f hls {{OUTPUT}} \n `,
      archiverCommand: `/usr/bin/{{Name}} -y \n -i {{INPUT}} \n -codec copy \n -map 0 \n -metadata service_name="{{NAME}}" \n -hls_time {{HLSTIME}} \n -hls_flags discont_start+split_by_time \n -hls_segment_type mpegts \n -hls_playlist_type event \n -t 01:00:00 \n -f hls {{OUTPUT}}`,
      streamerCommand: `/usr/bin/{{Name}} -y \n {{INPUTS}} \n {{MAPPING}} \n -g 25 \n {{BITRATES}} \n {{AUDIO}} \n -f hls -var_stream_map "{{STREAM_MAPPING}}" \n -master_pl_name "master.m3u8" \n -hls_time 2 \n -hls_list_size 50 \n -hls_flags delete_segments \n -f hls {{OUTPUT}}`,
    },
    resolutions: [
      { resolution: '1920:1080', bitrate: '5M', maxRate: '5.5M', minRate: '4M', bufSize: '6M', id: 1, name: '1080p' },
      { resolution: '720:576', bitrate: '2.5M', maxRate: '3M', minRate: '2M', bufSize: '4M', id: 0, name: '720p' },
      { resolution: '640:480', bitrate: '1M', maxRate: '2M', minRate: '500K', bufSize: '3M', id: 2, name: '480p' },
      { resolution: '320:240', bitrate: '500K', maxRate: '1M', minRate: '250K', bufSize: '2M', id: 3, name: '240p' },
    ]
  },
};

export default (state = defaults, action) => {
  switch (action.type) {
    case GET_SETTINGS:
      return { ...state, ...action.payload };
    case UPDATE_SETTINGS:
      return { ...state, ...action.payload };
    case UPDATE_SETTINGS_CATEGORIES:
      return { ...state, ...action.payload.state, settings: { ...state.settings, ...action.payload.settings }, };
    case UPDATE_SETTINGS_RESOLUTIONS:
      return { ...state, ...action.payload.state, settings: { ...state.settings, ...action.payload.settings }, };
    case UPDATE_SETTINGS_DEFAULT_COMMANDS:
      return { ...state, ...action.payload.state, settings: { ...state.settings, ...action.payload.settings }, };
      
    default:
      return state;
  }
};
