import { GET_STREAMERS, UPDATE_STREAM_STATUS } from "redux/types";

const defaults = {
  loading: false,
  list: [],
};

export default (state = defaults, action) => {
  switch (action.type) {
    case GET_STREAMERS:
      return { ...state, ...action.payload };
    case UPDATE_STREAM_STATUS: 
      return { ...state, activeStreams: action.payload }
    default:
      return state;
  }
};
