import { Add } from '@mui/icons-material'
import { Card, Grid, IconButton, Tooltip } from '@mui/material'
import { api } from 'api/config'
import MDBox from 'components/MDBox'
import MDButton from 'components/MDButton'
import MDTypography from 'components/MDTypography'
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout'
import DataTable from 'examples/Tables/DataTable'
import React, { useEffect, useReducer, useState } from 'react'
import { Delete, Edit } from "@mui/icons-material";
import SyncIcon from '@mui/icons-material/Sync';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox';
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { deleteEPG } from 'redux/actions/epg'
import { updateEPG } from 'redux/actions/epg'
import { createEPG } from 'redux/actions/epg'
import { getEPGs } from 'redux/actions/epg'
import { getSingleItemByAnOption } from 'utils/helpers'
import Modal from './modal'
import { ClipLoader } from 'react-spinners'
import { updateCrons } from 'redux/actions/epg'
import { EditPermission } from 'pages/settings/Admins/constants'
import WithPermission from 'components/withPermission/WithPermission'
import { DeletePermission } from 'pages/settings/Admins/constants'
import { RefreshPermission } from 'pages/settings/Admins/constants'
import { AddPermission } from 'pages/settings/Admins/constants'
import { checkPermission } from 'utils/checkPermission'

export default function Epg() {
    const dispatch = useDispatch()
    const { t } = useTranslation()
    const epgs = useSelector(store => store?.epg?.list)
    const loading = useSelector(store => store?.epg?.loading)
    const activeCrons = useSelector(store => store?.epg?.activeCrons)

    const [openModal, setOpenModal] = useState({ bool: false });
    const [apiLoadingId, dispatcher] = useReducer((state, action) => {
        switch (action.type) {
            case 'add':
                return [...state, action.id]
            case 'remove':
                return state.filter(item => item !== action.id)
            default:
                throw new Error()
        }
    }, [])

    useEffect(() => {
        dispatch(getEPGs())
    }, [])

    const handleCreateEPG = async (values) => {
        dispatch(createEPG(values))
    }

    function handleUpdateEPG(values) {
        dispatch(updateEPG(this._id, values))
    }

    const handleDelete = (id) => {
        dispatch(deleteEPG(id))
    }

    const handleOpenEPGModal = (item, isUpdate) => {

        setOpenModal({
            bool: true,
            onSave: isUpdate ? handleUpdateEPG.bind(item) : handleCreateEPG,
            modalValues: item,
        });
    }

    const handleFetchEPG = async (id) => {
        dispatcher({ type: 'add', id })
        dispatch(updateCrons(id, () => dispatcher({ type: 'remove', id })))
    }

    const handleFullListUpdate = () => {
        const ids = epgs.map(item => handleFetchEPG(item._id))
        Promise.all(ids)
    }

    const columns = [
        { Header: t("Source"), accessor: "Source", align: "left" },
        { Header: t("Interval"), accessor: "Interval", align: "left" },
        { Header: t("action"), accessor: "action", align: "center" },
    ];

    const rows = epgs?.map((item) => {
        const isCronActive = activeCrons?.includes(item._id)
        const isLoading = apiLoadingId.includes(item._id)
        return {
            Source: item.source,
            Interval: item?.interval,
            action: (
                <MDBox>
                    <WithPermission permissionsList={[EditPermission]} permissionKey={'EPG'} >
                        <IconButton
                            disabled={isLoading || !checkPermission([EditPermission], 'EPG')}
                            onClick={() => {
                                handleOpenEPGModal(item, true);
                            }}
                        >
                            <Edit />
                        </IconButton>
                    </WithPermission>
                    <WithPermission permissionsList={[DeletePermission]} permissionKey={'EPG'} >
                        <Tooltip title={t('dblClick')} >
                            <IconButton
                                disabled={isLoading || !checkPermission([DeletePermission], 'EPG')}
                                onDoubleClick={() => {
                                    confirm(`${t('askDelete')}`) && handleDelete(item._id);
                                }}
                            >
                                <Delete />
                            </IconButton>
                        </Tooltip>
                    </WithPermission>
                    <WithPermission permissionsList={[RefreshPermission]} permissionKey={'EPG'} >
                        <Tooltip title={isLoading ? t('loading') : t('updateNow')} >
                            <IconButton
                            disabled={isLoading || !checkPermission([RefreshPermission], 'EPG')}
                                onClick={() => {
                                    !isLoading && handleFetchEPG(item._id);
                                }}
                            >
                                {isLoading ? <ClipLoader color='#338BED' size={20} /> : <SyncIcon />}
                            </IconButton>
                        </Tooltip>
                    </WithPermission>
                    <Tooltip title={isCronActive ? t('activeCron') : t('inactiveCron')} >
                        <IconButton>
                            {isCronActive ? <CheckBoxIcon sx={{ color: 'success.main' }} /> : <IndeterminateCheckBoxIcon sx={{ color: 'error.main' }} />}
                        </IconButton>
                    </Tooltip>
                </MDBox>
            ),
        }
    });

    return (
        <DashboardLayout>
            <MDBox pt={6} pb={3}>
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Card>
                            <MDBox
                                mx={2}
                                mt={-3}
                                py={3}
                                px={2}
                                sx={{ display: "flex", justifyContent: "space-between" }}
                                variant="gradient"
                                bgColor="info"
                                borderRadius="lg"
                                coloredShadow="info"
                            >
                                <MDTypography variant="h6" color="white">
                                    {t("epg.header")}
                                </MDTypography>
                                <WithPermission permissionsList={[AddPermission]} permissionKey={'EPG'} >
                                    <MDButton disabled={!checkPermission([AddPermission], 'EPG')} onClick={() => { handleOpenEPGModal() }} withIcon={<Add />} loading={loading}>
                                        {t("epg.addNew")}
                                    </MDButton>
                                </WithPermission>
                            </MDBox>
                            <MDBox pt={3}>
                                <MDBox sx={{ display: 'flex', justifyContent: 'flex-end', padding: '0px 30px' }} >
                                    <MDButton onClick={handleFullListUpdate} variant='contained' sx={{ color: 'info.main', border: '1px solid' }} >{t('epg.updateFullList')}</MDButton>
                                </MDBox>
                                {rows && (
                                    <DataTable
                                        table={{ columns, rows }}
                                        isSorted={false}
                                        showTotalEntries={true}
                                        noEndBorder
                                    />
                                )}
                            </MDBox>
                        </Card>
                    </Grid>
                </Grid>
            </MDBox>
           {openModal.bool && <Modal
                openModal={openModal}
                setOpenModal={setOpenModal}
            />}
        </DashboardLayout>
    )
}
