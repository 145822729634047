import { Grid } from "@mui/material";
import MDBox from "components/MDBox";
import MDDialog from "components/MDDialog";
import MDInput from "components/MDInput";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getTariffs } from "redux/actions/tariffs";
import { api } from "api/config";
import { getCustomers } from "redux/actions/customers";
import { addNotification } from "redux/actions/notifications";
import { useTranslation } from "react-i18next";

export default function Modal({ openModal, setOpenModal }) {
  const { t } = useTranslation()
  const dispatch = useDispatch();
  const tariffs = useSelector((store) => store?.tariffs?.list);
  const users = useSelector((store) => store?.customer?.list);
  const [pay, setPay] = useState()
  const [userTariff, setUserTariff] = useState()
  const [user, setUser] = useState()
  const [errorPay, setErrorPay] = useState(false)
  const [errorPayText, setErrorPayText] = useState('')

  useEffect(() => {
    if (!openModal?.user?.tariff?.[0]) return
    const userTariff = tariffs?.find(item => item._id === openModal?.user?.tariff[0])
    if (userTariff) {
      setUserTariff(userTariff)
    }else{
      setUserTariff({})
    }
    if (openModal.user) {
      const currentUser = users?.find(item=>item._id === openModal.user._id)
      if(currentUser){
        setUser({...openModal.user, ...currentUser})
      }else{
        setUser({})
      }
    }
  }, [openModal, tariffs, users])

  useEffect(() => {
    if (!tariffs.length) {
      dispatch(getTariffs());
    }
  }, []);

  const handlePayChange = (e) => {
    if (`${e.target.value}`.includes('.')) return
    setPay(e.target.value)
  }

  const handleSubmit = async () => {
    if(!errorPay && !errorPayText){
      try {
        const res = await api.patch(`/users/${user._id}/payment`, {pay})
        dispatch(getCustomers())
        setPay(0)
        dispatch(addNotification({
          color: 'success',
          icon: 'success',
          title: 'Payment successfully',
          content: 'Payment successfully created',
          dateTime: Date.now(),
        }))
      } catch (error) {
        console.log({error: error.toJSON()});
        dispatch(addNotification({
          color: 'error',
          icon: 'error',
          title: 'An error occuired',
          content: error.errorMsg || error.message,
          dateTime: Date.now(),
        }))
      }
    }
  }


  return (
    <MDDialog
      maxWidth="xs"
      fullWidth
      dialogHeight="fit-content"
      open={openModal?.bool}
      setOpen={setOpenModal}
      onSave={handleSubmit}
    >
      <MDBox>
        <div style={{ display: 'flex', gap: '10px', flexDirection: 'column', marginBottom: '10px' }} >
          <h5>{`${user?.firstName} ${user?.lastName}`}</h5>
          <h5>{`${user?.email}`}</h5>
          <h5>{`${t('Price')}: ${userTariff?.price}`}</h5>
          <h5>{`${t('Type')}: ${userTariff?.billType}`}</h5>
        </div>

        <Grid rowSpacing={2} container>

          <Grid item xs={3}>
            <h5>{t('Amount')}:</h5>
          </Grid>
          <Grid item xs={9}>
            <MDInput
              fullWidth
              value={pay}
              id="Pay"
              type="number"
              name="Pay"
              error={errorPay}
              helperText={errorPayText}
              onChange={handlePayChange}
            />
            <h5 style={{ float: 'right' }} >{`${t('Balance')}: ${user?.balance}`}</h5>

          </Grid>
        </Grid>
      </MDBox>
    </MDDialog>
  );
}
