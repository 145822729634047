import { Box, Grid, IconButton, InputAdornment, TextField, Tooltip, Typography } from "@mui/material";
import PropTypes from 'prop-types';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import MDBox from "components/MDBox";
import MDDialog from "components/MDDialog";
import MDInput from "components/MDInput";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getTariffs } from "redux/actions/tariffs";
import DividerMui from "components/dividerMui/dividerMui";
import { Tab, Tabs } from '@mui/material';
import { useTranslation } from "react-i18next";
import Tariff from "./tariff";
import Subusers from "./subusers";
import Payments from "./payments";
import ChannelsWatched from "./channelsWatched";
import UsageHistory from "./usageHistory";
import ImageWithFallback from "components/ImgWithFallback";
import Access from "./Access";
import { updateCustomer } from "redux/actions/customers";
import { debounce } from "utils/helpers";
import MainLoader from "components/loader/loader";
import { Check } from "@mui/icons-material";



export default function ProfileModal({ openModal, setOpenModal }) {
    const { t } = useTranslation()
    const dispatch = useDispatch();
    const tariffs = useSelector((store) => store?.tariffs?.list);
    const initValues = {
        email: openModal?.user?.email ?? "",
        firstName: openModal?.user?.firstName ?? "",
        lastName: openModal?.user?.lastName ?? "",
        tariff: openModal?.user?.tariff ?? [],
        image: openModal?.user?.image,
        password: "",
        passwordConfirm: "",
        maxSubusersCount: openModal?.user?.maxSubusersCount ?? 3,
        note: openModal?.user?.note || ""
    };
    const [tabValue, setTabValue] = React.useState(0);
    const [openDropzone, setOpenDropzone] = React.useState(false);
    const [noteLoading, setNoteLoading] = React.useState(false);
    const [note, setNote] = useState(openModal?.user?.note || "")
    const debouncedNoteUpdate = useCallback(debounce((e) => dispatch(updateCustomer(openModal?.user?._id, { note: e }, () => setNoteLoading("saved"), true)), 600), [openModal?.user?._id])


    useEffect(() => {
        setNote(openModal?.user?.note || '')
        setNoteLoading(false)
        return () => {
            setNote('')
            setNoteLoading(false)
        }
    }, [openModal])

    const handleNoteChange = (e) => {
        if (openModal?.user?._id) {
            setNoteLoading(true)
            setNote(e.target.value)
            debouncedNoteUpdate(e.target.value)
        }
    }

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    const handleDropzoneOpen = () => {
        setOpenDropzone(true);
    };

    const handleDropzoneClose = () => {
        setOpenDropzone(false);
    };


    useEffect(() => {
        if (!tariffs.length) {
            dispatch(getTariffs());
        }
    }, []);


    return (
        <MDDialog
            maxWidth="lg"
            fullWidth
            dialogHeight="fit-content"
            open={openModal?.bool}
            setOpen={setOpenModal}
        >
            <MDBox sx={{ height: '70vh' }} my={1}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={4} sx={{ display: 'flex', flexDirection: 'column', position: 'relative' }} >
                        <MDBox sx={{ position: 'absolute', top: '15px', right: '20px' }}>
                            <Tooltip title={openModal?.user?.isOnline ? t('Online') : t('Offline')} >
                                <IconButton>
                                    {openModal?.user?.isOnline ? <FiberManualRecordIcon sx={{ color: 'success.main' }} /> : <FiberManualRecordIcon sx={{ color: 'error.main' }} />}
                                </IconButton>
                            </Tooltip>
                        </MDBox>
                        <ImageWithFallback
                            alt={'channel-img'}
                            style={{ width: 'auto', height: 'auto', maxHeight: "150px", margin: '20px auto' }}
                            src={`${process.env.REACT_APP_BASE_ORIGIN}/img/users/${initValues.image}`}
                        />
                        {/* <MDButton onClick={() => { setOpenDropZone(true) }} mt={2} variant="gradient" color="secondary">
                            Upload image
                        </MDButton> */}
                    </Grid>
                    <Grid item xs={12} md={8} container spacing={1} >
                        <Grid xs={12} >
                            <DividerMui text={'Customer Info'} />
                        </Grid>
                        {openModal?.user && [
                            { key: 'firstName', label: t('Firstname') },
                            { key: 'lastName', label: t('Lastname') },
                            { key: 'email', label: t('Email') },
                            { key: 'phone', label: t('phone') },
                            { key: 'tariff', label: t('Tariff') },
                            { key: 'maxSubusersCount', label: t('Max_Subusers') },
                            { key: 'balance', label: t('Balance') },
                            { key: 'lastPaymentDate', label: t('Last_Payment_Date') },
                            { key: 'createdAt', label: t('Created_At') },
                            { key: 'notes', label: t('Notes'), variant: "textArea" }
                        ]
                            .map(item => {
                                return <>
                                    {
                                        item.variant === "textArea" ? (
                                            <Grid item xs={12} key={item.key}>
                                                <TextField
                                                    multiline
                                                    color="success"
                                                    rows={3}
                                                    label={item.label}
                                                    fullWidth
                                                    onChange={handleNoteChange}
                                                    value={note || ""}
                                                    InputProps={{
                                                        endAdornment: <InputAdornment
                                                            position="start">{
                                                                noteLoading === "saved" ?
                                                                    <Check fontSize="20px" color="success" /> :
                                                                    noteLoading && <MainLoader size={20} />
                                                            }
                                                        </InputAdornment>,
                                                    }}

                                                />
                                            </Grid>
                                        ) : (
                                            <Grid item xs={4} key={item.key}>
                                                <MDInput
                                                    sx={{ margin: "0px" }}
                                                    label={item.label}
                                                    disabled
                                                    fullWidth
                                                    value={item.key === 'tariff' ? openModal?.userTariff?.name : item.key === 'createdAt' ? (typeof openModal?.user?.[item.key] === 'object' ? `${openModal?.user?.[item.key].year}/${openModal?.user?.[item.key].month}/${openModal?.user?.[item.key].day} ${openModal?.user?.[item.key].time}` : openModal?.user?.[item.key]) : openModal?.user?.[item.key]}
                                                />
                                            </Grid>
                                        )}
                                </>
                            })
                        }
                    </Grid>
                </Grid>
                <Tabs
                    variant="scrollable"
                    // aria-label="Vertical tabs example"
                    value={tabValue} onChange={handleTabChange}
                    sx={{
                        borderRight: 1, borderColor: 'divider', marginTop: '20px',
                        "& .MuiTabs-flexContainer": {
                            display: window.innerWidth < 600 ? "-webkit-box" : "flex"
                        }

                    }}
                >
                    <Tab sx={{ px: 2, }} label={t("Subusers")} {...a11yProps(0)} />
                    <Tab sx={{ px: 2, }} label={t("Tariff")} {...a11yProps(1)} />
                    <Tab sx={{ px: 2, }} label={t("Payments")} {...a11yProps(2)} />
                    <Tab sx={{ px: 2, }} label={t("accessibleDevices")} {...a11yProps(3)} />
                    <Tab sx={{ px: 2, }} label={t("ChannelsWatched")} {...a11yProps(4)} />
                    <Tab sx={{ px: 2, }} label={t("usageHistory")} {...a11yProps(5)} />
                    {/* <Tab label="Item Four" {...a11yProps(3)} />
                            <Tab label="Item Five" {...a11yProps(4)} />
                            <Tab label="Item Six" {...a11yProps(5)} />
                            <Tab label="Item Seven" {...a11yProps(6)} /> */}
                </Tabs>
                <TabPanel value={tabValue} index={0}>
                    {openModal?.user && <Subusers data={{ list: openModal?.user?.subusers, admin: openModal?.user }} />}
                </TabPanel>
                <TabPanel value={tabValue} index={1}>
                    {openModal?.userTariff && <Tariff tariff={openModal?.userTariff} />}
                </TabPanel>
                <TabPanel value={tabValue} index={2}>
                    {openModal?.user && <Payments data={openModal?.user} tariff={openModal?.userTariff} />}
                </TabPanel>
                <TabPanel value={tabValue} index={3}>
                    {openModal?.user && <Access user={openModal?.user} tariff={openModal?.userTariff} />}
                </TabPanel>
                <TabPanel value={tabValue} index={4}>
                    {openModal?.user && <ChannelsWatched user={openModal?.user} tariff={openModal?.userTariff} />}
                </TabPanel>
                <TabPanel value={tabValue} index={5}>
                    {openModal?.user && <UsageHistory data={openModal?.user} tariff={openModal?.userTariff} />}
                </TabPanel>
                {/* <TabPanel value={tabValue} index={6}>
                    {t('Item_Six')}
                </TabPanel>
                <TabPanel value={tabValue} index={6}>
                    {t('Item_Seven')}
                </TabPanel> */}

            </MDBox>
        </MDDialog>
    );
}



function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}
