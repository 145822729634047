import { api } from "api/config";
import { GET_USERS_BY_MONTH } from "redux/types";
import { catchAsync } from "utils/catchAsync";


export const getCustomersByMonth = () =>
catchAsync(async (dispatch) => {
  dispatch({
    type: GET_USERS_BY_MONTH,
    payload: {
      loading: true,
    },
  });
  const {
    data: { data: list },
  } = await api.get(`/users/countByMonth`);

  dispatch({
    type: GET_USERS_BY_MONTH,
    payload: {
      loading: false,
      list: list,
    },
  });
});