import axios from "axios";
import { statusChecker } from "redux/reduxHelpers";
import store from "redux/store";
import { UPDATE_STREAM_STATUS } from "redux/types";
import { UPDATE_CRONS } from "redux/types";
import { GET_STREAMER } from "redux/types";
import { catchAsync } from "utils/catchAsync";
import { api, apiAuth } from "../../api/config";
import { addNotification } from "./notifications";


const intervals = {}

export const getStreams = () =>
  catchAsync(async (dispatch) => {
    // dispatch({
    //   type: GET_STREAMER,
    //   payload: {
    //     loading: true,
    //   },
    // });
    const {
      data: { result }
    } = await api.get("/streams");

    dispatch({
      type: GET_STREAMER,
      payload: {
        loading: false,
        list: result.map(item => ({ ...item, loading: false }))
      },
    });
  });


export const createStream = (values) =>
  catchAsync(async (dispatch) => {
    const {
      data: { result },
    } = await api.post("/streams", values);

    dispatch({
      type: UPDATE_STREAM_STATUS,
      payload: {
        ...values,
        loading: true,
      },
    });
    dispatch({
      type: GET_STREAMER,
      payload: {
        list: result,

      },
    });
  });

export const updateStream = (id, values) => catchAsync(async (dispatch) => {
  dispatch({
    type: UPDATE_STREAM_STATUS,
    payload: { ...values, _id: id, loading: true }
  })
  const {
    data: { result },
  } = await api.put(`/streams/${id}`, values);

  dispatch({
    type: GET_STREAMER,
    payload: {
      loading: false,
      list: result
    },
  })
});

export const refreshStream = (values) => async (dispatch) => {
  try {
    dispatch({
      type: UPDATE_STREAM_STATUS,
      payload: { ...values, loading: true }
    })
    await apiAuth.post(`${values?.streamerInfo?.host}/stream/refresh`, values)
  } catch (error) {
  }
};

export const stopStream = (values) => async (dispatch) => {
  dispatch({
    type: UPDATE_STREAM_STATUS,
    payload: { ...values, loading: true }
  })
  await apiAuth.post(`${values?.streamerInfo?.host}/stream/destroy`, values)
}

export const deleteStream = (values) =>
  catchAsync(async (dispatch) => {
    dispatch({
      type: GET_STREAMER,
      payload: {
        loading: true,
      },
    });
    // const res = await apiAuth.post(`${values?.streamerInfo?.host}/stream/destroy`, {...values, isDelete: true} )
    //   if(res.status === 200){
    const { data: { result } } = await api.delete(`/streams/${values._id}`);
    dispatch({
      type: GET_STREAMER,
      payload: {
        loading: false,
        list: result
      },
    });
    // }
  });
