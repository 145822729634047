import { Card, Grid, IconButton, Tooltip } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DataTable from "examples/Tables/DataTable";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getTariffs } from "redux/actions/tariffs";
import AddIcon from "@mui/icons-material/Add";
import { Delete, Edit } from "@mui/icons-material";
import { updateTariff, deleteTariff, createTariff } from "redux/actions/tariffs";
import Modal from "./Modal";
import { getSingleItemByAnOption } from "utils/helpers";
import ChannelsModal from "./ChannelsModal";
import WithPermission from "components/withPermission/WithPermission";
import { EditPermission } from "pages/settings/Admins/constants";
import { DeletePermission } from "pages/settings/Admins/constants";
import { AddPermission } from "pages/settings/Admins/constants";
import { checkPermission } from "utils/checkPermission";
import { getChannels } from "redux/actions/channels";

export default function Tariffs() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const fullChannels = useSelector((store) => store?.channels?.list);
  const loading = useSelector((store) => store?.tariffs?.loading);
  const tariffs = useSelector((store) => store?.tariffs?.list);
  const [openModal, setOpenModal] = useState({ bool: false });
  const [openChannelsModal, setOpenChannelsModal] = useState({ bool: false });

  useEffect(() => {
    dispatch(getTariffs());
    if (!fullChannels.length) {
      dispatch(getChannels());
    }
  }, []);

  function handleCreateTariff(values) {
    dispatch(createTariff(values));
  }

  function handleDeleteTariff(item) {
    dispatch(deleteTariff(item._id));
  }

  async function handleUpdateTariff(values) {
    await dispatch(updateTariff(this._id, values));
    setOpenModal({ bool: false });
  }

  function handleOpenTariffModal(item, isUpdate) {
    setOpenModal({
      bool: true,
      onSave: isUpdate ? handleUpdateTariff.bind(item) : handleCreateTariff,
      modalValues: { ...item },
    });
  }

  const handleOpenChannelsModal = (channels) => {
    setOpenChannelsModal({ bool: true, channels })
  }

  const columns = [
    { Header: t("Name"), accessor: "name", align: "left" },
    { Header: t("addedBy"), accessor: "addedBy", align: "left" },
    { Header: t("channels.header"), accessor: "channels", align: "center" },
    { Header: t("Price"), accessor: "price", align: "center" },
    { Header: t("Type"), accessor: "billType", align: "center" },
    { Header: t("action"), accessor: "action", align: "center" },
  ];

  const rows = tariffs?.map((item) => {
    const channels = item?.channels
      ?.map((channel) => getSingleItemByAnOption(fullChannels, "_id", channel))
      ?.filter(item => item)

    return {
      name: item.name,
      channels: (
        <MDBox>
          <MDButton onClick={() => { handleOpenChannelsModal(channels) }} >
            {channels.length} {t("channel(s)")}
          </MDButton>
        </MDBox>
      ),
      addedBy: item?.addedBy?.email,
      price: item?.billType === 'FREE' ? '-' : item?.price,
      billType: item?.billType,
      action: (
        <MDBox>
          <WithPermission disable={true} permissionsList={[EditPermission]} permissionKey={'tariffs'} >
            <IconButton
              disabled={!checkPermission([EditPermission], 'tariffs')}
              onClick={() => {
                handleOpenTariffModal(item, true);
              }}
            >
              <Edit />
            </IconButton>
          </WithPermission>
          <WithPermission disable={true} permissionsList={[DeletePermission]} permissionKey={'tariffs'} >
            <Tooltip title={t('dblClick')} >
              <IconButton
                disabled={!checkPermission([DeletePermission], 'tariffs')}
                onDoubleClick={() => {
                  handleDeleteTariff(item);
                }}
              >
                <Delete />
              </IconButton>
            </Tooltip>
          </WithPermission>
        </MDBox>
      ),
    }
  });

  return (
    <DashboardLayout>
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                sx={{ display: "flex", justifyContent: "space-between" }}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  {t("tariffs.header")}
                </MDTypography>
                <WithPermission permissionsList={[AddPermission]} permissionKey={'tariffs'} >
                  <MDButton disabled={!checkPermission([AddPermission], 'tariffs')} onClick={handleOpenTariffModal} withIcon={<AddIcon />} loading={loading}>
                    {t("tariffs.addNew")}
                  </MDButton>
                </WithPermission>
              </MDBox>
              <MDBox pt={3}>
                {rows && (
                  <DataTable
                    table={{ columns, rows }}
                    isSorted={false}
                    noEndBorder
                  />
                )}
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <ChannelsModal openModal={openChannelsModal} setOpenModal={setOpenChannelsModal} />
      {openModal.bool && <Modal openModal={openModal} setOpenModal={setOpenModal} />}
    </DashboardLayout>
  );
}
