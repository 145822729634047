export default {
  translations: {
    appTitle: "RippleTV",
    noDataText: "Ցուցադրելու տողեր չկան",
    updateNow: "սեղմեք հիմա թարմացնելու համար",
    loading: "Բեռնվում է",
    noNotifications: "Ծանուցումներ չկան",
    noPermission: "Դուք չունեք համապատասխան թույլտվություն",
    active: 'Ակտիվ',
    inactive: 'Ոչ ակտիվ',
    create: 'Ստեղծել',
    forbidDelete: 'Սերվերը ունի պահպանված պրոցեսներ, նախ ջնջեք պրոցեսները, եթե ցանկանում եք ջնջել սերվերը.',
    EmailisnotValidated: 'Էլ.փոստը հաստատված չէ',
    EmailisValidated: 'Էլ.փոստը հաստատված է',
    Firstname: 'Անուն',
    getSoftwareId: 'Բեռնել Software ID-ն',
    Lastname: 'Ազգանուն',
    Email: 'Մուտքանուն',
    Tariff: 'Սակագին',
    Max_Subusers: 'Օգտվողների քանակ',
    Balance: 'Հաշվեկշիռ',
    Last_Payment_Date: 'Վերջին վճարման ամսաթիվ',
    Created_At: 'Ստեղծվել է',
    askDelete: 'Համոզվա՞ծ եք, որ ջնջում եք',
    dblClick: 'Կրկնակի սեղմեք ջնջելու համար',
    Notes: "Նշումներ",
    "Title": "Վերնագիր",
    "message": "Հաղորդագրություն",
    "confirmText": "Հաստատել",
    "cancelText": "Չեղարկել",
    "notifyAllCustomers": "Ծանուցել բոլոր հաճախորդներին",
    "addNotifications": "Ուղարկել Ծանուցում",
    "notifications": "Ծանուցումներ",
    
    FullFlow: "Полный поток",

    createStream: "Սարքել Ստրիմ",
    createChannel: "Սարքել Ալիք",
    createArchiver: "Սարքել Արխիվ",
    createTranscode: "Սարքել Տրանսկոդ",

    creatingTranscode: "Պատրաստվում է Տրանսկոդը",
    creatingStream: "Պատրաստվում է Ստրիմը",
    creatingArchive: "Պատրաստվում է Արխիվը",
    creatingChannel: "Պատրաստվում է Ալիքը",
    "AllStepscompleted": "Բոլոր քայլերը ավարտված են",

    Stream_URL: 'Հեռարձակման URL',
    Category: 'Կատեգորիա',
    Archive: 'Արխիվ',

    ChangePassword: "Փոխել գաղտնաբառը",

    Total_channels: 'Ընդհանուր ալիքներ',
    Total_subuser_accounts: 'Ենթաօգտատերերի ընդհանուր հաշիվները',
    Total_active_VODs: 'Ընդհանուր ակտիվ VOD-ներ',
    Revenue_this_month: 'Այս ամսվա եկամուտը',
    Total_payments_count: 'Ընդհանուր վճարումների քանակ',
    monthly_active_users: 'ամսական ակտիվ օգտվողներ',
    monthly_active_devices: 'ամսական ակտիվ սարքեր',
    active_devices: 'ակտիվ սարքեր',
    daily_active_devices: 'Օրական ակտիվ սարքեր',
    Monthly_payments: 'Ամսական վճարումներ',
    Total_User_Accounts: 'Օգտագործողների ընդհանուր հաշիվներ',
    Monthly_User_Accounts: 'Օգտագործողների ամսական հաշիվներ',
    Total_users_usage: "Լավագույն օգտատերերը ըստ օգտագործման",
    updated: 'Թարմացվել է',
    Name: "Անուն",
    Favorite_channels: "Նախնտրած ալիքներ",
    Language: "Լեզու",
    Last_online_date: "վերջին անգամ օնլայն",
    action: "Գործողություն",
    Search: 'Փնտրել',
    addedBy: 'Ավելացրել է',
    Type: 'Վճարման տեսակ',
    Close: 'Չեղարկել',
    Autofill: 'Ավտոլրացում',
    Upload: 'Վերբեռնել',
    Delete: 'Ջնջել',
    Defaults: 'Defaults',
    Clipboard: '',
    Copied: 'Պատճենվել է',

    General: 'Գլխավոր',
    Categories: 'Կատեգորիա',
    Admins: 'Ադմին',
    Add_new: 'Ավելացնել նորը',
    Year: 'Տարի',
    Price: 'Գին',
    Raiting: 'Վարկանիշ',
    'Length_(min)': 'Տևողություն (րոպե)',
    Genre: 'Ժանր',



    Created: 'Ստեղծել է',
    Updated: 'Թարմացրել է',
    Deleted: 'Ջնջել է',
    Viewed: 'Դիտել է',
    channel: 'ալիք',
    tariff: 'տարիֆ',
    customer: 'հաճախորդ',
    stream: 'սթրիմ',
    archive: 'արխիվ',
    transcode: 'տրանսկոդ',
    settings: 'կարգավորումներ',

    "Show inline": 'Ցուցադրել ներկառուցված',
    Old: 'Հին',
    New: 'Նոր',

    type: 'Տեսակ',
    'channel(s)': "Ալիք(ներ)",
    FFmpeg: 'Հրաման',
    Advanced_mode: 'Ընդլայնված ռեժիմ',
    Source: 'Մուտքային URL',
    Interval: 'Ինտերվալ',
    hours: 'ժամ',
    minutes: 'րոպե',
    seconds: 'վարկյան',
    activeCron: 'Cron-ը ակտիվ է',
    inactiveCron: 'Cron-ը ակտիվ չէ',

    epgHintHour: 'օգտագործեք թիվ՝ cron-ի աշխատանքի ժամ(եր)ը սահմանելու համար',
    epgHintCron: 'օգտագործեք cron շարահյուսությունը՝ միջակայքը սահմանելու համար, օրինակ. 0 */6 * * * * (ամեն 6 ժամը)',

    ShowingOfEntries: 'Ցուցադրված է {{entriesStart}}-ից {{entriesEnd}}-ը {{length}} գրառումից',

    UserName: "Օգտագործողի անուն",
    Fullname: "Ամբողջական անուն",

    Amount: 'Գումար',
    Balance_Before: 'Հաշվեկշիռը առաջ',
    Balance_After: 'Հաշվեկշիռը հետո',
    Made_By: 'Գանձապահ',
    Date: 'Ամսաթիվ',
    'archive_duration_(hours)': 'արխիվի տևողություն (ժամ)',
    'HLS_Time_(seconds)': 'Սեգմենտի չափ (վայրկյան)',
    learnMore: "Իմանալ Ավելին",
    en: 'Անգլերեն',
    ru: 'Ռուսերեն',
    am: 'Հայերեն',
    Resolutions: 'Որակ',
    Output: 'Ելքային URL',
    server_URL: 'Սերվերի URL',
    Important: 'Կարևոր',
    own_risk_TEXT: "Օգտագործեք սա ձեր ռիսկով: սա կարող է խափանել սերվերը",
    own_risk_suggest: "լրացրեք վերևում բոլոր դաշտերը՝ նախքան հրամանը փոխելը",

    IP: "IP",
    Device_Brand: "Բռենդ",
    Device_Model: "Մոդել",
    OS: "ՕՍ",
    Date: "Ամսաթիվ",

    Tariff_Type: "Տարիֆֆի տիպ",
    Price: 'Գին',

    Subusers: "Օգտվողներ",
    Tariff: "Սակագին",
    Payments: "Վճարումներ",
    ChannelsWatched: "Դիտումներ",
    usageHistory: "Օգտագործման պատմություն",

    Name: 'Անուն',
    archiver_URL: 'արխիվի URL',
    archive_duration_hours: 'արխիվի տևողությունը (ժամեր)',
    HLS_Time_seconds: 'HLS Ժամանակը վայրկյաններ',
    Amount: 'Գումարը',
    Upload_image: 'Վերբեռնեք պատկեր',
    Transcoder: 'Տրանսկոդեր',
    Archiver: 'Արխիվատոր',
    Streamer: 'Ստրիմեր',
    Configurator: 'Կոնֆիգուրատոր',
    sendResetEmail: "Ուղարկել Գաղտնաբառը փոխելու հղում",
    "Software ID": 'Ծրագրային ID',
    Reset: "Փոխել",
    passwordChanged: "Գաղտնաբառը փոխվել է",
    Customers: 'Հաճախորդներ',
    "Sidenav Colors": 'Կողային վահանակի գույն',
    "Sidenav Type": 'Կողային վահանակի տիպ',

    "attached Gpus": "Կցված GPU-ներ",
    "cuda Version": "Cuda վերսիա",
    "driver Version": "Ծրագրի վերսիա",

    Space: 'Հիշողություն',
    Brand: 'Ապրանքանիշ',
    "Fan speed": 'Օդափոխիչի արագություն',
    "Power/limit": 'Հզորություն/սահման',
    'GPU Temp': 'GPU ջերմաստիճան',

    errorOccuired: "Հաղորդագրությունը սերվերից",

    "Transparent": 'Թափանցիկ',
    "White": 'Սպիտակ',
    "Navbar Fixed": 'Navbar-ը ֆիքսված',
    "Light": 'Բաց',
    "Dark": 'Մուգ',

    Channels: 'Ալիքներ',
    EPG: 'EPG',
    Settings: 'Կարգավորումներ',
    License: 'Լիցենզիա',
    Item_Four: 'Չորրորդ կետ',
    Item_Five: 'Հինգերորդ կետ',
    Item_Six: 'Վեցերորդ կետ',
    Item_Seven: 'Յոթերորդ կետ',
    Save: 'Պահպանել',
    streamer_URL: 'Ստրիմերի URL',
    transcoder_URL: 'տրանսկոդերի URL',
    VOD_Server_URL: 'VOD սերվերի URL',
    Create_Account: 'Գրանցվել',
    Account_created_successfully: 'Հաշիվը հաջողությամբ ստեղծվեց',
    Go_to_login: 'գնալ մուտքի էջ',
    Create: 'Ստեղծել',
    watch_history: 'Դիտումներ',
    reorder_channels: 'Դասավորվածություն',
    firstName: "Անուն",
    lastName: "Ազգանուն",
    password: "Գաղտնաբառ",
    passwordConfirm: "Կրկնել գաղտնաբառը",
    Subusers_count: "Օգտվողների քանակ",
    tariff: "Տարիֆֆ",
    Avatar: "Նկար",
    Upload_image: "վերբեռնել Նկար",

    Admin_Info: "Ադմինիստրատորի ինֆորմացիա",
    Permissions: "Իրավունքներ",
    VOD_: "VOD",
    First_Name: "Անուն",
    Last_Name: "Ազգանուն",

    "Create_": "Ստեղծել",
    "View_": "Դիտել",
    "Update_": "Թարմացնել",
    "Delete_": "Ջնջել",
    "Advanced Mode_": "Ընդլայնված ռեժիմ",
    "Billing_": "Բիլինգ",
    "Add Subuser_": "Ավելացնել ենթաօգտատեր",
    "Update Subuser_": "Թարմացնել ենթաօգտատեր",
    "Delete Subuser_": "Ջնջել ենթաօգտատեր",
    "Refresh_": "Թարմացնել պրոցեսը",

    "justRefresh_": "Թարմացնել",
    "Play_": "Դիտել տեսանյութը",
    "Update Source_": "Փոխել մուտքային URL-ն",
    "Update Server Settings_": "Փոխել սերվերի կարգավորումները",
    "Change Active Status_": "Փոխել ակտիվ կարգավիճակը",


    "Global Admin_": "Գլոբալ ադմին",
    "Moderator_": "Մոդերատոր",
    "Analyst_": "Վերլուծաբան",
    "Cashier_": "Գանձապահ",



    "Total entries": 'Ընդհանուր քանակ',
    "Show detailed info": 'Ցուցադրել մանրամասն տեղեկատվություն',





    Default_devices: "Կանխադրված սարքեր",
    versions: "Թողարկումներ",



    advanced_settings: 'Ընդլայնված պարամետրեր',

    VOD_Categories: 'VOD Կատեգորիաներ',
    Channels_Categories: 'Ալիքների Կատեգորիաներ',

    Full_Name: 'Ամբողջական անուն',
    Created_By: 'Հրավիրել է',
    Role: 'Պաշտոն',

    Saved: 'Պահպանված է',
    success_saved: 'Փոփոխությունները հաջողությամբ պահպանվեցին',
    view_all: 'Դիտել ամբողջը',
    Total_watched_channels: 'Ամենաշատ դիտված ալիքներ',
    views: 'Դիտում',

    noViews: 'նշված ժամանակահատվածում դիտումներ չկան',

    CPU_Temp: 'Պրոցեսորի Ջերմաստիճան',
    CPU_average_load: 'Պրոցեսորի միջին ծանրաբեռնվածություն',
    platform: 'Պլատֆորմ',
    distro: 'Դիսթրո',
    release: 'Ռելիզ',
    kernel: 'Կերնել',
    manufacturer: 'Արտադրող',
    brand: 'Ապրանքանիշ',
    vendor: 'Վաճառող',
    cores: 'Միջուկներ',
    physicalCores: 'Ֆիզիկական միջուկ',
    RAM: 'Օպերատիվ հիշողություն',
    Used: 'Զբաղված',
    Free: 'Ազատ',

    Dashboard: "Վահանակ",
    Tariffs: "Սակագներ",
    Channels: "Ալիքներ",
    Customers: "Հաճախորդներ",
    Epg: "Ծրագիր",
    Streamers: "Ստրիմերներ",
    Transcoders: "Տրանսկոդերներ",
    Archivers: "Արխիվատորներ",
    VODRoute: "VOD Սերվերներ",
    Settings: "Կարգավորումներ",
    Streams: 'Ստրիմները',
    Archives: 'Արխիվները',
    Transcodes: 'Տրանսկոդները',
    View: 'Տեսնել',












    tariffs: {
      header: "Սակագնային պլաններ",
      addNew: "Ստեղծել նոր սակագին",
      freeBillTypeNoPrice: "Վճարման տեսակը սահմանված է որպես անվճար, սա ուժի մեջ չի մտնի:"
    },
    channels: {
      header: "Ալիքներ",
      addNew: "Ստեղծել նոր ալիք",
      emptyChannels: "Ցուցադրելու ալիքներ չկան",
    },
    customers: {
      header: "Հաճախորդներ",
      addNew: "Ավելացնել նոր հաճախորդ",
      empty: "Ցույց տալու հաճախորդներ չկան",
    },
    epg: {
      header: "EPG (Էլեկտրոնային ծրագրի ուղեցույց)",
      addNew: "Ավելացնել նոր աղբյուր կամ ֆայլ",
      empty: "Ցուցադրելու տեղեկատվություն չկա",
      updateFullList: "Թարմացնել ամբողջական ցանկը",
      inactiveCron: "Քրոնը ակտիվ չէ",
      activeCron: "Քրոնը ակտիվ է",
    },
    streamer: {
      header: "Ստրիմերներ",
      addNew: "Ավելացնել նոր Ստրիմ",
      addNewStreamer: "Ավելացնել նոր Ստրիմեր",
      empty: "Ցուցադրելու տեղեկատվություն չկա",
      updateFullList: "Թարմացնել ամբողջական ցանկը",
      create: 'Ստեղծել Ստրիմեր',
      update: 'Թարմացնել Ստրիմերները',
    },
    streams: {
      header: "{{streamer}}-ի Ստրիմներ",
      addNew: "Ավելացնել նոր Ստրիմեր",
      empty: "Ցուցադրելու տեղեկատվություն չկա",
      updateFullList: "Թարմացնել ամբողջական ցանկը",
      create: 'Ստեղծել Ստրիմ',
      update: 'Թարմացնել Ստրիմը',

    },
    VOD: {
      header: "VOD սերվերներ",
      addNew: "Ավելացնել նոր VOD սերվեր",
      addNewVOD: "Ավելացնել նոր VOD սերվեր",
      empty: "Ցուցադրելու տեղեկատվություն չկա",
      updateFullList: "Թարմացնել ամբողջական ցանկը",
      create: 'Ստեղծել VOD',
      update: 'Թարմացնել VOD-ը',
      processNupdate: 'Մշակել և թարմացնել VOD-ը',
      processNcreate: 'Մշակել և ստեղծել VOD',
    },
    VODServer: {
      header: "{{VOD}}-ի VOD-ներ",
      addNew: "Ավելացնել նոր VOD",
      empty: "Ցուցադրելու տեղեկատվություն չկա",
      updateFullList: "Թարմացնել ամբողջական ցանկը",
      create: 'Ստեղծել VOD',
      update: 'Թարմացրել VOD-ը',
    },
    archiver: {
      header: "Արխիվացներ",
      addNew: "Ավելացնել նոր արխիվ",
      empty: "Ցուցադրելու տեղեկատվություն չկա",
      updateFullList: "Թարմացնել ամբողջական ցանկը",
      addNewArchiver: "Ավելացնել նոր արխիվեր",
      update: 'Թարմացնել արխիվը',
    },

    archives: {
      header: "{{archiver}}-ի Արխիվներ",
      addNew: "Ավելացնել նոր արխիվ",
      empty: "Ցուցադրելու տեղեկատվություն չկա",
      updateFullList: "Թարմացնել ամբողջական ցանկը",
      addNewArchiver: "Ավելացնել նոր արխիվ",
      create: 'Ստեղծել արխիվ',
      update: 'Թարմացնել արխիվը',

    },

    channe: {
      create: 'Ստեղծել ալիք',
      update: 'Թարմացնել ալիքը',
    },

    transcoder: {
      header: "Տրանսկոդեր",
      addNew: "Ավելացնել նոր տրանսկոդr",
      empty: "Ցուցադրելու տեղեկատվություն չկա",
      updateFullList: "Թարմացնել ամբողջական ցանկը",
      addNewTranscoder: "Ավելացնել նոր տրանսկոդեր",
      update: 'Թարմացնել տրանսկոդերը',
    },
    transcodes: {
      header: "{{transcoder}}-ի տրանսկոդերը",
      addNew: "Ավելացնել նոր տրանսկոդe",
      empty: "Ցուցադրելու տեղեկատվություն չկա",
      updateFullList: "Թարմացնել ամբողջական ցանկը",
      addNewTranscoder: "Ավելացնել նոր տրանսկոդ",
      update: 'Թարմացնել տրանսկոդը',
      create: 'Ստեղծել տրանսկոդ',
    },
    advices: {
      cpuHighTempErr: 'Պրոցեսորի բարձր ջերմաստիճանի դեպքում առաջարկվում է բարելավել սառեացման լուծումները։',
      cpuHighTempWarn: 'Ստուգեք պրոցեսորի ջերմաստիճանները, հնարավոր է բարձր լինեն։',
      heatsink: 'Ստուգեք ջերմաստիճանահաղորդիչի մարտկոցի անհամասեռ կիրառումը կամ փոշիի կուտակումը։',
      lowRam: 'Առաջարկվում է ավելացնել RAM հիշողությունը։',
      Optimize: 'Օպտիմիզացրեք գործող պրոցեսները հիշողություն ազատելու համար։',
      highNetwork: 'Կանխատեսված է բարձր ցանցային ակտիվություն։ Ստուգեք, որ ավելորդ պրոցեսները չեն օգտագործում ցանցը։',
      GPUTempErr: 'GPU ({{gpuName}}) ջերմաստիճանը բարձր է, առաջարկվում է բարելավել սառեցման համակարգը',
      GPUTempWarn: 'GPU ({{gpuName}}) հիշողությունը բարձր է։ առաջարկվում է օպտիմիզացնել GPU պրոցեսսները։',
      unreachable: 'Սերվերն անհասանելի է',
      unreachableLong: 'Սերվերը անհասանելի է։ Ստորև ներկայացված է տվյալները սերվերի վերջին աշխատանքային վիճակից։',
      cpuHighUsageErr: 'CPU բեռնվածությունը բարձր է միջինը {{avgLoad}}%։ Պահանջվում է անհապաղ արձագանք։',
      cpuHighUsageWarn: 'CPU բեռնվածությունը բարձր է միջինը {{avgLoad}}%։ Մտածեք պրոցեսների օպտիմալացման համար։'
    },
    "Email is required": "Էլ. հասցե անհրաժեշտ է",
    "provided email is incorrect": "Տրված էլ. հասցեն սխալ է",
    "firstname is required": "Անունը անհրաժեշտ է",
    "lastName is required": "Ազգանունը անհրաժեշտ է",
    "plan is required": "Տարիֆֆն անհրաժեշտ է",
    "Subusers count is required": "Ենթաօգտատերերի քանակը անհրաժեշտ է",
    "password is required": "Գաղտնաբառը անհրաժեշտ է",
    "password confirmation is required": "Գաղտնաբառի հաստատումը անհրաժեշտ է",
    "Passwords do not match": "Գաղտնաբառերը չեն համընկնում",
    accessibleDevices: "Հասանելիություն",
    Device: "Սարքավորումներ",
    DeviceType: "Տեսակ",
    devicesCount: "Սարքերի առավելագույն քանակ",
    "codec_name": "Կոդեկի անունը",
    "codec_long_name": "Կոդեկի ամբողջական անունը",
    "display_aspect_ratio": "Էկրանի հարաբերակցությունը",
    "avg_frame_rate": "Միջին կադրերի հաճախականությունը",
    "coded_width": "լայնությունը",
    "coded_height": "բարձրությունը",
    "language": "Լեզու",
    "title": "Վերնագիր",
    "channel_layout": "Պատկերասրահի դասավորությունը",
    "content_of_vod": "VOD բովանդակությունը {{name}}",
    Content: "բովանդակությունը",
    selectvideoaudio: "Ընտրեք առնվազն մեկ աուդիո և մեկ վիդեո:",
    Uploaded_successfully: "Բեռնվել է հաջողությամբ!",
    Uploading: "Բեռնվում է",
    Transcoded_successfully: "Տրանսկոդավորվել է հաջողությամբ!",
    Transcoding: "Տրանսկոդավորում",
    Upload_subtitle: "Վերբեռնել ենթագրեր",
    Subtitle: "ենթագրեր",
    phone: "Հեռ․",
    rowsPerPage: "Տողեր էջում",
    customersOnline: "{{count}} Հաճախորդներ առցանց են",
    refresh: "Թարմացնել",
    AllWidgetsAdded: "Վիջեթներ չեն գտնվել",
    SaveWidgets: "Պահպանել վիջեթները",
    EditWidgets: "Խմբագրել վիջեթները"
  },
};
