import { GET_TARIFFS } from "redux/types";
import { catchAsync } from "utils/catchAsync";
import { api } from "../../api/config";

export const getTariffs = () =>
  catchAsync(async (dispatch) => {
    dispatch({
      type: GET_TARIFFS,
      payload: {
        loading: true,
      },
    });
    const {
      data: { data: allTariffs },
    } = await api.get("/tariffs");
    dispatch({
      type: GET_TARIFFS,
      payload: {
        loading: false,
        list: allTariffs,
      },
    });
  });

export const deleteTariff = (id) =>
  catchAsync(async (dispatch) => {
    dispatch({
      type: GET_TARIFFS,
      payload: {
        loading: true,
      },
    });
    const {
      data: { data: allTariffs },
    } = await api.delete(`/tariffs/${id}`);

    dispatch({
      type: GET_TARIFFS,
      payload: {
        loading: false,
        list: allTariffs,
      },
    });
  });

export const updateTariff = (id, body) =>
  catchAsync(async (dispatch) => {
    dispatch({
      type: GET_TARIFFS,
      payload: {
        loading: true,
      },
    });
    const {
      data: { data: allTariffs },
    } = await api.patch(`/tariffs/${id}`, body);

    dispatch({
      type: GET_TARIFFS,
      payload: {
        loading: false,
        list: allTariffs,
      },
    });
  });

export const createTariff = (body) =>
  catchAsync(async (dispatch) => {
    dispatch({
      type: GET_TARIFFS,
      payload: {
        loading: true,
      },
    });
    const {
      data: { data: allTariffs },
    } = await api.post(`/tariffs`, body);

    dispatch({
      type: GET_TARIFFS,
      payload: {
        loading: false,
        list: allTariffs,
      },
    });
  });
