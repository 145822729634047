import React, { useState } from "react";
import style from "./style.module.css";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import MDBox from "components/MDBox";
import MDAvatar from "components/MDAvatar";
import WithPermission from "components/withPermission/WithPermission";
import MDButton from "components/MDButton";
import { checkPermission } from "utils/checkPermission";
import { EditPermission } from "pages/settings/Admins/constants";
import { useTranslation } from "react-i18next";
import HeightIcon from '@mui/icons-material/Height';
import { api } from "api/config";
import { useDispatch } from "react-redux";
import { getChannels } from "redux/actions/channels";

export default function ReorderChannels({ list, onAfterAction }) {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    // React state to track order of items
    const [itemList, setItemList] = useState(list);

    const handleSave = async () => {
        await api.put('/channels/order', {channels: itemList})
        dispatch(getChannels());
        onAfterAction()
    }


    // Function to update list on drop
    const handleDrop = (droppedItem) => {
        // Ignore drop outside droppable container
        if (!droppedItem.destination) return;
        var updatedList = [...itemList];
        // Remove dragged item
        const [reorderedItem] = updatedList.splice(droppedItem.source.index, 1);
        // Add dropped item
        updatedList.splice(droppedItem.destination.index, 0, reorderedItem);
        // Update State
        setItemList(updatedList.map((item, i) => ({...item, order: i})));
    };

    return (
        <div className={style.App}>
            <DragDropContext onDragEnd={handleDrop}>
                <Droppable droppableId={style['list-container']}>
                    {(provided) => (
                        <div
                            className={style['list-container']}
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                        >
                            {itemList.map((item, index) => (
                                <Draggable key={item._id} draggableId={item._id} index={index}>
                                    {(provided) => (
                                        <div
                                            className={style["item-container"]}
                                            ref={provided.innerRef}
                                            {...provided.dragHandleProps}
                                            {...provided.draggableProps}
                                        >
                                            <MDBox sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                                <MDBox sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                    <MDAvatar
                                                        src={`${process.env.REACT_APP_BASE_ORIGIN}/img/channels/${item?.image}`}
                                                        name={item.email}
                                                        size="sm"
                                                    />{" "}
                                                    &nbsp; {item.name}
                                                </MDBox>
                                                <HeightIcon fontSize="medium" />
                                            </MDBox>
                                        </div>
                                    )}
                                </Draggable>
                            ))}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
            <MDBox sx={{ display: "flex", justifyContent: "center", gap: '10px', paddingBottom: '27px', alignItems: "center", padding: '10px', backgroundColor: '#eee', position: 'fixed', borderRadius: '10px', bottom: '15px', right: '30px', width: window.innerWidth < 600 ? 'calc(100% - 70px)' : '29.2vw', }}>
                <MDButton
                    variant="outlined"
                    color="error"
                    sx={{width: '50%'}}
                    disabled={!checkPermission([EditPermission], 'channels')}
                    onClick={onAfterAction}
                    fullwidth
                >
                    {t('cancel')}
                </MDButton>
                <WithPermission disable={true} permissionsList={[EditPermission]} permissionKey={'channels'} >
                    <MDButton
                        variant="contained"
                        color="info"
                        fullwidth
                        sx={{width: '50%'}}
                        disabled={!checkPermission([EditPermission], 'channels')}
                        onClick={handleSave}
                    >
                        {t('save')}
                    </MDButton>
                </WithPermission>
            </MDBox>
        </div>
    );
}