import * as React from 'react';
import Drawer from '@mui/material/Drawer';
import styles from './drawer.module.css'

export default function TemporaryDrawer({open, setOpen, children, paperStyles}) {
    const handleClose = () => {
        setOpen(false)
    }
  return (
    <div>
          <Drawer
            classes={{paper: paperStyles || styles.drawerPaper}}
            anchor={'right'}
            open={open}
            onClose={handleClose}
          >
            {children}
          </Drawer>
    </div>
  );
}
