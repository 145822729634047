export const SET_AUTH = "SET_AUTH";
export const GET_TARIFFS = "GET_TARIFFS";
export const ADD_NOTIFICATION = "ADD_NOTIFICATION";
export const ARCHIVE_NOTIFICATION = "ARCHIVE_NOTIFICATION";
export const GET_CHANNELS = "GET_CHANNELS";
export const GET_CUSTOMERS = "GET_CUSTOMERS";
export const GET_EPG = "GET_EPG";
export const UPDATE_CRONS = "UPDATE_CRONS";

export const GET_STREAMER = "GET_STREAMER";
export const GET_STREAMERS = "GET_STREAMERS";
export const UPDATE_STREAM_STATUS = "UPDATE_STREAM_STATUS";

export const GET_ARCHIVER = "GET_ARCHIVER";
export const GET_ARCHIVERS = "GET_ARCHIVERS";
export const UPDATE_ARCHIVE_STATUS = "UPDATE_ARCHIVE_STATUS";

export const GET_TRANSCODER = "GET_TRANSCODER";
export const GET_TRANSCODERS = "GET_TRANSCODERS";
export const UPDATE_TRANSCODE_STATUS = "UPDATE_TRANSCODE_STATUS";


export const GET_VOD = "GET_VOD";
export const GET_VODS = "GET_VODS";
export const UPDATE_VOD_STATUS = "UPDATE_VOD_STATUS"
export const UPDATE_VODS_STATUS = "UPDATE_VODS_STATUS"
export const UPDATE_SINGLE_VOD_STATUS = "UPDATE_SINGLE_VOD_STATUS"
export const FINISH_SINGLE_VOD_STATUS = "FINISH_SINGLE_VOD_STATUS"


export const GET_SYSTEM_STATUS="GET_SYSTEM_INFO";

export const GET_BILLING = 'GET_BILLING'
export const GET_USERS_BY_MONTH = 'GET_USERS_BY_MONTH'
export const GET_ACTIIVE_USERS = 'GET_ACTIIVE_USERS'
export const GET_ACTIIVE_DEVICES = 'GET_ACTIIVE_DEVICES'


export const GET_SETTINGS = "GET_SETTINGS"
export const UPDATE_SETTINGS = "UPDATE_SETTINGS"
export const UPDATE_SETTINGS_CATEGORIES = "UPDATE_SETTINGS_CATEGORIES"
export const UPDATE_SETTINGS_RESOLUTIONS = "UPDATE_SETTINGS_RESOLUTIONS"
export const UPDATE_SETTINGS_DASHBOARD = "UPDATE_SETTINGS_DASHBOARD"


export const GET_ADMINS = "GET_ADMINS"
export const UPDATE_ADMIN = "UPDATE_ADMIN"
export const UPDATE_SETTINGS_DEFAULT_COMMANDS = "UPDATE_SETTINGS_DEFAULT_COMMANDS"
export const INFO_MODAL_STATE = "INFO_MODAL_STATE"

export const GET_SERVER_ADVICES = 'GET_SERVER_ADVICES'



