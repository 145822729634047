import MDBox from "components/MDBox";
import MDDialog from "../../../components/MDDialog";
import MUITable from "../../../components/MuiTable";
import DividerMui from "../../../components/dividerMui/dividerMui";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import styles from "./infoStyles.module.css"
import ClosedCaptionIcon from '@material-ui/icons/ClosedCaption';
import OndemandVideoIcon from '@material-ui/icons/YouTube';
import AudiotrackIcon from '@material-ui/icons/LibraryMusic';
import MDButton from "components/MDButton";
import { DropzoneDialog } from "material-ui-dropzone";
import { convertBytes } from "utils/helpers";
import { apiAuth } from "api/config";
import MDInput from "components/MDInput";

export default function VodInfoModal({ openModal, setOpenModal, VODServerInfo, VODs }) {
    const { t } = useTranslation()
    const [selectedsVideo, setSelectedsVideo] = useState([])
    const [selectedsAudio, setSelectedsAudio] = useState([])
    const [selectedsSubtitle, setSelectedsSubtitle] = useState([])
    const [selectedsSubtitleUploaded, setSelectedsSubtitleUploaded] = useState([])
    const [videos, setVideos] = useState([])
    const [audios, setAudios] = useState([])
    const [subtitles, setSubtitles] = useState([])
    const [error, setError] = useState("")
    const [openDropZone, setOpenDropZone] = useState(false);
    const [subtitlesUploaded, setSubtitlesUploaded] = useState([])

    const handleSubmit = () => {
        if (selectedsVideo.length === 0 || selectedsAudio.length === 0) {
            setError(t('selectvideoaudio'))
            return
        }
        setOpenModal(prev => ({ ...prev, bool: false, selecteds: { selectedsVideo, selectedsAudio, selectedsSubtitle, selectedsSubtitleUploaded } }))
    }

    const handleSelectedsVideo = (list) => {
        setSelectedsVideo(list[list.length - 1] ? [list[list.length - 1]] : [])
    }

    const handleSelectedsAudio = (list) => {
        setSelectedsAudio(list)
    }

    const handleSelectedsSubtitle = (list) => {
        setSelectedsSubtitle(list)
    }

    
    const handleSelectedsSubtitleUploaded = (list) => {
        setSelectedsSubtitleUploaded(list)
    }

    useEffect(() => {
        const listVideos = openModal?.info?.streams.filter(item => item.codec_type === "video")
        const listAudios = openModal?.info?.streams.filter(item => item.codec_type === "audio").map(item => ({ ...item, ...(item?.tags || {}) }))
        const listSubtitles = openModal?.info?.streams.filter(item => item.codec_type === "subtitle").map(item => ({ ...item, ...(item?.tags || {}) }))
        setVideos(listVideos)
        setAudios(listAudios)
        setSubtitles(listSubtitles)
        setSelectedsVideo([listVideos[0]])
        setSelectedsAudio([listAudios[0]])
    }, [openModal?.info?.streams])



    const columnsVideo = [
        { header: t('codec_name'), accessor: "codec_name" },
        { header: t('codec_long_name'), accessor: "codec_long_name" },
        { header: t('display_aspect_ratio'), accessor: "display_aspect_ratio" },
        { header: t('avg_frame_rate'), accessor: "avg_frame_rate" },
        { header: t('coded_width'), accessor: "coded_width" },
        { header: t('coded_height'), accessor: "coded_height" },
    ];

    const columnsAudio = [
        { header: t('codec_name'), accessor: "codec_name" },
        { header: t('codec_long_name'), accessor: "codec_long_name" },
        { header: t('language'), accessor: "language" },
        { header: t('title'), accessor: "title" },
        { header: t('channel_layout'), accessor: "channel_layout" },
    ];

    const columnsSubtitle = [
        { header: t('codec_name'), accessor: "codec_name" },
        { header: t('codec_long_name'), accessor: "codec_long_name" },
        { header: t('language'), accessor: "language", renderer: SubtitleLanguageRenderer},
        { header: t('title'), accessor: "title", render: SubtitleTitleRenderer },
    ];


    function SubtitleLanguageRenderer({value, row}) {
        return value || `Track ${row.index}`
    }

    function SubtitleTitleRenderer({value, row}) {
        return value || `Track ${row.index}`
    }

    const handleSaveSubtitles = async (files) => {
        await uploadSubtitle(files)
        setOpenDropZone(false);
    }

    async function uploadSubtitle(files, name) {
        const formData = new FormData();
        for (let i = 0; i < files.length; i++) {
            const file = files[i];
            formData.append('files', file);
            
        }
        try {
            const response = await apiAuth.post(`${VODServerInfo.host}/VOD/uploadSubtitle`, formData);
            const data = response.data
            console.log(data);
            setSubtitlesUploaded(data.filesInfo)
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <MDDialog
            maxWidth="md"
            fullWidth
            dialogHeight="fit-content"
            open={openModal?.bool}
            setOpen={setOpenModal}
            onSave={handleSubmit}
            sx={{ overflowX: 'hidden' }}
            submitText={t('confirmText')}
            suppressClose
            error={error}
            title={t("content_of_vod", { name: openModal?.title?.replaceAll?.("_", " ") || "" })}
        >
            <MDBox>
                {!!videos.length && <>
                    <DividerMui textClass={styles.dividerText} text={<span className={styles.flex}><OndemandVideoIcon fontSize="large" color="#344767" />{`Video (${videos.length})`}</span>} />
                    <MUITable
                        suppressPagination
                        withCheckbox
                        columns={columnsVideo}
                        data={videos || []}
                        selecteds={selectedsVideo}
                        setSelecteds={setSelectedsVideo}
                        onChange={handleSelectedsVideo}
                        checkboxCompareKey="index"
                    />
                </>
                }
                {!!audios.length && <>
                    <DividerMui dividerClass={styles.dividerClass} textClass={styles.dividerText} text={<span className={styles.flex}><AudiotrackIcon fontSize="large" color="#344767" />{`Audio (${audios.length})`}</span>} />
                    <MUITable
                        headerColumnCheckbox
                        suppressPagination
                        withCheckbox
                        columns={columnsAudio}
                        data={audios || []}
                        selecteds={selectedsAudio}
                        setSelecteds={setSelectedsAudio}
                        onChange={handleSelectedsAudio}
                        checkboxCompareKey="index"
                    />
                </>}

                    <DividerMui dividerClass={styles.dividerClass} textClass={styles.dividerText} text={<span className={styles.flex}><ClosedCaptionIcon fontSize="large" color="#344767" />{`Subtitle (${subtitles.length + subtitlesUploaded.length})`}</span>} />
                    <MDBox className={styles.header}>
                        <MDButton onClick={() => { setOpenDropZone(true) }} variant="outlined" color="info" component="label" >
                            {t("Upload_subtitle")}
                        </MDButton>
                    </MDBox>
                {(!!subtitlesUploaded.length || !!subtitles.length) && <>
                    {!!subtitlesUploaded.length && <>
                    <DividerMui textClass={styles.dividerTextSm} text={<span className={styles.flex}><ClosedCaptionIcon fontSize="small" color="#344767" />{`${t("Upload_subtitle")} (${subtitlesUploaded.length})`}</span>} />
                    <MUITable
                        headerColumnCheckbox
                        suppressPagination
                        withCheckbox
                        columns={columnsSubtitle}
                        data={subtitlesUploaded || []}
                        selecteds={selectedsSubtitleUploaded}
                        setSelecteds={setSelectedsSubtitleUploaded}
                        onChange={handleSelectedsSubtitleUploaded}
                        checkboxCompareKey="title"
                    /></>}
                    {!!subtitles.length && <>
                    {!!subtitlesUploaded.length && <DividerMui textClass={styles.dividerTextSm} text={<span className={styles.flex}><ClosedCaptionIcon fontSize="small" color="#344767" />{`${t('Subtitle')} (${subtitles.length})`}</span>} />}
                    <MUITable
                        headerColumnCheckbox
                        suppressPagination
                        withCheckbox
                        columns={columnsSubtitle}
                        data={subtitles || []}
                        selecteds={selectedsSubtitle}
                        setSelecteds={setSelectedsSubtitle}
                        onChange={handleSelectedsSubtitle}
                        checkboxCompareKey="index"
                    /></>}
                </>}
            </MDBox>
            <DropzoneDialog
                acceptedFiles={['.srt', 'text/vnd.dvb.subtitle', 'text/vtt']}
                cancelButtonText={"cancel"}
                submitButtonText={"submit"}
                filesLimit={5}
                maxFileSize={5000000}
                open={openDropZone}
                onClose={() => setOpenDropZone(false)}
                onSave={handleSaveSubtitles}
                showPreviews={true}
                showFileNamesInPreview={true}
            />
        </MDDialog >
    );
}
