import { api } from "api/config";
import { GET_SERVER_ADVICES } from "redux/types";
import { catchAsync } from "utils/catchAsync";


export const getServerAdvices = () =>
catchAsync(async (dispatch) => {
  dispatch({
    type: GET_SERVER_ADVICES,
    payload: {
      loading: true,
    },
  });
  const {
    data: { data: list },
  } = await api.get(`/analyze`);

  dispatch({
    type: GET_SERVER_ADVICES,
    payload: {
      loading: false,
      list: list,
    },
  });
});