import { Divider } from '@mui/material'
import React from 'react'
import styles from './styles.module.css'
import classNames from 'classnames'

export default function DividerMui({ text, textClass, dividerClass, ...rest }) {
    if (!text) {
        <Divider {...rest} className={classNames(styles.divider, dividerClass)} sx={{ backgroundImage: 'none !important' }} role="presentation" />
    }
    return (
        <Divider {...rest} className={classNames(styles.divider, dividerClass)} sx={{ backgroundImage: 'none !important' }} role="presentation" >
            {textClass ?
                <span className={textClass} >{text}</span> :
                text
            }
        </Divider>
    )
}
