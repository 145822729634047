import { DeleteForever, Edit } from '@mui/icons-material';
import { IconButton, Tooltip } from '@mui/material';
import MDAvatar from 'components/MDAvatar';
import MDBox from 'components/MDBox';
import DataTable from 'examples/Tables/DataTable';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { ClipLoader } from 'react-spinners';
import { getAdmins } from 'redux/actions/admins';
import Modal from './modal';
import { createAdmin } from 'redux/actions/admins';
import MDButton from 'components/MDButton';
import { deleteAdmin } from 'redux/actions/admins';
import moment from 'moment';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox';
import { useTranslation } from 'react-i18next';
import { updateAdmin } from 'redux/actions/admins';
import { AddPermission, DeletePermission, EditPermission, roles } from './constants';
import WithPermission from 'components/withPermission/WithPermission';
import { checkPermission } from 'utils/checkPermission';
import HistoryToggleOffIcon from '@mui/icons-material/HistoryToggleOff';
import HistoryModal from './historyModal';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import LockResetIcon from '@mui/icons-material/LockReset';
import { api } from 'api/config';
import { addNotification } from 'redux/actions/notifications';
import Loader from 'react-spinners/ClipLoader';

export default function Admins() {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    
    const [openModal, setOpenModal] = useState({ bool: false });
    const [resetPasswordLoading, setResetPasswordLoading] = useState([]);
    const { list, loading } = useSelector(store => store.admins)
    const [openHistoryModal, setOpenHistoryModal] = useState({ bool: false })

    useEffect(() => {
        dispatch(getAdmins())
    }, [])


    function handleDeleteAdmin(item) {
        dispatch(deleteAdmin(item._id));
    }

    function handleUpdateAdmin(params) {
        const formData = new FormData();
        for (let key in params) {
            if (params[key] instanceof File) {
                formData.append(key, params[key], params[key].name);
            } else if (params[key] instanceof Object) {
                formData.append(key, JSON.stringify(params[key]));
            } else {
                formData.append(key, params[key]);
            }
        }
        dispatch(updateAdmin(this._id, formData));
    }

    function handleCreateAdmin(params) {
        const formData = new FormData();
        for (let key in params) {
            if (params[key] instanceof File) {
                formData.append(key, params[key], params[key].name);
            } else if (params[key] instanceof Object) {
                formData.append(key, JSON.stringify(params[key]));
            } else {
                formData.append(key, params[key]);
            }
        }
        dispatch(createAdmin(formData));
    }

    function handleOpenModal(item, isUpdate) {
        setOpenModal({
            bool: true,
            isUpdate,
            onSave: isUpdate ? handleUpdateAdmin.bind(item) : handleCreateAdmin,
            modalValues: {
                firstName: item?.firstName ?? "",
                lastName: item?.lastName ?? undefined,
                email: item?.email ?? "",
                permissions: item?.permissions,
                avatar: item?.avatar ?? ''
            },
        });
    }



    const handleOpenHistoryModal = (item) => {
        setOpenHistoryModal({
            bool: true,
            modalValues: item,
        })
    }

    const resetAdminPassword = async (admin) => {
        try {
            setResetPasswordLoading(prev=>[...prev, admin._id])
            await api.put(`/admins/resetPassword/${admin._id}`, admin, {params: {resetPassword: "true", redirectURL: window.location.origin}})
            setResetPasswordLoading(prev=>{
                const filtered = prev.filter(item=>item!==admin._id)
                return filtered
            })
            dispatch(addNotification({
                color: 'success',
                icon: 'success',
                title: 'Reset Password',
                content: 'Email sent successfully',
                dateTime: Date.now(),
              }))
        } catch (error) {
            setResetPasswordLoading(prev=>{
                const filtered = prev.filter(item=>item!==admin._id)
                return filtered
            })
            dispatch(addNotification({
                color: 'error',
                icon: 'error',
                title: 'An error occuired',
                content: error.errorMsg || error.message,
                dateTime: Date.now(),
              }))
        }

    }

    const columns = [
        { Header: t("Full_Name"), accessor: "Name", align: "left" },
        { Header: t("Email"), accessor: "Email", align: "left" },
        { Header: t("Created_At"), accessor: "createdAt", align: "left" },
        { Header: t("Created_By"), accessor: "createdBy", align: "left" },
        { Header: t("Role"), accessor: "Role", align: "center" },
        { Header: t("action"), accessor: "action", align: "center" },
    ];
    const rows = list?.map((item) => {
        const stringified = JSON.stringify(item.permissions)
        const found = roles.find(item => JSON.stringify(item.values) === stringified)
        const createdBy = !item.createdBy?.firstName && !item.createdBy?.lastName ? '-' : `${item.createdBy?.firstName ?? ''} ${item.createdBy?.lastName ?? ''}`
        return {
            Name: (
                <MDBox sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <MDAvatar
                        src={item?.avatar ? `${process.env.REACT_APP_BASE_ORIGIN}/img/users/${item?.avatar}` : `${process.env.REACT_APP_BASE_ORIGIN}/img/default.png`}
                        name={item.email}
                        size="sm"
                    />{" "}
                    &nbsp; {`${item.firstName} ${item.lastName}`}
                </MDBox>
            ),
            Email: `${item.email}`,
            createdAt: `${moment(item.createdAt).format('DD/MM/YYYY')}`,
            createdBy: createdBy,
            Role: `${found?.label ?? '-'}`,
            action: (
                <MDBox>
                    <Tooltip title={item.isEmailValidated ? t('EmailisValidated') : t('EmailisnotValidated')} >
                        <IconButton>
                            {item.isEmailValidated ? <CheckBoxIcon sx={{ color: 'success.main' }} /> : <IndeterminateCheckBoxIcon sx={{ color: 'error.main' }} />}
                        </IconButton>
                    </Tooltip>
                    <WithPermission disable={true} permissionsList={[EditPermission]} permissionKey={'settings'} >
                        <Tooltip title={t('sendResetEmail')} >
                            <IconButton
                                disabled={!checkPermission([EditPermission], 'settings')}
                                onClick={() => {
                                    resetAdminPassword(item);
                                }}
                            >
                                {resetPasswordLoading.includes(item._id) ? <Loader size={20}/> : <LockResetIcon />}
                            </IconButton>
                        </Tooltip>
                    </WithPermission>
                    <WithPermission disable={true} permissionsList={[EditPermission]} permissionKey={'settings'} >
                        <IconButton
                            disabled={!checkPermission([EditPermission], 'settings')}
                            onClick={() => {
                                handleOpenHistoryModal(item, true);
                            }}
                        >
                            <HistoryToggleOffIcon />
                        </IconButton>
                    </WithPermission>
                    <WithPermission disable={true} permissionsList={[EditPermission]} permissionKey={'settings'} >
                        <IconButton
                            disabled={!checkPermission([EditPermission], 'settings')}
                            onClick={() => {
                                handleOpenModal(item, true);
                            }}
                        >
                            <Edit />
                        </IconButton>
                    </WithPermission>
                    <WithPermission disable={true} permissionsList={[DeletePermission]} permissionKey={'settings'} >
                        <Tooltip title={t('dblClick')} >
                            <IconButton
                                disabled={!checkPermission([DeletePermission], 'settings')}
                                onDoubleClick={() => {
                                    confirm(`${t('askDelete')}`) && handleDeleteAdmin(item);
                                }}
                                color="error"
                            >
                                <DeleteForever />
                            </IconButton>
                        </Tooltip>
                    </WithPermission>
                    <Tooltip title={item.isOnline ? t('Online') : t('Offline')} >
                        <IconButton>
                            {item.isOnline ? <FiberManualRecordIcon sx={{ color: 'success.main' }} /> : <FiberManualRecordIcon sx={{ color: 'error.main' }} />}
                        </IconButton>
                    </Tooltip>
                </MDBox>
            ),
        }
    });

    return (
        <div style={{ position: 'relative' }} >
            {loading && <div className='loader' >
                <ClipLoader />
            </div>}
            <MDBox sx={{ display: "flex", justifyContent: 'flex-end' }}>
                <WithPermission disable={true} permissionsList={[AddPermission]} permissionKey={'settings'} >
                    <MDButton disabled={!checkPermission([AddPermission], 'settings')} variant={'outlined'} color={"info"} onClick={() => handleOpenModal()} >Create New Account</MDButton>
                </WithPermission>
            </MDBox>
            <DataTable
                table={{ columns, rows }}
                isSorted={false}
                noEndBorder
            />
            {openModal?.bool && (
                <Modal width={'60vw'} openModal={openModal} setOpenModal={setOpenModal} />
            )}

            {openHistoryModal?.bool && (
                <HistoryModal width={'60vw'} openModal={openHistoryModal} setOpenModal={setOpenHistoryModal} />
            )}
        </div>
    )
}
