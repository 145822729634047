import React from 'react'
import './styles.css'


const PiePercent = ({ percent, color, border, width, round, animate }) => {
    const pieStyle = {
      '--p': percent,
      '--c': color || 'darkred',
      '--b': border || '7px',
      '--w': width || '45px',
    };
    const className = `pie${round ? '' : ' no-round'}${animate ? ' animate' : ''}`;
  
    return (
      <div key={percent} className={className} style={{...pieStyle, fontSize: percent > 99 ? '12px' : '15px'}}>
        {percent}%
      </div>
    );
  };
  
  export default PiePercent;
