import { Chip, Grid, Tooltip } from '@mui/material'
import { api } from 'api/config'
import MDBox from 'components/MDBox'
import MDButton from 'components/MDButton'
import MDInput from 'components/MDInput'
import MDTypography from 'components/MDTypography'
import MUITable from '../../../components/MuiTable'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { ClipLoader } from 'react-spinners'
import { getCustomers } from 'redux/actions/customers'
import { updateCustomer } from 'redux/actions/customers'

export default function Access({ user: porpsUser, onSave, onChange }) {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const settings = useSelector((store) => store?.settings?.settings);
    const [devicesCount, setDeviceCount] = useState(3)
    const [user, setUser] = useState(porpsUser)
    const [selecteds, setSelecteds] = useState([])
    const [devicesList, setDevicesList] = useState([])
    const [loading, setLoading] = useState(false)

    const refresh = async () => {
        const user = await api.get(`/users`, {params: {id: porpsUser._id}})
        setUser(user.data.data)
    }

    const getDevices = async () => {
        try {
            setLoading(true)
            const { data } = await api.get("/devices")
            let devicesList = data.devicesList
            if(!onSave){
                devicesList = user.supportedUUIDs.reduce((acc, item) => {
                    const index = acc.findIndex(data => data.id === item.deviceId)
                    if (index > -1) {
                        if (!acc[index].uuidData) {
                            acc[index].uuidData = []
                        }
                        acc[index].uuidData.push(item)
                    }
                    return acc
                }, data.devicesList)
            }
            setDevicesList(devicesList)
            setLoading(false)
        } catch (error) {
            setLoading(false)
        }
    }

    const handleSaveDevices = async () => {
        if (onSave) {
            onSave({ accessibleDevices: selecteds, maxDevicesCount: devicesCount })
        } else {
            await dispatch(updateCustomer(user._id, { devices: { accessibleDevices: selecteds, maxDevicesCount: devicesCount } }));
        }
    }

    useEffect(() => {
        if (user.devices || settings.customerSettings) {
            setSelecteds(user?.devices?.accessibleDevices || settings.customerSettings.accessibleDevices)
            setDeviceCount(user?.devices?.maxDevicesCount || settings.customerSettings.maxDevicesCount)
        }
    }, [user, settings])

    useEffect(() => {
        getDevices()
    }, [user])

    const handleMaxDevices = (e) => {
        setDeviceCount(e.target.value)
    }

    const handleAccessibleDevices = (devices) => {
        setSelecteds(devices)
    }

    const CellRenderer = ({ value }) => {
        const handleDelete = async (deviceInfo) => {
            const supportedUUIDs = user.supportedUUIDs.filter(item=>item.deviceUUID !== deviceInfo.deviceUUID)
            await dispatch(updateCustomer(user._id, { supportedUUIDs: supportedUUIDs }));
            setUser(prev=>({...prev, supportedUUIDs}))
        }
        return <div style={{width: "350px",overflow: "auto", whiteSpace: "nowrap"}} >
            {value?.map((deviceInfo) => {
                return <Tooltip position="top" title={deviceInfo.deviceUUID ? `Device ID: ${deviceInfo.deviceUUID}` : ""} >
                    <Chip label={deviceInfo.device.deviceName} onDelete={() => handleDelete(deviceInfo)} />
                </Tooltip>
            })}
        </div>
    }

    const columns = [
        { header: t('DeviceType'), accessor: "name" },
        { header: t('Device'), accessor: "uuidData", renderer: CellRenderer },
    ];


    return (
        <Grid sx={{ position: "relative" }} container>
            {loading && <div className="loader">
                <ClipLoader />
            </div>}
            <Grid item xs={12}>
                <div style={{ display: 'flex', gap: '10px', marginBottom: '10px', justifyContent: "space-between" }} >
                    <MDTypography variant={'body1'} >
                        {`${t('devicesCount')}:`}
                        &nbsp;&nbsp;
                        <MDInput
                            value={devicesCount}
                            type="number"
                            min={0}
                            onChange={handleMaxDevices}
                        />
                    </MDTypography>
                    {!onChange && <div>
                        <MDButton onClick={refresh} sx={{ width: 100 }} variant='outlined' color="info" >{t("justRefresh_")}</MDButton>
                        <MDButton onClick={handleSaveDevices} sx={{ width: 100 }} variant='outlined' color="info" >{t("Save")}</MDButton>
                    </div>}
                </div>
            </Grid>
            <Grid item xs={12}>
                <MDBox>
                    <MUITable
                        withCheckbox
                        headerColumnCheckbox
                        columns={columns}
                        data={devicesList}
                        CellRenderer={CellRenderer}
                        selecteds={selecteds}
                        setSelecteds={setSelecteds}
                        onChange={handleAccessibleDevices}
                    />
                </MDBox>
            </Grid>
        </Grid>
    )
}
