import { Cancel, Check, DeleteForever, Edit, ExpandLess, ExpandMore } from '@mui/icons-material'
import { Collapse, IconButton, List, ListItemButton, ListItemText, TextField, Tooltip } from '@mui/material'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import styles from './styles.module.css';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import MDBox from 'components/MDBox';
import { AddOutlined, CloseRounded } from "@mui/icons-material";
import MDButton from 'components/MDButton';
import { v4 } from 'uuid';
import { useDispatch } from 'react-redux';
import { ClipLoader } from 'react-spinners';
import { updateCategories } from 'redux/actions/settings';
import { useTranslation } from 'react-i18next';
import { updateDefaultCommands } from 'redux/actions/settings';
import { addNotification } from 'redux/actions/notifications';

function createData(en, am, ru) {
    return { en, am, ru };
}


function Defaults({ settings, loading }) {
    const dispatch = useDispatch()
    const { t } = useTranslation()
    const [open, setOpen] = useState([8])
    const [inEdit, setInEdit] = useState(null)
    const [categoriesChannels, setCategoriesChannels] = useState(settings?.categoriesChannels)
    const [categoriesVOD, setCategoriesVOD] = useState(settings?.categoriesVOD)
    const refEn = useRef(null)
    const refAm = useRef(null)
    const refRu = useRef(null)

    useEffect(() => {
        setCategoriesChannels(settings?.categoriesChannels)
    }, [settings?.categoriesChannels])

    useEffect(() => {
        setCategoriesVOD(settings?.categoriesVOD)
    }, [settings?.categoriesVOD])

    const handleClick = (val) => {
        if (open.includes(val)) {
            setOpen(prev => prev.filter(item => item !== val))
        } else {
            setOpen(prev => [...prev, val])
        }
    }

    const cahnnelsRows = useMemo(() => categoriesChannels.map(item => createData(item.en, item.am, item.ru)), [categoriesChannels])
    const VodRows = useMemo(() => categoriesVOD.map(item => createData(item.en, item.am, item.ru)), [categoriesVOD])

    const handleEdit = (item, i, data) => {
        setInEdit(`${data}_${i}`)
    }

    const handleSave = async (item, i, type) => {
        const row = { am: refAm.current.value, ru: refRu.current.value, en: refEn.current.value }
        const data = type === 'c' ? [...categoriesChannels] : [...categoriesVOD]
        data.splice(i, 1, { ...data[i], ...row })
        const body = {
            endpoint: type === 'c' ? 'channels' : 'vod',
            key: type === 'c' ? 'categoriesChannels' : 'categoriesVOD',
            data: data
        }
        const onSuccess = () => { setInEdit(null) }
        await dispatch(updateCategories(body, onSuccess))

    }

    const handleCancel = (type) => {
        const data = type === 'c' ? categoriesChannels : categoriesVOD
        const setter = type === 'c' ? setCategoriesChannels : setCategoriesVOD
        if (!data[0].am && !data[0].en && !data[0].ru) {
            setter(data.slice(1))
        }
        setInEdit(null)
    }

    const handleDelete = async (item, i, type) => {
        const data = type === 'c' ? [...categoriesChannels] : [...categoriesVOD]
        data.splice(i, 1)
        const body = {
            endpoint: type === 'c' ? 'channels' : 'vod',
            key: type === 'c' ? 'categoriesChannels' : 'categoriesVOD',
            data: data
        }
        const onSuccess = () => { setInEdit(null) }
        await dispatch(updateCategories(body, onSuccess))
    }

    const handleAddRow = (type) => {
        const data = type === 'c' ? categoriesChannels : categoriesVOD
        if (!data[0].am && !data[0].en && !data[0].ru) {
            return
        }
        const setter = type === 'c' ? setCategoriesChannels : setCategoriesVOD
        setter([{ id: v4(), am: '', ru: '', en: '' }, ...data,])
        setInEdit(`${type}_0`)

    }

    const [commandT, setCommandT] = useState('')
    const [commandA, setCommandA] = useState('')
    const [commandS, setCommandS] = useState('')

    useEffect(() => {
        if(settings.defaults){
            let ffmpegCommand = settings.defaults.transcoderCommand
            const arr = ffmpegCommand.split('\n').filter(item => item.trim())
            setCommandT(arr.join('\n'))
    
            let ffmpegCommandA = settings.defaults.archiverCommand
            const arrA = ffmpegCommandA.split('\n').filter(item => item.trim())
            setCommandA(arrA.join('\n'))
    
            let ffmpegCommandS = settings.defaults.streamerCommand
            const arrS = ffmpegCommandS.split('\n').filter(item => item.trim())
            setCommandS(arrS.join('\n'))
        }
    }, [settings.defaults])



    const handleRemoveLine = (i, setter, value) => {
        const arr = value.split('\n')
        arr.splice(i, 1)
        setter(arr.join('\n'))
    }

    const handleAddLine = (i, setter, value) => {
        const arr = value.split('\n')
        arr.splice(i + 1, 0, ' ')
        setter(arr.join('\n'))
    }

    const handleCommandChange = (val, i, setter, value) => {
        const arr = value.split('\n')
        arr[i] = val
        setter(arr.join('\n'))
    }

    const willDisable = (i, value) => {
        const arr = value.split('\n')
        const disabledCommands = [
            "-vf scale_npp='{{RESOLUTION}}'",
            `-i '{{INPUT}}' -y`,
            '-f hls {{OUTPUT}}',
            '-i {{INPUT}}',
            '-metadata service_name="{{NAME}}"',
            '-hls_time {{HLSTIME}}',
            '-f hls {{OUTPUT}}',
            '{{INPUTS}}',
            '{{MAPPING}}',
            '{{BITRATES}}',
            '{{AUDIO}}',
            '-master_pl_name "master.m3u8"',
            '-f hls -var_stream_map "{{STREAM_MAPPING}}"',

        ]
        const disabledIndexes = [0]
        return (disabledIndexes.indexOf(i) > -1) || (disabledCommands.includes(arr[i]?.trim()))
    }


    const saveDefaultCommands = () => {
        dispatch(updateDefaultCommands({data: {
            transcoderCommand: commandT,
            archiverCommand: commandA,
            streamerCommand: commandS,
        }}))
        
    }


    return (
        <List sx={{ position: 'relative' }} component={Paper}>
            {loading && <div className='loader' ><ClipLoader /></div>}
            <ListItemButton sx={{ border: '1px solid lightgray', color: '#000' }} focusVisibleClassName={styles.focused} onClick={() => handleClick('t')}>
                <ListItemText primary={t("Transcoder")} />
                {open.includes('t') ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={open.includes('t')} timeout="auto" unmountOnExit>
                <MDBox className={styles.inputsCont}>
                    {commandT.split('\n').map((item, i) => (
                        <div className={styles.inputCont}>
                            <div className={styles.toolsLeft} >
                                <button disabled={willDisable(i, commandT)} onClick={() => { handleRemoveLine(i, setCommandT, commandT) }} className={styles.delete}><CloseRounded /></button>
                                <span className={styles.index}>{i + 1}</span>
                                <button onClick={() => { handleAddLine(i, setCommandT, commandT) }} className={styles.add}><AddOutlined /></button>
                            </div>
                            <input disabled={willDisable(i, commandT)} key={item} className={styles.input} defaultValue={item} onBlur={(e) => handleCommandChange(e.target.value, i, setCommandT, commandT)} />
                        </div>
                    ))}
                </MDBox>
            </Collapse>

            <ListItemButton sx={{ border: '1px solid lightgray', color: '#000' }} onClick={() => handleClick('a')}>

                <ListItemText primary={t("Archiver")} />
                {open.includes('a') ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={open.includes('a')} timeout="auto" unmountOnExit>
                <MDBox className={styles.inputsCont}>
                    {commandA.split('\n').map((item, i) => (
                        <div className={styles.inputCont}>
                            <div className={styles.toolsLeft} >
                                <button disabled={willDisable(i, commandA)} onClick={() => { handleRemoveLine(i, setCommandA, commandA) }} className={styles.delete}><CloseRounded /></button>
                                <span className={styles.index}>{i + 1}</span>
                                <button onClick={() => { handleAddLine(i, setCommandA, commandA) }} className={styles.add}><AddOutlined /></button>
                            </div>
                            <input disabled={willDisable(i, commandA)} key={item} className={styles.input} defaultValue={item} onBlur={(e) => handleCommandChange(e.target.value, i, setCommandA, commandA)} />
                        </div>
                    ))}
                </MDBox>
            </Collapse>
            <ListItemButton sx={{ border: '1px solid lightgray', color: '#000' }} onClick={() => handleClick('s')}>

                <ListItemText primary={t("Streamer")} />
                {open.includes('s') ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={open.includes('s')} timeout="auto" unmountOnExit>
                <MDBox className={styles.inputsCont}>
                    {commandS.split('\n').map((item, i) => (
                        <div className={styles.inputCont}>
                            <div className={styles.toolsLeft} >
                                <button disabled={willDisable(i, commandS)} onClick={() => { handleRemoveLine(i, setCommandS, commandS) }} className={styles.delete}><CloseRounded /></button>
                                <span className={styles.index}>{i + 1}</span>
                                <button onClick={() => { handleAddLine(i, setCommandS, commandS) }} className={styles.add}><AddOutlined /></button>
                            </div>
                            <input disabled={willDisable(i, commandS)} key={item} className={styles.input} defaultValue={item} onBlur={(e) => handleCommandChange(e.target.value, i, setCommandS, commandS)} />
                        </div>
                    ))}
                </MDBox>
            </Collapse>
            <MDButton
                onClick={saveDefaultCommands}
                variant={'gradient'}
                sx={{float: 'right', margin: '8px 0px'}}
                color="info"
            >
                {t("Save")}
            </MDButton>
        </List>
    )
}

export default Defaults