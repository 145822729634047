import { FormControlLabel, FormGroup, FormHelperText, Grid, Switch } from "@mui/material";
import MDBox from "components/MDBox";
import MDDialog from "components/MDDialog";
import MDInput from "components/MDInput";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import * as yup from "yup";
import { getTariffs } from "redux/actions/tariffs";
import { DropzoneDialog } from "material-ui-dropzone";
import MDSelect from "components/MDSelect";
import Tooltip from "@mui/material/Tooltip";
import { useTranslation } from "react-i18next";
import { usePrevious } from "utils/customHooks";
import styles from './styles.module.css'
import { AddOutlined, CloseRounded } from "@mui/icons-material";
import MDButton from "components/MDButton";
import MDSelectMui from "components/MDSelectMui";




export default function CreateArchive({ openModal, setOpenModal, archiverInfo, handleCancel, handleConfirm }) {
    const uniqueNameError = 'Name must be unique for a Archiver'
    const uniqueInputError = 'Input must be unique for a Archiver'

    const serversList = useSelector(store => store?.archivers?.list)
    const initSelectedServer = {
        host: archiverInfo?.host || serversList[0]?.url,
        id: archiverInfo?.id || serversList[0]?._id,
        _id: archiverInfo?.id || serversList[0]?._id,
        name: archiverInfo?.name || serversList[0]?.name
    }
    const [selectedServer, setSelectedServer] = useState(initSelectedServer)
    const server = useSelector(store => store?.archiver)
    const archives = server?.list?.filter(item => item.archiverId === (selectedServer?._id || archiverInfo?.id))
    const handleChangeSelectedServer = (_, server) => {
        setSelectedServer({
            host: server.host,
            id: server._id,
            _id: server._id,
            name: server.name
        })
    }

    const { settings } = useSelector(store => store.settings)
    const schema = yup.object({
        name: yup.string().required('field is required')
            .test('test', uniqueNameError, function (value) {
                if (openModal?.isUpdate) {
                    return archives.filter(item => item._id !== openModal?.modalValues?._id).every(item => item.name !== value)
                }
                return archives.every(item => item.name !== value)
            }),
        input: yup.string().required('field is required')
            .test('test', uniqueInputError, function (value) {
                if (openModal?.isUpdate) {
                    return archives.filter(item => item._id !== openModal?.modalValues?._id).every(item => item.input !== value)
                }
                return archives.every(item => item.input !== value)
            }),
        command: yup.string().required('field is required'),
        duration: yup.number().required('field is required'),
        hlsTime: yup.number().min(0, "Minimum atleast 0").max(20, "Allowed maximum is 20").required('field is required'),
    });



    const { t } = useTranslation()
    const [withCmd, setWithCmd] = useState(false)
    const dispatch = useDispatch();
    const initValues = {
        name: "ff_",
        input: "",
        command: "",
        duration: 48,
        hlsTime: 10,
        archiverInfo: selectedServer,
    };

    const { values, handleChange, touched, errors, handleSubmit, setFieldValue } = useFormik({
        initialValues: initValues,
        validationSchema: schema,
        onSubmit: (values) => {
            if (withCmd) {
                const arr = values.command
                    .split('\n')
                    .map(item => item.trim())
                    .filter(item => item)
                const data = { ...values, withCmd, command: arr.join('\n'), archiverInfo: selectedServer };
                handleConfirm ? handleConfirm(data) : openModal?.onSave(data);
            } else {
                const data = { ...values, withCmd, archiverInfo: selectedServer };
                handleConfirm ? handleConfirm(data) : openModal?.onSave(data);
            }
            setOpenModal({ bool: false });
        },
    });

    // useEffect(() => {
    //     if (openModal?.modalValues) {
    //         Object.keys(initValues).forEach((key) => {
    //             setFieldValue(key, openModal?.modalValues?.[key] ?? '');
    //         });
    //     }
    // }, [openModal?.modalValues]);
    const prevName = usePrevious(values?.name)
    const prevInput = usePrevious(values?.input)
    const prevHlsTime = usePrevious(values?.hlsTime)


    const generateArchiverCommand = (defaultCommand, values) => {
        const keywordsMapping = [
            { value: values.name, keyword: '{{Name}}' },
            { value: values.name, keyword: '{{NAME}}' },
            { value: values.input, keyword: '{{INPUT}}' },
            { value: values.hlsTime, keyword: '{{HLSTIME}}' },
            { value: `/index.m3u8`, keyword: '{{OUTPUT}}' },
        ]

        let command = defaultCommand
        keywordsMapping.forEach((item) => {
            command = command.replaceAll(item.keyword, item.value)
        })
        command = command.split('\n').filter(item => item.trim()).join('\n')
        return command
    }


    useEffect(() => {
        if (withCmd) {
            let command = values.command
            if (prevName) { command = command.replaceAll(prevName, values?.name) }
            if (prevInput) command = command.replaceAll(prevInput, values?.input)
            if (prevHlsTime) command = command.replaceAll(`-hls_time ${prevHlsTime}`, `-hls_time ${values.hlsTime}`)
            setFieldValue('command', command)
        } else {
            const command = generateArchiverCommand(settings.defaults.archiverCommand, values)
            setFieldValue('command', command)
        }

    }, [values?.name, values?.input, withCmd, values?.hlsTime])

    useEffect(() => {
        const initValues = {
            name: "",
            input: "",
            duration: 48,
            hlsTime: 10,
            command: "",
        };
        Object.keys(initValues).forEach((key) => {
            setFieldValue(key, openModal?.modalValues?.[key] ?? initValues[key]);
        });
        setWithCmd(openModal?.modalValues?.withCmd ?? false)

    }, [openModal?.bool]);



    const handleRemoveLine = (i) => {
        const arr = values?.command.split('\n')
        arr.splice(i, 1)
        setFieldValue('command', arr.join('\n'))
    }

    const handleAddLine = (i) => {
        const arr = values?.command.split('\n')
        arr.splice(i + 1, 0, ' ')
        setFieldValue('command', arr.join('\n'))
    }

    const handleCommandChange = (val, i) => {
        const arr = values?.command.split('\n')
        arr[i] = val
        setFieldValue('command', arr.join('\n'))
    }

    const willDisable = (i) => {
        const arr = values?.command.split('\n')
        const disabledCommands = [
            `-i ${values?.input}`,
            `-f hls /index.m3u8`,
            `-hls_time ${values.hlsTime}`,
            `-metadata service_name="${values.name}"`
        ]
        const disabledIndexes = [0]
        return disabledIndexes.includes(i) || (disabledCommands.includes(arr[i]?.trim()))
    }



    return (
        <MDBox>
            <Grid rowSpacing={2} container>
                <Grid item xs={3}>
                    {t("Archiver")}:
                </Grid>
                <Grid item xs={9}>
                    <MDSelectMui
                        value={selectedServer}
                        error={!selectedServer}
                        onChange={handleChangeSelectedServer}
                        labelKey={"name"}
                        valueKey="_id"
                        options={serversList}

                    />
                </Grid>
                <Grid item xs={3}>
                    {t('Name')}:
                </Grid>
                <Grid item xs={9}>
                    <MDInput
                        fullWidth
                        value={values.name}
                        id="name"
                        name="name"
                        error={touched.name && errors.name}
                        helperText={touched.name && errors.name}
                        onChange={handleChange}
                    />
                </Grid>
                <Grid item xs={3}>
                    {t('Source')}:
                </Grid>
                <Grid item xs={9}>
                    <MDInput
                        fullWidth
                        value={values.input}
                        id="input"
                        name="input"
                        error={touched.input && errors.input}
                        helperText={touched.input && errors.input}
                        onChange={handleChange}
                    />
                </Grid>
                <Grid item xs={3}>
                    {t('archive_duration_(hours)')}:
                </Grid>
                <Grid item xs={9}>
                    <MDInput
                        fullWidth
                        value={values.duration}
                        id="duration"
                        name="duration"
                        type="number"
                        error={touched.duration && errors.duration}
                        helperText={touched.duration && errors.duration}
                        onChange={handleChange}
                    />
                </Grid>
                <Grid item xs={3}>
                    {t('HLS_Time_(seconds)')}
                </Grid>
                <Grid item xs={9}>
                    <MDInput
                        fullWidth
                        value={values.hlsTime}
                        id="hlsTime"
                        name="hlsTime"
                        type="number"
                        error={touched.hlsTime && errors.hlsTime}
                        helperText={touched.hlsTime && errors.hlsTime}
                        onChange={handleChange}
                    />
                </Grid>
                <Grid item xs={3}>
                    {t('FFmpeg')}:
                </Grid>
                <Grid item xs={6}>
                    <FormGroup>
                        <FormControlLabel
                            control={
                                <Switch color="primary" checked={withCmd} onChange={(e) => setWithCmd(e.target.checked)} />
                            }
                            label={t("Advanced_mode")}
                            labelPlacement="end"
                            checked={withCmd}
                        />
                        {withCmd && <FormHelperText error filled>{t('own_risk_TEXT')} <br /> <strong> *{t('Important')}* </strong> <br /> {t('own_risk_suggest')}</FormHelperText>}
                    </FormGroup>
                </Grid>
                <Grid item xs={12}>
                    {withCmd ?

                        <MDBox className={styles.inputsCont}>
                            {values?.command.split('\n').map((item, i) => (
                                <div className={styles.inputCont}>
                                    <div className={styles.toolsLeft} >
                                        <button disabled={willDisable(i)} onClick={() => { handleRemoveLine(i) }} className={styles.delete}><CloseRounded /></button>
                                        <span className={styles.index}>{i + 1}</span>
                                        <button onClick={() => { handleAddLine(i) }} className={styles.add}><AddOutlined /></button>
                                    </div>
                                    <input disabled={willDisable(i)} key={item} className={styles.input} defaultValue={item} onBlur={(e) => handleCommandChange(e.target.value, i)} />
                                </div>
                            ))}

                        </MDBox>

                        :
                        <textarea
                            disabled
                            rows={15}
                            style={{ width: '100%' }}
                            value={values.command}
                            id="command"
                            name="command"
                            error={touched.command && errors.command}
                            helperText={touched.command && errors.command}
                            onChange={handleChange}
                        />}
                </Grid>
            </Grid>
            <MDBox sx={{ display: "flex", justifyContent: "flex-end", gap: "10px" }}>
                {(handleCancel || setOpenModal) && <MDButton variant="outlined" color="info" onClick={() => handleCancel ? handleCancel() : setOpenModal?.({ bool: false })}>
                    {t('Close')}
                </MDButton>}
                <MDButton variant="gradient" color="info" onClick={handleSubmit}>
                    {openModal?.isUpdate ? t('archives.update') : t('archives.create')}
                </MDButton>
            </MDBox>
        </MDBox>
    );
}
