import { Card, Grid, IconButton } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import DataTable from "examples/Tables/DataTable";
import React, { useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import { Delete, Edit } from "@mui/icons-material";
import ModalSubUser from '../SubUserModal';
import { updateSubuser, createSubuser } from "redux/actions/customers";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { deleteSubuser } from "redux/actions/customers";
import WithPermission from "components/withPermission/WithPermission";
import { AddSubuserPermission } from "pages/settings/Admins/constants";
import { checkPermission } from "utils/checkPermission";
import { DeleteSubuserPermission } from "pages/settings/Admins/constants";
import moment from "moment";
import { UpdateSubuserPermission } from "pages/settings/Admins/constants";

export default function Subusers({ data }) {

    const dispatch = useDispatch()
    const { t } = useTranslation();
    const [openSubuserModal, setOpenSubuserModal] = useState({ bool: false });
    const customers = useSelector((store) => store?.customer?.list);
    const currentUser = customers.find(item=>item._id === data.admin._id)
    
    const loading = useSelector((store) => store?.customer?.loading);

    const handleCreateSubuser = function (params) {
        const formData = new FormData();
        for (let key in params) {
            if (params[key] instanceof File) {
                formData.append(key, params[key], params[key].name);
            } else {
                formData.append(key, params[key]);
            }
        }
        dispatch(createSubuser(formData, data.admin._id));
    }

    const handleUpdateSubuser = function (params) {
        dispatch(updateSubuser({ ...this, ...params }, data.admin._id));
    }


    function handleSubuserModal(item, isUpdate) {
        setOpenSubuserModal({
            bool: true,
            onSave: isUpdate ? handleUpdateSubuser.bind(item) : handleCreateSubuser,
            modalValues: item,
        });
    }

    const handleDeleteSubuser = (item) => {
        dispatch(deleteSubuser(item, data.admin._id))
    }


    const columns = [
        { Header: t("Name"), accessor: "Fullname", align: "left" },
        { Header: t("Favorite_channels"), accessor: "FavoriteChannels", align: "left" },
        { Header: t("Language"), accessor: "lang", align: "left" },
        { Header: t("Last_online_date"), accessor: "lastOnline", align: "left" },
        { Header: t("action"), accessor: "action", align: "center" },
    ];

    const rows = currentUser?.subusers?.map((item) => ({

        Fullname: `${item?.name}`,
        FavoriteChannels: `${item?.favoriteChannels?.length || 0} ${t('channels.header')}`,
        lang: t(item?.language) || t('en'),
        lastOnline: moment(item.lastLoginTime).format('YYYY/MM/DD HH:mm:ss'),
        action: (
            <MDBox>
                <WithPermission disable={true} permissionsList={[UpdateSubuserPermission]} permissionKey={'customers'} >
                    <IconButton
                        disabled={!checkPermission([UpdateSubuserPermission], 'customers')}
                        onClick={() => {
                            handleSubuserModal(item, true);
                        }}
                    >
                        <Edit />
                    </IconButton>
                </WithPermission>
                <WithPermission disable={true} permissionsList={[DeleteSubuserPermission]} permissionKey={'customers'} >
                    <IconButton
                        disabled={!checkPermission([DeleteSubuserPermission], 'customers')}
                        onClick={() => {
                            handleDeleteSubuser(item);
                        }}
                    >
                        <Delete />
                    </IconButton>
                </WithPermission>
            </MDBox>
        ),
    }));

    return (
        <MDBox >
            <Grid container spacing={0}>
                <Grid item xs={12}>
                    <Card>
                        <MDBox
                            pl={2}
                            pr={2}
                            sx={{ display: "flex", justifyContent: "space-between", alignItems: 'center' }}
                        >
                            <MDTypography variant="body2" >
                                {t("customers.header")} ({data?.list?.length})
                            </MDTypography>
                            <WithPermission disable={true} permissionsList={[AddSubuserPermission]} permissionKey={'customers'} >
                                <MDButton disabled={!checkPermission([AddSubuserPermission], 'customers')} onClick={handleSubuserModal} withIcon={<AddIcon />} loading={loading}>
                                    {t("customers.addNew")}
                                </MDButton>
                            </WithPermission>
                        </MDBox>
                        <MDBox sx={{ height: '275px', overflow: 'auto' }}>
                            {rows && (
                                <DataTable
                                    table={{ columns, rows }}
                                    isSorted={false}
                                    showTotalEntries={false}
                                    canSearch={false}
                                    entriesPerPageBox={false}
                                />
                            )}
                        </MDBox>
                    </Card>
                </Grid>
            </Grid>
            <ModalSubUser openModal={openSubuserModal} setOpenModal={setOpenSubuserModal} />
        </MDBox>
    )
}
