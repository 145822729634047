import MDButton from 'components/MDButton'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import NotificationsList from './NotificationsList'

export default function Notifications() {
    const {t} = useTranslation()
    const [openList, setOpenList] = useState({ bool: false })
  return (
    <div>
        <MDButton onClick={()=>setOpenList({bool: true})} variant="outlined" color="info" >{t("notifications")}</MDButton>
        <NotificationsList isOpen={openList} setOpenModal={setOpenList} />
        
    </div>
  )
}
